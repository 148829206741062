import { emptyPromise, fetchWithErrHandler } from '../../helpers';

import { categoriesState } from '../../helpers/Atoms';
import { useCallback } from 'react';
import { useRecoilState } from 'recoil';

export function useFetchCategories() {
  const [categories, setCategories] = useRecoilState(categoriesState);

  const fetchCategories = useCallback(() => {
    if (!categories.isFetching) {
      return emptyPromise({});
    }

    return fetchWithErrHandler('async/categories.php').then(
      (json) => {
        const data = {};
        Object.keys(json).forEach((catID) => {
          const cat = json[catID];
          cat.prodIDs = cat.prodIDs.map((id) => parseInt(id, 10));
          cat.inNav = !!cat.inNav;
          cat.id = parseInt(cat.id, 10);
          cat.section = parseInt(cat.section, 10);
          cat.parent = parseInt(cat.parent, 10);
          cat.requestable = cat.requestable === '1';
          cat.highlighted = cat.highlighted === '1';
          data[parseInt(catID, 10)] = cat;
        });
        setCategories({
          ...categories,
          isFetching: false,
          data,
        });
      },
      (_error) => {
        setCategories({
          ...categories,
          isFetching: false,
          error: 'Invalid JSON returned',
        });
      },
    );
  }, [categories, setCategories]);

  return [categories.isFetching, fetchCategories];
}
