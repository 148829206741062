// @flow

import { fetchWithErrHandler, pageState } from '../../helpers';

import type { TPage } from '../../types';
import { useEffect } from 'react';
import { useRecoilState } from 'recoil';

export function usePage(id: ?number): {
  page: TPage,
  isFetching: boolean,
  error: ?null,
} {
  const [page, setPage] = useRecoilState(pageState(id));

  useEffect(() => {
    if (page.fetchStatus === 'NONE' && id !== 0 && id != null) {
      setPage({ ...page, fetchStatus: 'FETCHING' });
      fetchWithErrHandler('async/page.php?id=' + id).then(
        (json) => {
          setPage({
            ...page,
            fetchStatus: 'FETCHED',
            data: json,
          });
        },
        (error) => {
          setPage({
            ...page,
            fetchStatus: 'FETCHED',
            error,
          });
          console.log('An error occured.', error);
        },
      );
    }
  }, [page, id, setPage]);

  if (id == null) {
    return {
      page: {},
      isFetching: false,
      error: null,
    };
  }

  return {
    page: page.data,
    isFetching: page.data.id !== 0 && page.fetchStatus !== 'FETCHED',
    error: page.error,
  };
}
