// @flow

// Do this once before any other code in your app
// import 'babel-polyfill'
import 'core-js/stable';
import 'regenerator-runtime/runtime';

import * as Sentry from '@sentry/react';

import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from 'react-router-dom';

import { BrowserTracing } from '@sentry/tracing';
import React from 'react';
import Root from './shop/components/Root';
import { render } from 'react-dom';
import { useEffect } from 'react';

function getEnvironment(): string {
  switch (window.location.hostname) {
    case 'localhost':
    case '127.0.0.1':
    case '':
      return 'localhost';
    case 'dev.londoncigcard.co.uk':
      return 'dev';
    case 'www.londoncigcard.co.uk':
      return 'production';
    default:
      return 'unknown';
  }
}

Sentry.init({
  dsn: 'https://f89b3c8dc6e64332b907095c5f2341aa@o4504416834027520.ingest.sentry.io/4504416840318977',
  environment: getEnvironment(),
  integrations: [
    new BrowserTracing({
      routingInstrumentation: Sentry.reactRouterV6Instrumentation(
        useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes,
      ),
    }),
  ],
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
  // beforeSend(event, _hint) {
  //   // Check if it is an exception, and if so, show the report dialog
  //   if (event.exception) {
  //     Sentry.showReportDialog({ eventId: event.event_id });
  //   }
  //   return event;
  // },
});

render(<Root />, window.document.getElementById('root'));
