import { useCallback } from 'react';
import { useCustomer } from './';

export function useRegisterCustomer(): void {
  const [customer, setCustomer] = useCustomer();

  return useCallback(() => {
    setCustomer({
      ...customer,
      isGuest: false,
    });
  }, [customer, setCustomer]);
}
