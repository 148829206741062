// @flow

import * as React from 'react';

import {
  Box,
  Flexbox,
  Loading,
  Page,
  PriceWithButton,
  RTEText,
} from './common';
import { useCatalogues, useFetchCatalogues } from '../hooks/catalogues';

import { PageHelpers } from '../helpers';
import type { TPageContent } from '../types';
import { useEffect } from 'react';

type ContainerProps = {};

function Valuing(props: ContainerProps) {
  const [, fetchCatalogues] = useFetchCatalogues();

  useEffect(() => {
    fetchCatalogues();
  }, [fetchCatalogues]);

  return (
    <Page {...props}>
      <ValuingContent />
    </Page>
  );
}

export default Valuing;

type Props = {
  content?: TPageContent,
};

function ValuingContent(props: Props) {
  const [catalogues] = useCatalogues();
  const [isFetching] = useFetchCatalogues();
  const { content } = props;
  const catalogue = catalogues.details;
  const catPriceContent =
    !isFetching && catalogue !== null ? (
      <PriceWithButton
        label="Price"
        stateKey={catalogue.id}
        price={catalogue.price}
        type="catalogue"
        hash={catalogue.hash}
      />
    ) : (
      <Loading />
    );

  return (
    <Box>
      <Flexbox flexDirection="column">
        <Flexbox element="section">
          <span>
            <RTEText content={PageHelpers.getContent(content, 'Heading')} />
          </span>
        </Flexbox>
        <Flexbox element="section">
          <span>
            <RTEText content={PageHelpers.getContent(content, 'Catalogue')} />
            {catPriceContent}
          </span>
        </Flexbox>
        <Flexbox element="section">
          <span>
            <RTEText
              content={PageHelpers.getContent(content, 'Other Content')}
            />
          </span>
        </Flexbox>
      </Flexbox>
    </Box>
  );
}
