import { useCallback } from 'react';
import { useCustomer } from './';

export function useSetCCCMembership(): void {
  const [customer, setCustomer] = useCustomer();

  return useCallback(() => {
    setCustomer({
      ...customer,
      isCCC: !customer.isCCC,
    });
  }, [customer, setCustomer]);
}
