// @flow

import * as React from 'react';

import { Box, Flexbox, Link } from '../common';

import ProductList from '../ProductList';
import { usePromotions } from '../../hooks/offers';

type Props = {
  id: number,
};

export default function PromotionHome(props: Props) {
  const { promotion, error } = usePromotions(props.id);

  return (
    <Flexbox flexDirection="column">
      <Flexbox>
        <Box highlight={true}>
          <span>{_getIntro()}</span>
        </Box>
      </Flexbox>
      {_getEndedMessage()}
      <Flexbox>
        <span>{_getProducts()}</span>
      </Flexbox>
    </Flexbox>
  );

  function _getIntro(): React.Node {
    let content;
    switch (props.id) {
      case 23:
        content = (
          <React.Fragment>
            <h1 className="smaller">
              Bargain Offer: Complete sets in mint condition!
            </h1>
            <p className="smaller">
              Big savings on catalogues prices, some under half price. Ideal for
              a starter collection or Christmas gifts just for &pound;2 &amp;
              &pound;3 a set
            </p>
          </React.Fragment>
        );
        break;
      case 1313:
        content = (
          <React.Fragment>
            <h1 className="smaller">Special Offers</h1>
            <p className="smaller">
              As you are a CCN subscriber you are eligible for the following
              further offers which expire at the end of the month. Thank you for
              your continued custom.
            </p>
          </React.Fragment>
        );
        break;
      default:
        content = (
          <React.Fragment>
            <h1 className="smaller">Special Offers</h1>
            <p className="smaller">
              As you are an e-mail newsletter subscriber or have placed an order
              via our website you are eligible for the following further offers
              which expire at the end of the month. Thank you for your continued
              custom.
            </p>
          </React.Fragment>
        );
        break;
    }
    return (
      <React.Fragment>
        <img
          src="/images/postfree.gif"
          className="fright"
          alt="Free Shipping"
        />
        {content}
        <p>
          <b>Post free to UK addresses</b> Europe and International Postage will
          be charged at cost. Orders dispatched to outside the European
          Community will have 15% deducted.
        </p>
      </React.Fragment>
    );
  }

  function _getEndedMessage(): React.Node {
    if (!error.length) {
      return null;
    }

    return (
      <Flexbox>
        <Box>
          <span>
            <h1 className="smaller">Offer Ended</h1>
            <p className="smaller">
              Unfortunately this offer has ended. But for our other special
              offers please <Link page="OFFERS">click here</Link>
            </p>
          </span>
        </Box>
      </Flexbox>
    );
  }

  function _getProducts(): React.Node {
    if (error.length) {
      return null;
    }

    return <ProductList prodIDs={promotion.prodIDs} />;
  }
}
