import { albumState } from '../../helpers/Atoms';
import { useCallback } from 'react';
import { useRecoilState } from 'recoil';

export function useAlbums() {
  const [albums, setAlbumState] = useRecoilState(albumState);

  const setAlbums = useCallback(
    (newAlbums: TAlbums) => {
      setAlbumState({
        ...albums,
        data: newAlbums,
      });
    },
    [albums, setAlbumState],
  );

  return [albums.data, setAlbums];
}
