import * as React from 'react';

import { getNIMonth, getNIURI } from '../../helpers';
import { useEffect, useMemo } from 'react';
import { useFetchNewIssues, useNewIssues } from '../../hooks/newissues';

import { Link } from '../common';

type Props = {
  useNextMonth: booelan,
};

export default function NewIssuesIntro(props: Props) {
  const getNewIssues = useNewIssues();
  const { getIsFetching, getHasFetched, fetchNewIssues } = useFetchNewIssues();

  const newIssueMonth = getNIMonth(props.useNextMonth);
  const isFetching = getIsFetching(newIssueMonth);
  const hasFetched = getHasFetched(newIssueMonth);

  const newissues = useMemo(
    () => getNewIssues(newIssueMonth),
    [getNewIssues, newIssueMonth],
  );

  useEffect(() => {
    if (!isFetching && !hasFetched) {
      fetchNewIssues(newIssueMonth);
    }
  }, [isFetching, hasFetched, fetchNewIssues, newIssueMonth]);

  if (newissues == null || isFetching) {
    return null;
  }

  return (
    <li>
      <b>New issues</b>: We have added a further{' '}
      <Link hrefOverride={getNIURI(newIssueMonth)}>
        {newissues.prodIDs.length} Series
      </Link>{' '}
      to our website.
    </li>
  );
}
