// @flow

import * as React from 'react';

import BooksHome from './BooksHome';
import { Page } from '../common';
import { useParams } from 'react-router-dom';

function Books() {
  const { title } = useParams();

  function getPageComponent(): React.Node {
    switch (title) {
      default:
        return <BooksHome />;
    }
  }

  return <Page>{getPageComponent()}</Page>;
}

export default Books;
