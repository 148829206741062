// @flow

import * as React from 'react';

import { AddToBasketButton, Button, Link } from './common';

import Flexbox from 'flexbox-react';
import { ProductContext } from '../hooks/products';
import ProductImage from './ProductPage/ProductImage';
import ProductName from './Product/ProductName';
import ProductPrice from './Product/ProductPrice';
import type { TProduct } from '../types';
import { getProdURI } from '../helpers';

type DefaultProps = {
  excludeBorder: boolean,
};

type Props = DefaultProps & {
  product: TProduct,
};

function Product(props: Props) {
  const { product, excludeBorder } = props;

  return (
    <ProductContext.Provider value={product}>
      <Flexbox
        className={
          'productListItem' + (excludeBorder === true ? 'noBorder' : '')
        }
        flexDirection="row"
      >
        <Flexbox
          style={{
            minWidth: '170px',
            height: '170px',
            marginRight: '12px',
            textAlign: 'center',
            display: 'block',
          }}
        >
          <ProductImage />
        </Flexbox>
        <Flexbox flexDirection="column">
          <Flexbox element="header">
            <ProductName />
          </Flexbox>
          <Flexbox style={{ marginTop: '4px' }}>
            {product.issuer} - Set of {product.noinset}
          </Flexbox>
          <Flexbox style={{ marginTop: '4px' }}>
            <ProductPrice />
          </Flexbox>
          <Flexbox style={{ marginTop: '8px' }}>
            <AddToBasketButton
              type="product"
              stateKey={product.id}
              hash={product.hash}
            />
          </Flexbox>
          <Flexbox style={{ marginTop: '8px' }}>
            <Link hrefOverride={getProdURI(product.id, product.name)}>
              <Button label="More Info" />
            </Link>
          </Flexbox>
        </Flexbox>
      </Flexbox>
    </ProductContext.Provider>
  );
}

Product.defaultProps = {
  excludeBorder: false,
};

export default Product;
