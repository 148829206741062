// @flow

import * as React from 'react';

import { Box, Flexbox, Link } from '../common';

import { getNIURI } from '../../helpers/uris';

const date = new Date();
let startYear = date.getFullYear();
const curMonth = parseInt(date.getMonth(), 10) + 1; // 0 indexed
if (curMonth === 12) {
  startYear = startYear + 1;
}
let YEARS = [];
for (let i = startYear; i >= 1999; i--) {
  YEARS.push(i);
}
const MONTHS_GROUPS = [
  [
    { numeric: 1, name: 'January' },
    { numeric: 2, name: 'February' },
    { numeric: 3, name: 'March' },
    { numeric: 4, name: 'April' },
  ],
  [
    { numeric: 5, name: 'May' },
    { numeric: 6, name: 'June' },
    { numeric: 7, name: 'July' },
    { numeric: 8, name: 'August' },
  ],
  [
    { numeric: 9, name: 'September' },
    { numeric: 10, name: 'October' },
    { numeric: 11, name: 'November' },
    { numeric: 12, name: 'December' },
  ],
];

type Props = {};

export default class NewIssuesHome extends React.Component<Props> {
  static props: Props;

  render(): React.Node {
    return (
      <Flexbox
        flexDirection="column"
        element="section"
        style={{ width: '100%' }}
      >
        <Box highlight={true}>
          <Flexbox
            flexDirection="column"
            element="section"
            style={{ width: '100%' }}
          >
            <Flexbox flexDirection="row">
              <h1 className="smaller">Previous New Issues</h1>
            </Flexbox>
            <Flexbox flexDirection="row">
              Please select a month from the list below.
            </Flexbox>
          </Flexbox>
        </Box>
        {this._getMonths()}
      </Flexbox>
    );
  }

  _getMonths(): React.Node {
    const date = new Date();
    const curYear = date.getFullYear();
    const curDate = parseInt(date.getDate(), 10);
    const curMonthPlus1 = parseInt(date.getMonth(), 10) + 2; // 0 indexed
    return (
      <Box>
        <Flexbox
          flexDirection="column"
          element="section"
          style={{ width: '100%' }}
        >
          {YEARS.map((year) => {
            return (
              <Flexbox key={year} flexDirection="row">
                <table id="prodlist" width="500">
                  <thead>
                    <tr>
                      <td colSpan="4">{year}</td>
                    </tr>
                  </thead>
                  <tbody>
                    {MONTHS_GROUPS.map((months) => {
                      return (
                        <tr key={year + months[0].name}>
                          {months.map((month) => {
                            if (
                              year === startYear &&
                              curMonthPlus1 === 13 &&
                              month.name !== 'January'
                            ) {
                              return null;
                            }
                            if (year === curYear) {
                              if (
                                curMonthPlus1 < month.numeric ||
                                (curMonthPlus1 === month.numeric &&
                                  curDate <= 20)
                              ) {
                                return null;
                              }
                            }
                            if (year === 1999 && month.numeric <= 8) {
                              return null;
                            }
                            const twoDigitYear = year
                              .toString()
                              .substring(2, 4);
                            const paddedMonth = month.numeric
                              .toString()
                              .padStart(2, '0');
                            const monthforLink =
                              paddedMonth + '-' + twoDigitYear;
                            return (
                              <td
                                key={monthforLink}
                                style={{ textAlign: 'center' }}
                              >
                                <Link hrefOverride={getNIURI(monthforLink)}>
                                  {month.name}
                                </Link>
                              </td>
                            );
                          })}
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </Flexbox>
            );
          })}
        </Flexbox>
      </Box>
    );
  }
}
