// @flow

import * as React from 'react';

import { Box, Flexbox, Form, Link } from '../common';
import { useBasketHelper, useResetCart } from '../../hooks/basket';

import AnalyticsHelper from '../../helpers/AnalyticsHelper';
import type { TPageContent } from '../../types/page';
import { getFetchURI } from '../../helpers';
import { useCustomerHelper } from '../../hooks/customer';
import { useEffect } from 'react';

type Props = {
  content?: TPageContent,
  setSubscriber: Function,
  registerCustomer: Function,
};

const analytics = new AnalyticsHelper();

export default function BasketSuccess(props: Props) {
  const basketHelper = useBasketHelper();
  const customerHelper = useCustomerHelper();
  const resetBasket = useResetCart();

  useEffect(() => {
    analytics.conversion();
    if (basketHelper.getItemCount() > 0) {
      resetBasket();
    }
  }, [basketHelper, resetBasket]);

  return (
    <Flexbox flexDirection="column">
      <Flexbox>
        <Box highlight={true}>
          <span style={{ width: '100%' }}>
            <h1>Order Success #{basketHelper.getOrderID()}</h1>
            <p>
              Thank you for your order, you should shortly receive a
              confirmation email with the details of your order.{' '}
            </p>
          </span>
        </Box>
      </Flexbox>
      {_getRegistrationIfGuest()}
      {_getNewsletterSignUp()}
    </Flexbox>
  );

  function _getRegistrationIfGuest(): React.Node {
    if (
      !customerHelper.isCustomer() ||
      !customerHelper.isGuest() ||
      customerHelper.hasAccount()
    ) {
      return null;
    }

    return (
      <Flexbox>
        <Box>
          <Flexbox flexDirection="column">
            <Flexbox>
              <Form
                onValidate={_onValidate}
                buttonLabel="Create Account"
                honeyPot={true}
                success={_onSuccess}
                onSubmit={props.registerCustomer}
                uri={getFetchURI('async/register_customer.php')}
              >
                <span>
                  <h2>
                    Would you like a quicker and more convenient checkout
                    process?
                  </h2>
                  <p>
                    <strong>
                      If you would like us to save your details to make your
                      future purchases quicker please enter a password in the
                      box below and click 'Create Account':
                    </strong>
                  </p>
                  <p className="smaller">
                    <em>
                      We will send you an email with confirmation of your email
                      &amp; password for use when you next shop with us.
                    </em>
                  </p>
                  <dl class="medium">
                    <dd>
                      <label for="password">Password</label>
                      <input type="password" name="password" id="password" />
                    </dd>
                    <dd>
                      <label for="cpassword">Confirm Password</label>
                      <input type="password" name="cpassword" id="cpassword" />
                    </dd>
                    <dd>
                      <label for="email">Email</label>
                      {customerHelper.getEmail()}
                    </dd>
                  </dl>
                  <br />
                </span>
              </Form>
            </Flexbox>
            <Flexbox>
              <div style={{ marginTop: '8px' }}>
                <b>
                  If don't want to create an account then{' '}
                  <Link page="HOME">click here</Link> to get back to the{' '}
                  <Link page="HOME">home page.</Link>
                </b>
              </div>
            </Flexbox>
          </Flexbox>
        </Box>
      </Flexbox>
    );
  }

  function _onValidate(values: Object): Array<string> {
    let failures = [];
    if (values.password !== values.cpassword) {
      failures.push('Passwords do not match');
    }

    return failures;
  }

  function _onSuccess(): React.Node {
    analytics.trackSignUp('After Guest Checkout');
    return (
      <span>
        <h1>Account successfully created</h1>
        <p>Please check your email for confirmation of your login details.</p>
        <p>
          You can now continue to browse our website by{' '}
          <Link page="HOME">clicking here</Link>
        </p>
        <p>
          You can also visit your new <Link page="ACCOUNT">'My Account'</Link>{' '}
          page by <Link page="ACCOUNT">clicking here</Link>
        </p>
      </span>
    );
  }

  function _onEmailSuccess(): React.Node {
    return (
      <span>
        <h1>Newsletter Subscription Successfully</h1>
        <p>
          Thanks, you have now successfully registered for our email newsletter.
          You can now continue to browse our website by{' '}
          <Link page="HOME">clicking here</Link>
        </p>
      </span>
    );
  }

  function _getNewsletterSignUp(): React.Node {
    if (customerHelper.isSubscriber()) {
      return null;
    }

    return (
      <Flexbox>
        <Box>
          <Form
            buttonLabel="Register"
            honeyPot={true}
            success={_onEmailSuccess}
            onSubmit={props.setSubscriber}
            uri={getFetchURI('async/newsletter.php')}
          >
            <span>
              <h2>Would you like to be kept to date with our latest offers?</h2>
              <p>
                <strong>
                  Signup to our email newsletter for special offers and updates
                  to our site:
                </strong>
              </p>
              <dl class="medium">
                <dd>
                  <label for="password">Name: </label>
                  <strong>{customerHelper.getName()}</strong>
                </dd>
                <dd>
                  <label for="email">Email: </label>
                  <strong>{customerHelper.getEmail()}</strong>
                </dd>
              </dl>
              <br />
            </span>
          </Form>
        </Box>
      </Flexbox>
    );
  }
}
