// @flow

import * as React from 'react';

import { AddToBasketButton, Flexbox, Price } from './';

import type { TBasketItemType } from '../../types';

type Props = {
  label: string,
  stateKey: number | string,
  price: number,
  type: TBasketItemType,
  hash: string,
};

export default class PriceWithButton extends React.Component<Props> {
  render() {
    const { label, price, stateKey, hash, type } = this.props;
    return (
      <Flexbox style={{ marginBottom: '12px' }} flexDirection="column">
        <Flexbox style={{ marginBottom: '4px' }}>
          <span>
            {label}: <Price price={price} />{' '}
          </span>
        </Flexbox>
        <Flexbox>
          <AddToBasketButton stateKey={stateKey} type={type} hash={hash} />
        </Flexbox>
      </Flexbox>
    );
  }
}
