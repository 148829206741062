// @flow

import * as React from 'react';

import { getFetchURI, getProdURI } from '../../helpers';
import { useContext, useMemo } from 'react';

import { Link } from '../common';
import { ProductContext } from '../../hooks/products';

// import Img from 'react-image'

type Props = {
  imageOverride?: number,
  onMouseEnter?: Function,
  onMouseLeave?: Function,
  style?: { [string]: string },
  excludeLink?: boolean,
  caller?: string,
};

function ProductImage(props: Props) {
  // if (props.store.get('isDad') === true) {
  //   return (
  //     <img
  //       style={props.style}
  //       src="/admin/image/read/ianlaker.gif"
  //       alt="Happy Fathers day!"
  //     />
  //   );
  // }

  const { imageOverride, onMouseEnter, onMouseLeave, excludeLink } = props;
  const product = useContext(ProductContext);

  props.caller != null && console.log('ProductImage', product);

  const src = useMemo(() => {
    if (product == null) {
      return;
    }
    const loc: string = window.location.hostname.toString();
    if (loc.includes('localhost')) {
      return '/images/noimage.jpg';
    }
    return getFetchURI(
      'prod_images/' +
        (imageOverride != null ? imageOverride : product.image) +
        '.jpg',
    );
  }, [imageOverride, product]);

  const img = useMemo(() => {
    props.caller != null && console.log('img', product);
    if (product == null) {
      return null;
    }
    return (
      <img
        rel="schema:image"
        resource={src}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        style={props.style}
        src={src}
        alt={product.name}
      />
    );
  }, [src, onMouseEnter, onMouseLeave, props.style, product, props.caller]);

  const withLink = useMemo(() => {
    if (product == null) {
      return null;
    }
    return (
      <Link hrefOverride={getProdURI(product.id, product.name)}>{img}</Link>
    );
  }, [product, img]);

  if (excludeLink) {
    return img;
  } else {
    return withLink;
  }
}

export default ProductImage;
