// @flow

import * as React from 'react';

import { COUNTRY_US } from '../../helpers';
import { Form } from '../common';
import { useAddress } from '../../hooks/customer';
import { useConfigHelper } from '../../helpers/ConfigHelper';
import { useState } from 'react';

type Props = {
  id?: number,
  label: string,
  onSubmit?: Function,
};

function AddressForm({ id, label, onSubmit }: Props) {
  const [addressInternal, setAddressInternal] = useState(1);
  const { address, addAddress, updateAddress } = useAddress(id);
  const [countryID, setCountryID] = useState(
    address ? address.country_id : 183,
  );

  const [configHelper] = useConfigHelper();
  let line1 = address ? address.line1 : '';
  let line2 = address ? address.line2 : '';
  let town = address ? address.town : '';
  let county = address
    ? address.country_id === COUNTRY_US
      ? ''
      : address.county
    : '';
  let state = address
    ? address.country_id === COUNTRY_US
      ? address.county
      : ''
    : '';
  let postcode = address ? address.postcode : '';

  function _getCounty(county: string, state: string) {
    if (countryID !== COUNTRY_US) {
      return (
        <dd>
          <input
            type="text"
            name="address_county"
            id="address_county"
            title="Please enter your county"
            defaultValue={county}
          />
        </dd>
      );
    }
    return (
      <dd>
        <select
          name="address_county_us"
          id="address_county_us"
          title="Please enter your country"
          defaultValue={state}
        >
          <option value="">-- please select --</option>
          <option value="AL">Alabama</option>
          <option value="AK">Alaska</option>
          <option value="AZ">Arizona</option>
          <option value="AR">Arkansas</option>
          <option value="CA">California</option>
          <option value="CO">Colorado</option>
          <option value="CT">Connecticut</option>
          <option value="DE">Delaware</option>
          <option value="DC">District of Columbia</option>
          <option value="FL">Florida</option>
          <option value="GA">Georgia</option>
          <option value="HI">Hawaii</option>
          <option value="ID">Idaho</option>
          <option value="IL">Illinois</option>
          <option value="IN">Indiana</option>
          <option value="IA">Iowa</option>
          <option value="KS">Kansas</option>
          <option value="KY">Kentucky</option>
          <option value="LA">Louisiana</option>
          <option value="ME">Maine</option>
          <option value="MD">Maryland</option>
          <option value="MA">Massachusetts</option>
          <option value="MI">Michigan</option>
          <option value="MN">Minnesota</option>
          <option value="MS">Mississippi</option>
          <option value="MO">Missouri</option>
          <option value="MT">Montana</option>
          <option value="NE">Nebraska</option>
          <option value="NV">Nevada</option>
          <option value="NH">New Hampshire</option>
          <option value="NJ">New Jersey</option>
          <option value="NM">New Mexico</option>
          <option value="NY">New York</option>
          <option value="NC">North Carolina</option>
          <option value="ND">North Dakota</option>
          <option value="OH">Ohio</option>
          <option value="OK">Oklahoma</option>
          <option value="OR">Oregon</option>
          <option value="PA">Pennsylvania</option>
          <option value="RI">Rhode Island</option>
          <option value="SC">South Carolina</option>
          <option value="SD">South Dakota</option>
          <option value="TN">Tennessee</option>
          <option value="TX">Texas</option>
          <option value="UT">Utah</option>
          <option value="VT">Vermont</option>
          <option value="VA">Virginia</option>
          <option value="WA">Washington</option>
          <option value="WV">West Virginia</option>
          <option value="WI">Wisconsin</option>
          <option value="WY">Wyoming</option>
        </select>
      </dd>
    );
  }

  function _handleValidation(values: Object): Array<string> {
    let errors = [];

    if (!values.address_line1) {
      errors.push('Please enter Address Line 1');
    }

    if (!values.address_town) {
      errors.push('Please enter your town');
    }

    if (!values.address_postcode) {
      if (parseInt(values.address_country, 10) === COUNTRY_US) {
        errors.push('Please enter your ZIP Code');
      } else {
        errors.push('Please enter your Post Code');
      }
    }

    if (parseInt(values.address_country, 10) === COUNTRY_US) {
      if (!values.address_county_us) {
        errors.push('Please select your State');
      }
    } else if (!values.address_county) {
      errors.push('Please enter your County/State');
    }

    return errors;
  }

  function _onSubmit(values: Object) {
    if (id != null) {
      _updateAddress(values);
    } else {
      _addAddress(values);
    }
    onSubmit && onSubmit(values);
  }

  function _addAddress(values: Object): void {
    let addressPromise = addAddress({
      line1: values.address_line1,
      line2: values.address_line2,
      town: values.address_town,
      county:
        parseInt(values.address_country, 10) === COUNTRY_US
          ? values.address_county_us
          : values.address_county,
      country_id: parseInt(values.address_country, 10),
      postcode: values.address_postcode,
    });

    addressPromise.then((_) => {
      setAddressInternal(addressInternal + 1);
    });
  }

  function _updateAddress(values: Object): void {
    if (address == null) {
      return;
    }
    let addressPromise = updateAddress({
      id: address.id,
      line1: values.address_line1,
      line2: values.address_line2,
      town: values.address_town,
      county:
        parseInt(values.address_country, 10) === COUNTRY_US
          ? values.address_county_us
          : values.address_county,
      country_id: parseInt(values.address_country, 10),
      postcode: values.address_postcode,
    });

    addressPromise.then((_) => {
      setAddressInternal(addressInternal + 1);
    });
  }

  return (
    <Form
      key={'address' + addressInternal}
      onSubmit={_onSubmit}
      onValidate={_handleValidation}
      buttonLabel={label}
    >
      <dl className="standardform" id="add_address">
        <dt>
          <label htmlFor="address_country">Country</label>
        </dt>
        <dd>
          <select
            name="address_country"
            id="address_country"
            title="Please select your country"
            value={countryID}
            onChange={(e) => setCountryID(parseInt(e.target.value, 10))}
          >
            {Object.keys(configHelper.getShippingCountries()).map((id) => {
              const name =
                configHelper.getShippingCountries()[parseInt(id, 10)];
              return (
                <option key={id} value={id}>
                  {name}
                </option>
              );
            })}
          </select>
        </dd>
        <dt>
          <label htmlFor="address_line1">Address Line 1</label>
        </dt>
        <dd>
          <input
            type="text"
            name="address_line1"
            id="address_line1"
            title="Please enter address line 1"
            defaultValue={line1}
          />
        </dd>
        <dt>
          <label htmlFor="address_line2">Address Line 2</label>
        </dt>
        <dd>
          <input
            type="text"
            name="address_line2"
            id="address_line2"
            defaultValue={line2}
          />
        </dd>
        <dt>
          <label htmlFor="address_town">Town</label>
        </dt>
        <dd>
          <input
            type="text"
            name="address_town"
            id="address_town"
            title="Please enter your town"
            defaultValue={town}
          />
        </dd>
        <dt>
          <label htmlFor="address_county">County/State</label>
        </dt>
        {_getCounty(county, state)}
        <dt>
          <label htmlFor="address_postcode">Postcode / ZIP</label>
        </dt>
        <dd>
          <input
            type="text"
            name="address_postcode"
            id="address_postcode"
            title="Please enter your postcode"
            defaultValue={postcode}
          />
        </dd>
      </dl>
    </Form>
  );
}

export default AddressForm;
