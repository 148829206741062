// @flow

import * as React from 'react';

import { Box } from '../common';

export default function PostageMessage() {
  return (
    <Box>
      <em>
        <strong>Important Please Note: </strong>Postage is FREE within the
        United Kingdom including Guernsey, Isle of Man and Jersey. Postage to
        anywhere outside this area will be charged at cost but a nominal fee of
        &pound;15 will appear in your shopping basket, you will however only be
        charged the actual postage cost which is calculated by us when
        processing your order. If the postage cost is more than &pound;15 you
        will be advised.
      </em>
    </Box>
  );
}
