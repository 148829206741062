// @flow

import * as React from 'react';

import { AddToBasketButton, Box, Flexbox, Link, Price } from '../common';
import { ProductContext, useProductView } from '../../hooks/products';
import { useEffect, useState } from 'react';

import AnalyticsHelper from '../../helpers/AnalyticsHelper';
import ProductImage from './ProductImage';
import RelatedProducts from './RelatedProducts';
import type { TProduct } from '../../types';
import { getIssuerURI } from '../../helpers';
import { useLastList } from '../../helpers/ConfigHelper';

type Props = {
  product: TProduct,
};

function ProductDetails(props: Props) {
  const { product } = props;
  const { lastList } = useLastList();
  const addProductView = useProductView();

  const [selectedImage, setSelectedImage] = useState(product.image);

  const [lastViewed, setLastViewed] = useState(null);

  useEffect(() => {
    if (lastViewed !== product.id) {
      setLastViewed(product.id);
      const analytics = new AnalyticsHelper();
      analytics.trackProduct(product, lastList);
      addProductView(product.id);
    }
  }, [lastList, product, lastViewed, addProductView]);

  useEffect(() => {
    if (!product.images.includes(selectedImage)) {
      setSelectedImage(product.image);
    }
  }, [product, selectedImage, setSelectedImage]);

  return (
    <ProductContext.Provider value={product}>
      <Flexbox flexDirection="column" style={{ width: '100%' }}>
        <Flexbox flexGrow={1}>
          <Box>
            <Flexbox
              flexDirection="column"
              className="productPage"
              typeof="schema:Product"
            >
              <Flexbox>
                <h2 property="schema:name" content={product.name}>
                  {product.name}
                </h2>
              </Flexbox>
              <Flexbox style={{ marginBottom: '12px' }}>
                <Flexbox flexDirection="row">
                  <Flexbox
                    style={{
                      minWidth: '170px',
                      height: '170px',
                      marginRight: '12px',
                      textAlign: 'center',
                      display: 'block',
                    }}
                  >
                    <div>
                      <ProductImage
                        imageOverride={selectedImage}
                        excludeLink={true}
                      />
                    </div>
                  </Flexbox>
                  <Flexbox flexGrow={1}>
                    <Flexbox flexDirection="column">
                      {_getIssuer()}
                      {_getCondition()}
                      {_getDescription()}
                      {_getNoInSet()}
                      {_getAbbr()}
                      {_getPrice()}
                      <Flexbox>
                        <AddToBasketButton
                          type="product"
                          stateKey={product.id}
                          hash={product.hash}
                        />
                      </Flexbox>
                    </Flexbox>
                  </Flexbox>
                </Flexbox>
              </Flexbox>
              {product.images.length > 1 && (
                <Flexbox flexDirection="column">
                  <Flexbox>
                    <h3>Other Images</h3>
                  </Flexbox>
                  <Flexbox flexDirection="row">
                    {product.images.map((image) => (
                      <Flexbox
                        key={image}
                        style={{
                          height: '85px',
                          marginRight: '12px',
                          textAlign: 'center',
                          display: 'block',
                        }}
                      >
                        <div>
                          <ProductImage
                            onMouseEnter={() => setSelectedImage(image)}
                            style={{
                              height: '85px',
                              textAlign: 'center',
                              display: 'block',
                            }}
                            imageOverride={image}
                            excludeLink={true}
                          />
                        </div>
                      </Flexbox>
                    ))}
                  </Flexbox>
                </Flexbox>
              )}
            </Flexbox>
          </Box>
        </Flexbox>
        <Flexbox>
          <RelatedProducts productID={product.id} />
        </Flexbox>
      </Flexbox>
    </ProductContext.Provider>
  );

  function _getIssuer(): React.Node {
    return (
      <Flexbox style={{ marginBottom: '12px' }} typeof="schema:Brand">
        <span property="schema:name" content={product.issuer}>
          <strong>Issuer:</strong>{' '}
          <Link hrefOverride={getIssuerURI(product.issuerID, product.issuer)}>
            {product.issuer}
          </Link>
        </span>
      </Flexbox>
    );
  }

  function _getCondition(): React.Node {
    return (
      <Flexbox flexDirection="column" style={{ marginBottom: '12px' }}>
        <Flexbox>
          <span>
            <strong>Condition:</strong> {product.condition}
          </span>
        </Flexbox>
        <Flexbox>
          {product.name.toLowerCase().indexOf('reprint') !== -1 ||
          product.issuerID === '1473' ? (
            ''
          ) : (
            <em>* These are original and NOT reprints</em>
          )}
        </Flexbox>
      </Flexbox>
    );
  }

  function _getDescription(): React.Node {
    if (!product.description) {
      return null;
    }

    return (
      <Flexbox
        style={{ marginBottom: '12px' }}
        property="schema:description"
        content={product.description}
      >
        <span>
          <strong>Description:</strong> {product.description}
        </span>
      </Flexbox>
    );
  }

  function _getNoInSet(): React.Node {
    if (product.name.toLowerCase().indexOf('special album') !== -1) {
      return null;
    }
    if (product.issuerID === '1473') {
      return null;
    }

    let content = (
      <React.Fragment>
        <strong>Number of cards in set:</strong> {product.noinset}
      </React.Fragment>
    );

    if (product.issuer === 'Greetings Card') {
      return null;
    }

    return (
      <Flexbox style={{ marginBottom: '12px' }}>
        <span>{content}</span>
      </Flexbox>
    );
  }

  function _getAbbr(): React.Node {
    if (!isNaN(product.noinset)) {
      return null;
    }

    return (
      <Flexbox flexDirection="column" style={{ marginBottom: '12px' }}>
        <Flexbox style={{ fontWeight: 'bold' }}>
          Abbreviations used for Number In Set:
        </Flexbox>
        <Flexbox>
          EL = Extra Large; LT = Large Trade ( size 89 x 64mm); L = Large; M =
          Medium; K = Miniature.
        </Flexbox>
      </Flexbox>
    );
  }

  function _getPrice(): React.Node {
    if (!product.price) {
      return <strong className="nowprice">SOLD OUT</strong>;
    }
    let priceIntro = 'Price for complete set:';
    if (product.incomplete === '1') {
      priceIntro = 'Price for part set:';
    } else if (product.name.toLowerCase().indexOf('special album') !== -1) {
      priceIntro = 'Price for album (without cards):';
    } else if (product.issuer === 'Greetings Card') {
      priceIntro = 'Price for Greetings Card:';
    } else if (product.issuerID === '1473') {
      priceIntro = 'Price:';
    }

    const price =
      product.price === product.wasPrice ? (
        <Flexbox
          style={{ marginBottom: '12px' }}
          property="schema:price"
          content={product.price}
        >
          <Price
            property="schema:price"
            content={product.price}
            bold={true}
            label="Only"
            price={product.price}
            highlight={true}
          />
        </Flexbox>
      ) : (
        [
          <Flexbox key="was">
            <Price
              bold={true}
              label="Catalogue Price"
              price={product.wasPrice}
              strikethrough={true}
            />
          </Flexbox>,
          <Flexbox key="now" property="schema:price" content={product.price}>
            <Price
              bold={true}
              label="Special Offer"
              price={product.price}
              highlight={true}
            />
          </Flexbox>,
        ]
      );

    return (
      <Flexbox flexDirection="column" rel="schema:offers">
        <Flexbox>
          <strong>{priceIntro}</strong>
        </Flexbox>
        <div typeof="schema:Offer">{price}</div>
      </Flexbox>
    );
  }
}

export default ProductDetails;
