// @flow

import * as React from 'react';

import { Loading, Page } from '../common';
import { useEffect, useMemo, useState } from 'react';

import AnalyticsHelper from '../../helpers/AnalyticsHelper';
import SearchResults from './SearchResults';
import qs from 'qs';
import { useLastList } from '../../helpers/ConfigHelper';
import { useLocation } from 'react-router';
import { useSearch } from '../../hooks/search';

const analytics = new AnalyticsHelper();

function Search() {
  const location = useLocation();

  const searchString = useMemo(() => {
    return qs.parse(location.search.replace('?', '')).search;
  }, [location.search]);

  const { isFetching } = useSearch(searchString);
  const { lastList, setLastList } = useLastList();
  const [lastSearch, setLastSearch] = useState(null);

  useEffect((): void => {
    if (lastSearch !== searchString) {
      analytics.trackSearch(searchString);
      setLastSearch(searchString);
    }
  }, [lastSearch, searchString, setLastSearch]);

  useEffect((): void => {
    if (lastList !== 'Search Results') {
      setLastList('Search Results');
    }
  }, [setLastList, lastList]);

  function _getPageComponent(): React.Node {
    if (!searchString) {
      return <div>No Search Term Entered</div>;
    }

    if (isFetching) {
      return <Loading />;
    }

    return <SearchResults searchString={searchString} />;
  }

  return <Page>{_getPageComponent()}</Page>;
}

export default Search;
