// @flow

import * as EmailValidator from 'email-validator';
import * as React from 'react';

import { Form } from './common';
import { getFetchURI } from '../helpers';

type Props = {};
type State = {
  name: string,
  address: string,
  postcode: string,
  telephone: string,
  fax: string,
  email: string,
  comments: string,
};

export default class ContactForm extends React.Component<Props, State> {
  static props: Props;
  state: State = {
    name: '',
    address: '',
    postcode: '',
    telephone: '',
    fax: '',
    email: '',
    comments: '',
  };

  render(): React.Node {
    return (
      <Form
        onValidate={this._handleValidation.bind(this)}
        honeyPot={true}
        type="contact"
        uri={getFetchURI('async/common/mail.php')}
        className="contactform"
      >
        <dl className="small">
          <dd>
            <label htmlFor="name">Name</label>
            <input
              type="text"
              name="name"
              id="name"
              defaultValue={this.state.name}
            />
          </dd>
          <dd>
            <label htmlFor="address">Address</label>
            <textarea
              name="address"
              id="address"
              className="small"
              defaultValue={this.state.address}
            ></textarea>
          </dd>
          <dd>
            <label htmlFor="postcode">Post Code</label>
            <input
              type="text"
              name="postcode"
              id="postcode"
              defaultValue={this.state.postcode}
            />
          </dd>
          <dd>
            <label htmlFor="telephone">Tel</label>
            <input
              type="text"
              name="telephone"
              id="telephone"
              defaultValue={this.state.telephone}
            />
          </dd>
          <dd>
            <label htmlFor="fax">Fax</label>
            <input
              type="text"
              name="fax"
              id="fax"
              defaultValue={this.state.fax}
            />
          </dd>
          <dd>
            <label htmlFor="email">Email</label>
            <input
              type="text"
              name="email"
              id="email"
              defaultValue={this.state.email}
            />
          </dd>
          <dd>
            <label htmlFor="comments">Comments</label>
            <textarea
              name="comments"
              id="comments"
              className="medium"
              defaultValue={this.state.comments}
            ></textarea>
          </dd>
        </dl>
      </Form>
    );
  }

  _handleValidation(values: Object): Array<string> {
    let errors = [];

    if (!values.email && !values.address && !values.telephone) {
      errors.push(
        'Please enter either your email, phone number or address so we can get back to you',
      );
    }

    if (!values.comments) {
      errors.push('Please complete the comments field');
    }

    if (values.email) {
      const isEmailValid = EmailValidator.validate(values.email);
      if (!isEmailValid) {
        errors.push('Please enter a valid email address.');
      }
    }

    this.setState({
      name: values.name,
      address: values.address,
      postcode: values.postcode,
      telephone: values.telephone,
      fax: values.fax,
      email: values.email,
      comments: values.comments,
    });

    return errors;
  }
}
