// @flow

import * as React from 'react';

import { Box, Flexbox, RTEText } from '../common';
import { useCategories, useCategory } from '../../hooks/categories';

import CategoryListRow from './CategoryListRow';
import ProductList from '../ProductList';
import type { TProduct } from '../../types';
import { useEffect } from 'react';
import { useLastList } from '../../helpers/ConfigHelper';

type Props = {
  id: number,
};

function CategoryList(props: Props) {
  const [categories] = useCategories();
  const [category] = useCategory(props.id);

  const { setLastList } = useLastList();

  useEffect((): void => {
    setLastList(category.navName);
  }, [category.navName, setLastList]);

  function subCats(): React.Node {
    const subCats = {};
    Object.keys(categories)
      .filter(
        (subCatID) => categories[parseInt(subCatID, 10)].parent === category.id,
      )
      .forEach((catID) => {
        catID = parseInt(catID, 10);
        subCats[catID] = categories[catID];
      });

    if (!Object.keys(subCats).length) {
      return null;
    }

    return (
      <Flexbox>
        <Box>
          <span>
            <h2>Categories within '{category.name}'</h2>
            <table id="catlist">
              <thead>
                <tr valign="top">
                  <td>Title</td>
                  <td width="180">Last Updated</td>
                  <td width="60">View List</td>
                  <td width="60">Download?</td>
                </tr>
              </thead>
              <tbody>
                {Object.keys(subCats)
                  .sort((a, b) =>
                    subCats[parseInt(a, 10)].name.localeCompare(
                      subCats[parseInt(b, 10)].name,
                    ),
                  )
                  .map((catID) => {
                    const subCat = subCats[parseInt(catID, 10)];
                    return (
                      <CategoryListRow
                        key={subCat.id}
                        id={subCat.id}
                        isChild={false}
                      />
                    );
                  })}
              </tbody>
            </table>
          </span>
        </Box>
      </Flexbox>
    );
  }

  function sortOverride(catID: number) {
    switch (catID) {
      case 37:
        return (a: TProduct, b: TProduct) => {
          if (parseInt(a.noinset, 10) < parseInt(b.noinset, 10)) {
            return 1;
          }
          if (parseInt(a.noinset, 10) > parseInt(b.noinset, 10)) {
            return -1;
          }
          return 0;
        };
      case 41: // liebig
        return (a: TProduct, b: TProduct) => {
          // FXXXX set title
          const aNumber = a.name.split(' ', 1)[0].replace('F', '');
          const bNumber = b.name.split(' ', 1)[0].replace('F', '');
          return parseInt(aNumber) > parseInt(bNumber) ? 1 : -1;
        };
      case 80: // lavazza
        return (a: TProduct, b: TProduct) => {
          // Series XXX set title
          const aNumber = a.name.split(' ', 2)[1];
          const bNumber = b.name.split(' ', 2)[1];
          return parseInt(aNumber) > parseInt(bNumber) ? 1 : -1;
        };
      default:
        return null;
    }
  }

  return (
    <Flexbox flexDirection="column">
      <Flexbox flexGrow={1}>
        <Box highlight={true}>
          <Flexbox flexDirection="column">
            <Flexbox element="header">
              <h1>{category.name}</h1>
            </Flexbox>
            <Flexbox element="section">
              <span>
                <RTEText content={category.description} />
              </span>
            </Flexbox>
          </Flexbox>
        </Box>
      </Flexbox>
      {subCats()}
      <Flexbox>
        <ProductList
          key={category.id}
          list={category.navName}
          prodIDs={category.prodIDs}
          sortOverride={() => sortOverride(category.id)}
        />
      </Flexbox>
    </Flexbox>
  );
}

export default CategoryList;
