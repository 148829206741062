// @flow

import * as React from 'react';

import { Box, Flexbox } from '../common';
import type { TCustomerAddress, TCustomerAddresses } from '../../types';

import Address from './Address';
import { useCustomerHelper } from '../../hooks/customer';

type Props = {
  onSelect?: Function,
  addresses: TCustomerAddresses,
  inCheckout: boolean,
};

function Addresses({ addresses, onSelect, inCheckout }: Props) {
  const customerHelper = useCustomerHelper();

  function _getAddressWithLabel(address: TCustomerAddress): React.Node {
    return (
      <label htmlFor={'pickAddress_' + address.id}>
        <input
          type="radio"
          checked={address.id === customerHelper.getDID()}
          className="pick_address"
          onChange={() => onSelect && onSelect(address.id)}
        />
        {_getAddress(address)}
      </label>
    );
  }

  function _getAddress(address: TCustomerAddress): React.Node {
    return <Address onSelect={onSelect} id={address.id} />;
  }

  // function _getAddressLabel(address: TCustomerAddress) {
  //   if (address.id === customerHelper.getBID()) {
  //     return 'Primary/Billing Address';
  //   }

  //   return 'Additional Address';
  // }

  return Object.keys(addresses).map((id) => {
    const address = addresses[parseInt(id, 10)];
    return (
      <Flexbox key={'address_' + id}>
        <Box highlight={true}>
          <span className="pick_address_box" style={{ width: '100%' }}>
            {inCheckout ? _getAddressWithLabel(address) : _getAddress(address)}
          </span>
        </Box>
      </Flexbox>
    );
  });
}

export default Addresses;
