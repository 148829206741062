// @flow

import * as React from 'react';

import Flexbox from 'flexbox-react';

type Props = {
  children?: React.Node,
  highlight: boolean,
};

export default class Box extends React.Component<Props> {
  static defaultProps: Props = {
    highlight: false,
  };

  render() {
    let parentClass = 'box rounded';
    if (this.props.highlight) {
      parentClass += ' highlight';
    }
    return (
      <Flexbox flexGrow={1} className={parentClass}>
        <Flexbox flexGrow={1} className="inner rounded">
          {this.props.children}
        </Flexbox>
      </Flexbox>
    );
  }
}
