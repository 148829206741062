// @flow

import type { TBasket, TBasketItems } from '../types';

import CustomerHelper from './CustomerHelper';

const HANDLING_SURCHARGE: number = 2.5;
const OVERSEAS_SURCHARGE: number = 20;
const FREE_SHIPPING_LIMIT_CCC: number = 20;
const FREE_SHIPPING_LIMIT: number = 35;

export default class BasketHelper {
  _basket: TBasket;
  _customerHelper: CustomerHelper;

  constructor(basket: TBasket, customerHelper: CustomerHelper) {
    this._basket = basket;
    this._customerHelper = customerHelper;
  }

  // getItem(
  //   type: TBasketItemType,
  //   key: number,
  // ): TProduct | TSetOfTheMonth {
  //   switch (type) {
  //     case 'product':
  //       return store.getState().products.get(key);
  //     case 'sotm':
  //       return store.getState().sotm.data.get(key);
  //     default:
  //       throw Error('Unexpected Error: Type not implemented - ' + type);
  //   }
  // },

  getItems(): TBasketItems {
    return this._basket.items;
  }

  getItemCount(): number {
    let count = 0;
    const items = this.getItems();
    Object.keys(items).forEach((hash) => {
      count += this._basket.items[hash].qty;
    });

    return count;
  }

  requiresHandlingFee(): boolean {
    // need to check for ccn if they are then limit is 15
    const handlingLimit = this._customerHelper.isCCCMember()
      ? FREE_SHIPPING_LIMIT_CCC
      : FREE_SHIPPING_LIMIT;
    return this.getItemTotal() < handlingLimit;
  }

  getShipping(): number {
    // first lets check if all items in the cart are items that don't count
    // towards the shipping cost e.g. digital subscription
    let ignoreShipping = true;
    const items = this.getItems();
    Object.keys(items).forEach((hash) => {
      if (items[hash].iShipping !== true) {
        ignoreShipping = false;
      }
    });

    if (ignoreShipping) {
      return 0;
    }

    if (!this._customerHelper.isFreeShippingAvailable()) {
      return OVERSEAS_SURCHARGE;
    }

    if (this.requiresHandlingFee()) {
      return HANDLING_SURCHARGE;
    } else {
      return 0;
    }
  }

  getItemTotal(): number {
    let total = 0;
    const items = this.getItems();
    Object.keys(items).forEach((hash) => {
      total += items[hash].price * items[hash].qty;
    });

    return total;
  }

  getTotal(): number {
    let total = this.getItemTotal();
    total += this.getShipping();

    return total;
  }

  getComments(): string {
    return this._basket.comments;
  }

  getOrderID(): ?number {
    return this._basket.orderID;
  }
}
