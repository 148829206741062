// @flow

import * as React from 'react';

import { ButtonLink, Notice } from '../common';

import AddressForm from './AddressForm';
import { useAddress } from '../../hooks/customer';
import { useCustomerHelper } from '../../hooks/customer';
import { useState } from 'react';

type Props = {
  onSelect?: Function,
  id: number,
};

function Address({ onSelect, id }: Props) {
  const [editing, setEditing] = useState(false);
  const [success, setSuccess] = useState(false);

  const customerHelper = useCustomerHelper();
  const { address, removeAddress } = useAddress(id);

  function _getAddressLabel() {
    if (address.id === customerHelper.getBID()) {
      return 'Primary/Billing Address';
    }

    return 'Additional Address';
  }

  return (
    <span>
      <strong onClick={() => onSelect && onSelect(address.id)}>
        {_getAddressLabel()}
      </strong>
      <span className="fright">
        <ButtonLink onClick={() => setEditing(true)}>Edit</ButtonLink>
        {address.id !== customerHelper.getBID() && (
          <span>
            {' | '}
            <ButtonLink
              onClick={() => {
                if (
                  window.confirm(
                    'Are you sure you want to delete this address?',
                  )
                ) {
                  removeAddress();
                }
              }}
            >
              Remove
            </ButtonLink>
          </span>
        )}
      </span>
      <br />
      {editing ? (
        <span style={{ width: '100%' }}>
          <AddressForm
            id={id}
            label="Update Address"
            onSubmit={() => {
              setEditing(false);
              setSuccess(true);
            }}
          />
        </span>
      ) : (
        <div onClick={() => onSelect && onSelect(address.id)}>
          {address.line1 + ', '}
          {address.line2 ? address.line2 + ', ' : ''}
          {address.town + ', '}
          {address.county + ', '}
          {address.postcode + ', '}
          {address.country}
        </div>
      )}
      {success && !editing ? (
        <div style={{ marginTop: '8px' }}>
          <Notice type="success" message="Address Updated" />
        </div>
      ) : (
        ''
      )}
    </span>
  );
}

export default Address;
