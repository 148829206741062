// @flow

import * as React from 'react';

import { AddToBasketButton, Link, Price } from '../common';

import { useCCC } from '../../hooks/ccc';

export default function CardCollectorsClubBinders() {
  const [ccc] = useCCC();

  return (
    <React.Fragment>
      <table id="prodlist">
        <thead>
          <tr>
            <td colSpan="2">
              A special binder to house 12 copies is available in a choice of
              blue or maroon covers with gold lettering.{' '}
              <Link page="CCC">Click here</Link> for Card Collectors Club
              Memmbership Details.
            </td>
          </tr>
        </thead>
        <tbody>
          {ccc.binders.map((binder) => {
            return (
              <tr key={binder.id}>
                <td>
                  {binder.label + ' - '}
                  <Price price={binder.price} />
                </td>
                <td>
                  <AddToBasketButton
                    stateKey={binder.id}
                    type="magazineBinders"
                    hash={binder.hash}
                    soldout={binder.soldout}
                  />
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </React.Fragment>
  );
}
