// @flow

import * as React from 'react';

import { Box, Flexbox, Page } from '../common';
import { PageNames, getURI } from '../../helpers';
import { useNavigate, useParams } from 'react-router';

import LoginForgot from './LoginForgot';
import LoginForm from './LoginForm';
import RegisterForm from './RegisterForm';
import { useCustomerHelper } from '../../hooks/customer';
import { useEffect } from 'react';

function Login() {
  const customerHelper = useCustomerHelper();
  const navigate = useNavigate();
  const { title, id } = useParams();

  useEffect(() => {
    if (customerHelper.isLoggedIn()) {
      navigate(getURI('ACCOUNT'));
    }
  }, [navigate]);

  let content;
  switch (title) {
    case PageNames.LOGIN_FORGOT.title:
      content = <LoginForgot hash={id ? id.toString() : null} />;
      break;
    default:
      content = <LoginContent />;
      break;
  }

  return <Page>{content}</Page>;
}

export default Login;

function LoginContent() {
  return (
    <Flexbox flexDirection="column">
      <Flexbox element="section">
        <Box highlight={true}>
          <h2>Sign In</h2>
        </Box>
      </Flexbox>
      <Flexbox element="section" flexDirection="row">
        <Flexbox style={{ marginRight: '12px' }}>
          <LoginForm inCheckout={false} />
        </Flexbox>
        <Flexbox>
          <RegisterForm inCheckout={false} />
        </Flexbox>
      </Flexbox>
    </Flexbox>
  );
}
