// @flow

import * as React from 'react';

import { Box, Flexbox, Link, RTEText } from '../common';
import { PageHelpers, makeAuctionBidURI } from '../../helpers';
import type { TAuctionLots, TPageContent } from '../../types';

import AuctionBids from './AuctionBids';
import CatalogueDownload from './CatalogueDownload';
import { useAuction } from '../../hooks/auction';
import { useState } from 'react';

type Props = {
  content?: TPageContent,
};

export default function AuctionLots(props: Props) {
  const { auction, bids } = useAuction();
  const [filter, setFilter] = useState('');
  const { content } = props;

  return (
    <Flexbox flexDirection="column" element="section">
      <Box highlight={true}>
        <span>{_getHeading()}</span>
      </Box>
      <CatalogueDownload />
      {_getBids()}
      <Box>{_getLots()}</Box>
    </Flexbox>
  );

  function _getHeading() {
    const heading = PageHelpers.getContent(content, 'Heading')
      .replace(/{LOTS}/g, Object.keys(auction.lots).length.toString())
      .replace(/{DATE}/g, auction.date);

    return <RTEText content={heading} />;
  }

  function _getBids(): React.Node {
    if (Object.keys(bids).length === 0) {
      return null;
    }
    return (
      <Box>
        <AuctionBids hideShowAllButton={true} />
      </Box>
    );
  }

  function _getFilteredLots(): TAuctionLots {
    const filteredLots = {};
    Object.keys(auction.lots).forEach((key) => {
      const lot = auction.lots[key];
      if (lot.description.toLowerCase().indexOf(filter) !== -1) {
        filteredLots[key] = lot;
      }
    });
    return filteredLots;
  }

  function _getLots(): React.Node {
    const lots = _getFilteredLots();
    return (
      <Flexbox flexDirection="column" element="section">
        <form className="pure-form">
          <input
            type="text"
            onChange={(event) => setFilter(event.target.value.toLowerCase())}
            placeholder="Search Lots"
            style={{ width: '300px', marginBottom: '8px' }}
          />
        </form>
        <table id="prodlist">
          <thead>
            <tr valign="top">
              <td width="40">Lot #</td>
              <td>Description</td>
              <td width="100">Condition</td>
              <td width="60">Cat Value</td>
              <td width="60">Est Value</td>
              <td width="50" align="center">
                Bid
              </td>
            </tr>
          </thead>
          <tbody>
            {Object.keys(lots).map((lotNum) => {
              const lot = lots[lotNum];
              return (
                <tr key={lot.lotnum}>
                  <td valign="top">{lot.lotnum}</td>
                  <td valign="top">{lot.description}</td>
                  <td valign="top">{lot.condition}</td>
                  <td valign="top" align="right">
                    &pound;{lot.cat_value || '?'}
                  </td>
                  <td valign="top" align="right">
                    &pound;{lot.price || '?'}
                  </td>
                  <td valign="top" align="center" className="highlight">
                    <Link
                      hrefOverride={makeAuctionBidURI(lot.lotnum.toString())}
                    >
                      Bid
                    </Link>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </Flexbox>
    );
  }
}
