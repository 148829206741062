import { downloadState } from '../../helpers/Atoms';
import { fetch } from '../../helpers';
import { useCallback } from 'react';
import { useRecoilState } from 'recoil';

export function useCheckIntitiated() {
  const [download, setDownloadState] = useRecoilState(downloadState);

  const fetchIntiatiated = useCallback(() => {
    if (download.fetchStatusIntitated !== 'NONE') {
      return;
    }
    setDownloadState({ ...download, fetchStatusIntitated: 'FETCHING' });
    return fetch('async/ccn_downloads.php?check=init')
      .then(
        (response) => response.json(),
        (error) => {
          setDownloadState({
            ...download,
            fetchStatusIntitated: 'FETCHED',
            error,
          });
          console.log('An error occured.', error);
        },
      )
      .then((json) => {
        if (json.error) {
          setDownloadState({
            ...download,
            fetchStatusIntitated: 'FETCHED',
            error: json.error,
          });
          return;
        }
        setDownloadState({
          ...download,
          fetchStatusIntitated: 'FETCHED',
          data: {
            ...download.data,
            initiated: json.success,
          },
        });
        return json;
      });
  }, [download, setDownloadState]);

  return {
    initiated: download.data.initiated,
    isFetching: download.fetchStatusIntitated !== 'FETCHED',
    error: download.error,
    fetchIntiatiated,
  };
}
