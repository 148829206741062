// @flow
// @format

// import type {Node} from 'react';

import * as React from 'react';

import { Box, Button, Flexbox, Loading, Notice } from './';

import fetch from 'isomorphic-fetch';

export type FormType =
  | 'contact'
  | 'requests'
  | 'order_failed'
  | 'auction'
  | 'lists';

type Props = {
  button?: React.Node,
  buttonLabel?: React.Node,
  className?: string,
  onValidate?: Function,
  onSubmit?: Function,
  honeyPot: boolean,
  children: React.Node,
  failure?: React.Node,
  success?: React.Node,
  uri?: string,
  type?: FormType,
};

type State = {
  failures: Array<string>,
  sending: boolean,
  success: boolean,
};

export default class Form extends React.Component<Props, State> {
  state: State = {
    failures: [],
    sending: false,
    success: false,
  };
  formRef: any;

  // componentDidUpdate() {
  //   this.setState({
  //     failures: [],
  //   })
  // }

  render() {
    if (this.state.sending) {
      return <Loading />;
    }

    if (this.state.success) {
      return this._getSuccess();
    }

    let failure = null;
    if (this.state.failures.length > 0) {
      failure = this._getFailure();
    }
    const className = this.props.className || '';
    return (
      <form
        className={'pure-form ' + className}
        style={{ width: '100%' }}
        ref={(formRef) => (this.formRef = formRef)}
        onSubmit={(e) => this._handleSubmit(e)}
      >
        {this.props.honeyPot ? this._getHoneyPot() : null}
        {failure}
        {this.props.children}
        {this._getFormType()}
        {this._getSubmitButton()}
      </form>
    );
  }

  _getHoneyPot(): React.Node {
    return [
      <input
        type="text"
        style={{ display: 'none' }}
        name="thanks"
        defaultValue="thanks"
        key="hpthanks"
      />,
      <input
        type="text"
        style={{ display: 'none' }}
        name="thankyou"
        defaultValue=""
        key="hpthankyou"
      />,
    ];
  }

  _getSubmitButton(): React.Node {
    return this.props.button ? (
      this.props.button
    ) : (
      <Button label={this.props.buttonLabel || 'Send'} />
    );
  }

  _getFormType(): React.Node {
    return this.props.type != null ? (
      <input type="hidden" name="form_type" value={this.props.type} />
    ) : null;
  }

  _getSuccess(): React.Node {
    return (
      this.props.success || (
        <Box>
          Thanks for taking the time to complete the form, it has been
          sucessfully sent
        </Box>
      )
    );
  }

  _getFailure(): React.Node {
    return (
      <Notice type="error" message="Please fix the following errors:">
        <Flexbox flexDirection="column">
          <ul>
            {this.state.failures.map((failure) => {
              return <li key={failure.toString()}>{failure.toString()}</li>;
            })}
          </ul>
          {this.props.failure}
        </Flexbox>
      </Notice>
    );
  }

  _handleSubmit(e: Object): boolean {
    e.preventDefault();
    const values = Object.values(this.formRef).reduce(
      (obj: Object, field: Object) => {
        let value = field.value;
        switch (field.type) {
          case 'checkbox':
            if (field.checked === false) {
              value = '';
            }
            break;
          default:
            // nothing
            break;
        }
        obj[field.name] = value;
        return obj;
      },
      {},
    );

    if (this.props.onValidate) {
      const validationErrors = this.props.onValidate(values);
      if (validationErrors.length > 0) {
        this.setState({ sending: false, failures: validationErrors });
        return false;
      }
    }

    this.setState({ sending: true, failures: [] });

    if (this.props.uri) {
      this._doFetch(values);
    } else {
      if (!this.props.onSubmit) {
        throw Error('You must supply a submit call back if no uri supplied');
      }
      this.props.onSubmit(values);
    }

    return false;
  }

  _doFetch(values: Object): void {
    let uri = this.props.uri;

    let str = [];
    for (var p in values) {
      if (values.hasOwnProperty(p)) {
        str.push(encodeURIComponent(p) + '=' + encodeURIComponent(values[p]));
      }
    }
    fetch(uri, {
      credentials: 'include',
      method: 'POST',
      body: str.join('&'),
      // body: values,
      headers: {
        Accept: 'application/json',
        'content-type': 'application/x-www-form-urlencoded',
        // 'content-type':'application/json' // why you no work?!
      },
    })
      .then(
        (response) => response.json(),
        (error) => {
          return {
            success: false,
            errs: [error],
          };
        },
      )
      .then((json) => {
        if (json.success) {
          this.setState({ sending: false, success: true, failures: [] });
        } else {
          this.setState({ sending: false, failures: json.errs });
        }
        if (this.props.onSubmit) {
          this.props.onSubmit(values, json.success, json.errs, json.payload);
        }
      });
  }
}
