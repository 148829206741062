// @flow

import * as EmailValidator from 'email-validator';
import * as React from 'react';

import { Box, Form } from '../common';
import { getFetchURI, validatePassword } from '../../helpers';

import AnalyticsHelper from '../../helpers/AnalyticsHelper';
import { useBasketHelper } from '../../hooks/basket';
import { useReceiveCustomer } from '../../hooks/customer';
import { useState } from 'react';

type Props = {
  inCheckout: boolean,
};

const analytics = new AnalyticsHelper();

function RegisterForm(props: Props) {
  const basketHelper = useBasketHelper();
  const receiveCustomer = useReceiveCustomer();

  const [fname, setFname] = useState('');
  const [sname, setSname] = useState('');
  const [email, setEmail] = useState('');

  return (
    <Box>
      <span>
        <h1>Create Account</h1>
        <div style={{ marginBottom: '8px' }}>
          Being a registered customer will make the purchase process quicker,
          more convenient and give you the following benefits:
        </div>
        <ul style={{ marginBottom: '8px' }}>
          <li>Save multiple shipping locations</li>
          <li>View order history</li>
          <li>Faster check out</li>
        </ul>
        <div style={{ marginBottom: '12px' }}>
          If you wish to become a registered customer and receive these benefits
          please complete the following form:
        </div>
        <Form
          uri={getFetchURI('async/login/register_customer.php')}
          honeyPot={true}
          className="standard"
          buttonLabel="Create Account"
          onValidate={_handleValidation}
          onSubmit={_onReceiveCustomer}
        >
          <dl className="medium" style={{ marginBottom: '12px' }}>
            <dd>
              <label htmlFor="email">Email</label>
              <input type="text" name="email" id="email" defaultValue={email} />
            </dd>
            <dd>
              <label htmlFor="fname">First Name</label>
              <input type="text" name="fname" id="fname" defaultValue={fname} />
            </dd>
            <dd>
              <label htmlFor="sname">Last Name</label>
              <input type="text" name="sname" id="sname" defaultValue={sname} />
            </dd>
            <dd>
              <label htmlFor="password">Password</label>
              <input type="password" name="password" id="password" />
            </dd>
            <dd>
              <label htmlFor="cpassword">Repeat Password</label>
              <input type="password" name="cpassword" id="cpassword" />
            </dd>
          </dl>
          <input type="hidden" name="newAccount" value="yes" />
        </Form>
        <div>
          <em className="smaller">
            We will send you an email with confirmation of your password for use
            when you next shop with us.
          </em>
        </div>
      </span>
    </Box>
  );

  function _onReceiveCustomer(_, success, __, payload): void {
    let page;
    if (props.inCheckout) {
      page = 'BASKET_CONFIRM';
      analytics.continueCheckout(basketHelper.getItems(), 'sign_in');
      analytics.trackSignUp('In Checkout');
    } else {
      page = 'ACCOUNT';
      analytics.trackSignUp('Normal Sign Up');
    }
    receiveCustomer(payload, success, page);
  }

  function _handleValidation(values: Object): Array<string> {
    let errors = [];

    if (!values.password) {
      errors.push('Please enter a password');
    }

    if (values.password !== values.cpassword) {
      errors.push('Passwords do not match');
    }

    const passwordErrors = validatePassword(values.password);
    passwordErrors.forEach((err) => errors.push(err));

    if (!values.email) {
      errors.push('Please enter your email address');
    }

    if (values.email) {
      const isEmailValid = EmailValidator.validate(values.email);
      if (!isEmailValid) {
        errors.push('Please enter a valid email address.');
      }
    }

    if (!values.fname) {
      errors.push('Please enter your first name');
    }

    if (!values.sname) {
      errors.push('Please enter your last name');
    }

    setFname(values.fname);
    setSname(values.sname);
    setEmail(values.email);

    return errors;
  }
}

export default RegisterForm;
