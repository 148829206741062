// @flow

import { fetchWithErrHandler, getSOTMMonth } from '../../helpers';
import { useCallback, useEffect } from 'react';

import { sotmState } from '../../helpers/Atoms';
import { useMemo } from 'react';
import { useRecoilState } from 'recoil';

export function useSOTM(month: string) {
  const monthToUse = useMemo(() => {
    return month == null ? getSOTMMonth() : month;
  }, [month]);

  const [sotm, setSOTM] = useRecoilState(sotmState(monthToUse));

  const fetchSOTM = useCallback(() => {
    if (sotm.fetchStatus === 'FETCHED') {
      return;
    }
    setSOTM({ ...sotm, fetchStatus: 'FETCHING' });
    return fetchWithErrHandler('async/sotm.php?month=' + monthToUse).then(
      (json) => {
        if (json == null) {
          Sentry.captureMessage('No JSON returned for SOTM');
          setSOTM({
            ...sotm,
            fetchStatus: 'FETCHED',
            data: {
              ...sotm.data,
              month: monthToUse,
            },
          });
          return;
        }
        const sotmInternal = json[Object.keys(json)[0]];
        if (sotmInternal.error) {
          setSOTM({
            ...sotm,
            fetchStatus: 'FETCHED',
            data: {
              ...sotm.data,
              month: monthToUse,
            },
            error: sotmInternal.error,
          });
          return;
        }
        sotmInternal.id = parseInt(sotmInternal.id, 10);
        sotmInternal.price = parseFloat(sotmInternal.price);
        sotmInternal.ccnPrice = parseFloat(sotmInternal.ccnPrice);

        setSOTM({
          ...sotm,
          fetchStatus: 'FETCHED',
          data: sotmInternal,
        });
        return sotmInternal;
      },
      (error) => {
        setSOTM({
          ...sotm,
          fetchStatus: 'FETCHED',
          data: {
            ...sotm.data,
            month: monthToUse,
          },
          error,
        });
        console.log('An error occured.', error);
      },
    );
  }, [sotm, setSOTM, monthToUse]);

  useEffect(() => {
    if (sotm.fetchStatus === 'NONE') {
      fetchSOTM();
    }
  }, [sotm.fetchStatus, fetchSOTM]);

  return {
    sotm: sotm.data,
    isFetching: sotm.fetchStatus !== 'FETCHED',
    error: sotm.error,
    fetchSOTM,
  };
}
