// @flow

import * as React from 'react';

import {getFetchURI} from '../../helpers';

type Props = {
  id: string,
  style?: { [string]: string },
  alt: string,
}

function AuctionImage(props: Props): React.Node {
  const {id, style, alt} = props;
  return (
    <img
      style={style}
      src={getFetchURI('auction_images/'+id+'.jpg')}
      alt={alt}
    />
  )
}

export default AuctionImage
