// @flow

import * as React from 'react';

import { Box, Flexbox, Link } from '../common';

import ProductList from '../ProductList';
import type { TIssuer } from '../../types';

type Props = {
  issuer: TIssuer,
};

export default function IssuerProducts({ issuer }: Props) {
  return (
    <Flexbox flexDirection="column" style={{ width: '100%' }}>
      <Flexbox flexGrow={1}>
        <Box highlight={true}>
          <span>
            <img
              src="/images/postfree.gif"
              className="fright"
              alt="Free Shipping"
            />
            <h1 className="smaller">{issuer.name}</h1>
            <p>
              The sets listed below are all issued by{' '}
              <b>
                <em>{issuer.name}</em>
              </b>
            </p>
            <p>
              <Link page="ISSUERS">Click here</Link> to view all Issuers
            </p>
          </span>
        </Box>
      </Flexbox>
      <Flexbox>
        <ProductList prodIDs={issuer.prodIDs} hideIssuerFilter={true} />
      </Flexbox>
    </Flexbox>
  );
}
