// @flow

import { dailyOfferState } from '../../helpers/Atoms';
import { fetchWithErrHandler } from '../../helpers';
import { useEffect } from 'react';
import { useRecoilState } from 'recoil';

export function useDailyOffer() {
  const [offer, setOffer] = useRecoilState(dailyOfferState);

  useEffect(() => {
    if (offer.fetchStatus === 'NONE') {
      setOffer({ ...offer, fetchStatus: 'FETCHING' });
      fetchWithErrHandler('async/daily.php').then(
        (json) => {
          json.id = json?.id == null ? null : parseInt(json.id, 10);
          setOffer({
            ...offer,
            fetchStatus: 'FETCHED',
            data: json,
          });
        },
        (error) => {
          setOffer({ ...offer, fetchStatus: 'FETCHED', error });
          console.log('An error occured.', error);
        },
      );
    }
  }, [offer, setOffer]);

  return {
    dailyOffer: offer.data,
    isFetching: offer.fetchStatus !== 'FETCHED',
    error: offer.error,
  };
}
