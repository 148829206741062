import * as React from 'react';

import { ProductContainer, ProductName, ProductPrice } from '../Product/';

import { Link } from '../common';
import ProductImage from '../ProductPage/ProductImage';
import { useOffers } from '../../hooks/offers';

export default function HomeSpecialOffer({ prodID }: { prodID: number }) {
  const { offers } = useOffers();

  const date = new Date();
  const locale = 'en-GB';
  const monthName = date.toLocaleString(locale, { month: 'long' });
  const totalOffers = offers.prodIDs.length;

  return (
    <ProductContainer prodID={prodID}>
      <div className="subheading">
        <div className="fright" style={{ textAlign: 'center' }}>
          <ProductImage />
        </div>
        <h2>
          <em>{monthName} Special Offers</em>
        </h2>
        <div style={{ marginBottom: '8px' }}>
          This is just 1 of the{' '}
          <Link page="OFFERS">{totalOffers} special offers</Link> we have to
          offer throughout {monthName}, <Link page="OFFERS">Click here</Link> to
          view them all.
        </div>
        <div>
          <ProductName />
          <ProductPrice />
        </div>
      </div>
    </ProductContainer>
  );
}
