// @flow

import * as React from 'react';

import { Box, Form, Link } from '../common';
import { getFetchURI, validatePassword } from '../../helpers';

import { useBasketHelper } from '../../hooks/basket';
import { useState } from 'react';

type Props = {
  hash: ?string,
};

export default function LoginForgot(props: Props) {
  const basketHelper = useBasketHelper();
  const [email, setEmail] = useState('');

  return (
    <Box>
      <span>{props.hash ? _getResetForm() : _getForgotForm()}</span>
    </Box>
  );

  function _getForgotForm(): React.Node {
    return (
      <React.Fragment>
        <h1>Forgotten Password?</h1>
        <div>
          Please enter your email address in the form below, and you will
          emailed with instructions on how to reset your password.
        </div>
        <Form
          honeyPot={true}
          uri={getFetchURI('async/login/forgotten.php')}
          className="standard"
          success={_getForgotSuccess()}
          buttonLabel="Reset Password"
        >
          <dl>
            <dd>
              <label htmlFor="login_email">Email Address:</label>
              <input type="text" name="email" id="email" />
            </dd>
          </dl>
          <input type="hidden" name="getLink" value="yup" />
        </Form>
      </React.Fragment>
    );
  }

  function _getResetForm(): React.Node {
    return (
      <React.Fragment>
        <h1>Forgotten Password?</h1>
        <div style={{ marginBottom: '20px' }}>
          Please re-enter your email and a new password of your choice in the
          box below and click 'Reset Password'.
        </div>
        <Form
          honeyPot={true}
          uri={getFetchURI('async/login/forgotten.php')}
          className="standard"
          success={_getResetSuccess()}
          onValidate={_handleValidation}
          buttonLabel="Reset Password"
        >
          <dl>
            <dd>
              <label htmlFor="email">Email</label>
              <input type="text" name="email" id="email" defaultValue={email} />
            </dd>
            <dd>
              <label htmlFor="password">Password</label>
              <input type="password" name="password" id="password" />
            </dd>
            <dd>
              <label htmlFor="cpassword">Repeat New Password</label>
              <input type="password" name="cpassword" id="cpassword" />
            </dd>
          </dl>
          <input type="hidden" name="resetPass" value="yup" />
          <input type="hidden" name="hash" value={props.hash} />
        </Form>
      </React.Fragment>
    );
  }

  function _getForgotSuccess(): React.Node {
    return (
      <span style={{ marginTop: '20px' }}>
        <h2>Success</h2>
        <div>
          Please check your email as we have sent you a message with some simple
          instructions on how you can choose a new password for your account.
        </div>
      </span>
    );
  }

  function _getResetSuccess(): React.Node {
    return (
      <span style={{ marginTop: '20px' }}>
        <h2>Success</h2>
        <p>
          You have successfully updated your password, we have sent you an email
          with your updated password.
        </p>
        <p>Please feel free to browse through our website.</p>
        {basketHelper.getItems().size ? (
          <div>
            If you would now like to checkout please{' '}
            <Link page="BASKET">click here</Link>
          </div>
        ) : null}
      </span>
    );
  }

  function _handleValidation(values: Object): Array<string> {
    let errors = [];

    if (!values.password) {
      errors.push('Please enter a password');
    }

    if (values.password !== values.cpassword) {
      errors.push('Passwords do not match');
    }

    if (values.password) {
      const errors = validatePassword(values.password);
      errors.forEach((err) => errors.push(err));
    }

    if (!values.email) {
      errors.push('Please enter your email address');
    }

    setEmail(values.email);

    return errors;
  }
}
