// @flow

import * as React from 'react';

import { Loading, Page } from '../common';

import ProductDetails from './ProductDetails';
import { useMemo } from 'react';
import { useParams } from 'react-router';
import { useProduct } from '../../hooks/products/useProducts';

function ProductPage() {
  const params = useParams();
  const prodID = parseInt(params.id, 10);
  const { product, isFetching } = useProduct(prodID);

  const pageComponent = useMemo(() => {
    if (product == null || isFetching) {
      return <Loading />;
    }

    return <ProductDetails product={product} />;
  }, [product, isFetching]);

  return <Page>{pageComponent}</Page>;
}

export default ProductPage;
