// @flow

export function getNIMonth(nextMonth: boolean = false): string {
  // month is zero indexed so +1 but sometimes we want next month (Home page)
  const incr = nextMonth ? 2 : 1;
  const date = new Date();
  let year = date.getFullYear();
  let month = date.getMonth() + incr;
  if (month > 12) {
    year = year + 1;
    month = 1;
  }
  const twoDigitYear = year.toString().substring(2, 4);
  const paddedMonth = month.toString().padStart(2, '0');
  return paddedMonth + '-' + twoDigitYear;
}
