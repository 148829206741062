// @flow

import * as React from 'react';

import { PageNames, getURI } from '../../helpers';
import {
  useBasketComments,
  useBasketHelper,
  useRemoveFromBasket,
  useSetOrderID,
  useUpdateBasketQty,
} from '../../hooks/basket';
import {
  useCustomerHelper,
  useDeliveryAddress,
  useRegisterCustomer,
  useSetCCCMembership,
  useStartGuestCheckout,
  useSubscriber,
} from '../../hooks/customer';
import { useNavigate, useParams } from 'react-router';

import BasketAddress from './BasketAddress';
import BasketFailed from './BasketFailed';
import BasketLogin from './BasketLogin';
import BasketPayment from './BasketPayment';
import BasketSuccess from './BasketSuccess';
import BasketTable from './BasketTable';
import { Page } from '../common';

function Basket() {
  const basketHelper = useBasketHelper();
  const customerHelper = useCustomerHelper();
  const startGuestCheckout = useStartGuestCheckout();
  const updateBasketQty = useUpdateBasketQty();
  const removeFromBasket = useRemoveFromBasket();
  const [, setComments] = useBasketComments();
  const [, toggleSubscriber] = useSubscriber();
  const [, setDelivery] = useDeliveryAddress();
  const registerCustomer = useRegisterCustomer();
  const setCCCMembership = useSetCCCMembership();
  const setOrderID = useSetOrderID();
  const { title } = useParams();
  const navigate = useNavigate();

  function getPageComponent(): React.Node {
    switch (title) {
      case PageNames.BASKET_SUCCESS.title:
        return (
          <BasketSuccess
            customerHelper={customerHelper}
            basketHelper={basketHelper}
            setSubscriber={toggleSubscriber}
            registerCustomer={registerCustomer}
          />
        );
      case PageNames.BASKET_FAILED.title:
        return (
          <BasketFailed
            customerHelper={customerHelper}
            basketHelper={basketHelper}
          />
        );
      case PageNames.BASKET_PAYMENT.title:
        if (!customerHelper.isCustomer()) {
          return (
            <BasketLogin
              startGuestCheckout={(rawCustomer) =>
                startGuestCheckout(rawCustomer)
              }
            />
          );
        }
        return (
          <BasketPayment
            basketHelper={basketHelper}
            customerHelper={customerHelper}
            setOrderID={setOrderID}
          />
        );
      case PageNames.BASKET_ADDRESS.title:
        if (!customerHelper.isCustomer()) {
          return (
            <BasketLogin
              startGuestCheckout={(rawCustomer) =>
                startGuestCheckout(rawCustomer)
              }
            />
          );
        }
        return (
          <BasketAddress
            basketHelper={basketHelper}
            customerHelper={customerHelper}
            setDelivery={setDelivery}
            inCheckout={true}
          />
        );
      case PageNames.BASKET_CONFIRM.title:
        if (
          customerHelper.getBillingAddress() &&
          customerHelper.getDeliveryAddress()
        ) {
          return (
            <BasketTable
              basketHelper={basketHelper}
              customerHelper={customerHelper}
              confirmBasket={true}
              setCCC={setCCCMembership}
              setSubscriber={toggleSubscriber}
              updateBasketComments={(comments) => setComments(comments)}
            />
          );
        } else if (customerHelper.isCustomer()) {
          navigate(getURI('BASKET_ADDRESS'));
        }
        return (
          <BasketLogin
            startGuestCheckout={(rawCustomer) =>
              startGuestCheckout(rawCustomer)
            }
          />
        );
      default:
        return (
          <BasketTable
            basketHelper={basketHelper}
            customerHelper={customerHelper}
            confirmBasket={false}
            updateBasketQty={updateBasketQty}
            setCCC={setCCCMembership}
            setSubscriber={toggleSubscriber}
            updateBasketComments={setComments}
            removeFromBasket={removeFromBasket}
          />
        );
    }
  }

  return <Page>{getPageComponent()}</Page>;
}

export default Basket;
