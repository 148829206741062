// @flow

import * as EmailValidator from 'email-validator';
import * as React from 'react';

import { Box, Flexbox, Form, RTEText } from '../common';
import { PageHelpers, getFetchURI } from '../../helpers';
import { useMemo, useState } from 'react';

import AuctionBids from './AuctionBids';
import type { TPageContent } from '../../types';
import { useAuction } from '../../hooks/auction';

type Props = {
  content?: TPageContent,
};

export default function AuctionProcess(props: Props) {
  const { auction, bids, resetBids } = useAuction();

  const [name, setName] = useState('');
  const [address, setAddress] = useState('');
  const [email, setEmail] = useState('');
  const [postcode, setPostcode] = useState('');
  const [telephone, setTelephone] = useState('');
  const [paymentOption, setPaymentOption] = useState('');
  const [maxAmount, setMaxAmount] = useState(null);

  const { content } = props;

  const bidsToUse = useMemo(() => {
    const bidsInternal = {};
    Object.keys(bids).forEach((bidID) => {
      const bid = bids[bidID];
      bidsInternal[bid.lotnum] = bid.max_bid;
    });
    return bidsInternal;
  }, [bids]);

  return (
    <Flexbox flexDirection="column" element="section">
      <Flexbox>
        <Box highlight={true}>
          <span>{_getHeading()}</span>
        </Box>
      </Flexbox>
      <Flexbox>
        <Box>
          <span>
            <AuctionBids readOnly={true} />
          </span>
        </Box>
      </Flexbox>
      <Flexbox>
        <Box>
          <span>
            <Form
              uri={getFetchURI('async/auction/process_bids.php')}
              success={
                <div>
                  <h2>Bids Sent</h2>
                  <div>Bids successfully sent! Good Luck.</div>
                </div>
              }
              onSubmit={resetBids}
              onValidate={_handleValidation}
              buttonLabel="Send Bids"
              className="contactform"
              honeyPot={true}
            >
              {_getInfo()}
              <dl className="large">
                <dd>
                  <label htmlFor="name">Name *</label>
                  <input
                    type="text"
                    name="name"
                    id="name"
                    defaultValue={name}
                  />
                </dd>
                <dd>
                  <label htmlFor="address">Address *</label>
                  <textarea
                    name="address"
                    id="address"
                    className="small"
                    defaultValue={address}
                  />
                </dd>
                <dd>
                  <label htmlFor="postcode">Post Code</label>
                  <input
                    type="text"
                    name="postcode"
                    id="postcode"
                    defaultValue={postcode}
                  />
                </dd>
                <dd>
                  <label htmlFor="telephone">Tel</label>
                  <input
                    type="text"
                    name="telephone"
                    id="telephone"
                    defaultValue={telephone}
                  />
                </dd>
                <dd>
                  <label htmlFor="email">Email *</label>
                  <input
                    type="text"
                    name="email"
                    id="email"
                    defaultValue={email}
                  />
                </dd>
                <dd>
                  <label htmlFor="payment_option">
                    Payment Options (read below) *
                  </label>
                  <select
                    id="payment_option"
                    name="payment_option"
                    value={paymentOption}
                    onChange={(e) => setPaymentOption(e.target.value)}
                  >
                    <option value="">-- Please Select --</option>
                    <option value="invoice">Invoice</option>
                    <option value="online">Online</option>
                  </select>
                </dd>
              </dl>
              <input
                type="hidden"
                name="bids"
                value={JSON.stringify(bidsToUse)}
              />
            </Form>
            <div style={{ marginTop: '12px' }}>{_getNote()}</div>
          </span>
        </Box>
      </Flexbox>
    </Flexbox>
  );

  function _handleValidation(values: Object): Array<string> {
    let errors = [];

    if (!values.name) {
      errors.push('Please enter your name');
    }

    if (!values.address) {
      errors.push('Please enter your address');
    }

    if (!values.email) {
      errors.push('Please enter your email address');
    }

    if (!values.payment_option) {
      errors.push('Please select a payment option');
    }

    if (values.max_amount && isNaN(values.max_amount)) {
      errors.push('Please enter an valid max amount');
    }

    if (values.email) {
      const isEmailValid = EmailValidator.validate(values.email);
      if (!isEmailValid) {
        errors.push('Please enter a valid email address.');
      }
    }

    setName(values.name);
    setAddress(values.address);
    setEmail(values.email);
    setPostcode(values.postcode);
    setTelephone(values.telephone);
    setPaymentOption(values.payment_option);
    setMaxAmount(parseInt(values.max_amount, 10));

    return errors;
  }

  function _getHeading() {
    let intro = PageHelpers.getContent(content, 'Intro').replace(
      /{DATE}/g,
      auction.date,
    );

    return <RTEText content={intro} />;
  }

  function _getInfo() {
    let intro = PageHelpers.getContent(content, 'Bid Request Intro').replace(
      /{BOX}/g,
      '<input type="text" name="max_amount" size="3" defaultValue="' +
        maxAmount +
        '"/>',
    );

    return <RTEText content={intro} />;
  }

  function _getNote() {
    let note = PageHelpers.getContent(content, 'Payment Note');

    return <RTEText content={note} />;
  }
}
