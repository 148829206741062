// @flow

import * as React from 'react';

import { Box, ButtonLink, Flexbox, Link, Price } from '../common';
import { useCustomerHelper, useSubscriber } from '../../hooks/customer';

import LatestCCNImage from '../CardCollectorsClub/LatestCCNImage';
import type { TBasketItemType } from '../../types';
import dateFormat from 'dateformat';
import { getProdURIFromType } from '../../helpers';

function AccountHome() {
  const customerHelper = useCustomerHelper();
  const [isSubscriber, toggleSubscriber] = useSubscriber();

  function _getDate(timestamp: number): React.Node {
    const date = new Date(timestamp * 1000);
    return dateFormat(date, 'dddd, mmmm dS, yyyy');
  }

  function _getProductLink(
    id: string,
    title: string,
    type: TBasketItemType,
    issuer: string,
  ): React.Node {
    const href = getProdURIFromType(id, title, type || 'product');
    return (
      <Link hrefOverride={href}>
        {title} {issuer}
      </Link>
    );
  }

  function _getSecurityInfo(): React.Node {
    return (
      <Box highlight={true} style={{ marginTop: '16px' }}>
        <div className="subheading">
          <h4>Login & Security</h4>
          Your Email: {customerHelper.getEmail()}.{' '}
          <Link page="ACCOUNT_SECURITY">Click here</Link> to update your name,
          email address and password
        </div>
      </Box>
    );
  }

  function _getAddressInfo(): React.Node {
    return (
      <Box highlight={true} style={{ marginTop: '16px' }}>
        <div className="subheading">
          <h4>Your Address Information</h4>
          <div>
            <Link page="ACCOUNT_ADDRESS">Click here</Link> to manage your
            billing and delivery addresses as well as your phone numbers
          </div>
        </div>
      </Box>
    );
  }

  function _getNewsletter(): React.Node {
    let newsletterContent;
    if (isSubscriber) {
      newsletterContent = (
        <span>
          Thanks for subscribing, if you would like to unsubscribe{' '}
          <ButtonLink onClick={toggleSubscriber}>click here</ButtonLink>
        </span>
      );
    } else {
      newsletterContent = (
        <span>
          If you would like to subscribe to our newsletter please{' '}
          <ButtonLink onClick={toggleSubscriber}>click here</ButtonLink>
        </span>
      );
    }

    return (
      <Box highlight={true} style={{ marginTop: '16px' }}>
        <div className="subheading">
          <h4>Newsletter Subscription</h4>
          <div>{newsletterContent}</div>
        </div>
      </Box>
    );
  }

  function _getCCC(): React.Node {
    const isCCCMember = customerHelper.isCCCMember();

    let cccContent;
    if (isCCCMember) {
      cccContent = <span>Thanks for being a member of the club.</span>;
    } else {
      cccContent = (
        <span>
          Don't miss out, If you aren't already a Member{' '}
          <strong>Join the LCCC's Card Collectors Club Today.</strong>
        </span>
      );
    }

    return (
      <Box highlight={true} style={{ marginTop: '16px' }}>
        <div className="subheading">
          <div className="fright" style={{ textAlign: 'center' }}>
            <Link page="CCC">
              <LatestCCNImage />;
            </Link>
          </div>
          <h4>Card Collectors Club</h4>
          <div>
            {cccContent} Over 100 Discounts every month on sets of cards{' '}
            <Link page="CCC">click here for more information </Link>
          </div>
        </div>
      </Box>
    );
  }

  const prevOrders = customerHelper.getPreviousOrders();
  const prevOrdersArr = [];
  for (const o in prevOrders) {
    prevOrdersArr.push(prevOrders[parseInt(o, 10)]);
  }
  return (
    <Flexbox flexDirection="column" element="section">
      <Flexbox element="section">
        <Box highlight={true}>
          <span>
            <h1>Your Account</h1>
            <h4>Welcome {customerHelper.getFirstName()},</h4>
            <div>
              Your Account section allows you to manage your account preferences
              and view your previous orders.
            </div>
          </span>
        </Box>
      </Flexbox>
      <Flexbox element="section" flexDirection="row">
        <Flexbox
          flexGrow={1}
          flexShrink={1}
          flexBasis="0"
          style={{ marginRight: '8px' }}
        >
          <Box>
            <Flexbox flexGrow={1}>
              <Flexbox flexDirection="column" element="section">
                <h1>Previous Orders</h1>
                {prevOrders != null && prevOrdersArr.length === 0 ? (
                  <div>
                    <strong>No Orders</strong>
                  </div>
                ) : null}
                {prevOrdersArr.map((order) => {
                  return (
                    <Box highlight={true} key={order.id}>
                      <Flexbox flexDirection="column" flexGrow={1}>
                        <Flexbox style={{ marginBottom: '4px' }}>
                          <Flexbox flexDirection="row" flexGrow={1}>
                            <Flexbox flexGrow={1}>
                              <strong>
                                Order Placed:{' '}
                                {_getDate(parseInt(order.date, 10))}
                              </strong>
                            </Flexbox>
                            <Flexbox justifyContent="flex-end">
                              <em>Order #{order.id}</em>
                            </Flexbox>
                          </Flexbox>
                        </Flexbox>
                        <Flexbox style={{ marginBottom: '4px' }}>
                          <Flexbox flexDirection="column" element="section">
                            {order.items.map((item) => {
                              return (
                                <Flexbox key={item.type + item.id}>
                                  <span>
                                    {item.qty} x{' '}
                                    {_getProductLink(
                                      item.id.toString(),
                                      item.name,
                                      item.type,
                                      item.issuer,
                                    )}
                                    : <Price price={item.price * item.qty} />
                                  </span>
                                </Flexbox>
                              );
                            })}
                          </Flexbox>
                        </Flexbox>
                        <Flexbox>
                          <Flexbox flexDirection="row" flexGrow={1}>
                            <Flexbox flexGrow={1}>
                              <strong>
                                Order Total: <Price price={order.total} />
                              </strong>
                            </Flexbox>
                          </Flexbox>
                        </Flexbox>
                      </Flexbox>
                    </Box>
                  );
                })}
              </Flexbox>
            </Flexbox>
          </Box>
        </Flexbox>
        <Flexbox flexGrow={1} flexShrink={1} flexBasis="0">
          <Box>
            <Flexbox flexDirection="column" element="section" flexGrow={1}>
              <Flexbox>
                <h1>Account Details</h1>
              </Flexbox>
              <Flexbox>{_getSecurityInfo()}</Flexbox>
              <Flexbox>{_getAddressInfo()}</Flexbox>
              <Flexbox>{_getNewsletter()}</Flexbox>
              <Flexbox>{_getCCC()}</Flexbox>
            </Flexbox>
          </Box>
        </Flexbox>
      </Flexbox>
    </Flexbox>
  );
}

export default AccountHome;
