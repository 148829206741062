// @flow

import * as EmailValidator from 'email-validator';
import * as React from 'react';

import { Box, Flexbox, Form } from '../common';

import LoginForm from '../Login/LoginForm';
import type { TPageContent } from '../../types/page';
import { getFetchURI } from '../../helpers';
import { useState } from 'react';

type Props = {
  content?: TPageContent,
  startGuestCheckout: Function,
};

export default function BasketLogin(props: Props) {
  const [email, setEmail] = useState('');
  const [fname, setFname] = useState('');
  const [sname, setSname] = useState('');

  return (
    <Flexbox flexDirection="column">
      <Flexbox>
        <Box highlight={true}>
          <span style={{ width: '100%' }}>
            <h1>Secure Checkout</h1>
            <div>Have any questions? Please call us on 01458 273452</div>
          </span>
        </Box>
      </Flexbox>
      <Flexbox flexDirection="row">
        <Flexbox style={{ marginRight: '12px' }}>
          <Box>
            <span>
              <h1>Checkout as a guest</h1>
              <div style={{ marginBottom: '8px' }}>
                To shop with us you will need a valid email address, please
                enter it below:
              </div>
              <Form
                honeyPot={true}
                onValidate={handleValidation}
                onSubmit={handleStartCheckout}
                uri={getFetchURI('async/login/guest_checkout.php')}
                failure={getIgnoreAccountError()}
                className="standard"
                buttonLabel="Continue Checkout"
              >
                <dl className="medium" style={{ marginBottom: '12px' }}>
                  <dd>
                    <label htmlFor="email">Email</label>
                    <input
                      type="text"
                      name="email"
                      id="email"
                      key={email}
                      defaultValue={email}
                    />
                  </dd>
                  <dd>
                    <label htmlFor="fname">First Name</label>
                    <input
                      type="text"
                      name="fname"
                      id="fname"
                      key={fname}
                      defaultValue={fname}
                    />
                  </dd>
                  <dd>
                    <label htmlFor="sname">Last Name</label>
                    <input
                      type="text"
                      name="sname"
                      id="sname"
                      key={sname}
                      defaultValue={sname}
                    />
                  </dd>
                </dl>
              </Form>
              <div>
                <em className="smaller">
                  You will have the opportunity to sign up for an optional
                  account later.
                </em>
              </div>
            </span>
          </Box>
        </Flexbox>
        <Flexbox>
          <LoginForm inCheckout={true} />
        </Flexbox>
      </Flexbox>
    </Flexbox>
  );

  function getIgnoreAccountError(): React.Node {
    return (
      <div style={{ marginTop: '8px' }}>
        <div>
          If you would like to continue as a guest without logging in using the
          email address below please check this box{' '}
          <input type="checkbox" value="ignore_account" name="ignore_account" />{' '}
          and click "Contine Checkout" below
        </div>
        <div style={{ marginTop: '4px' }}>
          <strong>Note: </strong> If you continue as a guest this order will not
          be stored against your account
        </div>
      </div>
    );
  }

  function handleStartCheckout(
    values: Object,
    success: boolean,
    __: any,
    rawCustomer: Object,
  ): void {
    if (success) {
      props.startGuestCheckout(rawCustomer);
      return;
    }
    setFname(values.fname);
    setSname(values.sname);
    setEmail(values.email);
  }

  function handleValidation(values: Object): Array<string> {
    let errors = [];
    const isEmailValid = EmailValidator.validate(values.email);
    if (!isEmailValid) {
      errors.push('Invalid email entered.');
    }

    if (!values.fname) {
      errors.push('Please enter a First Name');
    }

    if (!values.sname) {
      errors.push('Please enter a Last Name');
    }

    return errors;
  }
}
