import type { TCategories } from '../../types';
import { categoriesState } from '../../helpers/Atoms';
import { useCallback } from 'react';
import { useRecoilState } from 'recoil';

export function useCategories(): [
  TCategories,
  (newCategories: TCategories) => void,
] {
  const [categories, setCategoriesState] = useRecoilState(categoriesState);

  const setCategories = useCallback(
    (newCategories: TCategory) => {
      setCategoriesState({
        ...categories,
        data: {
          ...newCategories,
        },
      });
    },
    [categories, setCategoriesState],
  );

  return [categories.data, setCategories];
}
