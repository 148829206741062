// @flow
// @format

import * as React from 'react';

import jss from 'jss';
import preset from 'jss-preset-default';

jss.setup(preset());

// Create your style.
const style = {
  linkButton: {
    backgroundColor: 'transparent',
    border: 'none',
    cursor: 'pointer',
    textDecoration: 'none',
    display: 'inline',
    margin: 0,
    padding: 0,
    '&:hover': {
      textDecoration: 'underline',
    },
    '&:focus': {
      textDecoration: 'underline',
    },
    color: '#b75031',
    fontWeight: 'normal',
    fontSize: 'inherit',
    fontFamily:
      'Helvetica, sans-serif, Georgia, "Times New Roman", Times, serif',
  },
};

// Compile styles, apply plugins.
const sheet = jss.createStyleSheet(style).attach();

type Props = {
  onClick?: Function,
  children?: ?React.Node,
};

export default class ButtonLink extends React.Component<Props> {
  render() {
    const { onClick, children } = this.props;
    return (
      <button className={sheet.classes.linkButton} onClick={onClick}>
        {children}
      </button>
    );
  }
}
