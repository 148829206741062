// @flow

import { emptyPromise, fetchWithErrHandler } from '../../helpers';

import { useBasket } from './';
import { useCallback } from 'react';
import { useFetchAlbums } from '../albums';
import { useFetchCCC } from '../ccc';
import { useFetchCatalogues } from '../catalogues';
import { useFetchProducts } from '../products';
import { useFetchSOTMByIDs } from '../sotm/useFetchSOTMByIDs';
import { useOffers } from '../offers';

export function useFetchAndRestoreCart() {
  const [basket, setBasket] = useBasket();
  const restoreProducts = useFetchRestoredProducts();

  const restoreCart = useCallback(
    (id: number, hash: string) => {
      if (
        basket.restoration == null ||
        basket.restoration.isFetching ||
        basket.restoration.hash
      ) {
        return emptyPromise();
      }

      setBasket({
        ...basket,
        restoration: {
          ...basket.restoration,
          isFetching: true,
        },
      });

      return fetchWithErrHandler(
        'async/basket/restore_cart.php?id=' + id + '&hash=' + hash,
      ).then((json) => {
        const neededProducts = {};
        let allIDsToHashes = {};
        if (json.success === false) {
          setBasket({
            ...basket,
            restoration: {
              ...basket.restoration,
              isFetching: false,
            },
          });
          return [];
        }
        json.items = json.items.map((item) => {
          if (item.key === 'a1c8c118dd3002003afe00809db32f4e') {
            item.type = 'offer';
            item.key = '10';
          }
          return item;
        });
        json.items.forEach((item) => {
          switch (item.type) {
            case 'sotm':
              neededProducts.sotm =
                neededProducts.sotm != null ? neededProducts.sotm : [];
              neededProducts.sotm.push({
                ...item,
                id: item.key,
              });
              break;
            case 'frames':
              // not supported
              // TODO: support frames once frames added
              break;
            default:
              neededProducts[item.type] =
                neededProducts[item.type] != null
                  ? neededProducts[item.type]
                  : [];
              neededProducts[item.type].push({
                ...item,
                id: parseInt(item.key, 10),
              });
              break;
          }
        });
        restoreProducts(neededProducts, allIDsToHashes).then((idsToHashes) => {
          console.log(json.items);
          json.items.forEach((_item) => {
            // idsToHashes[item]
          });
          console.log('restored', idsToHashes);
          setBasket({
            ...basket,
            items: {
              ...idsToHashes,
            },
            restoration: {
              ...basket.restoration,
              isFetching: false,
            },
          });
          return idsToHashes;
          // return json.items
          //   .filter((item) => item.type !== 'frames') // TODO: support once frames added
          //   .map((item) => {
          //     const extraData =
          //       idsToHashes[item.type + '-' + item.key.toString()];
          //     if (extraData == null) {
          //       return null;
          //     }
          //     item.key = extraData.key;
          //     item.hash = extraData.hash;
          //     item.qty = parseInt(item.qty, 10);
          //     item.oldPrice = parseFloat(item.oldPrice);
          //     return item;
          //   })
          //   .filter((item) => item != null);
        });
      });
    },
    [restoreProducts, basket.restoration, setBasket],
  );

  return restoreCart;
}

function useFetchRestoredProducts() {
  const fetchSOTMByIDs = useFetchSOTMByIDs();
  const fetchProducts = useFetchProducts();
  const [, fetchCCC] = useFetchCCC();
  const [, fetchCatalogues] = useFetchCatalogues();
  const [, fetchAlbums] = useFetchAlbums();
  const { fetchOffers } = useOffers();

  const fetchers = {
    fetchAlbums,
    fetchCatalogues,
    fetchCCC,
    fetchOffers,
    fetchProducts,
    fetchSOTMByIDs,
  };

  return useCallback(
    (
      neededProducts: { [string]: Array<string> },
      basketItems: any,
    ): Promise<any> => {
      return fetchRestoredProducts(neededProducts, basketItems, fetchers);
    },
    [fetchers],
  );
}

function addSOTM(sotm, item, month) {
  return {
    id: parseInt(sotm.id, 10),
    key: month,
    price: parseFloat(sotm.ccnHash === item.hash ? sotm.ccnPrice : sotm.price),
    wasPrice: parseFloat(sotm.price),
    title:
      sotm.ccnHash === item.hash
        ? sotm.title + ' - Card Collectors Club Discount'
        : sotm.title,
    iShipping: false,
    weight: 0,
    vatRate: 20,
    type: 'sotm',
    hash: sotm.hash,
    qty: parseInt(item.qty, 10),
  };
}

function addProduct(product, item) {
  const prodID = product.id;
  return {
    id: parseInt(prodID, 10),
    key: prodID.toString(),
    price: parseFloat(product.price),
    wasPrice: parseFloat(product.wasPrice),
    title: product.name,
    iShipping: false,
    weight: parseFloat(product.weight),
    vatRate: 20,
    type: 'product',
    hash: product.hash,
    qty: parseInt(item.qty, 10),
  };
}

function addCCC(ccc, item, iShipping) {
  return {
    id: parseInt(ccc.id, 10),
    key: ccc.id.toString(),
    price: parseFloat(ccc.price),
    wasPrice: parseFloat(ccc.price),
    title: ccc.name,
    iShipping,
    weight: 0,
    vatRate: 20,
    type: 'magazine',
    hash: ccc.hash,
    qty: parseInt(item.qty, 10),
  };
}

function addCatalogue(catalogue, item, isCCC) {
  return {
    id: parseInt(catalogue.id, 10),
    key: catalogue.id.toString(),
    price: parseFloat(isCCC ? catalogue.cccPrice : catalogue.price),
    wasPrice: parseFloat(catalogue.price),
    title: isCCC ? catalogue.cccName : catalogue.name,
    iShipping: false,
    weight: 0,
    vatRate: 20,
    type: 'catalogue',
    hash: isCCC ? catalogue.cccHash : catalogue.hash,
    qty: parseInt(item.qty, 10),
  };
}

function addAlbum(album, item) {
  return {
    id: parseInt(album.id, 10),
    key: album.id.toString(),
    price: parseFloat(album.price),
    wasPrice: parseFloat(album.price),
    title: album.name,
    iShipping: false,
    weight: 0,
    vatRate: 20,
    type: 'albums',
    hash: album.hash,
    qty: parseInt(item.qty, 10),
  };
}

function addOffer(offer, item) {
  return {
    id: parseInt(offer.id, 10),
    key: offer.id.toString(),
    price: parseFloat(offer.price),
    wasPrice: parseFloat(offer.price),
    title: offer.name,
    iShipping: false,
    weight: 0,
    vatRate: 20,
    type: 'offer',
    hash: offer.hash,
    qty: parseInt(item.qty, 10),
  };
}

function fetchRestoredProducts(
  neededProducts: { [string]: Array<string> },
  basketItems: any,
  fetchers,
): Promise<any> {
  const types = Object.keys(neededProducts);
  console.log('types left', types);
  if (types.length === 0) {
    console.log('we done', basketItems);
    return emptyPromise(basketItems);
  }
  const lastType = types[types.length - 1].toString();
  const itemsByID = {};
  neededProducts[lastType].forEach((item) => {
    itemsByID[item.id] = item;
  });
  const ids = Object.keys(itemsByID).map((id) => parseInt(id, 10));

  function getNeededProd(prodID) {
    return neededProducts[lastType].find((neededItem) => {
      return neededItem.id === prodID;
    });
  }

  switch (lastType) {
    case 'accessories':
    case 'albums':
    case 'leaves':
    case 'ltAlbums':
    case 'luxuryBinders':
    case 'luxuryLeaves':
    case 'wants':
    case 'wrappings':
    case 'xlAccessories':
      return fetchers.fetchAlbums().then(
        (albums) => {
          console.log('loaded albums', albums);
          Object.keys(albums).forEach((key) => {
            albums[key].forEach((album) => {
              if (getNeededProd(album.id) !== undefined) {
                const item = itemsByID[album.id];
                basketItems[album.hash] = addAlbum(album, item);
              }
            });
          });
          console.log('basketItems = ', { ...basketItems });
          delete neededProducts[lastType];
          return fetchRestoredProducts(neededProducts, basketItems, fetchers);
        },
        (error) => {
          console.log('An error occured when restoring products.', error);
          delete neededProducts[lastType];
          return fetchRestoredProducts(neededProducts, basketItems, fetchers);
        },
      );
    case 'catalogue':
      return fetchers.fetchCatalogues().then(
        (catalogues) => {
          console.log('loaded catalogue', catalogues);
          const thisYear = catalogues.details;
          if (getNeededProd(thisYear.id) !== undefined) {
            const item = itemsByID[thisYear.id];
            basketItems[thisYear.hash] = addCatalogue(thisYear, item, false);
          }
          if (getNeededProd(thisYear.cccID) !== undefined) {
            const item = itemsByID[thisYear.cccID];
            basketItems[thisYear.hash] = addCatalogue(thisYear, item, true);
          }
          const nextYear = catalogues.nextYear;
          if (nextYear != null) {
            if (getNeededProd(nextYear.id) !== undefined) {
              const item = itemsByID[nextYear.id];
              basketItems[nextYear.hash] = addCatalogue(nextYear, item, false);
            }
            if (getNeededProd(nextYear.cccID) !== undefined) {
              const item = itemsByID[nextYear.cccID];
              basketItems[nextYear.hash] = addCatalogue(nextYear, item, true);
            }
          }
          console.log('basketItems = ', { ...basketItems });
          delete neededProducts[lastType];
          return fetchRestoredProducts(neededProducts, basketItems, fetchers);
        },
        (error) => {
          console.log('An error occured when restoring products.', error);
          delete neededProducts[lastType];
          return fetchRestoredProducts(neededProducts, basketItems, fetchers);
        },
      );
    case 'magazine':
    case 'magazineBinders':
    case 'magazineCurrentIssues':
    case 'subscriptions_digital':
    case 'subscriptions_digital_renew':
    case 'subscriptions_uk':
    case 'subscriptions_uk_renew':
    case 'subscriptions_eu':
    case 'subscriptions_eu_renew':
    case 'subscriptions_row':
    case 'subscriptions_row_renew':
      return fetchers.fetchCCC().then(
        (ccc) => {
          console.log('loaded CCC', ccc);
          ccc.currentIssues.forEach((cccProd) => {
            if (getNeededProd(cccProd.id) !== undefined) {
              const item = itemsByID[cccProd.id];
              basketItems[cccProd.hash] = addCCC(cccProd, item, false);
            }
          });
          ccc.binders.forEach((cccProd) => {
            if (getNeededProd(cccProd.id) !== undefined) {
              const item = itemsByID[cccProd.id];
              basketItems[cccProd.hash] = addCCC(cccProd, item, false);
            }
          });
          Object.keys(ccc.subscriptions).forEach((key) => {
            const cccProd = ccc.subscriptions[key];
            if (getNeededProd(cccProd.id) !== undefined) {
              const item = itemsByID[cccProd.id];
              basketItems[cccProd.hash] = addCCC(cccProd, item, true);
            }
          });
          console.log('basketItems = ', { ...basketItems });
          delete neededProducts[lastType];
          return fetchRestoredProducts(neededProducts, basketItems, fetchers);
        },
        (error) => {
          console.log('An error occured when ccc products.', error);
          delete neededProducts[lastType];
          return fetchRestoredProducts(neededProducts, basketItems, fetchers);
        },
      );
    case 'offer':
      return fetchers.fetchOffers().then(
        (offers) => {
          console.log('loaded offers', offers);
          offers.offers.forEach((offer) => {
            if (getNeededProd(offer.id) !== undefined) {
              const item = itemsByID[offer.id];
              basketItems[offer.hash] = addOffer(offer, item);
            }
          });
          console.log('basketItems = ', { ...basketItems });
          delete neededProducts[lastType];
          return fetchRestoredProducts(neededProducts, basketItems, fetchers);
        },
        (error) => {
          console.log('An error occured when restoring products.', error);
          delete neededProducts[lastType];
          return fetchRestoredProducts(neededProducts, basketItems, fetchers);
        },
      );
    case 'product':
      return fetchers.fetchProducts(ids).then(
        (products) => {
          console.log('loaded prods', products);
          Object.keys(products).forEach((prodID) => {
            const prod = products[parseInt(prodID, 10)].data;
            const item = itemsByID[prodID];
            basketItems[prod.hash] = addProduct(prod, item);
          });
          console.log('basketItems = ', { ...basketItems });
          delete neededProducts[lastType];
          return fetchRestoredProducts(neededProducts, basketItems, fetchers);
        },
        (error) => {
          console.log('An error occured when restoring products.', error);
          delete neededProducts[lastType];
          return fetchRestoredProducts(neededProducts, basketItems, fetchers);
        },
      );
    case 'sotm':
      return fetchers.fetchSOTMByIDs(ids).then(
        (sotms) => {
          console.log('loaded SOTM', sotms);
          Object.keys(sotms).forEach((month) => {
            const sotm = sotms[month].data;
            const item = itemsByID[sotm.id];
            basketItems[sotm.hash] = addSOTM(sotm, item, month);
          });
          console.log('basketItems = ', { ...basketItems });
          delete neededProducts[lastType];
          return fetchRestoredProducts(neededProducts, basketItems, fetchers);
        },
        (error) => {
          console.log('An error occured when restoring SOTM.', error);
          delete neededProducts[lastType];
          return fetchRestoredProducts(neededProducts, basketItems, fetchers);
        },
      );
    default:
      console.log('Skipping', lastType);
      delete neededProducts[lastType];
      return fetchRestoredProducts(neededProducts, basketItems, fetchers);
    // throw new Error('Unexpecte Type: ' + lastType);
  }
}
