import CustomerHelper from '../../helpers/CustomerHelper';
import { useCustomer } from './';
import { useMemo } from 'react';

export function useCustomerHelper() {
  const [customer] = useCustomer();

  return useMemo(() => {
    return new CustomerHelper(customer);
  }, [customer]);
}
