// @flow

import * as React from 'react';

import { Box, Flexbox, Link, Page } from './common';

import ContactForm from './ContactForm';

function Contact() {
  return (
    <Page>
      <ContactContent />
    </Page>
  );
}

export default Contact;

function ContactContent() {
  return (
    <Flexbox flexDirection="column">
      <Flexbox element="section">
        <Box highlight={true}>
          <h2>Contact Us</h2>
        </Box>
      </Flexbox>
      <Flexbox element="section">
        <Box>
          <Flexbox flexDirection="column">
            <Flexbox style={{ marginBottom: '12px' }}>
              You can contact us by any of the following means.
            </Flexbox>
            <Flexbox>
              <Flexbox flexDirection="row">
                <Flexbox style={{ width: '300px' }}>
                  <p>
                    <strong>By Post</strong>
                    <br />
                    London Cigarette Card Co Ltd
                    <br />
                    Sutton Road
                    <br />
                    Somerton
                    <br />
                    Somerset
                    <br />
                    TA11 6QP
                    <br />
                  </p>
                </Flexbox>
                <Flexbox flexDirection="column">
                  <Flexbox>
                    <p>
                      <strong>By Phone</strong>
                      <br />
                      01458 273452
                      <br />
                    </p>
                  </Flexbox>
                  <Flexbox>
                    <p>
                      <strong>By Email</strong>
                      <br />
                      cards@londoncigcard.co.uk
                    </p>
                  </Flexbox>
                </Flexbox>
              </Flexbox>
            </Flexbox>
            <Flexbox style={{ marginBottom: '12px' }}>
              <span>
                If you wish to contact us about valuing and selling your
                collection please <Link page="VALUING">click here</Link> for
                further information.
              </span>
            </Flexbox>
            <Flexbox>
              <Flexbox flexDirection="column">
                <Flexbox style={{ marginBottom: '12px' }}>
                  <strong>
                    You can also contact us by filling out the following form
                  </strong>
                </Flexbox>
                <Flexbox>
                  <ContactForm />
                </Flexbox>
              </Flexbox>
            </Flexbox>
          </Flexbox>
        </Box>
      </Flexbox>
    </Flexbox>
  );
}
