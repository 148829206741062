// @flow

import * as React from 'react';

import {
  AddToBasketButton,
  Box,
  Flexbox,
  Link,
  Price,
  RTEText,
} from '../common';
import { PageHelpers, getCCNBackCopiesLink } from '../../helpers';

import CardCollectorsClubBinders from './CardCollectorsClubBinders';
import LatestCCNImage from './LatestCCNImage';
import type { TPageContent } from '../../types';
import { useCCC } from '../../hooks/ccc';

type Props = {
  content?: TPageContent,
};

export default function CardCollectorsClubHome(props: Props) {
  const [ccc] = useCCC();

  return (
    <Flexbox flexDirection="column">
      {_getHeading()}
      {_getSubscribtionText()}
      {_getOfferIntro()}
      <Flexbox>
        <Box>
          <span>
            {_getSubscribtionOptions()}
            {_getCurrentMagazine()}
            {_getBackCopies()}
            {_getBinders()}
            {_getFooter()}
          </span>
        </Box>
      </Flexbox>
    </Flexbox>
  );

  function _getSubscribtionOptions(): React.Node {
    return (
      <React.Fragment>
        <h2>Yearly Subscriptions - Subscribe NOW!</h2>
        <table id="prodlist">
          <thead>
            <tr>
              <td>Brand New Club Membership</td>
              <td>Renew Club Membership</td>
            </tr>
          </thead>
          <tbody>
            {_getSubscriptionRow('uk')}
            {_getSubscriptionRow('eu')}
            {_getSubscriptionRow('row')}
            {_getSubscriptionRow('digital')}
            <tr>
              <td colSpan="2">
                <div style={{ marginBottom: '8px' }}>
                  <b>Digital Subscriptions</b> can be purchased by anyone and
                  you will get an email once a month with a link to download the
                  magazine
                </div>
                <div>
                  <b>Important:</b> The Digital Subscriptions will not receive
                  the free cards, however you will receive an image of the cards
                  with the E-Magazine
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </React.Fragment>
    );
  }

  function _getSubscriptionRow(type: string): React.Node {
    const subscription = ccc.subscriptions[type];
    const renewal = ccc.subscriptions[type + '_renew'];
    return (
      <tr>
        <td>
          <div style={{ lineHeight: '30px' }}>
            <span style={{ marginRight: '12px' }}>
              <AddToBasketButton
                stateKey={subscription && subscription.id}
                type={'subscriptions_' + type}
                hash={subscription && subscription.hash}
                soldout={subscription && subscription.soldout}
              />
            </span>
            {subscription && subscription.label + ' - '}
            <Price price={subscription && subscription.price} />
          </div>
        </td>
        <td>
          <div style={{ lineHeight: '30px' }}>
            <span style={{ marginRight: '12px' }}>
              <AddToBasketButton
                stateKey={renewal && renewal.id}
                type={'subscriptions_' + type + '_renew'}
                hash={renewal && renewal.hash}
                soldout={renewal && renewal.soldout}
              />
            </span>
            {renewal && renewal.label + ' - '}
            <Price price={renewal && renewal.price} />
          </div>
        </td>
      </tr>
    );
  }

  function _getCurrentMagazine(): React.Node {
    return (
      <React.Fragment>
        <p>
          <b>
            Please Note receipts for renewal subscriptions will not be sent
            unless requested.
          </b>
        </p>
        <h2>
          Card Collectors News Magazine Current Issue (Also available to non
          members)
        </h2>
        <table id="prodlist">
          <thead>
            <tr>
              <td colSpan="3">
                To order the current issue of the magazine click the appropriate
                'Add' button.{' '}
              </td>
            </tr>
          </thead>
          <tbody>
            {ccc.currentIssues.map((issue, i) => {
              return (
                <tr key={issue.id}>
                  <td>
                    {issue.label + ' - '}
                    <Price price={issue.price} />
                  </td>
                  <td>
                    <AddToBasketButton
                      stateKey={issue.id}
                      type="magazineCurrentIssues"
                      hash={issue.hash}
                      soldout={issue.soldout}
                    />
                  </td>
                  {i === 0 ? (
                    <td rowSpan={ccc.currentIssues.length}>
                      <LatestCCNImage />
                    </td>
                  ) : null}
                </tr>
              );
            })}
          </tbody>
        </table>
      </React.Fragment>
    );
  }

  function _getBackCopies(): React.Node {
    return (
      <React.Fragment>
        <h2>Card Collectors News Magazine Back Copies</h2>
        <table id="prodlist">
          <thead>
            <tr>
              <td colSpan="2">
                Previous issues dating back to 1940 are also available
              </td>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <Link hrefOverride={getCCNBackCopiesLink()}>Click here</Link> to
                see the full list
              </td>
            </tr>
          </tbody>
        </table>
      </React.Fragment>
    );
  }

  function _getBinders(): React.Node {
    return <CardCollectorsClubBinders />;
  }

  function _getHeading(): React.Node {
    const { content } = props;
    const heading = PageHelpers.getContent(content, 'Heading');

    const offerContent = ccc.offerContent.heading ? (
      <RTEText content={ccc.offerContent.heading} />
    ) : null;

    return (
      <Flexbox>
        <Box highlight={true}>
          <span style={{ width: '100%' }}>
            <RTEText content={heading} />
            {offerContent}
          </span>
        </Box>
      </Flexbox>
    );
  }

  function _getFooter(): React.Node {
    const { content } = props;
    const footer = PageHelpers.getContent(content, 'Footer');

    return <RTEText content={footer} />;
  }

  function _getSubscribtionText(): React.Node {
    const { content } = props;
    const subscription = PageHelpers.getContent(
      content,
      'Subscription Includes',
    );

    return (
      <Flexbox>
        <Box>
          <span>
            <LatestCCNImage style={{ float: 'right', margin: '0 8px' }} />
            <RTEText content={subscription} />
          </span>
        </Box>
      </Flexbox>
    );
  }

  function _getOfferIntro(): React.Node {
    const offerContent = ccc.offerContent;
    if (offerContent === null) {
      return null;
    }

    return (
      <Flexbox>
        <Box>
          <span>
            <LatestCCNImage style={{ float: 'left', paddingRight: '8px' }} />
            <RTEText content={offerContent.info} />
          </span>
        </Box>
      </Flexbox>
    );
  }
}
