// @flow

import * as React from 'react';

import { Loading, Page } from '../common';

import DailyOffer from './DailyOffer';
import { useDailyOffer } from '../../hooks/daily';
import { useMemo } from 'react';
import { useParams } from 'react-router';

function DailyOfferIndex() {
  const { isFetching } = useDailyOffer();
  const { title } = useParams();

  const pageComponent = useMemo(() => {
    if (isFetching) {
      return <Loading />;
    }
    switch (title) {
      default:
        return <DailyOffer />;
    }
  }, [isFetching, title]);

  return <Page>{pageComponent}</Page>;
}

export default DailyOfferIndex;
