import { newIssuesState } from '../../helpers/Atoms';
import { useCallback } from 'react';
import { useRecoilState } from 'recoil';

export function useNewIssues() {
  const [newIssues] = useRecoilState(newIssuesState);

  return useCallback(
    (month) => {
      return newIssues[month]?.data ?? { prodIDs: [] };
    },
    [newIssues],
  );
}
