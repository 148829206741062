import { useCallback, useEffect } from 'react';

import { emailOfferState } from '../../helpers/Atoms';
import { fetch } from '../../helpers';
import { useRecoilState } from 'recoil';

export function useEmailOffer() {
  const [offer, setEmailOfferState] = useRecoilState(emailOfferState);

  const fetchOffer = useCallback(() => {
    if (offer.fetchStatus === 'FETCHED') {
      return;
    }
    setEmailOfferState({ ...offer, fetchStatus: 'FETCHING' });
    return fetch('async/email_offer.php')
      .then(
        (response) => response.json(),
        (error) => {
          setEmailOfferState({ ...offer, fetchStatus: 'FETCHED', error });
          console.log('An error occured.', error);
        },
      )
      .then((json) => {
        if (json.error) {
          setEmailOfferState({
            ...offer,
            fetchStatus: 'FETCHED',
            error: json.error,
          });
          return;
        }
        json.prodIDs =
          json.prodIDs == null
            ? []
            : json.prodIDs.map((id) => parseInt(id, 10));
        json.id = json.id != null ? parseInt(json.id, 10) : null;
        setEmailOfferState({
          ...offer,
          fetchStatus: 'FETCHED',
          data: json,
        });
        return json;
      });
  }, [offer, setEmailOfferState]);

  useEffect(() => {
    if (offer.fetchStatus === 'NONE') {
      fetchOffer();
    }
  }, [offer.fetchStatus, fetchOffer]);

  return {
    offer: offer.data,
    isFetching: offer.fetchStatus !== 'FETCHED',
    error: offer.error,
  };
}
