// @flow

import * as React from 'react';

import { Box, Flexbox, Link } from '../common';

import { getIssuerURI } from '../../helpers';
import { useIssuers } from '../../hooks/products';

export default function IssuerHome() {
  const { issuers } = useIssuers();

  const issuersArray = [];
  Object.keys(issuers).forEach((iID) =>
    issuersArray.push(issuers[parseInt(iID, 10)]),
  );
  issuersArray.sort((a, b) => a.name.localeCompare(b.name));
  return (
    <Flexbox flexDirection="column">
      <Flexbox flexGrow={1}>
        <Box highlight={true}>
          <span>
            <img
              src="/images/postfree.gif"
              className="fright"
              alt="Free Shipping"
            />
            <h1 className="smaller">List of Issuers</h1>
            <p>
              Below is a list of issuers, to view the sets issued by them click
              the 'View' button.
            </p>
          </span>
        </Box>
      </Flexbox>
      <Flexbox>
        <table id="prodlist">
          <thead>
            <tr valign="top">
              <td>Issuer</td>
              <td width="70">No. of Sets</td>
              <td width="50" align="center">
                View
              </td>
            </tr>
          </thead>
          <tbody>
            {issuersArray.map((issuer) => {
              return (
                <tr key={issuer.id}>
                  <td valign="top">
                    <Link hrefOverride={getIssuerURI(issuer.id, issuer.name)}>
                      {issuer.name}
                    </Link>
                  </td>
                  <td valign="top">{issuer.count}</td>
                  <td valign="top" align="center" className="highlight">
                    <Link hrefOverride={getIssuerURI(issuer.id, issuer.name)}>
                      View
                    </Link>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </Flexbox>
    </Flexbox>
  );
}
