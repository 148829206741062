// @flow

import * as React from 'react';

import { Button, ButtonLink, Flexbox, Link } from '../common';

import { useAuction } from '../../hooks/auction';

type Props = {
  readOnly?: boolean,
  hideShowAllButton?: boolean,
};

export default function AuctionBids(props: Props) {
  const { auction, bids, deleteBid } = useAuction();

  return (
    <Flexbox flexDirection="column" element="section">
      <Flexbox>
        <h2>Your bids so far</h2>
      </Flexbox>
      <Flexbox>
        <div className="prodlist" id="linelist">
          <table id="prodlist">
            <thead>
              <tr>
                <td>Lot #</td>
                <td>Description</td>
                <td>Condition</td>
                <td>Estimated Value</td>
                <td>Your Bid</td>
                {props.readOnly ? null : <td>Remove Bid?</td>}
              </tr>
            </thead>
            <tbody>
              {Object.keys(bids).map((bidID) => {
                const bid = bids[bidID];
                const lot = auction.lots[bid.lotnum.toString()];
                if (!lot) {
                  return null;
                }
                return (
                  <tr key={lot.lotnum}>
                    <td valign="top">{lot.lotnum}</td>
                    <td valign="top">{lot.description}</td>
                    <td valign="top">{lot.condition}</td>
                    <td valign="top">&pound;{lot.cat_value || '?'}</td>
                    <td valign="top">&pound;{bid.max_bid}</td>
                    {props.readOnly ? null : (
                      <td valign="top">
                        <ButtonLink onClick={() => deleteBid(lot.lotnum)}>
                          Remove
                        </ButtonLink>
                      </td>
                    )}
                  </tr>
                );
              })}
              {!Object.keys(bids).length && (
                <tr>
                  <td
                    colSpan="6"
                    valign="top"
                    style={{ textAlign: 'center', fontWeight: 'bold' }}
                  >
                    You currently haven't made any bids
                  </td>
                </tr>
              )}
            </tbody>
          </table>
          <Flexbox flexDirection="row">
            {!props.hideShowAllButton ? (
              <Flexbox style={{ marginRight: '12px' }}>
                <Link page="AUC_LOTS">
                  <Button label="View all lots" />
                </Link>
              </Flexbox>
            ) : null}
            {props.readOnly || !Object.keys(bids).length ? null : (
              <Flexbox>
                <Link page="AUC_PROCESS">
                  <Button label="Process Bids" />
                </Link>
              </Flexbox>
            )}
          </Flexbox>
        </div>
      </Flexbox>
    </Flexbox>
  );
}
