// @flow

import * as React from 'react';

import type { TPageNames } from '../../helpers';
import { getURI } from '../../helpers';
import { useNavigate } from 'react-router';

type Props = {
  children: React.Node,
  style?: Object,
  page: TPageNames,
  hrefOverride?: string,
  callback?: Function,
};

export default function Link(props: Props) {
  const navigate = useNavigate();
  function _handleClick(e: SyntheticEvent<*>, href: string) {
    e.preventDefault();
    navigate(href);
    if (props.callback) {
      props.callback();
    }
    if (document.body) {
      document.body.scrollTop = 0; // For Safari
    }
    if (document.documentElement) {
      document.documentElement.scrollTop = 0;
    }
  }

  const { page, children, hrefOverride } = props;
  const href = hrefOverride ? hrefOverride : getURI(page);
  return (
    <a style={props.style} href={href} onClick={(e) => _handleClick(e, href)}>
      {children}
    </a>
  );
}
