// @flow

import * as React from 'react';

import { ProductContainer, ProductName, ProductPrice } from '../Product/';
import { getNIMonth, getNIURI } from '../../helpers';
import { useEffect, useMemo, useState } from 'react';
import { useFetchNewIssues, useNewIssues } from '../../hooks/newissues';

import { Link } from '../common';
import ProductImage from '../ProductPage/ProductImage';

export default function NewIssues() {
  const [newIssueSet, setNewIssueSet] = useState(null);
  const { getIsFetching, getHasFetched, fetchNewIssues } = useFetchNewIssues();
  const getNewIssues = useNewIssues();

  const newIssueMonth = getNIMonth();
  const isFetching = getIsFetching(newIssueMonth);
  const hasFetched = getHasFetched(newIssueMonth);

  const newIssueNextMonth = getNIMonth(true);
  const isFetchingNextMonth = getIsFetching(newIssueNextMonth);
  const hasFetchedNextMonth = getHasFetched(newIssueNextMonth);

  const newissues = useMemo(
    () => getNewIssues(newIssueMonth),
    [getNewIssues, newIssueMonth],
  );

  useEffect(() => {
    if (!isFetching && !hasFetched) {
      fetchNewIssues(newIssueMonth);
    }
    let date = new Date();
    const day = date.getDate();
    if (day > 25 && !isFetchingNextMonth && !hasFetchedNextMonth) {
      fetchNewIssues(newIssueNextMonth);
    }
  }, [
    fetchNewIssues,
    newIssueMonth,
    newIssueNextMonth,
    isFetching,
    isFetchingNextMonth,
    hasFetched,
    hasFetchedNextMonth,
  ]);

  useEffect(() => {
    if (newIssueSet != null || !hasFetched) {
      return;
    }
    const randomSet =
      newissues.prodIDs[Math.floor(Math.random() * newissues.prodIDs.length)];
    setNewIssueSet(randomSet);
  }, [newIssueSet, setNewIssueSet, newissues.prodIDs, hasFetched]);

  if (!newissues || isFetching || !hasFetched) {
    return null;
  }

  const date = new Date();
  const locale = 'en-GB';
  const monthName = date.toLocaleString(locale, { month: 'long' });
  const totalNewIssues = newissues.prodIDs.length;

  if (newIssueSet == null) {
    return null;
  }

  return (
    <ProductContainer prodID={newIssueSet}>
      <div className="subheading">
        <div className="fright" style={{ textAlign: 'center' }}>
          <ProductImage />
        </div>
        <h2>
          <em>{monthName} New Issues</em>
        </h2>
        <div style={{ marginBottom: '8px' }}>
          This is just 1 of the{' '}
          <Link hrefOverride={getNIURI(newIssueMonth)}>
            {totalNewIssues} new issues
          </Link>{' '}
          we have to offer throughout {monthName},{' '}
          <Link hrefOverride={getNIURI(newIssueMonth)}>Click here</Link> to view
          them all.
        </div>
        <div>
          <ProductName />
          <ProductPrice />
        </div>
      </div>
    </ProductContainer>
  );
}
