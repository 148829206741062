// @flow

import * as React from 'react';

import { Box, Breadcrumb, Flexbox, Loading, RTEText } from './';
import {
  PageNames,
  auctionRoute,
  catRoute,
  issuerRoute,
  loginRoute,
  newIssueRoute,
  productRoute,
  restoreCartRoute,
  sotmRoute,
  specialRequestRoute,
} from '../../helpers';
import { matchPath, useLocation, useParams } from 'react-router';
import { useCallback, useMemo } from 'react';

// import { Page404 } from './';
import { useAllProducts } from '../../hooks/products';
import { useCategories } from '../../hooks/categories';
import { usePage } from '../../hooks/pages';

type Props = {
  children?: React.Node,
};

function Page(props: Props) {
  const location = useLocation();
  const params = useParams();
  const _getMatchingURL = useCallback(() => {
    let checkAddress;
    for (const pageConst in PageNames) {
      checkAddress =
        '/' + PageNames[pageConst].path + PageNames[pageConst].title;

      if (
        location.pathname === PageNames[pageConst].route ||
        location.pathname === checkAddress
      ) {
        return PageNames[pageConst].id;
      }
    }
    return null;
  }, [location.pathname]);

  const pageID = useMemo(() => {
    const routesToCheck = [
      {
        route: auctionRoute,
        callback: (params) => {
          const lot = params.id;
          if (lot) {
            return 0;
          }
          return _getMatchingURL();
        },
      },
      {
        route: catRoute,
        callback: (params) => {
          const catID = params.id;
          if (catID) {
            return 0;
          }
          return PageNames.CATEGORY.id;
        },
      },
      {
        route: productRoute,
        callback: (params) => {
          const prodID = parseInt(params.id, 10);
          if (prodID) {
            return 0;
          }
          return PageNames.PRODUCT.id;
        },
      },
      {
        route: newIssueRoute,
        callback: () => {
          return PageNames.NI.id;
        },
      },
      {
        route: restoreCartRoute,
        callback: () => {
          return PageNames.RESTORE_CART.id;
        },
      },
      {
        route: sotmRoute,
        callback: () => {
          return PageNames.SOTM.id;
        },
      },
      {
        route: specialRequestRoute,
        callback: () => {
          return 0;
        },
      },
      {
        route: issuerRoute,
        callback: (params) => {
          const iID = params.id;
          if (iID) {
            return 0;
          }
          return PageNames.ISSUERS.id;
        },
      },
      {
        route: loginRoute,
        callback: (params) => {
          const title = params.title;
          return title ? PageNames.LOGIN_FORGOT.id : PageNames.LOG_IN.id;
        },
      },
    ];

    for (const routeData of routesToCheck) {
      const match = matchPath(
        {
          path: routeData.route,
          exact: true,
          strict: true,
        },
        location.pathname,
      );
      if (match != null) {
        return routeData.callback(match.params);
      }
    }

    return _getMatchingURL();
  }, [params, _getMatchingURL]);

  const { page, isFetching } = usePage(pageID);

  const allProducts = useAllProducts();
  const [allCategories] = useCategories();
  // const { issuers: allIssuers} = useIssuers();

  const breadProps = useMemo(() => {
    return {
      products: allProducts,
      categories: allCategories,
      issuers: [], // allIssuers
    };
  }, [allProducts, allCategories]);

  const pageComponent = useMemo(() => {
    if (isFetching) {
      return <Loading />;
    }

    if (!props.children) {
      const content = page.content;
      return (
        <Flexbox flexDirection="column" element="section">
          <Box>
            <div>
              <span>
                <RTEText content={content[Object.keys(content)[0]]} />
              </span>
            </div>
          </Box>
        </Flexbox>
      );
    }

    return React.Children.map(props.children, (child) =>
      React.cloneElement(child, {
        content: page.content,
      }),
    );
  }, [page, isFetching, props.children]);

  return (
    <Flexbox flexDirection="column">
      <Flexbox>
        <Breadcrumb {...breadProps} />
      </Flexbox>
      <Flexbox flexGrow={1}>{pageComponent}</Flexbox>
    </Flexbox>
  );
}

export default Page;
