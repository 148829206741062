// @flow

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import * as React from 'react';

import { Box, Flexbox, Link, Loading, RTEText } from './common';
import { PageNames, getCategoryURI } from '../helpers';
import { useEffect, useState } from 'react';

import AuctionImage from './auction/AuctionImage';
import AuctionIntro from './Home/Auction';
import Banners from './Home/Banners';
import Columns from 'react-columns';
import DailyOfferIntro from './Home/DailyOffer';
import HomeSpecialOffer from './Home/HomeSpecialOffer';
import LatestCCNImage from './CardCollectorsClub/LatestCCNImage';
import LatestCatalogueImage from './Catalogue/LatestCatalogueImage';
import NewIssues from './Home/NewIssues';
import NewIssuesIntro from './Home/NewIssuesIntro';
import Product from './Product';
import SOTMIntro from './Home/SOTMIntro';
// import Slider from 'react-slick';
import type { TPageContent } from '../types';
import { navWidthWithMargin } from '../helpers/consts';
import { useAuction } from '../hooks/auction';
import { useDailyOffer } from '../hooks/daily';
import { useLastList } from '../helpers/ConfigHelper';
import { useOffers } from '../hooks/offers';
import { usePage } from '../hooks/pages';
import { useProduct } from '../hooks/products/useProducts';

// const COLORS = ['#d6ac2b', '#d6ac2b', '#d6ac2b', '#d6ac2b'];

// const COLORS1 = ['#82D8E3', '#ADE422', '#FFA724', '#FF5252'];

// const COLORS2 = [
//   '#766144',
//   '#766144',
//   '#766144',
//   '#766144',
// ];

const BOOK_ID = 8997;

const Home = () => {
  const { lastList, setLastList } = useLastList();
  const [specialSet, setSpecialSet] = useState(null);
  const { product: bookForHomePage } = useProduct(BOOK_ID);
  const { page, isFetching: pageIsFeching } = usePage(PageNames.HOME.id);
  const { dailyOffer, isFetching: isDailyFetching } = useDailyOffer();

  const {
    auction,
    isFetching: isAuctionFetching,
    error: auctionError,
  } = useAuction();
  const { offers, isFetching: isOffersFetching, fetchOffers } = useOffers();

  useEffect(() => {
    if (lastList !== 'Home') {
      setLastList('Home');
    }
    fetchOffers();
  }, [lastList, setLastList]);

  function _getHomeBoxes(content: TPageContent): React.Node {
    let queries = [];
    for (let i = 1; i <= 5; i++) {
      const width = 400 * i + navWidthWithMargin;
      queries.push({
        columns: i,
        query: 'min-width: ' + width + 'px',
      });
    }

    const boxes = [
      // _getIntro(content['Intro']),
      // _getHeading(content['Heading']),
      _getCurrentUpdates(),
      _getChristmasMessage(content['Christmas']),
      _getDailyOffer(),
      _getSpecialOffers(),
      _getCCC(),
      _getAuction(),
      _getNewsletter(),
      _getCatalogue(),
      _getNewIssues(), // problem here?
      _getSpecialRequestInfo(),
      _getBooks(),
    ].filter((box) => box !== null);

    return (
      <Columns gap="4px" queries={queries}>
        {/* <Flexbox className="homeListItem"
          flexDirection="row">
          <Box highlight={true}>
            <span>{_getHeading(content['Heading'] || '')}</span>
          </Box>
        </Flexbox> */}
        {boxes.map((box, i) => {
          return (
            <Flexbox key={i} className="homeListItem" flexDirection="row">
              {box}
            </Flexbox>
          );
        })}
      </Columns>
    );
  }

  // function _getSlider() {
  //   const settings = {
  //     dots: true,
  //     infinite: false,
  //     speed: 500,
  //     slidesToShow: 5,
  //     slidesToScroll: 5,
  //     initialSlide: 0,
  //     responsive: [
  //       {
  //         breakpoint: 1680,
  //         settings: {
  //           slidesToShow: 4,
  //           slidesToScroll: 4,
  //           infinite: true,
  //           dots: true,
  //         },
  //       },
  //       {
  //         breakpoint: 1320,
  //         settings: {
  //           slidesToShow: 3,
  //           slidesToScroll: 3,
  //         },
  //       },
  //       {
  //         breakpoint: 940,
  //         settings: {
  //           slidesToShow: 2,
  //           slidesToScroll: 2,
  //         },
  //       },
  //       {
  //         breakpoint: 600,
  //         settings: {
  //           slidesToShow: 1,
  //           slidesToScroll: 1,
  //         },
  //       },
  //     ],
  //   };
  //   return (
  //     <Box>
  //       <div style={{}}>
  //         <Slider {...settings}>
  //           {_getNewIssueSlide('New Issues', 0)}
  //           {_getSlide('Set of the Month', 1)}
  //           {_getSlide('Daily Offer', 2)}
  //           {_getSlide('Auction', 3)}
  //           {_getSlide('Catalogues', 0)}
  //           {_getSlide('Albums', 1)}
  //         </Slider>
  //       </div>
  //     </Box>
  //   );
  // }

  // function _getSlider2() {
  //   const settings = {
  //     dots: true,
  //     infinite: false,
  //     speed: 500,
  //     slidesToShow: 5,
  //     slidesToScroll: 5,
  //     initialSlide: 0,
  //     // responsive: [
  //     //   {
  //     //     breakpoint: 1680,
  //     //     settings: {
  //     //       slidesToShow: 4,
  //     //       slidesToScroll: 4,
  //     //       infinite: true,
  //     //       dots: true
  //     //     }
  //     //   },
  //     //   {
  //     //     breakpoint: 1320,
  //     //     settings: {
  //     //       slidesToShow: 3,
  //     //       slidesToScroll: 3,
  //     //     }
  //     //   },
  //     //   {
  //     //     breakpoint: 940,
  //     //     settings: {
  //     //       slidesToShow: 2,
  //     //       slidesToScroll: 2,
  //     //     }
  //     //   },
  //     //   {
  //     //     breakpoint: 600,
  //     //     settings: {
  //     //       slidesToShow: 1,
  //     //       slidesToScroll: 1
  //     //     }
  //     //   }
  //     // ]
  //   };
  //   return (
  //     <Box>
  //       <div style={{}}>
  //         <Slider {...settings}>
  //           {_getSlide2('New Issues', 0)}
  //           {_getSlide2('Set of the Month', 1)}
  //           {_getSlide2('Daily Offer', 2)}
  //           {_getSlide2('Auction', 3)}
  //           {_getSlide2('Catalogues', 0)}
  //           {_getSlide2('Albums', 1)}
  //         </Slider>
  //       </div>
  //     </Box>
  //   );
  // }

  // function _getSlide(title: string, colorKey: number) {
  //   const sepColor = COLORS1[colorKey];
  //   return (
  //     <div>
  //       <div
  //         className="rounded"
  //         style={{
  //           background: '#fdfdf9',
  //           padding: '12px',
  //           border: '1px solid #cab686',
  //           margin: '0 6px',
  //         }}
  //       >
  //         <div style={{ fontSize: '14px', color: '#8a1000' }}>{title}</div>
  //         <div
  //           style={{
  //             height: '5px',
  //             background: sepColor,
  //             marginBottom: '10px',
  //             marginTop: '5px',
  //           }}
  //         ></div>
  //         <div>
  //           We have 29 special offers throughout July, Click view them all
  //         </div>
  //         <div>
  //           <img
  //             src="/images/special_offer.png"
  //             alt="special offer"
  //             style={{ margin: '0 auto 0 auto' }}
  //           />
  //         </div>
  //       </div>
  //     </div>
  //   );
  // }

  // function _getNewIssueSlide(title: string, colorKey: number) {
  //   const sepColor = COLORS1[colorKey];
  //   return (
  //     <div>
  //       <div
  //         className="rounded"
  //         style={{
  //           background: '#fdfdf9',
  //           padding: '12px',
  //           border: '1px solid #cab686',
  //           margin: '0 6px',
  //         }}
  //       >
  //         <div style={{ fontSize: '14px', color: '#8a1000' }}>{title}</div>
  //         <div
  //           style={{
  //             height: '5px',
  //             background: sepColor,
  //             marginBottom: '10px',
  //             marginTop: '5px',
  //           }}
  //         ></div>
  //         <div>We have 29 new arrivals for July, Click view them all</div>
  //         <div>
  //           <img
  //             src="/images/special_offer.png"
  //             alt="special offer"
  //             style={{ margin: '0 auto 0 auto' }}
  //           />
  //         </div>
  //       </div>
  //     </div>
  //   );
  // }

  // function _getSlide2(title: string, colorKey: number) {
  //   const sepColor = COLORS[colorKey];
  //   return (
  //     <div>
  //       <div
  //         className="rounded"
  //         style={{
  //           background: '#fdfdf9',
  //           padding: '12px',
  //           border: '1px solid #cab686',
  //           margin: '0 6px',
  //         }}
  //       >
  //         <div style={{ fontSize: '14px', color: '#8a1000' }}>{title}</div>
  //         <div
  //           style={{
  //             height: '5px',
  //             background: sepColor,
  //             marginBottom: '10px',
  //             marginTop: '5px',
  //           }}
  //         ></div>
  //       </div>
  //     </div>
  //   );
  // }

  function _getHeading(heading: string): React.Node {
    const date = new Date();
    const locale = 'en-GB';
    const dateDisplay = date.toLocaleString(locale, {
      weekday: 'long',
      year: 'numeric',
      month: 'long',
      day: 'numeric',
    });
    heading = heading.replace(/{DATE}/g, dateDisplay);

    return <RTEText content={heading} />;
  }

  function _getChristmasMessage(content: string): ?React.Node {
    const curDate = new Date();
    const month = curDate.getMonth();
    const day = curDate.getDate();
    // month is 0 indexed, date must December & > 11th
    if (month !== 11 || day <= 11) {
      return null;
    }
    return (
      <div className="subheading">
        <RTEText content={content} />
      </div>
    );
  }

  // function _getIntro(intro: string): React.Node {
  //   return (
  //     <span>
  //       <h2>
  //         <em>Your essential collecting tools:</em>
  //       </h2>
  //       <RTEText content={intro} />
  //     </span>
  //   );
  // }

  function _getSpecialOffers(): React.Node {
    if (isOffersFetching || offers.prodIDs.length === 0) {
      return null;
    }

    if (specialSet == null) {
      const randomOffer =
        offers.prodIDs[Math.floor(Math.random() * offers.prodIDs.length)];
      setSpecialSet(randomOffer);
    } else {
      return <HomeSpecialOffer prodID={specialSet} />;
    }
  }

  function _getCCC(): React.Node {
    return (
      <div>
        <div className="subheading">
          <div className="fright" style={{ textAlign: 'center' }}>
            <Link page="CCC">
              <LatestCCNImage />
            </Link>
          </div>
          <h2>
            <em>Card Collectors Club</em>
          </h2>
          <p>
            Don't miss out, Become a Member Today.{' '}
            <strong>Join the LCCC's Card Collectors Club.</strong> Over 100
            Discounts every month on sets of cards{' '}
            <Link page="CCC">click here for more information </Link>
          </p>
        </div>
      </div>
    );
  }

  function _getCatalogue(): React.Node {
    return (
      <div>
        <div className="subheading">
          <div className="fright" style={{ textAlign: 'center' }}>
            <Link page="CATALOGUE">
              <LatestCatalogueImage />
            </Link>
          </div>
          <h2>
            <em>Cigarette and Trade Card Catalogue</em>
          </h2>
          <p>
            The catalogue covers over 17,000 different series, it is an
            essential tool for new or established collectors, or for those
            wanting an idea of the value of their collection.{' '}
            <Link page="CATALOGUE">click here for more information </Link>
          </p>
        </div>
      </div>
    );
  }

  function _getNewsletter(): React.Node {
    return (
      <div>
        <div className="subheading">
          <h2>
            <em>Email Newsletter</em>
          </h2>
          <p>
            Sign up to our email newsletter and keep up to date with what's
            going on in with the LCCC and the world of cards.{' '}
            <Link page="NEWSLETTER">Click here to sign up </Link>
          </p>
        </div>
      </div>
    );
  }

  function _getAuction(): React.Node {
    if (isAuctionFetching || auctionError) {
      return null;
    }
    const intro = auction.info[0];
    if (!intro || !auction) {
      return null;
    }

    return (
      <div>
        <div className="subheading">
          <div className="fright" style={{ textAlign: 'center' }}>
            <Link page="AUC_DETAILS">
              <AuctionImage
                id={intro.image}
                alt={'Auction Lot: ' + intro.img_lot}
                style={{ padding: '8px' }}
              />
              <br />
              <strong>Lot {intro.img_lot}</strong>
            </Link>
          </div>
          <h2>
            <em>Monthly Auction</em>
          </h2>
          <p>
            Our next postal auction ends on {auction.date} and contains{' '}
            {Object.keys(auction.lots).length} lots. With estimated values from
            &pound;1 upwards, Everyone should find something to interest them
            and suit their pocket{' '}
          </p>
        </div>
        <div>
          <div>
            <Link page="AUC_DETAILS">Read more</Link> |{' '}
            <Link page="AUC_LOTS">See list of lots</Link>
          </div>
        </div>
      </div>
    );
  }

  function _getNewIssues(): React.Node {
    return <NewIssues />;
  }

  function _getNextUpdates(): React.Node {
    let date = new Date();
    const day = date.getDate();
    date.setDate(5);
    date.setMonth(date.getMonth() + 1);

    const locale = 'en-GB';
    const monthName = date.toLocaleString(locale, { month: 'long' });

    if (day <= 25) {
      return null;
    }

    return (
      <Flexbox>
        <span>
          <h2>
            <em>{monthName} Updates</em>
          </h2>
          <ul>
            <AuctionIntro />
            <NewIssuesIntro useNextMonth={true} />
          </ul>
        </span>
      </Flexbox>
    );
  }

  function _getCurrentUpdates() {
    const date = new Date();
    const locale = 'en-GB';
    const monthName = date.toLocaleString(locale, { month: 'long' });

    return (
      <Flexbox flexDirection="column">
        {_getNextUpdates()}
        <Flexbox>
          <span>
            <h2>
              <em>{monthName} Updates:</em>
            </h2>
            <ul>
              {/* <li><b>&pound;2.00 & &pound;3.00 Offer</b>: Until December 31st we are also offering big savings on catalogues prices, some half price. Ideal for a starter collection or Christmas gifts - <a href="--><?php //echo $pageObj->getUrl('category', 45); ?><!--">click here for details</a></li> */}
              <NewIssuesIntro useNextMonth={false} />
              <AuctionIntro />
              <li>
                <b>Cards for sale</b>: We have updated many of our{' '}
                <Link page="CATEGORY">thematic lists, Click here</Link> to check
                them out.{' '}
              </li>
              <SOTMIntro />
              {_getOffersIntro(monthName)}
              <li>
                <b>Daily offer</b>: Everyday we offer a new set a special
                discount <Link page="DAILY">click here</Link> to see today's
                offer
              </li>
            </ul>
          </span>
        </Flexbox>
      </Flexbox>
    );
  }

  function _getOffersIntro(monthName: string): React.Node {
    const totalOffers = offers.prodIDs ? offers.prodIDs.length : 0;

    if (!totalOffers) {
      return null;
    }
    return (
      <li>
        <b>Special offers</b>: Throughout {monthName} you can take advantage of
        any of the <Link page="OFFERS">{totalOffers} special offers</Link>
      </li>
    );
  }

  function _getSpecialRequestInfo() {
    return (
      <div className="subheading">
        <h2>
          <em>Odd Cards &amp; Special Requests</em>
        </h2>
        <p>
          We don't list all our stock on the website, please use the{' '}
          <Link page="CONTACT">contact page</Link> to request a quote for odd
          cards and any other items not listed.
        </p>
      </div>
    );
  }

  function _getDailyOffer() {
    if (isDailyFetching) {
      return null;
    }
    return <DailyOfferIntro prodID={dailyOffer.id} />;
  }

  function _getBooks() {
    return (
      <div className="subheading">
        <h2>
          <em>Books for card collecting.</em>
        </h2>
        <p>
          The{' '}
          <Link hrefOverride={getCategoryURI(74, 'Reference Books')}>
            Reference Books page
          </Link>{' '}
          lists the numerous books giving detailed information on card issues
          from home and abroad. The{' '}
          <Link hrefOverride={getCategoryURI(75, 'General Books')}>
            General Books
          </Link>{' '}
          section amongst others items contains books on general card collecting
          and also the video devoted to cigarette cards.
        </p>
        {bookForHomePage && (
          <Product product={bookForHomePage} excludeBorder={true} />
        )}
      </div>
    );
  }

  if (pageIsFeching) {
    return <Loading />;
  }

  const content = page.content;
  return (
    <React.Fragment>
      <Flexbox flexDirection="column" flexGrow={1} style={{ width: '100%' }}>
        {/* <Flexbox style={{
          height: '300px',
          backgroundImage: "url('https://images-na.ssl-images-amazon.com/images/G/01/Gateway/Events/2018/PrimeDay/LeadUp/Desktop/PD18_US_Announce_GW_DT_1500x300._CB475253216_.jpg')",
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center',
        }}>
        </Flexbox> */}
        <Banners />
        <div>
          <Box highlight={true}>
            <div style={{ maxWidth: '800px', margin: '0 auto' }}>
              {_getHeading(content['New Heading'] || '')}
            </div>
          </Box>
        </div>
        {/* <div>
          <Box highlight={true}>
            <span>{_getHeading(content['Heading'] || '')}</span>
          </Box>
        </div> */}
        <div>{_getHomeBoxes(content)}</div>
        {/* <Flexbox>
          {_getSlider()}
        </Flexbox>
        <Flexbox>
          {_getSlider2()}
        </Flexbox> */}
      </Flexbox>
    </React.Fragment>
  );
};

export default Home;
