// @flow

import * as React from 'react';

type Props = {
  children?: React.Node,
  location: string,
};

class ScrollToTop extends React.Component<Props> {
  componentDidUpdate(prevProps: Props) {
    if (this.props.location !== prevProps.location) {
      window.scrollTo(0, 0);
    }
  }

  render(): React.Node {
    return this.props.children || null;
  }
}
export default ScrollToTop;
