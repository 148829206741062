// @flow

import * as PageHelpers from './PageHelpers';
import * as PasswordHelper from './PasswordHelper';
import * as ProductHelpers from './ProductHelpers';

// import AnalyticsHelper from './AnalyticsHelper';
// import BasketHelper from './BasketHelper';
// import ConfigHelper from './ConfigHelper';

// import CustomerHelper from './CustomerHelper';

export * from './Atoms';
export * from './sotm';
export * from './consts';
export * from './newissues';
export * from './PageHelpers';
export * from './PasswordHelper';
export * from './ProductHelpers';
export * from './uris';

export function emptyPromise<T>(val: T): Promise<T> {
  return new Promise((resolve) => {
    resolve(val);
  });
}

export {
  // AnalyticsHelper,
  // BasketHelper,
  // ConfigHelper,
  // CustomerHelper,
  PasswordHelper,
  PageHelpers,
  ProductHelpers,
};
