import * as React from 'react';

import { Link } from '../common';
import Product from '../Product';
import { useProduct } from '../../hooks/products/useProducts';

export default function DailyOfferIntro(props: { prodID: ?number }) {
  const { product } = useProduct(props.prodID);

  if (product == null) {
    return null;
  }

  return (
    <div className="subheading">
      <h2>
        <em>Daily Offer</em>
      </h2>
      <p>
        Everyday we offer a new set at a special discount see below or{' '}
        <Link page="DAILY">click here</Link> to see today's offer.
      </p>
      <Product product={product} excludeBorder={true} />
    </div>
  );
}
