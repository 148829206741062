// @flow

import * as React from 'react';

import { ProductContext, useProduct } from '../../hooks/products';
import { useEffect, useState } from 'react';

type Props = {
  prodID: number,
  children: React.Node,
  caller?: string,
};

/**
 * Only use this if you need to also load the product,
 * using this in a loop will dramtically slow the page
 */
export default function ProductContainer(props: Props) {
  const { product, isFetching } = useProduct(props.prodID, props.caller);
  const [prod, setProd] = useState(() => {
    props.caller != null && console.log('setting default state');
    return null;
  });

  useEffect(() => {
    if (prod == null && product != null) {
      props.caller != null && console.log('setting prod!');
      setProd(product);
    }
  }, [prod, product, setProd, props.caller]);

  props.caller != null &&
    console.log('ProductContainer', product, isFetching, prod, props.children);

  if (prod == null || isFetching) {
    props.caller != null && console.log('do not render');
    return null;
  }

  return (
    <ProductContext.Provider value={prod}>
      {props.children}
    </ProductContext.Provider>
  );
}
