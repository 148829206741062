// @flow

import { fetchWithErrHandler } from '../../helpers';
import { issuersState } from '../../helpers/Atoms';
import { useEffect } from 'react';
import { useRecoilState } from 'recoil';

export function useIssuers() {
  const [issuers, setIssuerState] = useRecoilState(issuersState);

  useEffect(() => {
    if (issuers.fetchStatus === 'NONE') {
      setIssuerState({ ...issuers, fetchStatus: 'FETCHING' });
      fetchWithErrHandler('async/products/issuers.php').then(
        (json) => {
          if (json.error) {
            setIssuerState({
              ...issuers,
              fetchStatus: 'FETCHED',
              error: json.error,
            });
            return;
          }
          const issuersData = {};
          let issuerNames = [];
          Object.keys(json).forEach((issuerID) => {
            const issuer = json[issuerID];
            issuerNames.push({ name: issuer.name, id: issuer.id });
          });
          const sortedIssuers = issuerNames
            .sort((a, b) => a.name.localeCompare(b.name))
            .filter((issuer) => issuer.name.length > 0);
          sortedIssuers.forEach((sortedIssuer) => {
            const issuerID = parseInt(sortedIssuer.id, 10);
            const issuer = json[issuerID];
            issuer.id = issuerID;
            issuer.count = parseInt(issuer.count, 10);
            issuer.prodIDs = issuer.prodIDs.map((id) => parseInt(id, 10));
            issuersData[issuerID] = issuer;
          });
          setIssuerState({
            ...issuers,
            fetchStatus: 'FETCHED',
            data: issuersData,
          });
        },
        (error) => {
          setIssuerState({ ...issuers, fetchStatus: 'FETCHED', error });
          console.log('An error occured.', error);
        },
      );
    }
  }, [issuers, setIssuerState]);

  return {
    issuers: issuers.data,
    isFetching: issuers.fetchStatus !== 'FETCHED',
    error: issuers.error,
  };
}
