// @flow

import type { TBasketItem, TBasketItemType } from '../../types';

import AnalyticsHelper from '../../helpers/AnalyticsHelper';
import { basketState } from '../../helpers/Atoms';
import { getURI } from '../../helpers';
// import { useAllProducts } from '../products';
import { useBasketItems } from './';
import { useCallback } from 'react';
import { useLastList } from '../../helpers/ConfigHelper';
import { useNavigate } from 'react-router';
import { useProdMetaForBasket } from './';
import { useRecoilState } from 'recoil';

export function useAddToBasket() {
  // const allProducts = useAllProducts();
  const getProdMetaForBasket = useProdMetaForBasket();
  const items = useBasketItems();
  const { lastList } = useLastList();
  const upsertItem = useUpsertBasketItem();
  const navigate = useNavigate();
  const getFromList = useCallback(
    (type) => {
      switch (type) {
        case 'product':
          return lastList;
        default:
          // leave as is
          return null;
      }
    },
    [lastList],
  );

  return useCallback(
    async (key: string, hash: string, type: TBasketItemType) => {
      const analytics = new AnalyticsHelper();
      const fromList = getFromList(type);

      console.log(key, hash, type);

      const {
        id,
        price,
        title,
        wasPrice,
        weight,
        iShipping,
        vatRate,
        typeToUse,
      } = await getProdMetaForBasket(key, hash, type);

      const item = {
        id,
        key,
        type: typeToUse,
        price,
        wasPrice,
        weight,
        title,
        hash,
        iShipping,
        qty: 1,
        vatRate,
        fromList,
      };

      let existingItem = items[hash];
      if (existingItem != null) {
        item.qty = item.qty + existingItem.qty;
      }

      upsertItem(item);

      analytics.addToCart(item);
      navigate(getURI('BASKET'));

      if (document.body) {
        document.body.scrollTop = 0; // For Safari
      }
      if (document.documentElement) {
        document.documentElement.scrollTop = 0;
      }
    },
    [items, getProdMetaForBasket, getFromList, upsertItem],
  );
}

function useUpsertBasketItem() {
  const [basket, setBasket] = useRecoilState(basketState);
  return useCallback(
    (item: TBasketItem) => {
      setBasket({
        ...basket,
        items: {
          ...basket.items,
          [item.hash]: item,
        },
      });
    },
    [basket, setBasket],
  );
}
