// @flow

import type {
  TAlbumMeta,
  TAuctionMeta,
  TBannerMeta,
  TBasket,
  TCCCMeta,
  TCCNDownloads,
  TCatalogueMeta,
  TCategoryMeta,
  TConfig,
  TCustomer,
  TEmailOfferMeta,
  TFetchStatus,
  TIssuersMeta,
  TNewIssues,
  TOfferMeta,
  TPage,
  TProduct,
  TPromotions,
  TSOTMs,
  TSearches,
  TSpecialRequests,
} from '../types';
import { atom, selector, selectorFamily } from 'recoil';

export type TLCCCState = $ReadOnly<{
  banners: TBannerMeta,
  categories: $ReadOnly<{}>,
  pages: $ReadOnly<{
    [number]: $ReadOnly<{
      data: TPage,
      fetchStatus: 'FETCHING' | 'FETCHED' | 'NONE',
      error: ?string,
    }>,
  }>,
  dailyOffer: $ReadOnly<{
    data: {
      id: ?number,
      date: ?string,
    },
    fetchStatus: 'FETCHING' | 'FETCHED' | 'NONE',
    error: ?string,
  }>,
  emailOffer: TEmailOfferMeta,
  promotions: TPromotions,
  searches: TSearches,
  specialRequests: TSpecialRequests,
  downloads: TCCNDownloads,
  sotms: TSOTMs,
  products: $ReadOnly<{
    [number]: $ReadOnly<{
      fetchStatus: TFetchStatus,
      error: ?string,
      data: ?TProduct,
    }>,
  }>,
}>;

export const lcccState = atom<TLCCCState>({
  key: 'lcccState',
  default: {
    banners: {
      data: {},
      fetchStatus: 'NONE',
      error: null,
    },
    categories: {},
    products: {},
    pages: {},
    dailyOffer: {
      data: {
        id: null,
        date: null,
      },
      fetchStatus: 'NONE',
      error: null,
    },
    emailOffer: {
      data: {
        id: null,
        active: false,
        prodIDs: [],
      },
      fetchStatus: 'NONE',
      error: null,
    },
    promotions: {},
    searches: {},
    specialRequests: {},
    downloads: {
      initiated: false,
      fetchStatus: 'NONE',
      fetchStatusIntitated: 'NONE',
      error: null,
      data: {
        downloads: [],
        success: false,
      },
    },
    sotms: {
      months: {},
      pastSOTMs: {
        fetchStatus: 'NONE',
        data: {},
        error: null,
      },
    },
  },
});

export const configState = atom<TConfig>({
  key: 'configState',
  default: {
    countries: {},
    products: {
      sortBy: 'name',
      display: 'gallery',
    },
    lastList: '',
    isFetching: false,
  },
});

const localStorageBasketEffect =
  (key) =>
  ({ setSelf, onSet, _trigger }) => {
    const savedValue = localStorage.getItem(key);
    let deserializedValue = {};
    if (savedValue != null) {
      try {
        deserializedValue = JSON.parse(savedValue);
        if (deserializedValue.basket == null) {
          deserializedValue.basket = defaultBasketState;
        }
        setSelf(deserializedValue.basket);
      } catch (e) {
        console.log('Invalid Local Storage');
      }
    }
    onSet((newValue, _oldValue) => {
      const newState = {
        ...deserializedValue,
        lastUpdated: +new Date(),
        basket: {
          ...(deserializedValue.basket ?? defaultBasketState),
          items: newValue.items,
          comments: newValue.comments,
        },
      };
      const serializedState = JSON.stringify(newState);
      localStorage.setItem(key, serializedState);
    });
  };

const localStorageAuctionEffect =
  (key) =>
  ({ setSelf, onSet, _trigger }) => {
    const savedValue = localStorage.getItem(key);
    let deserializedValue = {};
    if (savedValue != null) {
      try {
        deserializedValue = JSON.parse(savedValue);
        setSelf({
          fetchStatus: 'NONE',
          ...deserializedValue.auction,
        });
      } catch (e) {
        console.log('Invalid Local Storage');
      }
    }
    onSet((newValue, _oldValue) => {
      const auctionBids = newValue.bids;
      const auction = newValue.data;
      const date = auction ? auction.date : undefined;
      const newState = {
        ...deserializedValue,
        lastUpdated: +new Date(),
        auction: {
          data: {
            date,
          },
          bids: auctionBids,
        },
      };
      const serializedState = JSON.stringify(newState);
      localStorage.setItem(key, serializedState);
    });
  };

export const defaultBasketState = {
  items: {},
  orderID: null,
  comments: '',
  restoration: {
    isFetching: null,
  },
  trackedConversion: false,
};

export const basketState = atom<TBasket>({
  key: 'basketState',
  default: defaultBasketState,
  effects_UNSTABLE: [localStorageBasketEffect('lccc.state')],
});

export const customerState = atom<TCustomer>({
  key: 'customerState',
  default: {},
});

export const albumState = atom<TAlbumMeta>({
  key: 'albumState',
  default: { isFetching: true, data: {} },
});

export const cccState = atom<TCCCMeta>({
  key: 'cccState',
  default: { isFetching: true, data: {} },
});

export const auctionState = atom<TAuctionMeta>({
  key: 'auctionState',
  default: { fetchStatus: 'NONE', data: {}, bids: {}, error: null },
  effects_UNSTABLE: [localStorageAuctionEffect('lccc.state')],
});

export const catalogueState = atom<TCatalogueMeta>({
  key: 'catalogueState',
  default: { isFetching: true, data: {} },
});

export const categoriesState = atom<TCategoryMeta>({
  key: 'categoriesState',
  default: { isFetching: true, data: {} },
});

export const issuersState = atom<TIssuersMeta>({
  key: 'issuersState',
  default: { fetchStatus: 'NONE', data: {}, error: null },
});

export const newIssuesState = atom<TNewIssues>({
  key: 'newIssuesState',
  default: {},
});

export const offersState = atom<TOfferMeta>({
  key: 'offersState',
  default: { fetchStatus: 'NONE', data: {}, error: null },
});

export const productState = selectorFamily({
  key: 'productState',
  get:
    (prodID) =>
    ({ get }) => {
      const mainState = get(lcccState);
      return mainState.products[prodID];
    },
  set:
    (prodID) =>
    ({ set, get }, newValue) => {
      const mainState = get(lcccState);
      set(lcccState, {
        ...mainState,
        products: {
          ...mainState.products,
          [prodID]: newValue,
        },
      });
    },
});

export const bulkProdState = selector({
  key: 'bulkProdState',
  get: ({ get }) => {
    const mainState = get(lcccState);
    const bulkProdState = mainState.products;
    return bulkProdState == null ? {} : bulkProdState;
  },
  set: ({ set, get }, updatedProds) => {
    const mainState = get(lcccState);
    set(lcccState, {
      ...mainState,
      products: {
        ...mainState.products,
        ...updatedProds,
      },
    });
  },
});

export const relatedProductsState = selectorFamily({
  key: 'relatedProductsState',
  get:
    (prodID) =>
    ({ get }) => {
      const mainState = get(lcccState);
      const relatedProds = mainState.products[prodID].data.relatedProds;
      return relatedProds == null || relatedProds.length === 0
        ? {
            fetchStatus: 'NONE',
            prodIDs: [],
            error: null,
          }
        : mainState.products[prodID].data.relatedProds;
    },
  set:
    (prodID) =>
    ({ set, get }, newValue) => {
      const mainState = get(lcccState);
      set(lcccState, {
        ...mainState,
        products: {
          ...mainState.products,
          [prodID]: {
            ...mainState.products[prodID],
            data: {
              ...mainState.products[prodID].data,
              relatedProds: newValue,
            },
          },
        },
      });
    },
});

export const dailyOfferState = selector({
  key: 'dailyOffer',
  get: ({ get }) => {
    const mainState = get(lcccState);
    return mainState.dailyOffer;
  },
  set: ({ set, get }, newValue) => {
    const mainState = get(lcccState);
    set(lcccState, {
      ...mainState,
      dailyOffer: newValue,
    });
  },
});

export const emailOfferState = selector({
  key: 'emailOffer',
  get: ({ get }) => {
    const mainState = get(lcccState);
    return mainState.emailOffer;
  },
  set: ({ set, get }, newValue) => {
    const mainState = get(lcccState);
    set(lcccState, {
      ...mainState,
      emailOffer: newValue,
    });
  },
});

export const bannerState = selector({
  key: 'banners',
  get: ({ get }) => {
    const mainState = get(lcccState);
    return mainState.banners;
  },
  set: ({ set, get }, newValue) => {
    const mainState = get(lcccState);
    set(lcccState, {
      ...mainState,
      banners: newValue,
    });
  },
});

export const downloadState = selector({
  key: 'downloadState',
  get: ({ get }) => {
    const mainState = get(lcccState);
    return mainState.downloads;
  },
  set: ({ set, get }, newValue) => {
    const mainState = get(lcccState);
    set(lcccState, {
      ...mainState,
      downloads: newValue,
    });
  },
});

export const pageState = selectorFamily({
  key: 'pageState',
  get:
    (pageID) =>
    ({ get }) => {
      const mainState = get(lcccState);
      const pageState = mainState.pages[pageID];
      return pageState == null
        ? {
            data: {
              id: pageID,
              name: '',
              title: '',
              keywords: '',
              description: '',
              content: {},
            },
            fetchStatus: 'NONE',
            error: null,
          }
        : pageState;
    },
  set:
    (pageID) =>
    ({ set, get }, newValue) => {
      const mainState = get(lcccState);
      set(lcccState, {
        ...mainState,
        pages: {
          ...mainState.pages,
          [pageID]: newValue,
        },
      });
    },
});

export const promotionState = selectorFamily({
  key: 'promotionState',
  get:
    (promotionID) =>
    ({ get }) => {
      const mainState = get(lcccState);
      const promotionState = mainState.promotions[promotionID];
      return promotionState == null
        ? {
            data: {
              prodIDs: [],
            },
            fetchStatus: 'NONE',
            error: null,
          }
        : promotionState;
    },
  set:
    (promotionID) =>
    ({ set, get }, newValue) => {
      const mainState = get(lcccState);
      set(lcccState, {
        ...mainState,
        promotions: {
          ...mainState.promotions,
          [promotionID]: newValue,
        },
      });
    },
});

export const searchState = selectorFamily({
  key: 'searchState',
  get:
    (searchString) =>
    ({ get }) => {
      const mainState = get(lcccState);
      const searchState = mainState.searches[searchString];
      return searchState == null
        ? {
            data: {
              search: '',
              prodIDs: [],
              note: '',
            },
            fetchStatus: 'NONE',
            error: null,
          }
        : searchState;
    },
  set:
    (searchString) =>
    ({ set, get }, newValue) => {
      const mainState = get(lcccState);
      set(lcccState, {
        ...mainState,
        searches: {
          ...mainState.searches,
          [searchString]: newValue,
        },
      });
    },
});

export const specialRequestState = selectorFamily({
  key: 'specialRequestState',
  get:
    (id) =>
    ({ get }) => {
      const mainState = get(lcccState);
      const specialRequestState = mainState.specialRequests[id];
      return specialRequestState == null
        ? {
            data: {
              id: 0,
              hash: '',
              description: '',
              price: 0,
            },
            fetchStatus: 'NONE',
            error: null,
          }
        : specialRequestState;
    },
  set:
    (id) =>
    ({ set, get }, newValue) => {
      const mainState = get(lcccState);
      set(lcccState, {
        ...mainState,
        specialRequests: {
          ...mainState.specialRequests,
          [id]: newValue,
        },
      });
    },
});

export const sotmState = selectorFamily({
  key: 'sotmState',
  get:
    (month) =>
    ({ get }) => {
      const mainState = get(lcccState);
      const sotmState = mainState.sotms.months[month];
      return sotmState == null
        ? {
            data: {},
            fetchStatus: 'NONE',
            error: null,
          }
        : sotmState;
    },
  set:
    (month) =>
    ({ set, get }, newValue) => {
      const mainState = get(lcccState);
      set(lcccState, {
        ...mainState,
        sotms: {
          ...mainState.sotms,
          months: {
            ...mainState.sotms.months,
            [month]: newValue,
          },
        },
      });
    },
});

export const bulkSOTMsState = selector({
  key: 'bulkSOTMsState',
  get: ({ get }) => {
    const mainState = get(lcccState);
    const sotmState = mainState.sotms.months;
    return sotmState == null ? {} : sotmState;
  },
  set: ({ set, get }, newBulkMonts) => {
    const mainState = get(lcccState);
    set(lcccState, {
      ...mainState,
      sotms: {
        ...mainState.sotms,
        months: {
          ...mainState.sotms.months,
          ...newBulkMonts,
        },
      },
    });
  },
});

export const pastSOTMState = selector({
  key: 'pastSOTMState',
  get: ({ get }) => {
    const mainState = get(lcccState);
    const sotmState = mainState.sotms.pastSOTMs;
    return sotmState == null
      ? {
          data: {},
          fetchStatus: 'NONE',
          error: null,
        }
      : sotmState;
  },
  set: ({ set, get }, newValue) => {
    const mainState = get(lcccState);
    set(lcccState, {
      ...mainState,
      sotms: {
        ...mainState.sotms,
        pastSOTMs: newValue,
      },
    });
  },
});
