// @flow

import * as React from 'react';

import { getCategoryURI, getFetchURI } from '../../helpers';

import AnalyticsHelper from '../../helpers/AnalyticsHelper';
import { Link } from '../common';
import { useCategory } from '../../hooks/categories';

type Props = {
  id: number,
  isChild: boolean,
};

const analytics = new AnalyticsHelper();

export default function CategoryListRow(props: Props) {
  const { isChild, id } = props;
  const [category] = useCategory(id);
  const className = isChild ? 'list indented' : 'list';
  const prefix = isChild ? '\u00bb' : '';

  return (
    <tr>
      <td valign="top" className="highlight">
        <span className={className}>
          <Link
            hrefOverride={getCategoryURI(category.id, category.name)}
            callback={() => analytics.trackCategoryClick(category.navName)}
          >
            {prefix} {category.name}
          </Link>
        </span>
      </td>
      <td valign="top" className="highlight">
        {category.modified}
      </td>
      <td valign="top" align="center">
        <Link hrefOverride={getCategoryURI(category.id, category.name)}>
          View
        </Link>
      </td>
      <td align="center">{_getRequestable()}</td>
    </tr>
  );

  function _getRequestable(): React.Node {
    if (!category.requestable) {
      return '---';
    }
    const pdfURI = getFetchURI('list_download.php?list=' + category.id);
    return (
      <a href={pdfURI} target="_blank" rel="noopener noreferrer">
        PDF
      </a>
    );
  }
}
