// @flow

import * as React from 'react';

import { Loading, Page } from '../common';

import CatalogueHome from './CatalogueHome';
import { useEffect } from 'react';
import { useFetchCatalogues } from '../../hooks/catalogues';

type Props = {};

function Catalogue(props: Props) {
  const [isFetching, fetchCatalogues] = useFetchCatalogues();

  useEffect(() => {
    fetchCatalogues();
  }, [fetchCatalogues]);

  function _getPageComponent(): React.Node {
    if (isFetching) {
      return <Loading />;
    }

    return <CatalogueHome />;
  }

  return <Page {...props}>{_getPageComponent()}</Page>;
}

export default Catalogue;
