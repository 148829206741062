// @flow

import type { TSetOfTheMonth } from '../../types';
import { sotmState } from '../../helpers/Atoms';
import { useRecoilCallback } from 'recoil';

export function useSOTMFromSnapshot() {
  return useRecoilCallback<[string], Promise<TSetOfTheMonth>>(
    ({ snapshot }) =>
      async (monthToUse: string) => {
        const sotm = await snapshot.getPromise(sotmState(monthToUse));
        return sotm.data;
      },
    [],
  );
}
