// @flow

import * as React from 'react';

import { Button } from './';
import type { TBasketItemType } from '../../types';
import { useAddToBasket } from '../../hooks/basket';
import { useCallback } from 'react';

type Props = {
  stateKey: number | string,
  type: TBasketItemType,
  hash: string,
  buttonLabel?: string,
  soldout?: boolean,
  callback?: Function,
};

function AddToBasketButton(props: Props) {
  const addToBasket = useAddToBasket();

  const handleAdd = useCallback(() => {
    addToBasket(props.stateKey.toString(), props.hash, props.type);

    if (props.callback) {
      props.callback();
    }
  }, [addToBasket, props]);

  const defaultLabel = props.soldout ? 'Sold Out' : 'Add to Cart';
  return (
    <Button
      label={props.buttonLabel ? props.buttonLabel : defaultLabel}
      onClick={() => handleAdd()}
      disabled={props.soldout}
    />
  );
}

export default AddToBasketButton;
