// @flow

// import '../style.css'
// import '../../styles/oldStyles.css'
import '../../styles/main.css';
import '../../styles/searchBox.css';
import '../../styles/productList.css';

import * as React from 'react';

import { Outlet, useLocation } from 'react-router-dom';
import { navMargin, navWidth } from '../helpers/consts';
import { useCallback, useEffect } from 'react';

import AnalyticsHelper from '../helpers/AnalyticsHelper';
import Flexbox from 'flexbox-react';
import Footer from './Footer';
import Header from './header/Header';
import Left from './Left';
import { Loading } from './common';
import { fetchWithErrHandler } from '../helpers';
import { useBasket } from '../hooks/basket';
import { useConfigHelper } from '../helpers/ConfigHelper';
import { useCustomer } from '../hooks/customer';

const analytics = new AnalyticsHelper();

export type TMatch = {
  params: {
    month: string,
    title: string,
    id: number,
    hash: string,
    pg: string,
  },
  path: string,
  url: string,
};

export type TLocation = {
  pathname: string,
  search: string,
};

export type THistory = {
  push: Function,
  location: TLocation,
  listen: Function,
};

function App(): React.MixedElement {
  const [basketState, setBasketState] = useBasket();
  const [config, setConfig] = useConfigHelper();
  const [, setCustomerState] = useCustomer();

  const location = useLocation();

  useEffect(() => {
    analytics.trackPage();
  }, [location]);

  const setBasket = useCallback(
    (updatedValue) => {
      setBasketState({ ...basketState, ...updatedValue });
    },
    [setBasketState],
  );

  const setCustomer = useCallback(
    (customer) => {
      setCustomerState(customer);
    },
    [setCustomerState],
  );

  useEffect(() => {
    if (config.isFetching() || config.isLoaded()) {
      return;
    }
    setConfig({ isFetching: true });
    fetchWithErrHandler('async/session_state.php').then(
      (json) => {
        if (json == null) {
          Sentry.captureMessage('No JSON returned from session_state.php');
          setConfig({
            isFetching: false,
            isLoaded: true,
          });
          return;
        }
        if (Object.keys(json.customer).length) {
          const addresses = {};
          json.customer.addresses = Object.keys(
            json.customer.addresses,
          ).forEach((aID) => {
            aID = parseInt(aID, 10);
            const address = json.customer.addresses[aID];
            address.id = aID;
            address.country_id = parseInt(address.country_id, 10);
            addresses[aID] = address;
          });
          json.customer.addresses = addresses;
          json.customer.dID = parseInt(json.customer.dID, 10);
          json.customer.bID = parseInt(json.customer.bID, 10);

          setCustomer(json.customer);
        }
        setConfig({
          ...json.config,
          isFetching: false,
          isLoaded: true,
        });
        if (json.orderID) {
          setBasket({
            orderID: json.orderID,
          });
        }
      },
      (_error) => {
        setConfig({
          isFetching: false,
        });
      },
    );
  }, [config, setBasket, setConfig, setCustomer]);

  return (
    <Flexbox flexDirection="column" element="section">
      <Flexbox element="header">
        <Header />
        V6.
      </Flexbox>
      <Flexbox className="contentWrapper">
        <Flexbox className="contentOuter">
          <Flexbox
            flexDirection="row"
            element="section"
            className="content"
            flexGrow={1}
          >
            <Flexbox
              width={navWidth + 'px'}
              element="aside"
              style={{
                minWidth: navWidth + 'px',
                marginRight: navMargin + 'px',
              }}
            >
              <Left />
            </Flexbox>
            <Flexbox
              flexGrow={1}
              element="main"
              style={{
                width: '100%',
              }}
            >
              <div style={{ width: '100%', paddingRight: '12px' }}>
                {config.isFetching() ? <Loading /> : <Outlet />}
              </div>
            </Flexbox>
          </Flexbox>
        </Flexbox>
      </Flexbox>
      <Flexbox className="contentWrapper">
        <Flexbox className="contentOuter">
          <Flexbox element="footer" style={{ width: '100%' }}>
            <Footer />
          </Flexbox>
        </Flexbox>
      </Flexbox>
    </Flexbox>
  );
}

export default App;
