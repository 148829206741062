// @flow

import * as React from 'react';

import { useCategory, useFetchCategories } from '../../hooks/categories';
import { useEffect, useMemo } from 'react';

import { ProductContainer } from '../Product/';
import ProductImage from '../ProductPage/ProductImage';

type Props = {
  style?: Object,
  caller?: string,
};

function LatestCCNImage(props: Props) {
  const [cat] = useCategory(37);
  const [, fetchCategories] = useFetchCategories();

  const prodID = useMemo(() => {
    if (!cat) {
      return null;
    }
    return [...cat.prodIDs].sort((a, b) => {
      return parseInt(a) - parseInt(b);
    })[cat.prodIDs.length - 1];
  }, [cat]);

  useEffect(() => {
    fetchCategories();
  }, [fetchCategories]);

  props.caller != null &&
    console.log('LatestCCNImage attempt to Render', prodID);

  if (prodID == null) {
    return <span />;
  }

  return (
    <ProductContainer prodID={prodID} caller={props.caller}>
      <ProductImage
        style={props.style}
        excludeLink={true}
        caller={props.caller}
      />
    </ProductContainer>
  );
}

export default LatestCCNImage;
