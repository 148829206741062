// @flow

import * as React from 'react';

import { Box, Flexbox, Link } from '../common';

import ProductList from '../ProductList';
import { useEmailOffer } from '../../hooks/offers';

export default function EmailOfferHome() {
  const { offer } = useEmailOffer();

  return (
    <Flexbox flexDirection="column">
      <Flexbox flexGrow={1}>
        <Box highlight={true}>
          <span>
            <img
              src="/images/postfree.gif"
              className="fright"
              alt="Post Free"
            />
            <h1 className="smaller">{_getHeading()}</h1>
            {_getHeadingText()}
            <p>
              <b>Post free to UK addresses</b> Europe and International Postage
              will be charged at cost. Orders dispatched to outside the European
              Community will have 15% deducted.
            </p>
          </span>
        </Box>
      </Flexbox>
      {_getCheckActive()}
      {_getProducts()}
    </Flexbox>
  );

  function _getHeading(): React.Node {
    switch (offer.id) {
      case '23':
        return 'Bargain Offer: Complete sets in mint condition!';
      default:
        return 'Special Offers';
    }
  }

  function _getHeadingText(): React.Node {
    switch (offer.id) {
      case 23:
        return (
          <p className="smaller">
            Big savings on catalogues prices, some under half price. Ideal for a
            starter collection or Christmas gifts just for &pound;2 &amp;
            &pound;3 a set
          </p>
        );
      case 1313:
        return (
          <p className="smaller">
            As you are a CCN subscriber you are eligible for the following
            further offers which expire at the end of the month. Thank you for
            your continued custom.
          </p>
        );
      default:
        return (
          <p className="smaller">
            As you are an e-mail newsletter subscriber or have placed an order
            via our website you are eligible for the following further offers
            which expire at the end of the month. Thank you for your continued
            custom.
          </p>
        );
    }
  }

  function _getCheckActive(): React.Node {
    if (offer.active === true) {
      return null;
    }
    return (
      <Flexbox flexGrow={1}>
        <Box>
          <span>
            <h1 className="smaller">Offer Ended</h1>
            <p className="smaller">
              Unfortunately this offer has ended. But for our other special
              offers please <Link page="OFFERS">click here</Link>
            </p>
          </span>
        </Box>
      </Flexbox>
    );
  }

  function _getProducts(): React.Node {
    if (offer.prodIDs == null || offer.active === false) {
      return null;
    }
    return <ProductList prodIDs={offer.prodIDs} />;
  }
}
