import { emptyPromise, fetchWithErrHandler } from '../../helpers';

import { cccState } from '../../helpers/Atoms';
import { useCallback } from 'react';
import { useRecoilState } from 'recoil';

export function useFetchCCC() {
  const [ccc, setCCC] = useRecoilState(cccState);

  const fetchCCC = useCallback(() => {
    if (!ccc.isFetching) {
      return emptyPromise({});
    }

    return fetchWithErrHandler('async/ccc.php').then((json) => {
      json.latestCCN = parseInt(json.latestCCN, 10);
      json.currentIssues = json.currentIssues.map((item) => {
        item.id = parseInt(item.id, 10);
        item.price = parseFloat(item.price);
        return item;
      });
      json.binders = json.binders.map((item) => {
        item.id = parseInt(item.id, 10);
        item.price = parseFloat(item.price);
        return item;
      });
      let newSubscriptions = {};
      Object.keys(json.subscriptions).forEach((key) => {
        const newSubscription = json.subscriptions[key];
        newSubscription.id = parseInt(newSubscription.id, 10);
        newSubscription.price = parseFloat(newSubscription.price);
        newSubscriptions[key] = newSubscription;
      });
      json.subscriptions = newSubscriptions;
      setCCC({
        ...ccc,
        isFetching: false,
        data: json,
      });
      return json;
    });
  }, [ccc, setCCC]);

  return [ccc.isFetching, fetchCCC];
}
