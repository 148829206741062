// @flow

import 'react-awesome-button/dist/styles.css';
import 'react-awesome-button/dist/themes/theme-blue.css';

import * as React from 'react';

import { AwesomeButton } from 'react-awesome-button';

type Props = {
  label: string,
  onClick?: Function,
  style?: Object,
  disabled: boolean,
};

export default class Button extends React.Component<Props> {
  render() {
    return (
      <AwesomeButton
        disabled={this.props.disabled}
        style={this.props.style}
        bubbles={true}
        action={this.props.onClick}
        type="primary"
      >
        {this.props.label}
      </AwesomeButton>
    );
  }
}
