// @flow

import * as Sentry from '@sentry/react';

import {
  Route,
  RouterProvider,
  createBrowserRouter,
  createRoutesFromElements,
} from 'react-router-dom';
import {
  accountRoute,
  albumRoute,
  auctionRoute,
  basketRoute,
  bookRoute,
  catHome,
  catRoute,
  catRouteNoTitle,
  catalogueRoute,
  cccRoute,
  ccnDownloadRoute,
  contactRoute,
  dailyRoute,
  emailOfferRoute,
  homeRoute,
  issuerRootRoute,
  issuerRoute,
  loginRoute,
  newIssueRootRoute,
  newIssueRoute,
  newsletterRoute,
  offersRoute,
  pageRoute,
  productRoute,
  promotionRoute,
  restoreCartRoute,
  searchRoute,
  sotmRoute,
  specialRequestRoute,
  valuingRoute,
} from '../helpers';

import Account from './account';
import Album from './Album';
import App from './App';
import Auction from './auction';
import Basket from './Basket';
import Books from './Books';
import CCNDownloads from './CCNDownloads';
import CardCollectorsClub from './CardCollectorsClub';
import Catalogue from './Catalogue';
import Category from './Category/index';
import Contact from './Contact';
import Daily from './Daily';
import EmailOffer from './EmailOffer';
import Home from './Home';
import Issuers from './Issuers';
import Login from './Login/Login';
import NewIssues from './newissues';
import Newsletter from './Newsletter';
import Offers from './Offers';
import Page from './common/Page';
// import { Page404 } from './common';
import ProductPage from './ProductPage';
import Promotions from './Promotions';
import React from 'react';
import { RecoilRoot } from 'recoil';
import RestoreCart from './RestoreCart';
import ScrollToTop from './ScrollToTop';
import Search from './Search';
import SetOfTheMonth from './sotm';
import SpecialRequest from './SpecialRequest';
import Valuing from './Valuing';

const sentryCreateBrowserRouter =
  Sentry.wrapCreateBrowserRouter(createBrowserRouter);

const router = sentryCreateBrowserRouter(
  createRoutesFromElements(
    <Route path="/" element={<App />}>
      <Route index element={<Home />} />
      <Route exact path={accountRoute} element={<Account />} />
      <Route exact path={albumRoute} element={<Album />} />
      <Route exact path={auctionRoute} element={<Auction />} />
      <Route exact path={basketRoute} element={<Basket />} />
      <Route exact path={bookRoute} element={<Books />} />
      <Route exact path={catHome} element={<Category />} />
      <Route exact path={catRouteNoTitle} element={<Category />} />
      <Route exact path={catRoute} element={<Category />}>
        <Route exact path=":pg" element={<Category />} />
      </Route>
      <Route exact path={catalogueRoute} element={<Catalogue />} />
      <Route exact path={cccRoute} element={<CardCollectorsClub />} />
      <Route exact path={ccnDownloadRoute} element={<CCNDownloads />} />
      <Route exact path={contactRoute} element={<Contact />} />
      <Route exact path={dailyRoute} element={<Daily />} />
      <Route exact path={emailOfferRoute} element={<EmailOffer />} />
      <Route exact path={loginRoute} element={<Login />} />
      <Route path={newIssueRootRoute} element={<NewIssues />} />
      <Route path={newIssueRoute} element={<NewIssues />} />
      <Route exact path={issuerRootRoute} element={<Issuers />} />
      <Route exact path={issuerRoute} element={<Issuers />} />
      <Route exact path={newsletterRoute} element={<Newsletter />} />
      <Route exact path={offersRoute} element={<Offers />} />
      <Route exact path={productRoute} element={<ProductPage />} />
      <Route exact path={promotionRoute} element={<Promotions />} />
      <Route exact path={searchRoute} element={<Search />} />
      <Route path={sotmRoute} element={<SetOfTheMonth />} />
      <Route exact path={specialRequestRoute} element={<SpecialRequest />} />
      <Route exact path={valuingRoute} element={<Valuing />} />
      <Route exact path={homeRoute} element={<Home />} />
      <Route exact path={restoreCartRoute} element={<RestoreCart />} />
      <Route exact path={pageRoute} element={<Page />} />
    </Route>,
  ),
);

export default function Root() {
  return (
    <RecoilRoot>
      <ScrollToTop>
        <RouterProvider router={router}></RouterProvider>
      </ScrollToTop>
    </RecoilRoot>
  );
}
