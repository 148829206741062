// @flow

import { useCallback, useEffect, useMemo, useState } from 'react';

import { bulkProdState } from '../../helpers/Atoms';
import { fetchPOST } from '../../helpers';
import { useRecoilState } from 'recoil';

export function useFetchProducts(): (
  ids: $ReadOnlyArray<number>,
  caller?: string,
) => void {
  return useCallback((ids: $ReadOnlyArray<number>, _caller?: string) => {
    const searchParams = new URLSearchParams();
    searchParams.set('ids', JSON.stringify(ids));
    return fetchPOST('async/products.php', searchParams)
      .then(
        (response) => response.json(),
        (error) => console.log('An error occured.', error),
      )
      .then((json) => {
        const newProds = {};
        Object.keys(json).forEach((prodID) => {
          const rawProd = json[prodID];
          prodID = parseInt(prodID, 10);
          const prod = {
            ...rawProd,
            id: prodID,
            price: parseFloat(rawProd.price),
            wasPrice: parseFloat(rawProd.wasPrice),
            images: rawProd.images.map((image) => parseInt(image, 10)),
            relatedProds: [],
          };
          newProds[prodID] = {
            fetchStatus: 'FETCHED',
            error: null,
            data: prod,
          };
        });
        ids.forEach((id) => {
          if (newProds[id] == null) {
            newProds[id] = {
              fetchStatus: 'FETCHED',
              error: 'No Product Found',
              data: null,
            };
          }
        });
        return {
          ...newProds,
        };
      });
  }, []);
}

// export function useFetchProductsOLD() {
//   const products = useRecoilValue(bulkProdState);

//   return useCallback(
//     (ids: $ReadOnlyArray<number>, caller?: string) => {
//       const existingProds = {};
//       ids
//         .filter((id) => products[id] != null)
//         .forEach((id) => {
//           existingProds[id] = products[id];
//         });

//       caller != null &&
//         console.log('existingProds', Object.keys(existingProds).length);
//       caller != null && console.log('ids', ids.length);

//       const newProds = {};
//       const newIDs = ids.filter((id) => products[id] == null);
//       caller != null && console.log('newIDs', newIDs);

//       if (newIDs.length === 0) {
//         caller != null && console.log('return existing', existingProds);
//         return Promise.resolve(existingProds);
//       }

//       newIDs.forEach((id) => {
//         newProds[id] = {
//           fetchStatus: 'FETCHING',
//           error: null,
//           data: null,
//         };
//       });

//       const searchParams = new URLSearchParams();
//       searchParams.set('ids', JSON.stringify(newIDs));

//       return fetchPOST('async/products.php', searchParams)
//         .then(
//           (response) => response.json(),
//           (error) => console.log('An error occured.', error),
//         )
//         .then((json) => {
//           // console.log(
//           //   'json response: ',
//           //   Object.keys(json).length,
//           //   Object.keys(json),
//           // );
//           // console.log('ids: ', ids.length, ids);
//           // console.log(
//           //   ids.filter((x) => !Object.keys(json).includes(x.toString())),
//           // );
//           const newProds = {};
//           Object.keys(json).forEach((prodID) => {
//             const rawProd = json[prodID];
//             prodID = parseInt(prodID, 10);
//             const prod = {
//               ...rawProd,
//               id: prodID,
//               price: parseFloat(rawProd.price),
//               wasPrice: parseFloat(rawProd.wasPrice),
//               images: rawProd.images.map((image) => parseInt(image, 10)),
//               relatedProds: [],
//             };
//             newProds[prodID] = {
//               fetchStatus: 'FETCHED',
//               error: null,
//               data: prod,
//             };
//           });
//           return {
//             ...existingProds,
//             ...newProds,
//           };
//         });
//     },
//     [products],
//   );
// }

export function useProducts(ids: $ReadOnlyArray<number>, caller?: string) {
  const [calledIDs, setCalledIDs] = useState(new Set());

  const hasNewIDs = useMemo(() => {
    return ids.some((id) => !calledIDs.has(id));
  }, [calledIDs]);

  useEffect(() => {
    if (!hasNewIDs) {
      return;
    }
    ids.forEach((id) => calledIDs.add(id));
    setCalledIDs(new Set([...calledIDs]));
  }, [calledIDs, setCalledIDs]);

  const fetchProducts = useFetchProducts();

  const [products, upsertProducts] = useRecoilState(bulkProdState);

  const prodsToLoad = useMemo(
    () =>
      ids.filter((id) => {
        return id != null && products[id] == null;
      }),
    [products, ids],
  );

  const prodsStillFetching = useMemo(
    () =>
      ids.filter((id) => {
        return products[id]?.fetchStatus === 'FETCHING';
      }),
    [products, ids],
  );

  const loadedProds = useMemo(() => {
    const prods = [];
    ids.forEach((id) => {
      const prod = products[id];
      if (prod == null || prod.fetchStatus !== 'FETCHED' || prod.data == null) {
        return;
      }
      prods.push(prod.data);
    });
    return prods;
  }, [products, ids]);

  useEffect(() => {
    if (!hasNewIDs || prodsToLoad.length === 0) {
      return;
    }
    const newProds = {};
    prodsToLoad.forEach((prodToLoad) => {
      newProds[prodToLoad] = {
        fetchStatus: 'FETCHING',
        error: null,
        data: null,
      };
    });

    upsertProducts({
      ...newProds,
    });

    fetchProducts(prodsToLoad, caller)
      .then((prodsRes) => {
        upsertProducts({
          ...prodsRes,
        });
      })
      .catch((err) => {
        throw err;
      });
  }, [prodsToLoad]);

  // console.log('hasNewIDs', hasNewIDs);
  // console.log('prodsToLoad', prodsToLoad);
  // console.log('prodsStillFetching', prodsStillFetching);
  // console.log('loadedProds', loadedProds);
  return {
    products: loadedProds,
    isFetching: prodsToLoad.lenght > 0 || prodsStillFetching.length > 0,
  };
}

// export function useProductsOLD(ids: $ReadOnlyArray<number>, caller?: string) {
//   const [loadedProducts, setLoadedProducts] = useState({});
//   const [_products, upsertProducts] = useRecoilState(bulkProdState);

//   const mountedRef = useRef(true);

//   const fetchProducts = useFetchProducts();

//   const performFetch = useCallback(
//     (ids) => {
//       return fetchProducts(ids, caller)
//         .then((prodsRes) => {
//           if (!mountedRef.current) {
//             caller != null && console.log('not setting as unmounted', prodsRes);
//             return prodsRes;
//           }
//           upsertProducts(prodsRes);
//           setLoadedProducts(prodsRes);
//         })
//         .catch((err) => {
//           if (!mountedRef.current) {
//             return null;
//           }
//           throw err;
//         });
//     },
//     [fetchProducts, mountedRef.current],
//   );

//   const areAllLoaded = useMemo(() => {
//     if (Object.keys(loadedProducts).length === 0) {
//       return false;
//     }
//     return (
//       Object.keys(loadedProducts).find((prodID) => {
//         const prod = loadedProducts[prodID];
//         return prod.fetchStatus === 'FETCHING';
//       }) === undefined
//     );
//   }, [loadedProducts]);

//   useEffect(() => {
//     if (Object.keys(loadedProducts).length === 0) {
//       performFetch(ids).then((prodsRes) => {
//         if (prodsRes != undefined) {
//           setLoadedProducts(prodsRes);
//           upsertProducts(prodsRes);
//         }
//       });
//     }
//     return () => {
//       mountedRef.current = false;
//     };
//   }, [performFetch, loadedProducts, mountedRef]);

//   caller &&
//     console.log(
//       'retutning these prods: ',
//       Object.keys(loadedProducts).length,
//       loadedProducts,
//     );

//   return {
//     products: [
//       ...Object.keys(loadedProducts)
//         .map((prodID) => {
//           return loadedProducts[prodID].data;
//         })
//         .filter((prod) => prod != null),
//     ],
//     isFetching: areAllLoaded === false,
//   };
// }

export function useProduct(id: number, caller?: string) {
  const { products, isFetching } = useProducts([id], caller);
  const product = useMemo(() => {
    if (products.length === 0) {
      return null;
    } else {
      return products[0];
    }
  }, [products]);

  return {
    product,
    isFetching,
  };
}
