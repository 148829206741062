// @flow

import * as React from 'react';

import {
  PageNames,
  catRoute,
  issuerRoute,
  newIssueRoute,
  productRoute,
  sotmRoute,
} from '../../helpers';
import type { TCategoryMeta, TIssuers, TProducts } from '../../types';
import { useLocation, useParams } from 'react-router';

import { Link } from './';
import type { TPageMeta } from '../../helpers';
import { getSOTMDateForDisplay } from '../../helpers';

type Props = {
  categories: TCategoryMeta,
  products: TProducts,
  issuers: TIssuers,
};

type TCrumbMeta = {
  elem: React.Node,
  next: ?TPageMeta,
};

export default function Breadcrumb(props: Props) {
  const params = useParams();
  const { pathname } = useLocation();
  function _getPageBreadcrumb(
    pageMeta: TPageMeta,
    isLast: boolean,
  ): TCrumbMeta {
    const crumb = pageMeta.crumb;
    let next = null;
    if (crumb.customParent) {
      next = crumb.customParent;
    } else {
      next = crumb.parent ? PageNames[crumb.parent] : null;
    }
    const link = isLast ? (
      <span>{crumb.display}</span>
    ) : (
      <Link page={pageMeta.key}>{crumb.display}</Link>
    );
    const elem = (
      <li key={pageMeta.key} className={isLast ? 'last' : null}>
        {link}
      </li>
    );

    return { elem, next };
  }

  function _getCategoryBreadcrumb(id: ?number): ?TPageMeta {
    if (!id) {
      return null;
    }
    const categories = props.categories;
    const category = categories[id];

    const parentCategory =
      category && category.parent !== 0
        ? props.categories[category.parent]
        : null;

    if (parentCategory === null) {
      return null;
    }

    return {
      key: 'CAT_' + parentCategory.id,
      title: 'NOT_USED',
      id: 0,
      path: 'category',
      crumb: {
        display: parentCategory.name,
        parent: 'CATEGORY',
        customParent: _getCategoryBreadcrumb(parentCategory.id),
      },
    };
  }

  function _getProductBreadcrumb(prodID: number): ?TPageMeta {
    const categories = props.categories;
    let catID = null;
    Object.keys(categories).forEach((id) => {
      id = parseInt(id, 10);
      if (categories[id].prodIDs.indexOf(prodID) !== -1) {
        if (categories[id].parent === 0) {
          catID = catID === null ? id : catID;
        } else {
          catID = id;
        }
      }
    });

    if (catID === null) {
      return null;
    }
    const category = categories[catID];

    return {
      key: 'CAT_' + category.id,
      title: 'NOT_USED',
      id: 0,
      path: 'category',
      crumb: {
        display: category.name,
        parent: 'CATEGORY',
        customParent: _getCategoryBreadcrumb(category.id),
      },
    };
  }

  function _getInitialPage(): ?TPageMeta {
    switch (pathname) {
      case catRoute:
        const catID = parseInt(params.id, 10);
        if (catID) {
          const categories = props.categories;
          const category = categories[catID];
          // mock the page meta object
          return {
            key: 'CAT_' + catID,
            title: 'NOT_USED',
            id: 0,
            path: 'category',
            crumb: {
              display: category ? category.name : '',
              parent: 'CATEGORY',
              customParent: _getCategoryBreadcrumb(catID),
            },
          };
        }
        return PageNames.CATEGORY;
      case issuerRoute:
        const iID = parseInt(params.id, 10);
        if (iID) {
          const issuer = props.issuers[iID];
          // mock the page meta object
          return {
            key: 'ISSUER_' + iID,
            title: 'NOT_USED',
            id: 0,
            path: 'issuers',
            crumb: {
              display: issuer ? issuer.name : '',
              parent: 'ISSUERS',
            },
          };
        }
        return PageNames.ISSUERS;
      case productRoute:
        const prodID = parseInt(params.id, 10);
        if (prodID) {
          const product = props.products[prodID];

          // mock the page meta object
          return {
            key: 'PROD_' + prodID,
            title: 'NOT_USED',
            id: 0,
            path: 'product',
            crumb: {
              display: product ? product.name : 'Unexpected Product',
              parent: 'CATEGORY',
              customParent: _getProductBreadcrumb(prodID),
            },
          };
        }
        return PageNames.PRODUCT;
      case newIssueRoute:
        const niMonth = params.month;
        if (niMonth) {
          const m = parseInt(niMonth.substring(0, 2), 10) - 1;
          const y = niMonth.substring(3, 5);
          const yearPrefix = y === '99' ? '19' : '20';
          const date = new Date(parseInt(yearPrefix + y, 10), m, 5, 5, 0, 0);
          const options = { month: 'long', year: 'numeric' };
          // mock the page meta object
          return {
            key: 'NI_' + niMonth,
            title: niMonth,
            id: PageNames.NI.id,
            path: 'newissues',
            crumb: {
              display: date.toLocaleDateString('en-GB', options),
              parent: 'NI',
            },
          };
        }
        return PageNames.NI;
      case sotmRoute:
        const sotmMonth = params.month;
        if (sotmMonth) {
          // mock the page meta object
          return {
            key: 'SOTM_' + sotmMonth,
            title: sotmMonth,
            id: PageNames.SOTM.id,
            path: 'sotm',
            crumb: {
              display: getSOTMDateForDisplay(sotmMonth),
              parent: 'SOTM',
            },
          };
        }
        return PageNames.SOTM;
      default:
        return _getTitleMatching();
    }
  }

  function _getTitleMatching(): ?TPageMeta {
    let checkAddress;
    for (const pageConst in PageNames) {
      checkAddress =
        '/' + PageNames[pageConst].path + PageNames[pageConst].title;
      if (pathname === checkAddress) {
        return PageNames[pageConst];
      }
    }

    return null;
  }

  let crumbs = [];
  let next = _getInitialPage();

  if (next === null) {
    return null;
  }

  let crumbMeta;
  let elem;
  let isLast = true;

  if (next && !next.crumb) {
    console.log('Breadcrumb data not prvided');
    next = PageNames.HOME;
  }

  do {
    // $FlowFixMe - why does flow think next is undefined
    crumbMeta = _getPageBreadcrumb(next, isLast);
    next = crumbMeta.next;
    elem = crumbMeta.elem;
    isLast = false;
    crumbs.unshift(elem);
    if (next) {
      crumbs.unshift(
        <li key={'sep_' + next.key} className="seperator">
          {'\u00bb'}
        </li>,
      );
    }
  } while (next !== null);

  return <ul id="breadcrumb">{crumbs}</ul>;
}
