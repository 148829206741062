import { useCallback, useEffect } from 'react';

import { fetchWithErrHandler } from '../../helpers';
import { specialRequestState } from '../../helpers/Atoms';
import { useRecoilState } from 'recoil';

export function useSpecialRequest(id: number) {
  const [specialRequest, setSpecialRequestState] = useRecoilState(
    specialRequestState(id),
  );

  const fetchSpecialRequest = useCallback(() => {
    if (specialRequest.fetchStatus === 'FETCHED') {
      return;
    }
    setSpecialRequestState({ ...specialRequest, fetchStatus: 'FETCHING' });
    return fetchWithErrHandler('async/special_requests.php?id=' + id).then(
      (json) => {
        if (json.error) {
          setSpecialRequestState({
            ...specialRequest,
            fetchStatus: 'FETCHED',
            error: json.error,
          });
          return;
        }
        json.id = parseInt(json.id, 10);
        json.price = Number(json.price);
        setSpecialRequestState({
          ...specialRequest,
          fetchStatus: 'FETCHED',
          data: json,
        });
        return json;
      },
      (error) => {
        setSpecialRequestState({
          ...specialRequest,
          fetchStatus: 'FETCHED',
          error,
        });
        console.log('An error occured.', error);
      },
    );
  }, [specialRequest, setSpecialRequestState, id]);

  useEffect(() => {
    if (specialRequest.fetchStatus === 'NONE') {
      fetchSpecialRequest();
    }
  }, [specialRequest.fetchStatus, fetchSpecialRequest]);

  return {
    specialRequest: specialRequest.data,
    isFetching: specialRequest.fetchStatus !== 'FETCHED',
    error: specialRequest.error,
  };
}
