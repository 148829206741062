// @flow

import * as React from 'react';

import Autosuggest from 'react-autosuggest';
import Flexbox from 'flexbox-react';
import { Link } from '../common';
import { getURI } from '../../helpers';
import { useNavigate } from 'react-router';
import { useState } from 'react';

// Teach Autosuggest how to calculate suggestions for any given input value.
const getSuggestions = (value) => {
  if (value === 'dr who') {
    return ['Doctor Who'];
  }
  return [];
  // const inputValue = value.trim().toLowerCase();
  // const inputLength = inputValue.length;
  //
  // return inputLength === 0 ? [] : languages.filter(lang =>
  //   lang.name.toLowerCase().slice(0, inputLength) === inputValue
  // );
};

// When suggestion is clicked, Autosuggest needs to populate the input
// based on the clicked suggestion. Teach Autosuggest how to calculate the
// input value for every given suggestion.
const getSuggestionValue = (suggestion) => suggestion;

// Use your imagination to render suggestions.
const renderSuggestion = (suggestion) => {
  const searchLink = getURI('SEARCH') + '?search=' + suggestion;
  return (
    <div>
      <Link hrefOverride={searchLink}>{suggestion}</Link>
    </div>
  );
};

export default function SearchBar(): React.MixedElement {
  const [value, setValue] = useState('');
  const [suggestions, setSuggestions] = useState([]);
  const navigate = useNavigate();

  const _handleKeyPress = (e: Object) => {
    if (e.key === 'Enter') {
      const searchLink = getURI('SEARCH') + '?search=' + value;
      navigate(searchLink);
    }
  };

  const _onChange = (_event: Object, data: Object) => {
    const newValue = data.newValue;
    setValue(newValue);
  };

  // Autosuggest will call this function every time you need to update suggestions.
  // You already implemented this logic above, so just use it.
  const _onSuggestionsFetchRequested = (suggestions: Object) => {
    const value = suggestions.value;
    setSuggestions(getSuggestions(value));
  };

  // Autosuggest will call this function every time you need to clear suggestions.
  const _onSuggestionsClearRequested = () => {
    setSuggestions([]);
  };

  // Autosuggest will pass through all these props to the input.
  const inputProps = {
    placeholder: 'Find a set of cards',
    value,
    onChange: _onChange,
    onKeyPress: _handleKeyPress,
  };

  // Finally, render it!
  const searchLink = getURI('SEARCH') + '?search=' + value;
  return (
    <Flexbox flexDirection="row" flexGrow={1}>
      <Flexbox flexGrow={1}>
        <Autosuggest
          suggestions={suggestions}
          onSuggestionsFetchRequested={_onSuggestionsFetchRequested}
          onSuggestionsClearRequested={_onSuggestionsClearRequested}
          getSuggestionValue={getSuggestionValue}
          renderSuggestion={renderSuggestion}
          inputProps={inputProps}
        />
      </Flexbox>
      <Flexbox>
        <Flexbox>
          <Link
            hrefOverride={searchLink}
            style={{
              borderRadius: '0px 4px 4px 0',
              backgroundColor: '#febd69',
              alignItems: 'center',
              padding: '10px 12px',
              cursor: 'pointer',
              fontWeight: 'bold',
              textDecoration: 'none',
            }}
          >
            Search
          </Link>
        </Flexbox>
      </Flexbox>
    </Flexbox>
  );
}
