import { fetchWithErrHandler } from '../../helpers';
import { offersState } from '../../helpers/Atoms';
import { useCallback } from 'react';
import { useRecoilState } from 'recoil';

export function useOffers() {
  const [offers, setOffersState] = useRecoilState(offersState);

  const fetchOffers = useCallback(() => {
    if (offers.fetchStatus === 'FETCHED' || offers.fetchStatus === 'FETCHING') {
      return Promise.resolve(offers);
    }
    setOffersState({ ...offers, fetchStatus: 'FETCHING' });
    return fetchWithErrHandler('async/offers.php').then(
      (json) => {
        if (json.error) {
          setOffersState({
            ...offers,
            fetchStatus: 'FETCHED',
            error: json.error,
          });
          return;
        }
        json.prodIDs = json.prodIDs.map((id) => parseInt(id, 10));
        json.num23 = parseInt(json.num23, 10);
        json.numOffers = parseInt(json.numOffers, 10);
        json.offers = json.offers.map((offer) => {
          offer.id = parseInt(offer.id, 10);
          offer.price = parseFloat(offer.price);
          return offer;
        });
        setOffersState({
          ...offers,
          fetchStatus: 'FETCHED',
          data: json,
        });
        return json;
      },
      (error) => {
        setOffersState({ ...offers, fetchStatus: 'FETCHED', error });
        console.log('An error occured.', error);
      },
    );
  }, [offers, setOffersState]);

  return {
    offers: offers.data,
    isFetching: offers.fetchStatus !== 'FETCHED',
    fetchStatus: offers.fetchStatus,
    error: offers.error,
    fetchOffers,
  };
}
