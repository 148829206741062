import { fetch, fetchPOST } from '../../helpers';
import { useCallback, useMemo } from 'react';

import type { TCustomerAddress } from '../../types';
import { useCustomer } from './';

export function useAddress(id) {
  const [customer] = useCustomer();
  const removeAddress = useRemoveAddress(id);
  const updateAddress = useUpdateAddress(id);
  const addAddress = useAddAddress();

  const address = useMemo(() => {
    return customer.addresses[id];
  }, [customer.addresses, id]);

  return {
    address,
    updateAddress,
    addAddress,
    removeAddress,
  };
}

export function useDeliveryAddress(): void {
  const [customer, setCustomer] = useCustomer();

  const setDelivery = useCallback(
    (id) => {
      fetch('async/set_delivery_address.php?id=' + id.toString()).then(
        (_response) => {
          setCustomer({
            ...customer,
            dID: id,
          });
        },
        (error) => console.log('An error occured.', error),
      );
    },
    [customer, setCustomer],
  );

  return [customer.dID, setDelivery];
}

function useBillingAddress(): void {
  const [customer] = useCustomer();

  return customer.bID;
}

function useRemoveAddress(id: number): void {
  const [customer, setCustomer] = useCustomer();
  const bID = useBillingAddress();

  return useCallback(() => {
    fetch(
      'async/remove_address.php?id=' +
        id.toString() +
        '&newDID=' +
        bID.toString(),
    ).then(
      (_response) => {
        const newAddresses = { ...customer.addresses };
        delete newAddresses[id];
        setCustomer({
          ...customer,
          addresses: newAddresses,
          dID: bID,
        });
      },
      (error) => console.log('An error occured.', error),
    );
  }, [id, bID, customer, setCustomer]);
}

function useUpdateAddress(_id): void {
  const [customer, setCustomer] = useCustomer();

  return useCallback(
    (newAddress: TCustomerAddress) => {
      const params = new URLSearchParams();
      params.set('id', newAddress.id);
      params.set('line1', newAddress.line1);
      params.set('line2', newAddress.line2);
      params.set('town', newAddress.town);
      params.set('country_id', newAddress.country_id);
      params.set('county', newAddress.county);
      params.set('postcode', newAddress.postcode);
      return fetchPOST('async/update_address.php', params)
        .then(
          (response) => response.json(),
          (error) => console.log('An error occured.', error),
        )
        .then((json) => {
          setCustomer({
            ...customer,
            addresses: {
              ...customer.addresses,
              [json.address.id]: json.address,
            },
          });
        });
    },
    [customer, setCustomer],
  );
}

function useAddAddress(): void {
  const [customer, setCustomer] = useCustomer();

  return useCallback(
    (newAddress: TCustomerAddress) => {
      const params = new URLSearchParams();
      params.set('id', newAddress.id);
      params.set('line1', newAddress.line1);
      params.set('line2', newAddress.line2);
      params.set('town', newAddress.town);
      params.set('country_id', newAddress.country_id);
      params.set('county', newAddress.county);
      params.set('postcode', newAddress.postcode);
      return fetchPOST('async/add_address.php', params)
        .then(
          (response) => response.json(),
          (error) => console.log('An error occured.', error),
        )
        .then((json) => {
          json.address.country_id = parseInt(json.address.country_id, 10);
          setCustomer({
            ...customer,
            bID: json.bID,
            dID: json.dID,
            addresses: {
              ...customer.addresses,
              [json.address.id]: json.address,
            },
          });
          return json.address;
        });
    },
    [customer, setCustomer],
  );
}
