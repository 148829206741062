// @flow

import type {
  TCustomer,
  TCustomerAddress,
  TCustomerAddresses,
  TCustomerOrders,
} from '../types';

import { freeShipping } from './';

export default class CustomerHelper {
  _customer: TCustomer;

  constructor(customer: TCustomer) {
    this._customer = customer;
  }

  isCustomer(): boolean {
    return !!this._customer.id;
  }

  getBID(): number {
    return this._customer.bID;
  }

  getDID(): number {
    return this._customer.dID;
  }

  getName(): string {
    return this.getFirstName() + ' ' + this.getLastName();
  }

  getFirstName(): string {
    return this._customer.fname;
  }

  getLastName(): string {
    return this._customer.sname;
  }

  getEmail(): string {
    return this._customer.email;
  }

  getAddresses(): TCustomerAddresses {
    return this._customer.addresses || {};
  }

  getBillingAddress(): ?TCustomerAddress {
    return this.getAddresses()[this.getBID()] || null;
  }

  getDeliveryAddress(): ?TCustomerAddress {
    return this.getAddresses()[this.getDID()] || null;
  }

  getBillingAddresses(): TCustomerAddresses {
    const addresses = this.getAddresses();
    const deliveryAddresses = {};
    Object.keys(addresses)
      .filter((aID) => parseInt(aID, 10) === this.getBID())
      .forEach((aID) => {
        aID = parseInt(aID, 10);
        deliveryAddresses[aID] = addresses[aID];
      });
    return deliveryAddresses;
  }

  getDeliveryAddresses(): TCustomerAddresses {
    const addresses = this.getAddresses();
    const deliveryAddresses = {};
    Object.keys(addresses)
      .filter((aID) => parseInt(aID, 10) !== this.getBID())
      .forEach((aID) => {
        aID = parseInt(aID, 10);
        deliveryAddresses[aID] = addresses[aID];
      });
    return deliveryAddresses;
  }

  getDayPhone(): string {
    return this._customer.phoneNumbers.daytime;
  }

  getEveningPhone(): string {
    return this._customer.phoneNumbers.evening;
  }

  getMobilePhone(): string {
    return this._customer.phoneNumbers.mobile;
  }

  isCCCMember(): boolean {
    return this._customer.isCCC;
  }

  isGuest(): boolean {
    return this._customer.isGuest;
  }

  hasAccount(): boolean {
    return this._customer.hasAccount;
  }

  isSubscriber(): boolean {
    return this._customer.isSubscriber;
  }

  isFreeShippingAvailable(): boolean {
    const address = this.getDeliveryAddress();
    return address ? freeShipping.has(address.country_id.toString()) : true;
  }

  isLoggedIn(): boolean {
    return (
      Object.keys(this._customer).length > 1 &&
      this._customer.id !== 0 &&
      !this.isGuest()
    );
  }

  getPreviousOrders(): ?TCustomerOrders {
    return this._customer.orders;
  }
}
