import { bulkProdState, lcccState } from '../../helpers';

import { useMemo } from 'react';
import { useRecoilValue } from 'recoil';

export function useAllProducts() {
  const products = useRecoilValue(bulkProdState);
  return useMemo(() => {
    return Object.keys(products).map((prodID) => {
      return products[prodID].data;
    });
  }, [products]);
}

export function useTotalLoadedProductCount() {
  const { products } = useRecoilValue(lcccState);

  return Object.keys(products).filter((prodID) => {
    return (
      products[prodID].isFetching === 'FETCHED' &&
      products[prodID].error == null
    );
  }).length;
}
