// @flow

import * as React from 'react';

import { Box, Flexbox, Link, RTEText } from '../common';
import type { TCategory, TPageContent } from '../../types';

import CategoryListRow from './CategoryListRow';
import ContactForm from '../ContactForm';
import { PageHelpers } from '../../helpers';
import { useCategories } from '../../hooks/categories';
import { useMemo } from 'react';

type Props = {
  content?: TPageContent,
};

export default function CategoryHome(props: Props) {
  const [categories] = useCategories();

  console.log(categories);

  const allCats = useMemo(() => {
    const cats = [];
    // section 1 and 0 are the main cats (unsure difference in 1 and 0)
    // section 2 are the special categories
    for (const cat in categories) {
      cats.push(categories[parseInt(cat, 10)]);
    }
    return cats;
  }, [categories]);

  const mainCats = useMemo(() => {
    return allCats
      .filter((cat) => cat.section <= 1)
      .filter((cat) => cat.parent === 0)
      .sort((a, b) => a.navName.localeCompare(b.navName));
  }, [allCats]);

  const specialCats = useMemo(() => {
    return allCats
      .filter((cat) => cat.section > 1)
      .sort((a, b) => a.navName.localeCompare(b.navName));
  }, [allCats]);

  const { content } = props;

  return (
    <Flexbox flexDirection="column">
      <Flexbox flexGrow={1}>
        <Box highlight={true}>
          <span>asdsd {_getHeading()}</span>
        </Box>
      </Flexbox>
      <Flexbox flexGrow={1}>
        <Box>
          <span>
            <h2>Alphabetical list of issuers</h2>
            <div>
              If you wish to view a list of issuers and their sets{' '}
              <Link page="ISSUERS">click here</Link>
            </div>
          </span>
        </Box>
      </Flexbox>
      <Flexbox>
        <table id="catlist">
          <thead>
            <tr valign="top">
              <td>Title</td>
              <td width="180">Last Updated</td>
              <td width="60">View List</td>
              <td width="60">Download?</td>
            </tr>
          </thead>
          <tbody>
            {mainCats.map((cat) => {
              let rows = [];
              rows.push(_getTableRow(cat, false));
              const filteredCats = [];
              for (const cat in categories) {
                filteredCats.push(categories[parseInt(cat, 10)]);
              }

              filteredCats
                .filter((subCat) => subCat.parent === cat.id)
                .sort((a, b) => a.navName.localeCompare(b.navName))
                .forEach((subCat) => {
                  rows.push(_getTableRow(subCat, true));
                });
              return rows;
            })}
            <tr>
              <td colSpan="4">Other Lists</td>
            </tr>
            {specialCats.map((cat) => {
              return _getTableRow(cat, false);
            })}
          </tbody>
        </table>
      </Flexbox>
      <Flexbox>
        <Box>
          <span>
            <div>
              If you can't find the set you are interested in, please complete
              the form below and we will endeavour to help you with a quote if
              we can supply.
            </div>
            <ContactForm />
          </span>
        </Box>
      </Flexbox>
    </Flexbox>
  );

  function _getTableRow(cat: TCategory, isChild: boolean) {
    return <CategoryListRow key={cat.id} id={cat.id} isChild={isChild} />;
  }

  function _getHeading(): React.Node {
    let intro = PageHelpers.getContent(content, 'Intro');

    return <RTEText content={intro} />;
  }
}
