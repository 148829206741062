// @flow

import * as React from 'react';

import { Box, Flexbox, Form, Notice } from '../common';
import { useCustomerHelper, useUpdateNumbers } from '../../hooks/customer';

import { useState } from 'react';

function AccountPhones() {
  const [numberInternal, setNumberInternal] = useState(1);
  const [numbersUpdated, setNumbersUpdated] = useState(false);

  const customerHelper = useCustomerHelper();
  const updateNumbers = useUpdateNumbers();

  return (
    <span style={{ width: '100%', marginTop: '8px' }}>
      <h3>Phone Numbers</h3>
      <Box highlight={true}>
        <Flexbox flexDirection="column" style={{ width: '100%' }}>
          {numbersUpdated && _showSuccessMessage()}
          <Form
            key={numberInternal}
            onSubmit={_onPhoneSubmit}
            buttonLabel="Update Phone Numbers"
          >
            <dl className="standardform" id="update_phone">
              <dt>
                <label htmlFor="day_phone" className="smaller">
                  Daytime Number
                </label>
              </dt>
              <dd>
                <input
                  type="text"
                  name="day_phone"
                  defaultValue={customerHelper.getDayPhone()}
                />
              </dd>
              <dt>
                <label htmlFor="evening_phone" className="smaller">
                  Evening Number
                </label>
              </dt>
              <dd>
                <input
                  type="text"
                  name="evening_phone"
                  defaultValue={customerHelper.getEveningPhone()}
                />
              </dd>
              <dt>
                <label htmlFor="mobile_phone" className="smaller">
                  Mobile
                </label>
              </dt>
              <dd>
                <input
                  type="text"
                  name="mobile_phone"
                  defaultValue={customerHelper.getMobilePhone()}
                />
              </dd>
            </dl>
          </Form>
        </Flexbox>
      </Box>
    </span>
  );

  function _showSuccessMessage(): React.Node {
    return <Notice type="success" message="Phone numbers updated" />;
  }

  function _onPhoneSubmit(values: Object): void {
    const numberPromise = updateNumbers({
      daytime: values.day_phone,
      evening: values.evening_phone,
      mobile: values.mobile_phone,
    });

    numberPromise.then((_) => {
      setNumberInternal(numberInternal + 1);
      setNumbersUpdated(true);
    });
  }
}

export default AccountPhones;
