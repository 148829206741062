// @flow

import * as React from 'react';

import { Link, Price } from '../common';
import { getSOTMMonth, getSOTMURI } from '../../helpers';

import { useSOTM } from '../../hooks/sotm';

export default function SOTMIntro() {
  const month = getSOTMMonth();
  const { sotm, isFetching, error } = useSOTM(month);

  if (isFetching || error != null) {
    return null;
  }

  const saving = sotm.price - sotm.ccnPrice;
  return (
    <li>
      <b>Set of the month</b>: This month we feature{' '}
      <Link hrefOverride={getSOTMURI(month, sotm.title)}>{sotm.title}</Link>.
      Card Collectors Club members can buy this set for{' '}
      <Price price={sotm.ccnPrice} /> a saving of <Price price={saving} />
    </li>
  );
}
