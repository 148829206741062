// @flow

import * as React from 'react';

import { Loading, Page } from '../common';

import IssuerHome from './IssuerHome';
import IssuerProducts from './IssuerProducts';
import { useIssuers } from '../../hooks/products';
import { useMemo } from 'react';
import { useParams } from 'react-router-dom';

function Issuers() {
  const { issuers, isFetching } = useIssuers();
  const params = useParams();

  const pageComponent = useMemo(() => {
    const iID = parseInt(params.id, 10);
    const issuer = issuers[iID];

    if (isFetching || (iID && !issuer)) {
      return <Loading />;
    }

    if (!iID) {
      return <IssuerHome />;
    }

    if (!issuer) {
      throw new Error('for flow');
    }

    return <IssuerProducts issuer={issuer} />;
  }, [params.id, isFetching, issuers]);

  return <Page>{pageComponent}</Page>;
}

export default Issuers;
