import { fetchWithErrHandler } from '../../helpers';
import { newIssuesState } from '../../helpers/Atoms';
import { useCallback } from 'react';
import { useRecoilState } from 'recoil';

export function useFetchNewIssues() {
  const [newIssues, setNewIssues] = useRecoilState(newIssuesState);

  const getIsFetching = useCallback(
    (month) => newIssues[month]?.fetchStatus === 'FETCHING',
    [newIssues],
  );

  const getHasFetched = useCallback(
    (month) => newIssues[month]?.fetchStatus === 'FETCHED',
    [newIssues],
  );

  const fetchNewIssues = useCallback(
    (month) => {
      const ni = newIssues[month];

      if (ni != null && ni.fetchStatus !== 'NONE') {
        return;
      }

      setNewIssues({
        ...newIssues,
        [month]: {
          fetchStatus: 'FETCHING',
          data: {
            prodIDs: [],
            month: month,
          },
        },
      });

      fetchWithErrHandler('async/newissues.php?month=' + month).then((json) => {
        if (json == null) {
          Sentry.captureMessage('No JSON returned for New Isssues');
          setNewIssues({
            ...newIssues,
            [month]: {
              fetchStatus: 'FETCHED',
              data: null,
            },
          });
          return;
        }
        json.prodIDs = json.prodIDs.map((prodID) => parseInt(prodID, 10));
        setNewIssues({
          ...newIssues,
          [month]: {
            fetchStatus: 'FETCHED',
            data: json,
          },
        });
      });
    },
    [newIssues, setNewIssues],
  );

  return {
    getIsFetching,
    getHasFetched,
    fetchNewIssues,
  };
}
