import * as React from 'react';

import { ButtonLink, Link } from '../components/common';
import { getPrice, getWasPrice } from '../helpers/ProductHelpers';

import { getProdURI } from '../helpers/';
import { useAddToBasket } from '../hooks/basket';

export default function ProductListTable({
  products,
}: {
  products: Array<TProduct>,
}): React.Node {
  const addToBasket = useAddToBasket();

  return (
    <table id="prodlist">
      <thead>
        <tr valign="top">
          <td>Issuer</td>
          <td width="70">No. In Set</td>
          <td>Title &amp; Date Of Issue</td>
          <td width="60" style={{ textAlign: 'right' }}></td>
          <td width="60" style={{ textAlign: 'right' }}>
            Price
          </td>
          <td width="50" align="center">
            View
          </td>
          <td width="50" align="center">
            Basket
          </td>
        </tr>
      </thead>
      <tbody>
        {products.map((product) => {
          return (
            <tr key={product.id}>
              <td valign="top">{product.issuer}</td>
              <td valign="top">{product.noinset}</td>
              <td valign="top">
                <Link hrefOverride={getProdURI(product.id, product.name)}>
                  {product.name}
                </Link>
              </td>
              <td valign="top" style={{ textAlign: 'right' }}>
                {getWasPrice(product)}
              </td>
              <td valign="top" style={{ textAlign: 'right' }}>
                {getPrice(product)}
              </td>
              <td valign="top" align="center" className="highlight">
                <Link hrefOverride={getProdURI(product.id, product.name)}>
                  View
                </Link>
              </td>
              <td align="center" className="highlight">
                <ButtonLink
                  onClick={() =>
                    addToBasket(product.id.toString(), product.hash, 'product')
                  }
                >
                  Add
                </ButtonLink>
              </td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
}
