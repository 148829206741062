// @flow

import * as React from 'react';

import CCNDownloadsHome from './CCNDownloadsHome';
import { Page } from '../common';

type Props = {};

function CCNDownloads(props: Props) {
  return (
    <Page {...props}>
      <CCNDownloadsHome {...props} />
    </Page>
  );
}

export default CCNDownloads;
