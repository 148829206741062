import { fetchPOST } from '../../helpers';
import { useCallback } from 'react';

export function useAddToAuctionNewsletter() {
  return useCallback((email: string, name: ?string) => {
    const params = new URLSearchParams();
    params.set('email', email);
    params.set('name', name || '');
    return fetchPOST('async/customer/auction_newsletter.php', params).then(
      (response) => response.json(),
      (error) => console.log('An error occured.', error),
    );
  }, []);
}
