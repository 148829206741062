// @flow

import * as React from 'react';

import { Loading, Page } from '../common';
import { useEffect, useMemo } from 'react';
import { useFetchNewIssues, useNewIssues } from '../../hooks/newissues';

import NewIssuesHome from './NewIssuesHome';
import NewIssuesMonth from './NewIssuesMonth';
import { useParams } from 'react-router';

function NewIssues(): React.MixedElement {
  const params = useParams();
  const getNewIssues = useNewIssues();
  const {
    getIsFetching: isFetching,
    getHasFetched,
    fetchNewIssues,
  } = useFetchNewIssues();

  const newIssueMonth = useMemo(() => {
    return params.month;
  }, [params.month]);

  useEffect(() => {
    if (!isFetching(newIssueMonth) && !getHasFetched(newIssueMonth)) {
      fetchNewIssues(newIssueMonth);
    }
  }, [isFetching, fetchNewIssues, getHasFetched, newIssueMonth]);

  const newissues = useMemo(
    () => getNewIssues(newIssueMonth),
    [getNewIssues, newIssueMonth],
  );

  const pageComponent = useMemo(() => {
    if (newIssueMonth) {
      if (isFetching(newIssueMonth) || !getHasFetched(newIssueMonth)) {
        return <Loading />;
      }
      return <NewIssuesMonth newissues={newissues} />;
    }

    return <NewIssuesHome />;
  }, [isFetching, getHasFetched, newIssueMonth, newissues]);

  return <Page>{pageComponent}</Page>;
}

export default NewIssues;
