// @flow

import { bannerState } from '../../helpers/Atoms';
import { fetchWithErrHandler } from '../../helpers';
import { useEffect } from 'react';
import { useRecoilState } from 'recoil';

export function useBanners() {
  const [banners, setBannersState] = useRecoilState(bannerState);

  useEffect(() => {
    if (banners.fetchStatus === 'NONE') {
      setBannersState({ ...banners, fetchStatus: 'FETCHING' });
      fetchWithErrHandler('async/banners.php').then(
        (json) => {
          if (json == null) {
            setBannersState({
              ...banners,
              fetchStatus: 'FETCHED',
              error: 'No JSON Returned',
            });
          } else if (json.error) {
            setBannersState({
              ...banners,
              fetchStatus: 'FETCHED',
              error: json.error,
            });
            return;
          } else {
            const data = json.map((banner) => {
              banner.id = parseInt(banner.id, 10);
              return banner;
            });
            json.info = json.info ? Object.values(json.info) : {};
            setBannersState({
              ...banners,
              fetchStatus: 'FETCHED',
              data,
            });
          }
        },
        (error) => {
          setBannersState({ ...banners, fetchStatus: 'FETCHED', error });
          console.log('An error occured.', error);
        },
      );
    }
  }, [banners, setBannersState]);

  return {
    banners: banners.data,
    isFetching: banners.fetchStatus !== 'FETCHED',
    error: banners.error,
  };
}
