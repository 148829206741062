// @flow

import * as React from 'react';

type Props = {
  price: number,
  label?: string,
  strikethrough?: boolean,
  highlight?: boolean,
  bold?: boolean,
};

export default class Price extends React.Component<Props> {
  static props: Props;

  render(): React.Node {
    let price = Number(this.props.price).toLocaleString('en-GB', {
      style: 'currency',
      currency: 'GBP',
    });
    const highlightStyles = this.props.highlight
      ? {
          background: '#a54936',
          color: '#fff',
          padding: '0 2px',
        }
      : {};
    return (
      <span
        style={{
          ...highlightStyles,
          fontWeight: this.props.bold ? 'bold' : '',
        }}
      >
        {this.props.label ? this.props.label + ' ' : null}
        <span
          style={{
            textDecoration: this.props.strikethrough
              ? 'line-through'
              : 'normal',
          }}
        >
          {price}
        </span>
      </span>
    );
  }
}
