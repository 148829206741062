import { bulkSOTMsState } from '../../helpers/Atoms';
import { fetchWithErrHandler } from '../../helpers';
import { useCallback } from 'react';
import { useRecoilState } from 'recoil';

export function useFetchSOTMByIDs() {
  const [sotms, setSOTMs] = useRecoilState(bulkSOTMsState);

  return useCallback(
    (ids: Array<number>) => {
      return fetchWithErrHandler(
        'async/sotm.php?ids=' + JSON.stringify(ids),
      ).then((json) => {
        const newSOTMS = {};
        Object.keys(json).forEach((month) => {
          const sotm = json[month];
          sotm.id = parseInt(sotm.id, 10);
          sotm.price = parseFloat(sotm.price);
          sotm.ccnPrice = parseFloat(sotm.ccnPrice);
          newSOTMS[month] = {
            fetchStatus: 'FETCHED',
            data: sotm,
          };
        });
        setSOTMs({
          ...sotms,
          ...newSOTMS,
        });
        return newSOTMS;
      });
    },
    [sotms, setSOTMs],
  );
}
