// @flow

import * as React from 'react';

import { Loading, Page } from '../common';

import SOTMHome from './SOTMHome';
import { getSOTMMonth } from '../../helpers';
import { useParams } from 'react-router';
import { useSOTM } from '../../hooks/sotm';

function SetOfTheMonth() {
  const params = useParams();
  const month = params.month || getSOTMMonth();

  const { isFetching } = useSOTM(month);

  function _getPageComponent(): React.Node {
    if (isFetching) {
      return <Loading />;
    }

    return <SOTMHome month={month} />;
  }

  return <Page>{_getPageComponent()}</Page>;
}

export default SetOfTheMonth;
