// @flow

import * as React from 'react';

import HtmlToReact from 'html-to-react';
import Link from './Link';

const Parser = HtmlToReact.Parser;

type Props = {
  content: string,
};

const LINKS = {
  'auction.php?section=condition': 'AUC_CONDITION',
  'auction.php?section=guide': 'AUC_GUIDE',
  'auction.php?section=list': 'AUC_LOTS',
  'auction.php?section=details': 'AUC_DETAILS',
  'newissues.php?month=list': 'NI',
  'products.php?list=37': 'CAT_37',
  'products.php?list=39': 'CAT_39',
  'products.php?list=16': 'CAT_16',
  'products.php?list=73': 'CAT_73',
  'products.php?list=74': 'CAT_74',
  'products.php?list=75': 'CAT_75',
  'magazine.php': 'CCC',
  'books.php?section=reference': 'BOOKS_REF',
  'books.php?section=general': 'BOOKS_GEN',
  'books.php': 'BOOKS',
  'email.php': 'NEWSLETTER',
  'catalogues.php': 'CATALOGUE',
  'offers.php': 'OFFERS',
  'daily.php': 'DAILY',
};

export default class RTEText extends React.Component<Props> {
  render() {
    const isValidNode = function () {
      return true;
    };
    const processNodeDefinitions = new HtmlToReact.ProcessNodeDefinitions(
      React,
    );
    const processingInstructions = [
      {
        replaceChildren: true,
        shouldProcessNode: (node) => {
          return node.attribs && node.attribs['data-link'] === 'link';
          // return node.parent && node.parent.name && node.parent.name === 'Link';
        },
        processNode: function (node, children) {
          let href = node.attribs.href;
          delete node.attribs.href;
          let found = false;
          for (let key in LINKS) {
            if (LINKS.hasOwnProperty(key)) {
              if (href.indexOf(key) !== -1) {
                found = true;
                return <Link page={LINKS[key]}>{children}</Link>;
                // delete node.parent.attribs.href;
                // node.parent.attribs.onClick = () => dispatch(push(LINKS[key]))
                // break;
              }
            }
          }
          if (!found) {
            if (href.indexOf('pdf/') !== -1) {
              return (
                <a href={href} target="_blank" rel="noopener noreferrer">
                  {children}
                </a>
              );
            }
            if (
              href.indexOf('http') !== -1 &&
              href.indexOf('londoncigcard') === -1
            ) {
              return (
                <a href={href} target="_blank" rel="noopener noreferrer">
                  {children}
                </a>
              );
            }
            if (href.indexOf('mailto') !== -1) {
              return <a href={href}>{children}</a>;
            }
            // node.parent.attribs.onClick = () => dispatch(push(node.parent.attribs.href))
            // delete node.parent.attribs.href;
            return <Link hrefOverride={href}>{children}</Link>;
          }

          return node.data;
        },
      },
      {
        // Anything else
        shouldProcessNode: function (_node) {
          return true;
        },
        processNode: processNodeDefinitions.processDefaultNode,
      },
    ];
    const htmlParser = new Parser();
    let content = this.props.content
      .replace(/<a/g, '<span data-link="link"')
      .replace(/<\/a/g, '</span');
    return htmlParser.parseWithInstructions(
      content,
      isValidNode,
      processingInstructions,
    );
  }
}
