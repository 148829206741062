// @flow

import * as React from 'react';

import { Flexbox, Link, Loading } from './common';
import {
  catRoute,
  fetch,
  getCCNBackCopiesLink,
  getCategoryURI,
  getNIURI,
  getSOTMURI,
  getURI,
} from '../helpers';
import { matchPath, useLocation } from 'react-router';
import { useEffect, useMemo, useState } from 'react';

import { Map } from 'immutable';
import { NavLink } from 'react-router-dom';

function Left() {
  const [navLinks, setNavLinks] = useState(Map());
  const [loading, setLoading] = useState(true);
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    if (loaded) {
      return;
    }
    fetch('async/left/links.php')
      .then(
        (response) => response.json(),
        (error) => console.log('An error occured.', error),
      )
      .then((json) => {
        json = Map(json)
          .map((cat) => {
            cat.subCats = Map(cat.subCats).sort((a, b) => a.localeCompare(b));
            return cat;
          })
          .sort((a, b) => a.name.localeCompare(b.name));
        setLoaded(true);
        setLoading(false);
        setNavLinks(json);
      });
  }, [loaded, setLoaded, setLoading, setNavLinks]);

  const location = useLocation();

  const categoryID = useMemo(() => {
    const match = matchPath(
      {
        path: catRoute,
        exact: true,
        strict: false,
      },
      location.pathname,
    );
    return match ? match.params.id : null;
  }, [location.pathname]);

  return (
    <Flexbox flexDirection="column" element="section" className="left">
      <Flexbox element="nav" className="navBanner">
        <Flexbox flexDirection="column">
          <Flexbox className="heading">Cards For Sale</Flexbox>
          {loading ? (
            <Loading />
          ) : (
            <dl className="leftNav">
              <dd key="offers">
                <Link hrefOverride={getURI('OFFERS')}>
                  <em>
                    <strong>Monthly Special Offers</strong>
                  </em>
                </Link>
              </dd>
              <dd key="dailyoffer">
                <Link hrefOverride={getURI('DAILY')}>
                  <em>
                    <strong>Daily Special Offer</strong>
                  </em>
                </Link>
              </dd>
              <dd key="new">
                <Link hrefOverride={getNIURI()}>
                  <em>
                    <strong>New This Month</strong>
                  </em>
                </Link>
              </dd>
              {navLinks.entrySeq().map(([id, cat]) => {
                let links = [
                  <dd key={id}>
                    <NavLink
                      className={({ isActive }) =>
                        isActive ? 'selected' : undefined
                      }
                      to={getCategoryURI(parseInt(id, 10), cat.name)}
                    >
                      {cat.name}
                    </NavLink>
                  </dd>,
                ];
                let showSubCats = false;
                cat.subCats.forEach((_, subCatID) => {
                  if (subCatID === categoryID) {
                    showSubCats = true;
                  }
                });
                if ((showSubCats || id === categoryID) && cat.subCats.size) {
                  cat.subCats.entrySeq().forEach(([id, name]) => {
                    links.push(
                      <dd key={id} className="navSecondary">
                        <NavLink
                          className={({ isActive }) =>
                            isActive ? 'selected' : undefined
                          }
                          to={getCategoryURI(parseInt(id, 10), name)}
                        >
                          &raquo; {name}
                        </NavLink>
                      </dd>,
                    );
                  });
                }
                return links;
              })}
              <dd key="all">
                <Link page="CATEGORY">
                  <em>View all categories</em>
                </Link>
              </dd>
            </dl>
          )}
        </Flexbox>
      </Flexbox>
      <Flexbox flexDirection="column" element="section" className="navBanner">
        <Flexbox className="heading">Monthly Auction</Flexbox>
        <dl className="leftNav">
          <dd>
            <NavLink
              className={({ isActive }) => (isActive ? 'selected' : undefined)}
              to={getURI('AUC')}
            >
              Auction Home
            </NavLink>
          </dd>
          <dd>
            <NavLink
              className={({ isActive }) => (isActive ? 'selected' : undefined)}
              to={getURI('AUC_DETAILS')}
            >
              Auction Details
            </NavLink>
          </dd>
          <dd>
            <NavLink
              className={({ isActive }) => (isActive ? 'selected' : undefined)}
              to={getURI('AUC_LOTS')}
            >
              Auction Lots
            </NavLink>
          </dd>
          <dd>
            <NavLink
              className={({ isActive }) => (isActive ? 'selected' : undefined)}
              to={getURI('AUC_CONDITION')}
            >
              Conditions of Sale
            </NavLink>
          </dd>
          <dd>
            <NavLink
              className={({ isActive }) => (isActive ? 'selected' : undefined)}
              to={getURI('AUC_GUIDE')}
            >
              Guide to Success
            </NavLink>
          </dd>
        </dl>
      </Flexbox>
      <Flexbox flexDirection="column" element="section" className="navBanner">
        <Flexbox className="heading">Collecting Essentials</Flexbox>
        <dl className="leftNav">
          <dd>
            <NavLink
              className={({ isActive }) => (isActive ? 'selected' : undefined)}
              to={getURI('CATALOGUE')}
            >
              Catalogues
            </NavLink>
          </dd>
          <dd>
            <NavLink
              className={({ isActive }) => (isActive ? 'selected' : undefined)}
              to={getURI('ALBUMS')}
            >
              Albums & Accessories
            </NavLink>
          </dd>
          <dd>
            <NavLink
              className={({ isActive }) => (isActive ? 'selected' : undefined)}
              to={getURI('CCC')}
            >
              Card Collectors Club
            </NavLink>
          </dd>
          <dd>
            <NavLink
              className={({ isActive }) => (isActive ? 'selected' : undefined)}
              to={getCCNBackCopiesLink()}
            >
              Collectors Magazine
            </NavLink>
          </dd>
          <dd>
            <NavLink
              className={({ isActive }) => (isActive ? 'selected' : undefined)}
              to={getURI('BOOKS')}
            >
              Books
            </NavLink>
          </dd>
          <dd>
            <NavLink
              className={({ isActive }) => (isActive ? 'selected' : undefined)}
              to={getSOTMURI()}
            >
              Set of the Month
            </NavLink>
          </dd>
          <dd>
            <NavLink
              className={({ isActive }) => (isActive ? 'selected' : undefined)}
              to={getCategoryURI(38, 'Greetings Cards')}
            >
              Greetings Cards
            </NavLink>
          </dd>
          <dd>
            <NavLink
              className={({ isActive }) => (isActive ? 'selected' : undefined)}
              to={getURI('VALUING')}
            >
              Valuing your cards
            </NavLink>
          </dd>
          <dd>
            <NavLink
              className={({ isActive }) => (isActive ? 'selected' : undefined)}
              to={getURI('CARD_HISTORY')}
            >
              Card History
            </NavLink>
          </dd>
          <dd>
            <NavLink
              className={({ isActive }) => (isActive ? 'selected' : undefined)}
              to={getURI('ABOUT_US')}
            >
              About the LCCC
            </NavLink>
          </dd>
          <dd>
            <NavLink
              className={({ isActive }) => (isActive ? 'selected' : undefined)}
              to={getURI('WHOSWHO')}
            >
              Who's Who
            </NavLink>
          </dd>
          <dd>
            <NavLink
              className={({ isActive }) => (isActive ? 'selected' : undefined)}
              to={getURI('CONTACT')}
            >
              Contact Us
            </NavLink>
          </dd>
        </dl>
      </Flexbox>
      {/* <Flexbox element="section" style={{ marginBottom: '10px' }}>
        <iframe
          title="LCCC FB Page"
          src="https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2Flondoncigarettecard%2F&tabs=timeline&width=210&height=210&small_header=false&adapt_container_width=true&hide_cover=false&show_facepile=true&appId=185043224917654"
          width="210"
          height="210"
          style={{ border: 'none', overflow: 'hidden' }}
          scrolling="no"
          frameBorder="0"
          allowtransparency="true"
          allow="encrypted-media"
        ></iframe>
      </Flexbox> */}
      <Flexbox
        flexDirection="column"
        element="section"
        className="navBanner infopages"
      >
        <Link page="CATALOGUE">
          <Flexbox className="heading">Catalogues</Flexbox>
          <Flexbox>Featuring 17,000 different series</Flexbox>
        </Link>
      </Flexbox>
      <Flexbox element="section" className="navBanner infopages">
        <Link page="CCC">
          <div className="heading">Card Collectors Club</div>
        </Link>
      </Flexbox>
      <Flexbox element="section" className="navBanner infopages">
        <Link page="CAT_37">
          <div className="heading">Collectors Magazine</div>
        </Link>
      </Flexbox>
      <Flexbox
        flexDirection="column"
        element="section"
        className="navBanner infopages"
      >
        <Link page="VALUING">
          <Flexbox className="heading">Valuing &amp; Selling</Flexbox>
          <Flexbox>Information on how to value or sell your cards</Flexbox>
        </Link>
      </Flexbox>
    </Flexbox>
  );
}

export default Left;
