// @flow

import * as React from 'react';

import { Box, Flexbox, RTEText } from '../common';
import type { TNewIssue, TPageContent } from '../../types';

import { PageHelpers } from '../../helpers';
import ProductList from '../ProductList';
import { useEffect } from 'react';
import { useLastList } from '../../helpers/ConfigHelper';

type Props = {
  content?: TPageContent,
  newissues: TNewIssue,
};

export default function NewIssuesMonth(props: Props) {
  const { lastList, setLastList } = useLastList();

  useEffect(() => {
    if (lastList !== 'New Issues') {
      setLastList('New Issues');
    }
  }, [setLastList, lastList]);

  function getHeading(): React.Node {
    const month = props.newissues.month;
    const m = parseInt(month.substring(0, 2), 10) - 1;
    const y = month.substring(3, 5);
    const yearPrefix = y === '99' ? '19' : '20';
    const date = new Date(parseInt(yearPrefix + y, 10), m, 5, 5, 0, 0);
    const shortOptions = { month: 'long' };
    const longOptions = { month: 'long', year: 'numeric' };

    const { content } = props;
    let intro = PageHelpers.getContent(content, 'Intro')
      .replace(/{MONTH_YEAR}/g, date.toLocaleDateString('en-GB', longOptions))
      .replace(/{MONTH}/g, date.toLocaleDateString('en-GB', shortOptions))
      .replace(/{COUNT}/g, props.newissues.prodIDs.length.toString());

    return <RTEText content={intro} />;
  }

  return (
    <Flexbox flexDirection="column" element="section">
      <Box highlight={true}>
        <div>{getHeading()}</div>
      </Box>
      <div>
        <ProductList prodIDs={props.newissues.prodIDs} />
      </div>
    </Flexbox>
  );
}
