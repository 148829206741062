// @flow

import * as React from 'react';

import { Loading, Page } from '../common';

import PromotionHome from './PromotionHome';
import { useParams } from 'react-router-dom';
import { usePromotions } from '../../hooks/offers';

function Promotions() {
  const params = useParams();
  const id = params.id;
  const { isFetching } = usePromotions(id);

  function _getPageComponent(): React.Node {
    if (isFetching) {
      return <Loading />;
    }

    return <PromotionHome id={id} />;
  }

  return <Page>{_getPageComponent()}</Page>;
}

export default Promotions;
