// @flow

import { Set } from 'immutable';

export const prodListMinBoxWidth: number = 330;

export const navWidth: number = 224;
export const navMargin: number = 12;
export const navWidthWithMargin: number = navWidth + navMargin;

export const COUNTRY_US = 184;
export const freeShipping = Set(['220', '221', '222', '183']);
export const gaTrackers = ['tracker1', 'tracker2', 'tracker3'];
