// @flow
// @format

import * as EmailValidator from 'email-validator';
import * as React from 'react';

import { Box, Flexbox, Form, Link } from '../common';
import { getFetchURI, validatePassword } from '../../helpers';
import { useCustomer, useCustomerHelper } from '../../hooks/customer';

import { useState } from 'react';

function AccountSecurity() {
  const customerHelper = useCustomerHelper();
  const [customer, setCustomer] = useCustomer();
  const [firstName, setFirstName] = useState(customerHelper.getFirstName());
  const [lastName, setLastName] = useState(customerHelper.getLastName());
  const [email, setEmail] = useState('');
  const [emailConfirm, setEmailConfirm] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [passwordConfirm, setPasswordConfirm] = useState('');

  function _handleNameResponse(
    values: Object,
    success: boolean,
    _errs: Array<string>,
    _payload: Object,
  ): void {
    if (success) {
      setCustomer({
        ...customer,
        fname: values.firstName,
        sname: values.lastName,
      });
    }
  }

  function _handleEmailResponse(
    values: Object,
    success: boolean,
    _errs: Array<string>,
    _payload: Object,
  ): void {
    if (success) {
      setCustomer({
        ...customer,
        email: values.email,
      });
    }
  }

  function _handleNameValidation(values: Object): Array<string> {
    let errors = [];

    if (!values.firstName) {
      errors.push('Please enter your first name');
    }

    if (!values.lastName) {
      errors.push('Please enter your last name');
    }

    return errors;
  }

  function _handleEmailValidation(values: Object): Array<string> {
    let errors = [];

    if (!values.curEmailPassword) {
      errors.push('Please enter your current pasword');
    }

    if (values.email) {
      const isEmailValid = EmailValidator.validate(values.email);
      if (!isEmailValid) {
        errors.push('Please enter a valid email address.');
      } else if (values.email !== values.emailConfirm) {
        errors.push('Email addresses entered do not match');
      }
    } else {
      errors.push('Please enter your new email address.');
    }

    return errors;
  }

  function _handlePasswordValidation(values: Object): Array<string> {
    let errors = [];

    if (!values.curPassword) {
      errors.push('Please enter your current pasword');
    }

    if (!values.newPassword) {
      errors.push('Please enter your new password.');
    }

    if (values.newPassword && values.newPassword !== values.passwordConfirm) {
      errors.push('Passwords entered do not match');
    }

    if (values.newPassword) {
      const passwordErrors = validatePassword(values.newPassword);
      passwordErrors.forEach((err) => errors.push(err));
    }

    return errors;
  }

  return (
    <Flexbox flexDirection="column" element="section">
      <Flexbox element="section">
        <Box highlight={true}>
          <span>
            <h1>Login & Security</h1>
            <div>
              Below you can update your name, email address and password. If you
              have any questions please <Link page="CONTACT">contact us</Link>
            </div>
          </span>
        </Box>
      </Flexbox>
      <Flexbox element="section" flexDirection="row">
        <Flexbox
          flexGrow={1}
          flexShrink={1}
          flexBasis="0"
          style={{ marginRight: '8px' }}
        >
          <Box>
            <Flexbox flexGrow={1}>
              <Flexbox flexDirection="column" element="section">
                <Box highlight={true} style={{ marginTop: '16px' }}>
                  <div className="subheading">
                    <h4>Name</h4>
                    <Form
                      className="contactform"
                      buttonLabel="Update Name"
                      onSubmit={_handleNameResponse}
                      onValidate={_handleNameValidation}
                      honeyPot={true}
                      uri={getFetchURI('async/account/security.php')}
                      success={<Box>Name successfully updated</Box>}
                    >
                      <dl className="medium">
                        <dd>
                          <label htmlFor="firstName">First Name</label>
                          <input
                            id="firstName"
                            name="firstName"
                            type="text"
                            value={firstName}
                            onChange={(event) =>
                              setFirstName(event.target.value)
                            }
                          />
                        </dd>
                        <dd>
                          <label htmlFor="lastName">Last Name</label>
                          <input
                            id="lastName"
                            name="lastName"
                            type="text"
                            value={lastName}
                            onChange={(event) =>
                              setLastName(event.target.value)
                            }
                          />
                        </dd>
                      </dl>
                      <input type="hidden" name="actionType" value="name" />
                    </Form>
                  </div>
                </Box>
                <Box highlight={true} style={{ marginTop: '16px' }}>
                  <div className="subheading">
                    <h4>Email Address</h4>
                    <div>Your current email: {customerHelper.getEmail()}</div>
                    <Form
                      className="contactform account"
                      buttonLabel="Update Email"
                      onSubmit={_handleEmailResponse}
                      onValidate={_handleEmailValidation}
                      honeyPot={false}
                      uri={getFetchURI('async/account/security.php')}
                      success={<Box>Email successfully updated</Box>}
                    >
                      <div style={{ marginTop: '4px' }}>
                        <em>
                          For extra security please confirm your existing
                          passsord
                        </em>
                      </div>
                      <dl className="medium">
                        <dd>
                          <label htmlFor="curEmailPassword">
                            Your current password
                          </label>
                          <input
                            id="curEmailPassword"
                            name="curEmailPassword"
                            type="password"
                          />
                        </dd>
                        <dd>
                          <label htmlFor="email">New email address</label>
                          <input
                            id="email"
                            name="email"
                            type="text"
                            value={email}
                            onChange={(event) => setEmail(event.target.value)}
                          />
                        </dd>
                        <dd>
                          <label htmlFor="emailConfirm">Re-enter email</label>
                          <input
                            id="emailConfirm"
                            name="emailConfirm"
                            type="text"
                            value={emailConfirm}
                            onChange={(event) =>
                              setEmailConfirm(event.target.value)
                            }
                          />
                        </dd>
                        <input type="hidden" name="actionType" value="email" />
                      </dl>
                    </Form>
                  </div>
                </Box>
              </Flexbox>
            </Flexbox>
          </Box>
        </Flexbox>
        <Flexbox flexGrow={1} flexShrink={1} flexBasis="0">
          <Box>
            <Flexbox flexDirection="column" element="section" flexGrow={1}>
              <Flexbox>
                <Box highlight={true} style={{ marginTop: '16px' }}>
                  <div className="subheading">
                    <h4>Password</h4>
                    <Form
                      className="contactform account"
                      buttonLabel="Update Password"
                      onValidate={_handlePasswordValidation}
                      honeyPot={true}
                      uri={getFetchURI('async/account/security.php')}
                      success={<Box>Password successfully updated</Box>}
                    >
                      <div>
                        <em>
                          For extra security please confirm your existing
                          passsord
                        </em>
                      </div>
                      <dl className="medium">
                        <dd>
                          <label htmlFor="curPassword">
                            Your current password
                          </label>
                          <input
                            id="curPassword"
                            name="curPassword"
                            type="password"
                          />
                        </dd>
                        <dd>
                          <label htmlFor="newPassword">New password</label>
                          <input
                            id="newPassword"
                            name="newPassword"
                            type="password"
                            value={newPassword}
                            onChange={(event) =>
                              setNewPassword(event.target.value)
                            }
                          />
                        </dd>
                        <dd>
                          <label htmlFor="passwordConfirm">
                            Re-enter new password
                          </label>
                          <input
                            id="passwordConfirm"
                            name="passwordConfirm"
                            type="password"
                            value={passwordConfirm}
                            onChange={(event) =>
                              setPasswordConfirm(event.target.value)
                            }
                          />
                        </dd>
                      </dl>
                      <input type="hidden" name="actionType" value="password" />
                    </Form>
                  </div>
                </Box>
              </Flexbox>
            </Flexbox>
          </Box>
        </Flexbox>
      </Flexbox>
    </Flexbox>
  );
}

export default AccountSecurity;
