// @flow

import * as React from 'react';

import type { TProdSortByOptions, TProduct, TProducts } from '../types';

import { Price } from '../components/common';

export function getPrice(product: TProduct): React.Node {
  if (!product.price) {
    return 'SOLD OUT';
  }

  return <Price price={product.price} />;
}

export function getWasPrice(product: TProduct): React.Node {
  return product.price === product.wasPrice ? null : (
    <Price price={product.wasPrice} strikethrough={true} />
  );
}

export function getProducts(
  products: TProducts,
  prodIDs: Array<number>,
  sortBy: TProdSortByOptions,
  filter: string,
  issuer: string,
  sortOverride?: Function,
): Array<TProduct> {
  const filertredProducts: Array<TProduct> = [];
  prodIDs.forEach((prodID) => {
    if (products[prodID]) {
      let issuerFound = true;
      if (
        issuer.length !== 0 &&
        products[prodID].issuer.toLowerCase().indexOf(issuer.toLowerCase()) ===
          -1
      ) {
        issuerFound = false;
      }
      let filterFound = true;
      if (filter.length !== 0) {
        if (
          products[prodID].name.toLowerCase().indexOf(filter) === -1 &&
          products[prodID].issuer.toLowerCase().indexOf(filter) === -1
        ) {
          filterFound = false;
        }
      }
      if (issuerFound && filterFound) {
        filertredProducts.push(products[prodID]);
      }
    }
  });

  if (sortOverride && sortOverride()) {
    return filertredProducts.sort(sortOverride());
  }

  switch (sortBy) {
    case 'price':
      return filertredProducts.sort(sortByPrice());
    case 'issuer':
      return filertredProducts.sort(sortByIssuer());
    case 'name':
    default:
      return filertredProducts.sort(sortByName());
  }
}

export function getIssuers(products: Array<TProduct>): Array<string> {
  const issuers: { [string]: string } = {};
  products.forEach((prod) => {
    issuers[prod.issuer] = prod.issuer;
  });

  return Object.keys(issuers).sort((a, b) => a.localeCompare(b));
}

function sortByName(): Function {
  return function (a, b) {
    if (a.name < b.name) {
      return -1;
    }
    if (a.name > b.name) {
      return 1;
    }
    return 0;
  };
}

function sortByPrice(): Function {
  return function (a, b) {
    if (a.price < b.price) {
      return -1;
    }
    if (a.price > b.price) {
      return 1;
    }
    if (a.name < b.name) {
      return -1;
    }
    if (a.name > b.name) {
      return 1;
    }
    return 0;
  };
}

function sortByIssuer(): Function {
  return function (a, b) {
    if (a.issuer < b.issuer) {
      return -1;
    }
    if (a.issuer > b.issuer) {
      return 1;
    }
    if (a.name < b.name) {
      return -1;
    }
    if (a.name > b.name) {
      return 1;
    }
    return 0;
  };
}
