// @flow

import * as React from 'react';

import { Link } from '../common';
import { ProductContext } from '../../hooks/products';
import { getProdURI } from '../../helpers/uris';
import { useContext } from 'react';

export default function ProductName() {
  const product = useContext(ProductContext);

  if (product == null) {
    return null;
  }

  return (
    <Link hrefOverride={getProdURI(product.id, product.name)}>
      <strong>{product.name}</strong>
    </Link>
  );
}
