// @flow

import * as React from 'react';

import { Loading, Page } from '../common';

import CardCollectorsClubHome from './CardCollectorsClubHome';
import { useEffect } from 'react';
import { useFetchCCC } from '../../hooks/ccc';

type Props = {};

function CardCollectorsClub(props: Props) {
  const [isCCCFetching, fetchCCC] = useFetchCCC();

  useEffect(() => {
    fetchCCC();
  }, [fetchCCC]);

  function _getPageComponent(): React.Node {
    if (isCCCFetching) {
      return <Loading />;
    }

    return <CardCollectorsClubHome />;
  }

  return <Page {...props}>{_getPageComponent()}</Page>;
}

export default CardCollectorsClub;
