import type { TCatalogues } from '../../types';
import { catalogueState } from '../../helpers/Atoms';
import { useCallback } from 'react';
import { useRecoilState } from 'recoil';

export function useCatalogues() {
  const [catalogues, setCatloguesState] = useRecoilState(catalogueState);

  const setCatalogues = useCallback(
    (newCatalgoues: TCatalogues) => {
      setCatloguesState({
        ...catalogues,
        data: newCatalgoues,
      });
    },
    [catalogues, setCatloguesState],
  );

  return [catalogues.data, setCatalogues];
}
