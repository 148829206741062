import * as React from 'react';

import { Link } from '../common';
import { useAuction } from '../../hooks/auction';

export default function AuctionIntro() {
  const { auction, error, isFetching } = useAuction();

  if (!auction || isFetching || error) {
    return null;
  }
  return (
    <li>
      <b>Monthly Auction</b>: This months postal auction ends on{' '}
      <Link page="AUC_DETAILS">{auction.date}</Link>. Our auction contains{' '}
      {Object.keys(auction.lots).length} lots with estimated values from
      &pound;1 upwards
    </li>
  );
}
