// @flow

import * as React from 'react';

import { Box, Flexbox, Form, Price } from '../common';

import AuctionBids from './AuctionBids';
import type { TPageContent } from '../../types';
import { useAuction } from '../../hooks/auction';

type Props = {
  lot: string,
  content?: TPageContent,
};

function AuctionMakeBid(props: Props) {
  const { auction, placeBid } = useAuction();

  return (
    <Flexbox flexDirection="column" element="section">
      <Box highlight={true}>
        <span>
          <h1>Postal Auction: Ending {auction.date}</h1>
          <p>
            Please confirm the lot information and the maximum amount you wish
            to bid for this item.
          </p>
        </span>
      </Box>
      <Box>{_getBid()}</Box>
    </Flexbox>
  );

  function _getBid(): React.Node {
    const lot = auction.lots[props.lot];
    if (!lot) {
      return null;
    }
    return (
      <span>
        <h2>Lot Information</h2>
        <p>
          Please confirm the lot information and the maximum amount you wish to
          bid for this item.
        </p>
        <table id="prodlist">
          <tbody>
            <tr>
              <td className="highlight" valign="top">
                <strong>Lot #</strong>
              </td>
              <td valign="top">{lot.lotnum}</td>
            </tr>
            <tr>
              <td className="highlight" valign="top">
                <strong>Description</strong>
              </td>
              <td valign="top">{lot.description}</td>
            </tr>
            <tr>
              <td className="highlight" valign="top">
                <strong>Condition</strong>
              </td>
              <td valign="top">{lot.condition}</td>
            </tr>
            <tr>
              <td className="highlight" valign="top">
                <strong>Catalogue Value</strong>
              </td>
              <td valign="top">
                {lot.cat_value ? <Price price={lot.cat_value} /> : '?'}
              </td>
            </tr>
            <tr>
              <td className="highlight" valign="top">
                <strong>Estimated Value</strong>
              </td>
              <td valign="top">
                {lot.price ? <Price price={lot.price} /> : '?'}
              </td>
            </tr>
          </tbody>
        </table>
        <Form
          buttonLabel="Make Bid"
          onSubmit={_handleSubmit}
          onValidate={_handleValidation}
        >
          Please enter the maximum amount you wish to bid for the above item.
          <br />
          <strong>Enter Max Bid:</strong>{' '}
          <input type="text" size="6" name="amount" />{' '}
          <em className="small">Note Please enter just the value e.g 57</em>
          <div style={{ marginBottom: '8px' }}></div>
        </Form>
        <div style={{ marginTop: '12px', marginBottom: '24px' }}>
          <b>Note</b> On the next page you can limit yourself to a total amount
          you wish to spend in this auction, this enables bidders to bid for
          more lots than they expect to get, to allow for some lots that may be
          beaten by other bidders).
        </div>
        <AuctionBids />
      </span>
    );
  }

  function _handleSubmit(values: Object): void {
    return placeBid(props.lot, parseInt(values.amount, 10));
  }

  function _handleValidation(values: Object): Array<string> {
    let failures = [];

    if (!values.amount) {
      failures.push('Please enter an amount for the bid');
    }

    if (values.amount && isNaN(values.amount)) {
      failures.push('Please enter an valid amount for the bid');
    }

    if (values.amount && values.amount < 1) {
      failures.push('No bids under £1.00 can be accepted');
    }

    const lot = auction.lots[props.lot];
    if (!lot) {
      failures.push('Lot not found');
      return failures;
    }

    let reserve = lot.price / 2;
    if (lot.price >= 10 && values.amount < reserve) {
      const reservePrice = Number(reserve).toLocaleString('en-GB', {
        style: 'currency',
        currency: 'GBP',
      });
      failures.push(
        'We have a minimum reserve price of 50% (' +
          reservePrice +
          ') for this lot',
      );
    }

    return failures;
  }
}

export default AuctionMakeBid;
