// @flow
import * as Sentry from '@sentry/react';

import { getNIMonth, getSOTMMonth } from './';

import type { TBasketItemType } from '../types';
import { default as isoFetch } from 'isomorphic-fetch';

export const accountRoute: string = '/account/:title?';
export const albumRoute: string = '/albums';
export const auctionRoute: string = '/auction/:title?/:id?';
export const basketRoute: string = '/basket/:title?';
export const bookRoute: string = '/books/:title?';
export const catHome: string = '/category';
export const catRouteNoTitle: string = '/category/:id';
export const catRoute: string = '/category/:id/:title/:pg?';
export const catalogueRoute: string = '/catalogues';
export const cccRoute: string = '/card_collectors_club';
export const ccnDownloadRoute: string = '/CCN-Download';
export const contactRoute: string = '/contact';
export const dailyRoute: string = '/daily';
export const emailOfferRoute: string = '/EmailOffer';
export const homeRoute: string = '/';
export const issuerRootRoute: string = '/issuers';
export const issuerRoute: string = '/issuers/:id/:title/:pg?';
export const loginRoute: string = '/login/:title?/:id?';
export const newIssueRootRoute: string = '/newissues/';
export const newIssueRoute: string = '/newissues/:month';
export const newsletterRoute: string = '/newsletter';
export const offersRoute: string = '/offers/:pg?';
export const pageRoute: string = '/:title';
export const productRoute: string = '/product/:name/:id';
export const promotionRoute: string = '/(promotion|EmailOffer)/:id?';
export const restoreCartRoute: string = '/restoreCart/:id/:hash';
export const searchRoute: string = '/search/:pg?';
export const specialRequestRoute: string = '/special_request/:id';
export const sotmRoute: string = '/sotm/:month/*';
export const valuingRoute: string = '/valuing';

export type TCrumb = {
  display: string,
  parent: string,
  // Recursive Type?? How to fix this?
  // eslint-disable-next-line
  customParent?: ?TPageMeta,
};

export type TPageMeta = {
  key: string,
  title: string,
  id: number,
  path: string,
  crumb: TCrumb,
  route?: string,
};

export const PageNames: { [string]: TPageMeta } = {
  HOME: {
    key: 'HOME',
    title: '',
    id: 8,
    path: '',
    crumb: {
      display: 'Home',
      parent: '',
    },
  },
  ABOUT_US: {
    key: 'ABOUT_US',
    title: 'aboutus',
    id: 15,
    path: '',
    crumb: {
      display: 'About the LCCC',
      parent: 'HOME',
    },
  },
  ACCOUNT: {
    key: 'ACCOUNT',
    title: 'account',
    id: 0,
    path: '',
    crumb: {
      display: 'Your Account',
      parent: 'HOME',
    },
  },
  ACCOUNT_ADDRESS: {
    key: 'ACCOUNT_ADDRESS',
    title: 'address',
    id: 0,
    path: 'account/',
    crumb: {
      display: 'My Addresses',
      parent: 'ACCOUNT',
    },
  },
  ACCOUNT_SECURITY: {
    key: 'ACCOUNT_SECURITY',
    title: 'security',
    id: 0,
    path: 'account/',
    crumb: {
      display: 'Login & Security',
      parent: 'ACCOUNT',
    },
  },
  ALBUMS: {
    key: 'ALBUMS',
    title: 'albums',
    id: 3,
    path: '',
    crumb: {
      display: 'Albums',
      parent: 'HOME',
    },
  },
  AUC: {
    key: 'AUC',
    title: 'auction',
    id: 29,
    path: '',
    crumb: {
      display: 'Auction',
      parent: 'HOME',
    },
  },
  AUC_BIDS: {
    key: 'AUC_BIDS',
    title: 'bids',
    id: 0,
    path: 'auction/',
    crumb: {
      display: 'Your Bids',
      parent: 'AUC_LOTS',
    },
  },
  AUC_CONDITION: {
    key: 'AUC_CONDITION',
    title: 'condition',
    id: 27,
    path: 'auction/',
    crumb: {
      display: 'Condition of Sale',
      parent: 'AUC',
    },
  },
  AUC_GUIDE: {
    key: 'AUC_GUIDE',
    title: 'guide',
    id: 28,
    path: 'auction/',
    crumb: {
      display: 'Guide to Success',
      parent: 'AUC',
    },
  },
  AUC_DETAILS: {
    key: 'AUC_DETAILS',
    title: 'details',
    id: 31,
    path: 'auction/',
    crumb: {
      display: 'Auction Details',
      parent: 'AUC',
    },
  },
  AUC_LOTS: {
    key: 'AUC_LOTS',
    title: 'lots',
    id: 30,
    path: 'auction/',
    crumb: {
      display: 'Auction Lots',
      parent: 'AUC_DETAILS',
    },
  },
  AUC_MAKE_BID: {
    key: 'AUC_MAKE_BID',
    title: 'make_bid',
    id: 0,
    path: 'auction/',
    crumb: {
      display: 'Make A Bid',
      parent: 'AUC_LOTS',
    },
  },
  AUC_PROCESS: {
    key: 'AUC_PROCESS',
    title: 'process',
    id: 18,
    path: 'auction/',
    crumb: {
      display: 'Confirm Your Bids',
      parent: 'AUC_BIDS',
    },
  },
  BASKET: {
    key: 'BASKET',
    title: 'basket',
    id: 24,
    path: '',
    crumb: {
      display: 'Basket',
      parent: 'HOME',
    },
  },
  BASKET_ADDRESS: {
    key: 'BASKET_ADDRESS',
    title: 'address',
    id: 0,
    path: 'basket/',
    crumb: {
      display: 'Review your address',
      parent: 'BASKET',
    },
  },
  BASKET_CONFIRM: {
    key: 'BASKET_CONFIRM',
    title: 'confirm',
    id: 24,
    path: 'basket/',
    crumb: {
      display: 'Review Your Order',
      parent: 'BASKET',
    },
  },
  BASKET_PAYMENT: {
    key: 'BASKET_PAYMENT',
    title: 'payment',
    id: 0,
    path: 'basket/',
    crumb: {
      display: 'Redirecting you to SagePay',
      parent: 'BASKET_CONFIRM',
    },
  },
  BASKET_SUCCESS: {
    key: 'BASKET_SUCCESS',
    title: 'success',
    id: 0,
    path: 'basket/',
    crumb: {
      display: 'Thankyou for your Order',
      parent: 'HOME',
    },
  },
  BASKET_FAILED: {
    key: 'BASKET_FAILED',
    title: 'failed',
    id: 0,
    path: 'basket/',
    crumb: {
      display: 'Problem with your Order',
      parent: 'BASKET_CONFIRM',
    },
  },
  BOOKS: {
    key: 'BOOKS',
    title: 'books',
    id: 5,
    path: '',
    crumb: {
      display: 'Books',
      parent: 'HOME',
    },
  },
  CARD_HISTORY: {
    key: 'CARD_HISTORY',
    title: 'cardhistory',
    id: 16,
    path: '',
    crumb: {
      display: 'Card History',
      parent: 'HOME',
    },
  },
  CATALOGUE: {
    key: 'CATALOGUE',
    title: 'catalogues',
    id: 1,
    path: '',
    crumb: {
      display: 'Our Catalogues',
      parent: 'HOME',
    },
  },
  CATEGORY: {
    key: 'CATEGORY',
    title: 'category',
    id: 23,
    path: '',
    crumb: {
      display: 'Our Categories (Cards for Sale)',
      parent: 'HOME',
    },
  },
  CCC: {
    key: 'CCC',
    title: 'card_collectors_club',
    id: 2,
    path: '',
    crumb: {
      display: 'Card Collectors Club',
      parent: 'HOME',
    },
  },
  CCN_DOWNLOAD: {
    key: 'CCN_DOWNLOAD',
    title: 'CCN-Download',
    id: 20,
    path: '',
    crumb: {
      display: 'Card Collectors New Downloads',
      parent: 'HOME',
    },
  },
  CONTACT: {
    key: 'CONTACT',
    title: 'contact',
    id: 0,
    path: '',
    crumb: {
      display: 'Contact Us',
      parent: 'HOME',
    },
  },
  DAILY: {
    key: 'DAILY',
    title: 'daily',
    id: 19,
    path: '',
    crumb: {
      display: 'Daily Offer',
      parent: 'OFFERS',
    },
  },
  DELIVERY: {
    key: 'DELIVERY',
    title: 'delivery',
    id: 35,
    path: '',
    crumb: {
      display: 'Delivery and Returns',
      parent: 'HOME',
    },
  },
  EMAIL_OFFER: {
    key: 'EMAIL_OFFER',
    title: 'EmailOffer',
    id: 0,
    path: '',
    crumb: {
      display: 'Email Offer',
      parent: 'OFFERS',
    },
  },
  ISSUERS: {
    key: 'ISSUERS',
    title: 'issuers',
    id: 0,
    path: '',
    crumb: {
      display: 'Issuers',
      parent: 'HOME',
    },
    route: issuerRoute,
  },
  LOG_IN: {
    key: 'LOG_IN',
    title: 'login',
    id: 34,
    path: '',
    crumb: {
      display: 'Log In',
      parent: 'HOME',
    },
  },
  LOGIN_FORGOT: {
    key: 'LOGIN_FORGOT',
    title: 'forgot',
    id: 0,
    path: 'login/',
    crumb: {
      display: 'Forgotten Password',
      parent: 'LOG_IN',
    },
  },
  NEWSLETTER: {
    key: 'NEWSLETTER',
    title: 'newsletter',
    id: 0,
    path: '',
    crumb: {
      display: 'Email Newsletter',
      parent: 'HOME',
    },
  },
  NI: {
    key: 'NI',
    title: 'newissues',
    id: 22,
    path: '',
    crumb: {
      display: 'New Issues',
      parent: 'HOME',
    },
  },
  OFFERS: {
    key: 'OFFERS',
    title: 'offers',
    id: 21,
    path: '',
    crumb: {
      display: 'Special Offers',
      parent: 'HOME',
    },
    route: offersRoute,
  },
  PRIVACY: {
    key: 'PRIVACY',
    title: 'privacy',
    id: 33,
    path: '',
    crumb: {
      display: 'Privacy Policy',
      parent: 'HOME',
    },
  },
  PRODUCT: {
    key: 'PRODUCT',
    title: 'product',
    id: 0,
    path: '',
    crumb: {
      display: '',
      parent: 'CATEGORY',
    },
  },
  PROMOTION: {
    key: 'PROMOTION',
    title: 'promotion',
    id: 0,
    path: '',
    crumb: {
      display: 'Promotion',
      parent: 'OFFERS',
    },
  },
  SEARCH: {
    key: 'SEARCH',
    title: 'search',
    id: 0,
    path: '',
    crumb: {
      display: 'Search Results',
      parent: 'HOME',
    },
    route: searchRoute,
  },
  RESTORE_CART: {
    key: 'RESTORE_CART',
    title: 'restoreCart',
    id: 0,
    path: '',
    crumb: {
      display: 'Restore Your Cart',
      parent: 'HOME',
    },
  },
  SOTM: {
    key: 'SOTM',
    title: 'sotm',
    id: 14,
    path: '',
    crumb: {
      display: 'Set of the Month',
      parent: 'HOME',
    },
  },
  SPECIAL_REQUEST: {
    key: 'SPECIAL_REQUEST',
    title: 'special_request',
    id: 0,
    path: '',
    crumb: {
      display: 'Set of the Month',
      parent: 'HOME',
    },
  },
  TERMS: {
    key: 'TERMS',
    title: 'terms',
    id: 32,
    path: '',
    crumb: {
      display: 'Terms & Conditions',
      parent: 'HOME',
    },
  },
  VALUING: {
    key: 'VALUING',
    title: 'valuing',
    id: 4,
    path: '',
    crumb: {
      display: 'Valuing & Selling Cards',
      parent: 'HOME',
    },
  },
  WHOSWHO: {
    key: 'WHOSWHO',
    title: 'whoswho',
    id: 26,
    path: '',
    crumb: {
      display: "Who's Who",
      parent: 'HOME',
    },
  },
};
export type TPageNames = $Keys<typeof PageNames>;

export const PageTypes = {
  PAGE: 'pg',
  CATEGORY: 'category',
};
export type TPageTypes = $Keys<typeof PageTypes>;

export function fetch(path: string) {
  return isoFetch(getFetchURI(path), { credentials: 'include' });
}
export function fetchWithErrHandler(path: string) {
  return isoFetch(getFetchURI(path), { credentials: 'include' }).then(
    async (response) => {
      const body = await response.text();
      try {
        return JSON.parse(body);
      } catch (err) {
        err.name = 'Invalid JSON response for: ' + path;
        Sentry.captureException(err, {
          extra: {
            fullMessage: body,
          },
        });
        throw err;
      }
    },
    (error) => {
      console.log('Failed to load: ' + path, error);
      Sentry.captureException(error),
        {
          extra: {
            fullURL: getFetchURI(path),
          },
        };
    },
  );
}

export function fetchPOST(path: string, params: URLSearchParams) {
  return isoFetch(getFetchURI(path), {
    credentials: 'include',
    method: 'POST',
    body: params,
  });
}

export function getFetchURI(path: string): string {
  const loc: string = window.location.hostname.toString();
  if (loc !== 'www.londoncigcard.co.uk' && loc !== 'dev.londoncigcard.co.uk') {
    path = 'http://' + loc + '/~davidlaker/lccc/public/' + path;
  } else {
    path = '/' + path;
  }

  return path;
}

export const getURI = (key: TPageNames) => {
  if (!key) {
    return '/';
  }
  if (key.indexOf('NI_') !== -1) {
    return getNIURI(key.substring(3, 8));
  }
  if (key.indexOf('SOTM_') !== -1) {
    return getSOTMURI(key.substring(5, 11));
  }
  if (key.indexOf('CAT_') !== -1) {
    return getCategoryURI(parseInt(key.substring(4), 10));
  }
  if (key.indexOf('ISSUER_') !== -1) {
    return getIssuerURI(parseInt(key.substring(7), 10));
  }
  if (key.indexOf('SPECIAL_REQUEST_') !== -1) {
    return getSpecialRequestURI(parseInt(key.substring(16), 10));
  }
  const page = PageNames[key];
  if (!page) {
    throw new Error('Unable to find page: ' + key);
  }
  return '/' + page.path + page.title;
};

export const getNIURI = (month: ?string) => {
  month = month ? month : getNIMonth();
  const base = PageNames.NI;
  return '/' + base.title + '/' + month;
};

export const getSOTMURI = (month: ?string, name: ?string) => {
  month = month ? month : getSOTMMonth();
  const base = PageNames.SOTM;
  name = name ? '/' + createSlug(name) : '';
  return '/' + base.title + '/' + month + name;
};

export const getIssuerURI = (id: number, name: ?string) => {
  const base = PageNames.ISSUERS;
  name = name ? '/' + createSlug(name) : '';
  return '/' + base.title + '/' + id.toString() + name;
};

export const getSpecialRequestURI = (id: number) => {
  const base = PageNames.SPECIAL_REQUEST;
  return '/' + base.title + '/' + id.toString();
};

export const makeAuctionBidURI = (id: string) => {
  const base = PageNames.AUC_MAKE_BID;
  return '/' + base.path + base.title + '/' + id;
};

export const getProdURI = (id: number, name: string) => {
  const base = PageNames.PRODUCT;
  return '/' + base.title + '/' + createSlug(name) + '/' + id.toString();
};

export const getProdURIFromType = (
  id: string,
  name: string,
  type: TBasketItemType,
) => {
  switch (type) {
    case 'accessories':
    case 'albums':
    case 'leaves':
    case 'ltAlbums':
    case 'luxuryBinders':
    case 'luxuryLeaves':
    case 'wants':
    case 'wrappings':
    case 'xlAccessories':
      return getURI('ALBUMS');
    case 'booksRef':
      return getURI('BOOKS_REF');
    case 'booksGen':
      return getURI('BOOKS_GEN');
    case 'frames':
      return getURI('HOME');
    case 'product':
      return getProdURI(parseInt(id, 10), name);
    case 'sotm':
      return getSOTMURI(id, name);
    case 'catalogue':
    case 'catalogues':
    case 'newYearCatalogue':
      return getURI('CATALOGUE');
    case 'magazine':
    case 'magazineBinders':
    case 'magazineCurrentIssues':
    case 'subscriptions_digital':
    case 'subscriptions_digital_renew':
    case 'subscriptions_uk':
    case 'subscriptions_uk_renew':
    case 'subscriptions_eu':
    case 'subscriptions_eu_renew':
    case 'subscriptions_row':
    case 'subscriptions_row_renew':
    case 'magazines':
      return getURI('CCC');
    case 'offer':
      return getURI('OFFERS');
    case 'specialRequest':
      return getURI('SPECIAL_REQUEST_' + parseInt(id, 10));
    default:
      throw Error('Unexpected type ' + type);
  }
};

export const getCategoryURI = (cat: number, name: ?string) => {
  const base = PageNames.CATEGORY;
  name = name ? '/' + createSlug(name) : '';
  return '/' + base.title + '/' + cat + name;
};

export const getCCNBackCopiesLink = () => {
  return getCategoryURI(37, 'Card Collectors News Magazine Back Copies');
};

function createSlug(text: string): string {
  text = text
    .toString()
    .toLowerCase()
    .trim()
    .replace(new RegExp('[ÁÀÂÃ]', 'gi'), 'a')
    .replace(new RegExp('[ÉÈÊ]', 'gi'), 'e')
    .replace(new RegExp('[ÍÌÎ]', 'gi'), 'i')
    .replace(new RegExp('[ÓÒÔÕ]', 'gi'), 'o')
    .replace(new RegExp('[ÚÙÛ]', 'gi'), 'u')
    .replace(new RegExp('[Ç]', 'gi'), 'c')
    .replace(/(?:(the\s|a\s|an\s)\s+)/g, '')
    .replace(/\s+/g, '-') // Replace spaces with -
    .replace(/-+/g, '-') // Replace multiple - with single -
    .replace(/^-+/, '') // Trim - from start of text
    .replace(/-+$/, '') // Trim - from end of text
    .replace(/[^\w-]+/g, '') // Remove all non-word chars
    .substring(0, 50);

  if (text.length < 50) {
    return text;
  }

  return text.substring(0, Math.min(text.length, text.lastIndexOf('-')));
}
