// @flow

import * as React from 'react';

import { Box, Flexbox, Form, Link, Loading, RTEText } from '../common';
import { useCheckIntitiated, useDownloads } from '../../hooks/downloads';
import { useEffect, useMemo } from 'react';

import { PageHelpers } from '../../helpers';
import type { TPageContent } from '../../types';
import { useCustomerHelper } from '../../hooks/customer';

type Props = {
  content?: TPageContent,
};

function CCNDownloadsHome(props: Props) {
  const {
    error,
    downloads,
    fetchDownloads,
    isFetching: areDownloadsFetching,
  } = useDownloads();
  const {
    initiated,
    fetchIntiatiated,
    isFetching: isInitFetching,
  } = useCheckIntitiated();
  const customerHelper = useCustomerHelper();

  const { content } = props;

  useEffect(() => {
    if (customerHelper.isLoggedIn()) {
      fetchDownloads(customerHelper.getEmail());
    } else {
      fetchIntiatiated();
    }
  }, [customerHelper]);

  const isFetching = useMemo(() => {
    if (customerHelper.isLoggedIn()) {
      return areDownloadsFetching;
    } else {
      return isInitFetching;
    }
  }, [areDownloadsFetching, isInitFetching]);

  return (
    <Flexbox flexDirection="column">
      <Flexbox flexGrow={1}>
        <Box highlight={true}>
          <span>{_getHeading()}</span>
        </Box>
      </Flexbox>
      <Flexbox flexGrow={1}>
        <Box>{_getContent()}</Box>
      </Flexbox>
    </Flexbox>
  );

  function _getContent() {
    if (isFetching) {
      return <Loading />;
    }

    if (!customerHelper.isLoggedIn() && !downloads.downloads.length) {
      return (
        <span>
          {!initiated ? (
            _getInitiatedError()
          ) : (
            <>
              {_getConfirmEmail()}
              {_getFailure()}
              {_getForm()}
            </>
          )}
        </span>
      );
    }

    return (
      <span>
        {_getIntro()}
        {_getFailure()}
        {_getDownloads()}
      </span>
    );
  }

  function _getHeading(): React.Node {
    const intro = PageHelpers.getContent(content, 'Heading');

    return <RTEText content={intro} />;
  }

  function _getIntro(): React.Node {
    const intro = PageHelpers.getContent(content, 'Download Intro');

    return <RTEText content={intro} />;
  }

  function _getConfirmEmail(): React.Node {
    const intro = PageHelpers.getContent(content, 'Confirm Email');

    return <RTEText content={intro} />;
  }

  function _getFailure(): React.Node {
    if (!error) {
      return null;
    }

    return (
      <div className="notice error">
        {error +
          ' If this problem persists please contact cards@londoncigcard.co.uk'}
      </div>
    );
  }

  function _getInitiatedError(): React.Node {
    return (
      <>
        <h3>Please log in or click link in email</h3>
        <div>
          To access the Card Collectors Club magazine downloads please either{' '}
          <Link page="LOG_IN">login</Link> with the registered account or click
          the link you recieved in your card collectos club email.
        </div>
      </>
    );
  }

  function _getForm(): React.Node {
    return (
      <Form
        onSubmit={(values) => fetchDownloads(values.email)}
        buttonLabel="Confirm Email"
        className="contactform"
      >
        <dl class="small">
          <dd>
            <label for="email">Email</label>
            <input type="text" name="email" id="email" />
          </dd>
        </dl>
      </Form>
    );
  }

  function _getDownloads(): React.Node {
    if (!downloads.success) {
      return null;
    }

    return (
      <ul>
        {downloads.downloads.map((download) => {
          return (
            <li key={download.id}>
              <a
                href={
                  '/CCN-Download?file=' +
                  download.id.toString() +
                  '&hash=' +
                  download.hash
                }
              >
                {download.name}
              </a>
            </li>
          );
        })}
      </ul>
    );
  }
}

export default CCNDownloadsHome;
