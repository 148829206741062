// @flow

import * as React from 'react';

import { Box, Flexbox, PriceWithButton, RTEText } from '../common';
import type { TCatalogue, TPageContent } from '../../types';

import { PageHelpers } from '../../helpers';
import { useCatalogues } from '../../hooks/catalogues';

type Props = {
  content?: TPageContent,
};

export default function CatalogueHome(props: Props) {
  const [catalogues] = useCatalogues();

  const { content } = props;
  return (
    <Flexbox flexDirection="column">
      <Flexbox flexGrow={1}>
        <Box highlight={true}>
          <span>{_getHeading()}</span>
        </Box>
      </Flexbox>
      <Flexbox flexGrow={1}>
        <Box>
          <span>
            {_getNewCatalogue(catalogues.nextYear)}
            {_getCatalogue(catalogues.details, 'catalogue')}
          </span>
        </Box>
      </Flexbox>
      <Flexbox flexGrow={1}>
        <Box>
          <span>{_getFooter()}</span>
        </Box>
      </Flexbox>
    </Flexbox>
  );

  function _getNewCatalogue(catalogue: ?TCatalogue): React.Node {
    if (!catalogue) {
      return null;
    }

    return _getCatalogue(catalogue, 'newYearCatalogue');
  }

  function _getCatalogue(catalogue: TCatalogue, type: string): React.Node {
    if (
      catalogue === null ||
      catalogue.description === null ||
      catalogue.price === null
    ) {
      return null;
    }

    return (
      <Flexbox>
        <span>
          <RTEText content={catalogue.description} />
          <Flexbox
            flexDirection="column"
            element="section"
            style={{ width: '100%', marginTop: '12px' }}
          >
            <PriceWithButton
              label="Price"
              stateKey={catalogue.id}
              price={catalogue.price}
              type={type}
              hash={catalogue.hash}
            />
            <PriceWithButton
              label="Card Collectors Club discount price"
              stateKey={catalogue.cccID}
              price={catalogue.cccPrice}
              type={type}
              hash={catalogue.cccHash}
            />
          </Flexbox>
        </span>
      </Flexbox>
    );
  }

  function _getHeading(): React.Node {
    // $year_msg = $year . ($hasNewCatalogue ? ' and NEW '.($year + 1) : '');
    //     echo str_replace('{YEAR}', $year_msg, $content['Heading']);
    //     if($hasNewCatalogue) {
    //         $content['New Year Pricing'] = str_replace('{YEAR1}', $year1, $content['New Year Pricing']);
    //         $content['New Year Pricing'] = str_replace('{YEAR}', $year, $content['New Year Pricing']);
    //         echo $content['New Year Pricing'];
    //     }
    //     echo $content['Shipping'];
    let yearMsg = catalogues.details.year.toString();
    let year1 = '';
    if (catalogues.nextYear) {
      yearMsg += ' and NEW ' + catalogues.nextYear.year.toString();
      year1 = catalogues.nextYear ? catalogues.nextYear.year : '';
    }

    const date = new Date();
    const currentYear = date.getFullYear();
    let heading = PageHelpers.getContent(content, 'Heading').replace(
      /{YEAR}/g,
      yearMsg,
    );
    let newYearPricing = PageHelpers.getContent(content, 'New Year Pricing')
      .replace(/{YEAR}/g, currentYear.toString())
      .replace(/{YEAR1}/g, year1.toString());
    let shipping = PageHelpers.getContent(content, 'Shipping');
    return (
      <div>
        <RTEText content={heading} />
        {catalogues.nextYear ? <RTEText content={newYearPricing} /> : null}
        <RTEText content={shipping} />
      </div>
    );
  }

  function _getFooter(): React.Node {
    let intro = PageHelpers.getContent(content, 'Footer');

    return <RTEText content={intro} />;
  }
}
