// @flow

import * as React from 'react';

import { BeatLoader } from 'react-spinners';

type Props = {};

export default class Loading extends React.Component<Props> {
  render() {
    return <BeatLoader color={'#F5A623'} loading={true} size={8} />;
  }
}
