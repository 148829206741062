// @flow

import * as React from 'react';

import { Box, Flexbox } from './';

type Props = {};

export default class Page404 extends React.Component<Props> {
  render() {
    return (
      <Flexbox flexDirection="column">
        <Flexbox flexGrow={1}>
          <Box highlight={true}>
            <span>
              <h1>Error 404 - Page Not Found</h1>
            </span>
          </Box>
        </Flexbox>
      </Flexbox>
    );
  }
}
