import { cccState } from '../../helpers/Atoms';
import { useCallback } from 'react';
import { useRecoilState } from 'recoil';

export function useCCC() {
  const [ccc, setCCCState] = useRecoilState(cccState);

  const setCCC = useCallback(
    (newCCC: TCCC) => {
      setCCCState({
        ...ccc,
        data: newCCC,
      });
    },
    [ccc, setCCCState],
  );

  return [ccc.data, setCCC];
}
