import { useCallback, useEffect } from 'react';

import { fetchWithErrHandler } from '../../helpers';
import { promotionState } from '../../helpers/Atoms';
import { useRecoilState } from 'recoil';

export function usePromotions(id: number) {
  const [promotion, setPromotionState] = useRecoilState(promotionState(id));

  const fetchpromotion = useCallback(() => {
    if (promotion.fetchStatus === 'FETCHED') {
      return;
    }
    setPromotionState({ ...promotion, fetchStatus: 'FETCHING' });
    return fetchWithErrHandler('async/promotions.php?id=' + id).then(
      (json) => {
        if (json.error) {
          setPromotionState({
            ...promotion,
            fetchStatus: 'FETCHED',
            error: json.error,
          });
          return;
        }
        json.success = !!json.success;
        json.prodIDs = json.prodIDs.map((id) => parseInt(id, 10));
        setPromotionState({
          ...promotion,
          fetchStatus: 'FETCHED',
          data: json,
        });
        return json;
      },
      (error) => {
        setPromotionState({ ...promotion, fetchStatus: 'FETCHED', error });
        console.log('An error occured.', error);
      },
    );
  }, [promotion, setPromotionState, id]);

  useEffect(() => {
    if (promotion.fetchStatus === 'NONE') {
      fetchpromotion();
    }
  }, [promotion.fetchStatus, fetchpromotion]);

  return {
    promotion: promotion.data,
    isFetching: promotion.fetchStatus !== 'FETCHED',
    error: promotion.error,
  };
}
