// @flow

import * as React from 'react';

import { Button, Flexbox, Link, Price } from '../common';
import { getNIURI, getSOTMURI } from '../../helpers';
import { useCustomerHelper, useLogout } from '../../hooks/customer';

import SearchBar from './SearchBar';
import { useBasketHelper } from '../../hooks/basket';
import { useConfigHelper } from '../../helpers/ConfigHelper';

function Header() {
  const doLogout = useLogout();
  const basketHelper = useBasketHelper();
  const customerHelper = useCustomerHelper();
  const [configHelper] = useConfigHelper();
  const linkStyles = {
    marginRight: '12px',
    float: 'left',
    marginBottom: '25px',
    marginTop: '23px',
  };

  function _getLogIn(): React.Node {
    if (customerHelper.isCustomer()) {
      const name = customerHelper.getFirstName();
      return (
        <strong>
          <Link page="LOG_IN" callback={doLogout}>
            Hi, not {name}? click here
          </Link>
        </strong>
      );
    }

    return (
      <strong>
        <Link page="LOG_IN">Hi, sign in or create an account</Link>
      </strong>
    );
  }

  function _getMyAccount(): React.Node {
    return (
      <strong>
        <Link page="ACCOUNT">Your Account</Link>
        <div style={{ marginLeft: '4px' }}>&middot;</div>
        <Link page="LOG_IN" callback={doLogout} style={{ marginLeft: '4px' }}>
          Log Out
        </Link>
      </strong>
    );
  }

  return (
    <Flexbox
      flexDirection="row"
      style={{
        margin: '12px',
        width: '100%',
      }}
    >
      <Flexbox
        style={{
          minWidth: '208px',
        }}
      >
        <Link page="HOME">
          <img alt="LCCC Logo" src="/images/logo.png" />
        </Link>
      </Flexbox>
      <Flexbox
        flexGrow={1}
        flexDirection="column"
        style={{
          padding: '0 36px',
        }}
      >
        <Flexbox>
          <SearchBar />
        </Flexbox>
        <Flexbox
          style={{
            alignItems: 'flex-end',
            display: 'inline-flex',
            whiteSpace: 'nowrap',
            height: '59px',
          }}
        >
          <div
            style={{
              position: 'static',
              float: 'none',
              padding: '2px 0 0 0',
            }}
          >
            <div
              style={{
                overflow: 'hidden',
                margin: '32px 0 0',
                height: '37px',
              }}
              className="headerNav"
            >
              <Link style={linkStyles} hrefOverride={getNIURI()}>
                New This Month
              </Link>
              <Link style={linkStyles} page="CATALOGUE">
                Catalogues
              </Link>
              <Link style={linkStyles} page="AUC">
                Auction
              </Link>
              <Link style={linkStyles} page="CCC">
                Card Collectors Club
              </Link>
              <Link style={linkStyles} page="ALBUMS">
                Albums
              </Link>
              <Link style={linkStyles} page="OFFERS">
                Special Offers
              </Link>
              <Link style={linkStyles} hrefOverride={getSOTMURI()}>
                Set of the Month
              </Link>
            </div>
          </div>
        </Flexbox>
      </Flexbox>
      <Flexbox
        style={{
          minWidth: '420px',
        }}
        className="headerCart"
      >
        <dl id="basket">
          <dt>Your Shopping Basket:&nbsp;</dt>
          <dd className="items">{basketHelper.getItemCount()} Items</dd>
          <dd className="total">
            <strong>Total:</strong> <Price price={basketHelper.getTotal()} />
          </dd>
          <dd className="account">
            {configHelper.isFetching()
              ? null
              : customerHelper.isLoggedIn()
              ? _getMyAccount()
              : _getLogIn()}
          </dd>
          <dd className="checkout">
            <Link page="BASKET">
              <Button label="View Basket" />
            </Link>
          </dd>
        </dl>
      </Flexbox>
    </Flexbox>
  );
}

export default Header;
