// @flow

import * as React from 'react';

import { Box, Flexbox } from '../common';

import AuctionBids from './AuctionBids';
import { useAuction } from '../../hooks/auction';

type Props = {
  readOnly?: boolean,
};

export default function AuctionBidsPage(_props: Props) {
  const { auction } = useAuction();

  return (
    <Flexbox flexDirection="column" element="section">
      <Flexbox element="section">
        <Box highlight={true}>
          <span>
            <h1>Postal Auction: Ending {auction.date}</h1>
            <div>
              <strong>NOTE: </strong>Before your bid(s) are placed you will need
              to press the 'Process Bids' button at the bottom of then page then
              enter your name etc.
            </div>
          </span>
        </Box>
      </Flexbox>
      <Flexbox element="section">
        <Box>
          <AuctionBids {...this.props} />
        </Box>
      </Flexbox>
    </Flexbox>
  );
}
