// @flow

import * as React from 'react';

import { Box, Flexbox, RTEText } from '../common';
import type { TAuctionDetail, TPageContent } from '../../types';

import AuctionImage from './AuctionImage';
import CatalogueRequest from './CatalogueRequest';
import { PageHelpers } from '../../helpers';
import { useAuction } from '../../hooks/auction';

type Props = {
  content?: TPageContent,
};

export default function AuctionDetails(props: Props) {
  const { auction } = useAuction();
  const { content } = props;

  return (
    <Flexbox flexDirection="column" element="section">
      <Box highlight={true}>
        <div>{_getHeading()}</div>
      </Box>
      <Box>{_getDetails()}</Box>
      <CatalogueRequest />
    </Flexbox>
  );

  function _getHeading(): React.Node {
    let heading = PageHelpers.getContent(content, 'Heading')
      .replace(/{LOTS}/g, Object.keys(auction.lots).length.toString())
      .replace(/{DATE}/g, auction.date);

    return <RTEText content={heading} />;
  }

  function _getDetails() {
    const catalogueText = PageHelpers.getContent(content, 'Catalogue').replace(
      /{LOTS}/g,
      Object.keys(auction.lots).length.toString(),
    );
    return (
      <Flexbox flexDirection="column" element="section">
        {auction.info.map((detail, i) => {
          if (detail.title === '') {
            return null;
          }
          return (
            <Flexbox flexDirection="row" element="section" key={i}>
              <Flexbox flexDirection="column">
                {_getTitle(detail.title)}
                <Flexbox flexGrow={1}>
                  <div>
                    {_getLot(detail)}
                    {detail.description && (
                      <RTEText content={detail.description} />
                    )}
                  </div>
                </Flexbox>
              </Flexbox>
            </Flexbox>
          );
        })}
        <RTEText content={catalogueText} />
      </Flexbox>
    );
  }

  function _getTitle(title: string): React.Node {
    if (title.length === 0) {
      return null;
    }

    return (
      <Flexbox element="header">
        <h2>{title}</h2>
      </Flexbox>
    );
  }

  function _getLot(detail: TAuctionDetail): React.Node {
    if (detail.image == null || detail.image.length === 0) {
      return null;
    }

    return (
      <div style={{ float: 'right', textAlign: 'center' }}>
        <AuctionImage
          id={detail.image}
          alt={'Lot: ' + detail.img_lot}
          style={{ padding: '8px' }}
        />
        <br />
        <strong>Lot: {detail.img_lot}</strong>
      </div>
    );
  }
}
