import type { TCustomer } from '../../types';
import { useCallback } from 'react';

export function useMakeCustomer(): TCustomer {
  return useCallback((rawCustomer: Object) => {
    let addresses = {};
    Object.keys(rawCustomer.addresses).forEach((aID) => {
      aID = parseInt(aID, 10);
      const address = rawCustomer.addresses[aID];
      address.id = aID;
      address.country_id = parseInt(address.country_id, 10);
      addresses[aID] = address;
    });
    rawCustomer.addresses = addresses;
    return rawCustomer;
  }, []);
}
