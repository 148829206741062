import { useCallback, useMemo } from 'react';

import type { TCategory } from '../../types';
import { categoriesState } from '../../helpers/Atoms';
import { useRecoilState } from 'recoil';

export function useCategory(id): [TCategory, (newCategory: TCategory) => void] {
  const [categories, setCategoryState] = useRecoilState(categoriesState);

  const category = useMemo(() => {
    return categories.data[id];
  }, [id, categories]);

  const setCateogry = useCallback(
    (newCategory: TCategory) => {
      setCategoryState({
        ...categories,
        data: {
          ...categories.data,
          [newCategory.id]: newCategory,
        },
      });
    },
    [categories, setCategoryState],
  );

  return [category, setCateogry];
}
