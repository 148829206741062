import { fetch } from '../../helpers';
import { useCustomer } from './';

export function useFetchCustomerOrders() {
  const [customer, setCustomer] = useCustomer();

  return () => {
    fetch('async/customer_orders.php')
      .then(
        (response) => response.json(),
        (error) => console.log('An error occured.', error),
      )
      .then((json) => {
        const orders = [];
        Object.keys(json).forEach((oID) => {
          oID = parseInt(oID, 10);
          const order = json[oID];
          order.total = parseFloat(order.total);
          order.dID = parseInt(order.dID, 10);
          order.data = parseInt(order.data, 10);
          order.items = order.items.map((item) => {
            item.id = parseInt(item.id, 10);
            item.price = parseFloat(item.price);
            return item;
          });
          orders.push(order);
        });
        orders.sort((a, b) => b.date - a.date);
        const ordersMap = {};
        orders.forEach((o) => {
          ordersMap[o.id] = o;
        });

        setCustomer({
          ...customer,
          orders: ordersMap,
        });
      });
  };
}
