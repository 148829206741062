// @flow

export function getSOTMMonth(nextMonth: boolean = false): string {
  const date = new Date();
  date.setDate(1); // set to first of month as this fails when the current date is the 31st as next month only has 30 days so it tries to skip 2 months
  let year = date.getFullYear();
  let month = date.getMonth();
  if (nextMonth) {
    if (month === 11) {
      // DECEMBER
      year = year + 1;
      date.setMonth(0);
    } else {
      // month is zero indexed so +1 but sometimes we want next month (Home page)
      date.setMonth(month + 1);
    }
  }
  const locale = 'en-GB';
  const monthName = date.toLocaleString(locale, { month: 'long' });
  const twoDigitYear = year.toString().substring(2, 4);
  const monthLowerCase = monthName.toLowerCase().substring(0, 3);
  return monthLowerCase + '-' + twoDigitYear;
}

export function getSOTMDateForDisplay(sotmMonth: string): string {
  const m = sotmMonth.substring(0, 3);
  const monthNum = 'janfebmaraprmayjunjulaugsepoctnovdec'.indexOf(m) / 3;
  const y = sotmMonth.substring(4, 7);
  const yearPrefix = y === '99' ? '19' : '20';
  const date = new Date(parseInt(yearPrefix + y, 10), monthNum, 5, 5, 0, 0);
  const options = { month: 'long', year: 'numeric' };

  return date.toLocaleDateString('en-GB', options);
}
