// @flow

import '../../styles/pager.css';

import * as React from 'react';

import { Box, Flexbox, Loading } from './common';
import {
  catRoute,
  issuerRoute,
  offersRoute,
  searchRoute,
} from '../helpers/uris';
import {
  generatePath,
  matchRoutes,
  useLocation,
  useNavigate,
  useParams,
} from 'react-router';
import { navWidthWithMargin, prodListMinBoxWidth } from '../helpers/consts';
import { useDisplayOption, useSortOption } from '../helpers/ConfigHelper';
import { useEffect, useMemo, useState } from 'react';
import { useFilteredProducts, useProducts } from '../hooks/products';

import AnalyticsHelper from '../helpers/AnalyticsHelper';
import Columns from 'react-columns';
import Product from './Product';
import { ProductHelpers } from '../helpers';
import ProductListTable from './ProductListTable';
import ReactPaginate from 'react-paginate';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { useOffers } from '../hooks/offers';

const getIssuers = ProductHelpers.getIssuers;
const analytics = new AnalyticsHelper();

const GALLERY_MAX = 60;
const LIST_MAX = 200;

const onSearch$ = new Subject().pipe(debounceTime(300));

type DefaultProps = {
  hidePagination: boolean,
  hideIssuerFilter: boolean,
};

type Props = DefaultProps & {
  prodIDs: $ReadOnlyArray<number>, // list of products to show
  sortOverride?: Function,
  list?: string,
};

const useCurrentPath = () => {
  const location = useLocation();
  const routeData = matchRoutes(
    [
      { path: issuerRoute },
      { path: catRoute },
      { path: offersRoute },
      { path: searchRoute },
    ],
    location,
  );

  if (routeData == null) {
    return null;
  }

  return routeData[0].route.path;
};

function ProductList(props: Props) {
  const { products: loadedProducts, isFetching } = useProducts(props.prodIDs);
  const params = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const currentRoute = useCurrentPath();

  const { fetchStatus, fetchOffers } = useOffers();
  if (fetchStatus === 'NONE') {
    fetchOffers();
  }

  const uriPage = useMemo(() => {
    return parseInt(params.pg, 10) || 1;
  }, [params.pg]);

  // state for filtter/sorting the prods to display
  const [sortOption, setSortOption] = useSortOption();
  const [displayOption, setDisplayOption] = useDisplayOption();
  const [filter, setFilter] = useState('');
  const [issuer, setIssuer] = useState('');
  const [lastTracked, setLastTracked] = useState('');
  const [page, setPage] = useState(uriPage);

  useEffect(() => {
    if (uriPage !== page) {
      setPage(uriPage);
    }
  }, [page, setPage, uriPage]);

  const perPage = useMemo(() => {
    return displayOption === 'gallery' ? GALLERY_MAX : LIST_MAX;
  }, [displayOption]);

  const offset = useMemo(() => {
    return Math.ceil((page - 1) * perPage);
  }, [page, perPage]);

  const filteredProducts = useFilteredProducts(
    loadedProducts,
    sortOption,
    filter,
    issuer,
    props.sortOverride,
  );

  const prodsToShow = useMemo(() => {
    return filteredProducts.slice(offset, offset + perPage);
  }, [filteredProducts, offset, perPage]);

  useEffect(() => {
    if (
      props.list != null &&
      lastTracked !== props.list &&
      prodsToShow.length > 0
    ) {
      analytics.trackList(props.list, prodsToShow);
      setLastTracked(props.list);
    }
  }, [props.list, prodsToShow, lastTracked, setLastTracked]);

  const productsGallery = useMemo(() => {
    let queries = [];
    for (let i = 1; i <= 5; i++) {
      const width = prodListMinBoxWidth * i + navWidthWithMargin;
      queries.push({
        columns: i,
        query: 'min-width: ' + width + 'px',
      });
    }
    return (
      <Columns gap="4px" queries={queries}>
        {prodsToShow.map((product) => {
          return <Product key={product.id.toString()} product={product} />;
        })}
      </Columns>
    );
  }, [prodsToShow]);

  const productsDisplay = useMemo(() => {
    return displayOption === 'gallery' ? (
      productsGallery
    ) : (
      <ProductListTable products={prodsToShow} />
    );
  }, [prodsToShow, displayOption, productsGallery]);

  if (isFetching) {
    return <Loading />;
  }

  if (loadedProducts.length === 0) {
    return (
      <div style={{ width: '100%' }}>
        {_getPaginator(perPage, true)}
        <div>No Products Found</div>
      </div>
    );
  }

  return (
    <div style={{ width: '100%' }}>
      {_getPaginator(perPage, true)}
      {productsDisplay}
      {_getPaginator(perPage, false)}
    </div>
  );

  function _getPaginator(
    perPage: number,
    showViewOptions: boolean,
  ): React.Node {
    if (props.hidePagination === true) {
      return null;
    }
    return (
      <Box>
        <Flexbox flexDirection="row">
          <Flexbox flexDirection="column">
            <Flexbox>
              Viewing {offset + 1} to{' '}
              {Math.min(offset + perPage, filteredProducts.length)} of{' '}
              {filteredProducts.length}
            </Flexbox>
            {showViewOptions && _getViewOptions()}
            {showViewOptions && _getFilters()}
          </Flexbox>
          <Flexbox>
            <div className="react-paginate">
              <ReactPaginate
                previousLabel={'< Previous'}
                nextLabel={'Next >'}
                breakLabel="..."
                breakClassName={'break-me'}
                pageCount={Math.ceil(filteredProducts.length / perPage)}
                marginPagesDisplayed={2}
                pageRangeDisplayed={7}
                onPageChange={(data) => _handlePageClick(data)}
                containerClassName={'pagination'}
                subContainerClassName={'pages pagination'}
                activeClassName={'active'}
                forcePage={page - 1}
              />
            </div>
          </Flexbox>
        </Flexbox>
      </Box>
    );
  }

  function _getFilters(): React.Node {
    return (
      <Flexbox style={{ marginTop: '12px' }}>
        <form className="pure-form list">
          <label>Filter Results:</label>
          <FilterInput
            key={props.prodIDs.length + props.prodIDs[0]}
            onChange={(event) => _handleFilterChange(event)}
          />
          {_getIssuerFilter()}
        </form>
      </Flexbox>
    );
  }

  function _getIssuerFilter(): React.Node {
    if (props.hideIssuerFilter) {
      return null;
    }
    const issuers = getIssuers(loadedProducts);
    return (
      <React.Fragment>
        <label>By Issuer:</label>
        <select
          value={issuer}
          onChange={(e) => {
            setPage(1);
            setIssuer(e.target.value);
            _updatePageNumInURI(1);
            _resetScroll();
          }}
        >
          <option value="">All Issuers</option>
          {issuers.map((issuer) => (
            <option key={issuer} value={issuer}>
              {issuer}
            </option>
          ))}
        </select>
      </React.Fragment>
    );
  }

  function _handleFilterChange(event: SyntheticEvent<any>): void {
    // $FlowFixMe (unsure what to do here (no internet to check))
    setFilter(event.target.value.toLowerCase());
    // const filter = event.target.value.toLowerCase();
    // setFilterInput(filter);
    onSearch$.next(filter);
  }

  function _getViewOptions(): React.Node {
    const { sortOverride } = props;
    return (
      <Flexbox style={{ marginTop: '12px' }}>
        <form className="pure-form list">
          <label>View as:</label>
          <select
            value={displayOption}
            onChange={(e) => {
              setDisplayOption(e.target.value);
              setPage(1);
              _updatePageNumInURI(1);
              _resetScroll();
            }}
          >
            <option value="gallery">Gallery</option>
            <option value="list">List</option>
          </select>
          {sortOverride && sortOverride() ? null : (
            <>
              <label>Order By:</label>
              <select
                value={sortOption}
                onChange={(e) => {
                  setSortOption(e.target.value);
                  setPage(1);
                  _updatePageNumInURI(1);
                  _resetScroll();
                }}
              >
                <option value="price">Price</option>
                <option value="name">Set Title (A-Z)</option>
                <option value="issuer">Issuer (A-Z)</option>
              </select>
            </>
          )}
        </form>
      </Flexbox>
    );
  }

  function _handlePageClick(data: Object) {
    const newPage = data.selected + 1; // +1 because non 0 index
    setPage(newPage);
    _updatePageNumInURI(newPage);
    _resetScroll();
  }

  function _updatePageNumInURI(newPage: number): void {
    params.pg = newPage ?? params.pg;
    let path = generatePath(currentRoute, params);
    path = path + location.search;
    navigate(path.replace('??', '?'));
  }

  function _resetScroll(): void {
    if (document.body) {
      document.body.scrollTop = 0; // For Safari
    }
    if (document.documentElement) {
      document.documentElement.scrollTop = 0;
    }
  }
}

export default ProductList;

type FilterProps = {
  onChange: Function,
};

function FilterInput(props: FilterProps) {
  return (
    <input
      type="text"
      onChange={props.onChange}
      placeholder="Filter Results"
      style={{ width: '300px' }}
    />
  );
}
