// @flow

import * as React from 'react';

import {
  AddToBasketButton,
  Box,
  Flexbox,
  Link,
  Price,
  PriceWithButton,
  RTEText,
} from '../common';

import LatestCCNImage from '../CardCollectorsClub/LatestCCNImage';
import { PageHelpers } from '../../helpers';
import ProductList from '../ProductList';
import type { TPageContent } from '../../types';
import { useOffers } from '../../hooks/offers';

type Props = {
  content?: TPageContent,
};

export default function OffersHome(props: Props) {
  const { content } = props;

  const { offers } = useOffers();

  return (
    <Flexbox flexDirection="column">
      <Flexbox flexGrow={1}>
        <Box highlight={true}>
          <span>
            <img
              src="/images/postfree.gif"
              className="fright"
              alt="Post Free"
            />
            <h1 className="smaller">Special Offers</h1>
            {_getHeading()}
          </span>
        </Box>
      </Flexbox>
      <ProductList prodIDs={offers.prodIDs} />
      <Flexbox flexGrow={1}>
        <Box>
          <span>
            {_getStarterText()}
            {_getBargains()}
            {_getSamplers()}
            <LatestCCNImage style={{ float: 'right', paddingRight: '8px' }} />
            <h1 className="smaller">
              Even more offers with a magazine subscription
            </h1>
            <p>
              <b>
                Join subscribers all over the world and save the price of the
                annual subscription many times over with the special offers each
                month.
              </b>
            </p>
            <p>
              <Link page="CCC">Click here</Link> for more information
            </p>
          </span>
        </Box>
      </Flexbox>
    </Flexbox>
  );

  function _getBargains(): React.Node {
    const bargains = offers.offers.filter((offer) =>
      offer.name.includes('Bargain'),
    );
    return (
      <React.Fragment>
        {bargains.map((offer) => {
          return (
            <React.Fragment key={offer.id}>
              <RTEText
                content={offer.description}
                style={{ marginTop: '12px' }}
              />
              <PriceWithButton
                label="Price"
                stateKey={offer.id}
                price={offer.price}
                type="offer"
                hash={offer.hash}
              />
            </React.Fragment>
          );
        })}
      </React.Fragment>
    );
  }

  function _getSamplers(): React.Node {
    const intro = PageHelpers.getContent(content, 'Sampler Collections');
    const samplers = offers.offers.filter((offer) =>
      offer.name.includes('Sampler Collection'),
    );
    return (
      <React.Fragment>
        <RTEText content={intro} />
        <table id="catlist" style={{ marginTop: '12px' }}>
          <thead>
            <tr valign="top">
              <td>Title / Description</td>
              <td width="60">Price</td>
              <td width="120">Basket</td>
            </tr>
          </thead>
          <tbody>
            {samplers.map((offer) => {
              return (
                <tr key={offer.id}>
                  <td valign="top" className="highlight">
                    {offer.name}
                  </td>
                  <td valign="top">
                    <Price price={offer.price} />
                  </td>
                  <td align="center" valign="top">
                    <AddToBasketButton
                      stateKey={offer.id}
                      type="offer"
                      hash={offer.hash}
                    />
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </React.Fragment>
    );
  }

  function _getHeading(): React.Node {
    let text23 = parseInt(offers.num23, 10)
      ? PageHelpers.getContent(content, '2 & 3')
      : '';
    let intro = PageHelpers.getContent(content, 'Intro')
      .replace(/{SO_COUNT}/g, offers.numOffers.toString())
      .replace(/{TWO_THREE}/g, text23);
    return (
      <div>
        <RTEText content={intro} />
      </div>
    );
  }

  function _getStarterText(): React.Node {
    let intro = PageHelpers.getContent(content, 'Starter Collections');
    return (
      <div>
        <RTEText content={intro} />
      </div>
    );
  }
}
