import { emptyPromise, fetchWithErrHandler } from '../../helpers';

import { albumState } from '../../helpers/Atoms';
import { useCallback } from 'react';
import { useRecoilState } from 'recoil';

export function useFetchAlbums() {
  const [albums, setAlbums] = useRecoilState(albumState);

  const fetchAlbums = useCallback(() => {
    if (!albums.isFetching) {
      return emptyPromise({});
    }

    return fetchWithErrHandler('async/albums.php').then((json) => {
      const data = {};
      Object.keys(json).forEach((key) => {
        data[key] = json[key].map((album) => {
          album.id = parseInt(album.id, 10);
          album.price = parseFloat(album.price);
          album.price = parseFloat(album.price);
          return album;
        });
      });
      setAlbums({
        ...albums,
        isFetching: false,
        data,
      });
      return data;
    });
  }, [albums, setAlbums]);

  return [albums.isFetching, fetchAlbums];
}
