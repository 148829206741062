// @flow

import * as React from 'react';

import { Box, Flexbox, RTEText } from '../common';

import { PageHelpers } from '../../helpers';
import type { TPageContent } from '../../types';
import { useMemo } from 'react';

type Props = {
  content?: TPageContent,
};

export default function BooksHome(props: Props) {
  const heading = useMemo(() => {
    let intro = PageHelpers.getContent(props.content, 'Content');

    return <RTEText content={intro} />;
  }, [props.content]);

  return (
    <Flexbox flexDirection="column">
      <Flexbox flexGrow={1}>
        <Box highlight={true}>
          <span>{heading}</span>
        </Box>
      </Flexbox>
    </Flexbox>
  );
}
