import { fetchPOST } from '../../helpers';
import { useCallback } from 'react';
import { useCustomer } from './';

export function useUpdateNumbers(): Function {
  const [customer, setCustomer] = useCustomer();

  return useCallback(
    (numbers: Object) => {
      const params = new URLSearchParams();
      params.set('daytime', numbers.daytime);
      params.set('evening', numbers.evening);
      params.set('mobile', numbers.mobile);
      setCustomer({
        ...customer,
        phoneNumbers: numbers,
      });
      return fetchPOST('async/update_numbers.php', params).then(
        (response) => response.json(),
        (error) => console.log('An error occured.', error),
      );
    },
    [customer, setCustomer],
  );
}
