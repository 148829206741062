// @flow

import { useCallback, useEffect } from 'react';

import { fetchWithErrHandler } from '../../helpers';
import { pastSOTMState } from '../../helpers/Atoms';
import { useRecoilState } from 'recoil';

export function usePastSOTMs() {
  const [sotms, setSOTMs] = useRecoilState(pastSOTMState);

  const fetchPastSOTMs = useCallback(() => {
    if (sotms.fetchStatus === 'FETCHED') {
      return;
    }
    setSOTMs({ ...sotms, fetchStatus: 'FETCHING' });
    return fetchWithErrHandler('async/pastsotm.php').then(
      (json) => {
        if (json.error) {
          setSOTMs({
            ...sotms,
            fetchStatus: 'FETCHED',
            error: json.error,
          });
          return;
        }
        setSOTMs({
          ...sotms,
          fetchStatus: 'FETCHED',
          data: json,
        });
        return json;
      },
      (error) => {
        setSOTMs({
          ...sotms,
          fetchStatus: 'FETCHED',
          error,
        });
        console.log('An error occured.', error);
      },
    );
  }, [sotms, setSOTMs]);

  useEffect(() => {
    if (sotms.fetchStatus === 'NONE') {
      fetchPastSOTMs();
    }
  }, [sotms.fetchStatus, fetchPastSOTMs]);

  return {
    pastSOTMs: sotms.data,
    isFetching: sotms.fetchStatus !== 'FETCHED',
    error: sotms.error,
    fetchPastSOTMs,
  };
}
