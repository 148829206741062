import { emptyPromise, fetchWithErrHandler } from '../../helpers';

import { catalogueState } from '../../helpers/Atoms';
import { useCallback } from 'react';
import { useRecoilState } from 'recoil';

export function useFetchCatalogues() {
  const [catalogues, setCatalogues] = useRecoilState(catalogueState);

  const fetchCatalogues = useCallback(() => {
    if (!catalogues.isFetching) {
      return emptyPromise({});
    }

    return fetchWithErrHandler('async/catalogues.php').then((json) => {
      json.details.id = parseInt(json.details.id, 10);
      json.details.cccID = parseInt(json.details.cccID, 10);
      json.details.price = parseFloat(json.details.price);
      json.details.cccPrice = parseFloat(json.details.cccPrice);
      if (json.nextYear != null) {
        json.nextYear.id = parseInt(json.nextYear.id, 10);
        json.nextYear.cccID = parseInt(json.nextYear.cccID, 10);
        json.nextYear.price = parseFloat(json.nextYear.price);
        json.nextYear.cccPrice = parseFloat(json.nextYear.cccPrice);
      }
      const data = {
        details: json.details,
        nextYear: json.nextYear ? json.nextYear : null,
      };
      setCatalogues({
        ...catalogues,
        isFetching: false,
        data,
      });
      return data;
    });
  }, [catalogues, setCatalogues]);

  return [catalogues.isFetching, fetchCatalogues];
}
