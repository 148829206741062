// @flow

import * as React from 'react';

type Props = {};

export default class HoneyPot extends React.Component<Props> {
  render() {
    return (
      <span>
        <input
          type="text"
          style={{ display: 'none' }}
          name="thanks"
          value="thanks"
        />
        <input
          type="text"
          style={{ display: 'none' }}
          name="thankyou"
          value=""
        />
      </span>
    );
  }
}
