// @flow

import * as React from 'react';

import { Notice } from './';

type Props = {
  errorMessage: string,
};

export default function ErrorMessage(props: Props): React.MixedElement {
  return (
    <Notice type="error" message="Unexpected Error">
      {props.errorMessage}
    </Notice>
  );
}
