import * as Sentry from '@sentry/react';

import { customerState } from '../../helpers/Atoms';
import { useCallback } from 'react';
import { useRecoilState } from 'recoil';

export function useCustomer() {
  const [customer, setCustomer] = useRecoilState(customerState);

  const setCustomerInternal = useCallback(
    (customerToSet) => {
      setCustomer(customerToSet);
      Sentry.configureScope(function (scope) {
        scope.setUser({
          id: customerToSet.id,
          email: customerToSet.email,
          fname: customerToSet.fname,
          sname: customerToSet.sname,
          isCCC: customerToSet.isCCC,
          isGuest: customerToSet.isGuest,
          hasAccount: customerToSet.hasAccount,
          orderCount: customerToSet.orders?.length ?? 0,
        });
      });
    },
    [setCustomer],
  );

  if (customer.id != null) {
    return [customer, setCustomerInternal];
  }

  return [
    {
      id: 0,
      fname: '',
      sname: '',
      email: '',
      isCCC: false,
      isGuest: true,
      hasAccount: false,
      isSubscriber: false,
      addresses: {},
      bID: 0,
      dID: 0,
      phoneNumbers: {
        daytime: '',
        evening: '',
        mobile: '',
      },
      orders: {},
    },
    setCustomerInternal,
  ];
}
