// @flow

import * as React from 'react';

type Props = {
  name: string,
  label?: React.Node,
};

type State = {
  checked: boolean,
};

export default class Checkbox extends React.Component<Props, State> {
  static props: Props;
  state: State = {
    checked: false,
  };

  render(): React.Node {
    return (
      <span>
        <input
          type="checkbox"
          name={this.props.name}
          id={this.props.name}
          onChange={() => this.setState({ checked: !this.state.checked })}
          defaultValue={this.state.checked ? 'checked' : ''}
        />
        {this._getLabel()}
      </span>
    );
  }

  _getLabel(): React.Node {
    if (this.props.label) {
      return <label htmlFor={this.props.name}>{this.props.label}</label>;
    }

    return null;
  }
}
