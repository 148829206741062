// @flow

import type { TBasketItem, TBasketItems, TProduct } from '../types';

import { getProdNameForBasket } from '../hooks/basket';

const GA_IN_LOCAL = false;

export default class AnalyticsHelper {
  _isLocalHost: boolean;

  constructor() {
    this._isLocalHost =
      window.location.hostname === 'localhost' ||
      window.location.hostname === '127.0.0.1' ||
      window.location.hostname === '';
  }

  isLocalHost(): boolean {
    return this._isLocalHost;
  }

  isDev(): boolean {
    return window.location.hostname === 'dev.londoncigcard.co.uk';
  }

  shouldTrack(): boolean {
    return GA_IN_LOCAL || !this.isLocalHost();
  }

  trackPage(_page: string): void {
    this.trackEvent('page_view', {});
  }

  //https://developers.google.com/analytics/devguides/collection/gtagjs/enhanced-ecommerce
  addToCart(item: TBasketItem): void {
    const options = {
      items: [this.getGAItem(item)],
    };
    this.trackEvent('add_to_cart', options);
  }

  removeFromCart(item: TBasketItem): void {
    const options = {
      items: [this.getGAItem(item)],
    };
    this.trackEvent('remove_from_cart', options);
  }

  beginCheckout(items: TBasketItems): void {
    const options = {
      items: Object.keys(items).map((hash) => {
        return this.getGAItem(items[hash]);
      }),
    };
    this.trackEvent('begin_checkout', options);
  }

  continueCheckout(items: TBasketItems, checkout_step: string): void {
    const options = {
      items: Object.keys(items).map((hash) => {
        return this.getGAItem(items[hash]);
      }),
      checkout_step: checkout_step,
    };
    this.trackEvent('checkout_progress', options);
  }

  getGAItem(item: TBasketItem): Object {
    return {
      id: item.type + '-' + item.id,
      name: item.title,
      list_name: item.fromList,
      quantity: 1,
      price: item.price,
    };
  }

  trackProduct(product: TProduct, lastList: ?string): void {
    const options = {
      items: [
        {
          id: 'product-' + product.id,
          name: getProdNameForBasket(product),
          price: product.price,
          brand: product.issuer,
          list_name: lastList,
        },
      ],
    };
    this.trackEvent('view_item', options);
  }

  trackCategoryClick(_name: string): void {
    // currently not support in GA
  }

  trackList(list: string, products: Array<TProduct>): void {
    const options = {
      items: products.map((product, _i) => ({
        id: 'product-' + product.id,
        name: getProdNameForBasket(product),
        list_name: list,
        price: product.price,
        brand: product.issuer,
      })),
    };
    this.trackEvent('view_item_list', options);
  }

  trackLogin(method: string): void {
    this.trackEvent('login', { method: method });
  }

  trackSignUp(method: string): void {
    this.trackEvent('sign_up', { method: method });
  }

  trackSearch(search: string): void {
    this.trackEvent('search', { search_term: search });
  }

  conversion(): void {
    // todo track conversion
    // const basketHelper = new BasketHelper(store);
    // const basket = store.get('basket');
    // this.customLog('Attempting Conversion', 'info');
    // if (basket.trackedConversion) {
    //   this.customLog('Already tracked', 'success');
    //   return;
    // }
    // if (basketHelper.getOrderID() == null) {
    //   this.customLog('No Order ID to track...', 'warning');
    //   return;
    // }
    // store.set('basket')({
    //   ...basket,
    //   trackedConversion: true,
    // });
    // const items = basketHelper.getItems();
    // const options = {
    //   transaction_id: basketHelper.getOrderID(),
    //   affiliation: 'Web Store',
    //   value: basketHelper.getTotal(),
    //   // "currency": "GBP",
    //   // "tax": basketHelper,
    //   shipping: basketHelper.getShipping(),
    //   items: Object.keys(items).map((hash) => {
    //     return this.getGAItem(items[hash]);
    //   }),
    // };
    // this.trackEvent('purchase', options);
  }

  customLog(message: string, type: string): void {
    if (!this.showDebug()) {
      return;
    }
    let color = 'black';
    switch (type) {
      case 'success':
        color = 'Green';
        break;
      case 'info':
        color = 'Blue';
        break;
      case 'error':
        color = 'Red';
        break;
      case 'warning':
        color = 'Orange';
        break;
      default:
        // keep color as default
        break;
    }
    console.log(`%c${message}`, `color:${color}`);
  }

  showDebug(): boolean {
    return this.isDev() || this.isLocalHost();
  }

  trackEvent(action: string, options: Object): void {
    this.customLog('Tracking Event:', 'info');
    if (!this.shouldTrack()) {
      this.customLog('Debug Only... not sent', 'warning');
    } else {
      this.customLog('Sending to GA...', 'success');
    }
    if (this.showDebug()) {
      function GAevent(_firstName, _lastName) {
        this.action = action;
        this.options = JSON.stringify(options);
      }
      console.table(new GAevent());
    }
    if (this.shouldTrack()) {
      window.gtag('event', action, options);
    }
  }
}
