// @flow

import * as React from 'react';

import { Box, Form, Link } from '../common';

import AnalyticsHelper from '../../helpers/AnalyticsHelper';
import { getFetchURI } from '../../helpers';
import { useBasketHelper } from '../../hooks/basket';
import { useReceiveCustomer } from '../../hooks/customer';

type Props = {
  inCheckout: boolean,
};

const analytics = new AnalyticsHelper();

function LoginForm(props: Props) {
  const basketHelper = useBasketHelper();
  const receiveCustomer = useReceiveCustomer();

  return (
    <Box>
      <span>
        <h1>Registered Customers</h1>
        <div>
          If you have shopped with us before and choose a password please login
          using your email address and password
        </div>
        <Form
          uri={getFetchURI('async/login/login.php')}
          className="standard"
          buttonLabel="Sign In"
          failure={_getFailure()}
          onSubmit={_onReceiveCustomer}
        >
          <dl className="smaller">
            <dd>
              <label htmlFor="login_email">Email Address:</label>
              <input type="text" name="email" id="login_email" />
            </dd>
            <dd>
              <label htmlFor="login_pass">Password:</label>
              <input type="password" name="pass" id="login_pass" />
            </dd>
          </dl>
        </Form>
      </span>
    </Box>
  );

  function _onReceiveCustomer(
    _: any,
    success: boolean,
    __: any,
    payload: Object,
  ): void {
    let page;
    if (props.inCheckout) {
      page = 'BASKET_CONFIRM';
      analytics.continueCheckout(basketHelper.getItems(), 'sign_in');
      analytics.trackLogin('In Checkout');
    } else {
      page = 'ACCOUNT';
      analytics.trackLogin('Normal Login');
    }

    receiveCustomer(payload, success, page);
  }

  function _getFailure(): React.Node {
    return (
      <div style={{ margin: '8px 0' }}>
        If you have forgotten your password please{' '}
        <Link page="LOGIN_FORGOT">click here</Link>
      </div>
    );
  }
}

export default LoginForm;
