// @flow

import * as React from 'react';

import { Box, Flexbox, RTEText } from '../common';

import CatalogueRequest from './CatalogueRequest';
import { PageHelpers } from '../../helpers';
import type { TPageContent } from '../../types';
import { useAuction } from '../../hooks/auction';

type Props = {
  content?: TPageContent,
};

export default function AuctionHome(props: Props) {
  const { auction } = useAuction();
  const { content } = props;

  return (
    <Flexbox flexDirection="column" element="section">
      <Flexbox flexDirection="row" element="section">
        <Box highlight={true}>
          <span>
            <RTEText content={PageHelpers.getContent(content, 'Heading')} />
          </span>
        </Box>
      </Flexbox>
      <Flexbox flexDirection="row" element="section">
        <Box>
          <Flexbox flexDirection="column" element="section">
            <Flexbox flexDirection="row" element="section">
              <span>{_getIntro()}</span>
            </Flexbox>
            <Flexbox flexDirection="row" element="section">
              <ul style={{ marginBottom: '8px' }}>
                {auction.upcoming.map((next) => (
                  <li key={next}>{next}</li>
                ))}
              </ul>
            </Flexbox>
            <Flexbox flexDirection="row" element="section">
              <span>
                <RTEText
                  content={PageHelpers.getContent(content, 'More Info')}
                />
              </span>
            </Flexbox>
            <Flexbox flexDirection="row" element="section">
              <CatalogueRequest />
            </Flexbox>
          </Flexbox>
        </Box>
      </Flexbox>
    </Flexbox>
  );

  function _getIntro() {
    let intro = PageHelpers.getContent(content, 'Intro').replace(
      /{DATE}/g,
      auction.date,
    );

    return <RTEText content={intro} />;
  }
}
