// @flow

import * as React from 'react';

import { Box, Flexbox } from './';

type Props = {
  type: 'success' | 'error',
  message: string,
  children?: React.Node,
};

export default function Notice(props: Props): React.MixedElement {
  function _getAsciiCode() {
    // https://www.w3schools.com/charsets/ref_utf_dingbats.asp
    switch (props.type) {
      case 'success':
        return <span style={{ marginRight: '4px' }}>&#10004;</span>;
      case 'error':
        return <span style={{ marginRight: '4px' }}>&#10007;</span>;
      default:
        break;
    }
  }

  function _getChildren() {
    if (props.children == null) {
      return null;
    }

    return <Flexbox style={{ marginTop: '8px' }}>{props.children}</Flexbox>;
  }

  return (
    <Box>
      <Flexbox flexDirection="column">
        <Flexbox>
          <h3>
            {_getAsciiCode()} <strong>{props.message}</strong>
          </h3>
        </Flexbox>
        {_getChildren()}
      </Flexbox>
    </Box>
  );
}
