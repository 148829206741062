// @flow

import * as React from 'react';

import { Box, Flexbox, Link, PriceWithButton, RTEText } from '../common';
import {
  getContent,
  getFetchURI,
  getSOTMDateForDisplay,
  getSOTMURI,
} from '../../helpers';
import { usePastSOTMs, useSOTM } from '../../hooks/sotm';

import type { TPageContent } from '../../types';

type Props = {
  month: string,
  content?: TPageContent,
};

export default function SOTMHome(props: Props) {
  const { sotm, error: sotmError } = useSOTM(props.month);
  const {
    pastSOTMs,
    isFetching: isPastFetching,
    error: pastError,
  } = usePastSOTMs();

  return (
    <Flexbox flexDirection="column" element="section" style={{ width: '100%' }}>
      <Flexbox>
        <Box highlight={true}>
          <h1 className="smaller">
            Set of the Month - {getSOTMDateForDisplay(sotm.month)}
          </h1>
        </Box>
      </Flexbox>
      <Flexbox>
        <Box>
          <Flexbox
            flexDirection="column"
            element="section"
            style={{ width: '100%' }}
          >
            {sotmError ? (
              <div>No Set Found</div>
            ) : (
              <>
                <Flexbox>
                  <h2>{sotm.title}</h2>
                </Flexbox>
                <Flexbox>
                  <span>
                    <RTEText content={sotm.description} />
                  </span>
                </Flexbox>
                <Flexbox style={{ marginTop: '12px' }}>
                  <div style={{ textAlign: 'center', width: '100%' }}>
                    {sotm.imgs.map((img) => {
                      return (
                        <img
                          key={img}
                          src={getFetchURI('sotm_img.php?id=' + img)}
                          alt="Set of The Month"
                        />
                      );
                    })}
                  </div>
                </Flexbox>
                <Flexbox
                  flexDirection="column"
                  element="section"
                  style={{ width: '100%', marginTop: '12px' }}
                >
                  {_getPrice()}
                  {_getCCNPrice()}
                </Flexbox>
              </>
            )}
          </Flexbox>
        </Box>
      </Flexbox>
      <Flexbox>
        <Box>
          <Flexbox
            flexDirection="column"
            element="section"
            style={{ width: '100%' }}
          >
            <Flexbox>
              <span>
                <RTEText
                  content={getContent(props.content, 'Previous Intro')}
                />
              </span>
            </Flexbox>
            <Flexbox>{_getPastSOTMs()}</Flexbox>
          </Flexbox>
        </Box>
      </Flexbox>
    </Flexbox>
  );

  function _getPrice(): React.Node {
    return (
      <PriceWithButton
        label="Price"
        stateKey={sotm.month}
        price={sotm.price}
        type="sotm"
        hash={sotm.hash}
      />
    );
  }

  function _getCCNPrice(): React.Node {
    if (!sotm.ccnPrice) {
      return null;
    }
    return (
      <PriceWithButton
        label="Card Collectors Club Member Special Price"
        stateKey={sotm.month}
        price={sotm.ccnPrice}
        type="sotm"
        hash={sotm.ccnHash}
      />
    );
  }

  function _getPastSOTMs() {
    if (isPastFetching) {
      return null;
    }
    if (pastError) {
      return pastError;
    }
    return (
      <table id="prodlist">
        <thead>
          <tr valign="top">
            <td width="120">Month</td>
            <td>Previous months 'set of the month' see below:</td>
          </tr>
        </thead>
        <tbody>
          {Object.keys(pastSOTMs).map((month) => {
            const title = pastSOTMs[month];
            return (
              <tr key={month}>
                <td valign="top" className="highlight small">
                  {getSOTMDateForDisplay(month)}
                </td>
                <td valign="top">
                  <Link hrefOverride={getSOTMURI(month, title)}>{title}</Link>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    );
  }
}
