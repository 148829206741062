// @flow

import * as React from 'react';

import { Box, Flexbox } from '../common';

import AccountPhones from './AccountPhones';
import AddressForm from './AddressForm';
import Addresses from './Addresses';
import PostageMessage from '../Basket/PostageMessage';
import { useCustomerHelper } from '../../hooks/customer';

export default function AccountAddress() {
  const customerHelper = useCustomerHelper();

  function _getHeading(): React.Node {
    return (
      <Flexbox>
        <Box highlight={true}>
          <span>
            <h1>Address Details</h1>
            <div>
              {customerHelper.getFirstName()}, You can edit any of your
              addresses by click on the appropiate link below. You can also add
              a new address.
            </div>
          </span>
        </Box>
      </Flexbox>
    );
  }

  function _getLeftSide(): React.Node {
    const addresses = customerHelper.getAddresses();
    let content;
    if (Object.keys(addresses).length === 0) {
      content = (
        <em>
          You may also add additional addresses. If you would like us to deliver
          your items to another location please add a new address on the right.
        </em>
      );
    } else {
      content = (
        <Flexbox flexDirection="column">
          <div style={{ marginBottom: '8px' }}>
            Below are the addresses you have added.
          </div>
          <Addresses addresses={addresses} inCheckout={false} />
        </Flexbox>
      );
    }

    return (
      <span>
        <h3>Your Addresses</h3>
        {content}
        <PostageMessage />
      </span>
    );
  }

  function _getRightSide(): React.Node {
    return (
      <Flexbox flexDirection="column" style={{ width: '100%' }}>
        <span style={{ width: '100%' }}>
          <h3>Add another address...</h3>
          <div style={{ marginBottom: '8px' }}>
            Below are the addresses you have added.
          </div>
          <Box highlight={true}>
            <AddressForm label="Save Address" />
          </Box>
        </span>
      </Flexbox>
    );
  }

  return (
    <Flexbox flexDirection="column" style={{ width: '100%' }}>
      {_getHeading()}
      <Flexbox>
        <Box>
          <span style={{ width: '100%' }}>
            <Flexbox flexDirection="column">
              <Flexbox flexDirection="row">
                <Flexbox style={{ width: '100%', marginRight: '12px' }}>
                  {_getLeftSide()}
                </Flexbox>
                <Flexbox style={{ width: '100%' }}>{_getRightSide()}</Flexbox>
              </Flexbox>
              <Flexbox>
                <AccountPhones />
              </Flexbox>
            </Flexbox>
          </span>
        </Box>
      </Flexbox>
    </Flexbox>
  );
}
