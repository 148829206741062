// @flow

import * as React from 'react';

import { Box, Flexbox } from '../common';

import type { TPageContent } from '../../types/page';

type Props = {
  content?: TPageContent,
};

export default function BasketFailed(_props: Props) {
  return (
    <Flexbox flexDirection="column">
      <Flexbox>
        <Box>
          <span style={{ width: '100%' }}>
            <h1>Order Failed or Cancelled</h1>
            <p>
              There are many reasons why your order might fail, you could have
              hit 'Cancel', or your session timed out or even incorrect
              card/address details.
            </p>
            <p>
              <em style={{ fontWeight: 'bold' }}>
                Your items have been left in your cart, please try placing your
                order again.
              </em>
            </p>
            <p>
              If you are still having problems placing an order please contact
              us by any of the following means.
            </p>
            <div className="fleft" style={{ width: '300px' }}>
              <p>
                <strong>By Post</strong>
                <br />
                London Cigarette Card Co Ltd
                <br />
                Sutton Road
                <br />
                Somerton
                <br />
                Somerset
                <br />
                TA11 6QP
                <br />
              </p>
            </div>
            <div className="fleft">
              <p>
                <strong>By Phone</strong>
                <br />
                01458 273452
                <br />
              </p>
              <p>
                <strong>By Email</strong>
                <br />
                cards@londoncigcard.co.uk
              </p>
            </div>
          </span>
        </Box>
      </Flexbox>
    </Flexbox>
  );
}
