import { fetchPOST } from '../../helpers';
import { useCallback } from 'react';

export function useProductView() {
  const addProductView = useCallback((prodID: number) => {
    const searchParams = new URLSearchParams();
    searchParams.set('id', prodID.toString());
    fetchPOST('async/products/product_view.php', searchParams).then(
      (response) => response.json(),
      (error) => console.log('An error occured.', error),
    );
  }, []);

  return addProductView;
}
