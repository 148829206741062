// @flow

import * as React from 'react';

import { Box, Flexbox } from '../common';

import { getFetchURI } from '../../helpers';
import { useAuction } from '../../hooks/auction';

export default function CatalogueDownload() {
  const { auction } = useAuction();
  if (!auction.file) {
    return null;
  }

  const aucDownloadURI = getFetchURI('auc_cat_download.php?file=' + auction.id);
  return (
    <Box highlighted={true}>
      <Flexbox flexDirection="column" element="section">
        <Flexbox flexDirection="row">
          <h2>Download Auction Catalogue</h2>
        </Flexbox>
        <Flexbox flexDirection="row">
          <a href={aucDownloadURI} target="_blank" rel="noopener noreferrer">
            Click here
          </a>
          &nbsp;to download a PDF version of the auction catalogue
        </Flexbox>
      </Flexbox>
    </Box>
  );
}
