// @flow

import * as React from 'react';

import { Box, Flexbox, RTEText } from '../common';

import { PageHelpers } from '../../helpers';
import ProductDetails from '../ProductPage/ProductDetails';
import type { TPageContent } from '../../types';
import { useDailyOffer } from '../../hooks/daily';
import { useProduct } from '../../hooks/products/useProducts';

type Props = {|
  content?: TPageContent,
|};

function DailyOffer(props: Props) {
  const { dailyOffer } = useDailyOffer();

  const { product } = useProduct(dailyOffer.id);

  if (dailyOffer.id == null) {
    return (
      <Flexbox flexDirection="column">
        <Flexbox flexGrow={1}>
          <Box highlight={true}>No Daily Offer Found</Box>
        </Flexbox>
      </Flexbox>
    );
  }

  if (product == null) {
    return null;
  }

  function _getHeading(): React.Node {
    const { content } = props;
    let intro = PageHelpers.getContent(content, 'Intro').replace(
      /{DATE}/g,
      dailyOffer.date,
    );

    return <RTEText content={intro} />;
  }

  function _getFooter(): React.Node {
    const { content } = props;
    let footer = PageHelpers.getContent(content, 'Footer');

    return <RTEText content={footer} />;
  }

  return (
    <Flexbox flexDirection="column">
      <Flexbox flexGrow={1}>
        <Box highlight={true}>
          <span>{_getHeading()}</span>
        </Box>
      </Flexbox>
      <Flexbox flexGrow={1}>
        <ProductDetails product={product} />
      </Flexbox>
      <Flexbox flexGrow={1}>
        <Box>
          <span>{_getFooter()}</span>
        </Box>
      </Flexbox>
    </Flexbox>
  );
}

export default DailyOffer;
