// @flow

import * as React from 'react';

import { Loading, Page } from '../common';

import CategoryHome from './CategoryHome';
import CategoryList from './CategoryList';
import { useEffect } from 'react';
import { useFetchCategories } from '../../hooks/categories';
import { useParams } from 'react-router';

function Category() {
  const [isFetching, fetchCategories] = useFetchCategories();
  const params = useParams();

  useEffect(() => {
    fetchCategories();
  }, [fetchCategories]);

  function _getPageComponent(): React.Node {
    if (isFetching) {
      return <Loading />;
    }

    const catID = parseInt(params.id, 10);
    if (!catID) {
      return <CategoryHome />;
    }

    return <CategoryList id={catID} />;
  }

  return <Page>{_getPageComponent()}</Page>;
}

export default Category;
