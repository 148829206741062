// @flow

// test

import * as React from 'react';

import { Flexbox, Link, Price } from '../common';
import {
  getFetchURI,
  getNIMonth,
  getNIURI,
  getSOTMDateForDisplay,
  getSOTMMonth,
  getSOTMURI,
} from '../../helpers';

import AuctionImage from '../auction/AuctionImage';
import Columns from 'react-columns';
import type { TBanner } from '../../types';
import { navWidthWithMargin } from '../../helpers/consts';
import { useAuction } from '../../hooks/auction';
import { useBanners } from '../../hooks/banners';
import { useSOTM } from '../../hooks/sotm';

function Banners() {
  const { banners, isFetching } = useBanners();
  const { auction } = useAuction();
  const sotmMonth = getSOTMMonth();
  const { sotm, error, isFetching: sotmFetching } = useSOTM(sotmMonth);

  function getBanner(bannerInfo: TBanner): React.Node {
    let backgroundImg = getFetchURI(bannerInfo.background);
    let overlay = getFetchURI(bannerInfo.overlay);
    let link = getFetchURI(bannerInfo.link);
    let page = null;
    switch (bannerInfo.type) {
      case 'sotm':
        overlay = _getSOTMBanner(bannerInfo);
        link = getSOTMURI(sotmMonth, sotm.title);
        break;
      case 'auction':
        overlay = _getAuctionBanner(bannerInfo);
        page = 'AUC_DETAILS';
        link = null;
        break;
      case 'newissues':
        overlay = _getNewIssuesBanner(bannerInfo);
        link = getNIURI(getNIMonth());
        break;
      case 'ccc':
        overlay = _getCCCBanner(bannerInfo);
        page = 'CCC';
        break;
      case 'custom':
      default:
        break;
    }

    if (overlay == null) {
      return null;
    }

    return (
      <Flexbox
        style={{
          position: 'relative',
          overflow: 'hidden',
          backgroundImage: 'url(' + backgroundImg + ')',
          border: '2px solid #cab686',
          height: '144px',
          marginBottom: '8px',
        }}
        flexDirection="row"
      >
        <div
          style={{
            width: '100%',
            position: 'relative',
            overflow: 'hidden',
            border: '2px solid #ffffff',
            padding: '8px',
          }}
        >
          <Link hrefOverride={link} page={page}>
            {overlay}
          </Link>
        </div>
      </Flexbox>
    );
  }

  function _getSOTMBanner(bannerInfo: TBanner): React.Node {
    if (sotmFetching || error != null) {
      return null;
    }
    const sotmImageID = sotm.imgs[0];
    const sotmImage = getFetchURI('sotm_img.php?id=' + sotmImageID);
    // const sotmImage = getFetchURI('images/banners/' + sotm.data.banner);
    return (
      <>
        <img
          src={sotmImage}
          alt={bannerInfo.title}
          style={{ position: 'absolute', top: '0', right: '0' }}
        />
        <div style={{ position: 'relative', overflow: 'hidden' }}>
          <img
            src={getFetchURI('images/banners/sotm.png')}
            style={{ position: 'absolute', top: '0', left: '0' }}
            alt={bannerInfo.title}
          />
          <div style={{ marginTop: '40px', maxWidth: '150px' }}>
            <div>
              <h4>{getSOTMDateForDisplay(sotm.month)}</h4>
            </div>
            <div>
              <strong>{sotm.title}</strong>
            </div>
            <div style={{ marginTop: '8px' }}>
              <Price price={sotm.price} />
            </div>
          </div>
        </div>
      </>
    );
  }

  function _getAuctionBanner(bannerInfo: TBanner): React.Node {
    let auctionImage;
    if (auction == null || auction.info == null) {
      return null;
    }
    if (auction.info[0] != null) {
      const auctionImageID = auction.info[0].image;
      auctionImage = (
        <AuctionImage
          id={auctionImageID}
          alt={bannerInfo.title}
          style={{ position: 'absolute', top: '0', right: '0' }}
        />
      );
    } else {
      auctionImage = (
        <img
          src={getFetchURI('images/banners/' + auction.banner)}
          alt={bannerInfo.title}
          style={{ position: 'absolute', top: '0', right: '0' }}
        />
      );
    }
    return (
      <>
        {auctionImage}
        <div style={{ position: 'relative', overflow: 'hidden' }}>
          <img
            src={getFetchURI('images/banners/auction.png')}
            style={{ position: 'absolute', top: '0', left: '0' }}
            alt="Auction"
          />
          <div style={{ marginTop: '80px', maxWidth: '140px' }}>
            <div>
              <strong>With {Object.keys(auction.lots).length} Lots </strong>
            </div>
            <div>
              <strong>Ending {auction.date}</strong>
            </div>
          </div>
        </div>
      </>
    );
  }

  function _getNewIssuesBanner(_bannerInfo: TBanner): React.Node {
    return (
      <>
        <img
          src={getFetchURI('banners/ni_' + getNIMonth() + '.png')}
          alt="New Issue"
          style={{
            position: 'absolute',
            marginLeft: 'auto',
            marginRight: 'auto',
            bottom: '0',
            left: '0',
            right: '0',
          }}
        />
        <div style={{ position: 'relative' }}>
          <img
            src={getFetchURI('images/banners/ni.png')}
            alt="New Issues"
            style={{
              position: 'absolute',
              marginLeft: 'auto',
              marginRight: 'auto',
              top: '0',
              left: '0',
              right: '0',
            }}
          />
        </div>
      </>
    );
  }

  function _getCCCBanner(_bannerInfo: TBanner): React.Node {
    return (
      <>
        <img
          src={getFetchURI('images/banners/ccc.png')}
          alt="Card Collectors Club"
          style={{
            position: 'absolute',
            bottom: '0',
            left: '0',
          }}
        />
        <div style={{ position: 'relative' }}>
          <img
            alt="Card Collectors Club"
            src={getFetchURI('images/banners/ccc_header.png')}
            style={{
              position: 'absolute',
              marginLeft: 'auto',
              marginRight: 'auto',
              top: '0',
              left: '0',
              right: '0',
            }}
          />
          <div style={{ paddingTop: '40px', marginLeft: '165px' }}>
            <h4>
              Join from just &pound;15, 100s of savings when you join the club
            </h4>
          </div>
        </div>
      </>
    );
  }

  let queries = [];
  for (let i = 1; i <= 5; i++) {
    const width = 320 * i + navWidthWithMargin;
    queries.push({
      columns: i,
      query: 'min-width: ' + width + 'px',
    });
  }

  return (
    !isFetching && (
      <Columns
        queries={queries}
        gap="4px"
        rootStyles={{
          overflowX: 'hidden',
          overflowY: 'hidden',
          height: '156px',
        }}
      >
        {banners.map((bannerInfo, i) => {
          const banner = getBanner(bannerInfo);
          return <React.Fragment key={i}>{banner}</React.Fragment>;
        })}
      </Columns>
    )
  );
}

export default Banners;
