// @flow

import * as React from 'react';

import { Box, Flexbox } from '../common';

import ProductList from '../ProductList';
import { RTEText } from '../common';
import type { TPageContent } from '../../types';
import { getURI } from '../../helpers';
import { useSearch } from '../../hooks/search';

type Props = {
  content?: TPageContent,
  searchString: string,
};

export default function SearchResults(props: Props) {
  const searchString = props.searchString;

  const { search } = useSearch(searchString);

  return (
    <Flexbox flexDirection="column" element="section">
      <Box highlight={true}>
        <div>
          <h1>You searched for '{searchString}'</h1>
          {_getNote()}
        </div>
      </Box>
      <div>
        <ProductList list="Search Results" prodIDs={search.prodIDs} />
      </div>
    </Flexbox>
  );

  function _getNote(): React.Node {
    const note = search.note;
    if (note.length === 0) {
      return null;
    }

    const content = note.replace(
      /<search>([a-z ]+)<\/search>/gi,
      '<a href="' + getURI('SEARCH') + '?search=$1">$1</a>',
    );

    return (
      <div>
        <em>
          <RTEText content={content} />
        </em>
      </div>
    );
  }
}
