import * as Sentry from '@sentry/react';

import { fetchWithErrHandler } from '../../helpers';
import { useCallback } from 'react';
import { useCustomer } from './';

export function useLogout(): void {
  const [, setCustomer] = useCustomer();

  return useCallback(() => {
    fetchWithErrHandler('async/account/logout.php');
    Sentry.setUser(null);

    setCustomer({});
  }, [setCustomer]);
}
