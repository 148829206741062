// @flow

import * as React from 'react';

import { Box, Flexbox, PriceWithButton, RTEText } from '../common';

import { useSpecialRequest } from '../../hooks/requests';

type Props = {
  id: number,
};

export default function SpecialRequestHome(props: Props) {
  const { id } = props;
  const { specialRequest, error } = useSpecialRequest(id);

  return (
    <Flexbox flexDirection="column">
      <Flexbox flexGrow={1}>
        <Box highlight={true}>
          <span>
            <h1>Special Request Secure Order Page</h1>
            <p>
              Below is the special request you have requested, if everything is
              ok please click 'Add to Basket' button and then continue through
              our secure checkout
            </p>
            {error && error.length > 0 ? (
              error
            ) : (
              <>
                <h3>Special Request #{specialRequest.id}</h3>
                <p>
                  <b>Description: </b>
                  <br />
                  <RTEText content={specialRequest.description} />
                </p>
                <PriceWithButton
                  stateKey={specialRequest.id}
                  hash={specialRequest.hash}
                  price={specialRequest.price}
                  label="Price"
                  type="specialRequest"
                />
              </>
            )}
          </span>
        </Box>
      </Flexbox>
    </Flexbox>
  );
}
