// @flow

import type {
  TConfig,
  TProdDisplayOptions,
  TProdSortByOptions,
} from '../types';

import { configState } from './Atoms';
import { useCallback } from 'react';
import { useRecoilState } from 'recoil';

export function useConfigHelper() {
  const [config, setConfig] = useRecoilState(configState);

  const setConfigValue = useCallback(
    (updatedConfig) => {
      setConfig({
        ...config,
        ...updatedConfig,
      });
    },
    [config, setConfig],
  );

  return [new ConfigHelper(config), setConfigValue];
}

export function useSortOption() {
  const [configHelper, setConfigValue] = useConfigHelper();
  const config = configHelper.getConfig();
  return [
    configHelper.getProductSortByOption(),
    (newValue) => {
      setConfigValue({
        products: {
          ...config.products,
          sortBy: newValue,
        },
      });
    },
  ];
}

export function useLastList() {
  const [configHelper, setConfigValue] = useConfigHelper();

  const setLastList = useCallback(
    (newValue: string) => {
      if (configHelper.getLastList() !== newValue) {
        setConfigValue({
          lastList: newValue,
        });
      }
    },
    [setConfigValue],
  );

  return {
    lastList: configHelper.getLastList(),
    setLastList,
  };
}

export function useDisplayOption() {
  const [configHelper, setConfigValue] = useConfigHelper();
  const config = configHelper.getConfig();
  return [
    configHelper.getProductDisplayOption(),
    (newValue) => {
      setConfigValue({
        products: {
          ...config.products,
          display: newValue,
        },
      });
    },
  ];
}

class ConfigHelper {
  constructor(config: TConfig) {
    this._config = config;
  }

  getConfig(): TConfig {
    return this._config;
  }

  isFetching(): boolean {
    return this._config.isFetching;
  }

  isLoaded(): boolean {
    return this._config.isLoaded;
  }

  getShippingCountries(): { [number]: string } {
    return this._config.countries;
  }

  getProductDisplayOption(): TProdDisplayOptions {
    return this._config.products.display;
  }

  getProductSortByOption(): TProdSortByOptions {
    return this._config.products.sortBy;
  }

  getLastList(): string {
    return this._config.lastList;
  }
}
