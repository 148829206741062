// @flow

import * as React from 'react';

import { ErrorMessage, Loading, Page } from '../common';

import AuctionBidsPage from './AuctionBidsPage';
import AuctionDetails from './AuctionDetails';
import AuctionHome from './AuctionHome';
import AuctionLots from './AuctionLots';
import AuctionMakeBid from './AuctionMakeBid';
import AuctionProcess from './AuctionProcess';
import { PageNames } from '../../helpers/uris';
import SimplePage from '../SimplePage';
import { useAuction } from '../../hooks/auction';
import { useParams } from 'react-router';

function Auction() {
  const { error, isFetching: isAuctionFetching } = useAuction();
  const { title, id } = useParams();

  function _getPageComponent(): React.Node {
    if (isAuctionFetching) {
      return <Loading />;
    }
    if (error != null) {
      return <ErrorMessage errorMessage={error} />;
    }

    switch (title) {
      case PageNames.AUC_BIDS.title:
        return <AuctionBidsPage />;
      case PageNames.AUC_DETAILS.title:
        return <AuctionDetails />;
      case PageNames.AUC_LOTS.title:
        return <AuctionLots />;
      case PageNames.AUC_MAKE_BID.title:
        return <AuctionMakeBid lot={id.toString()} />;
      case PageNames.AUC_PROCESS.title:
        return <AuctionProcess />;
      case PageNames.AUC_GUIDE.title:
      case PageNames.AUC_CONDITION.title:
        return <SimplePage />;
      default:
        return <AuctionHome />;
    }
  }

  return <Page>{_getPageComponent()}</Page>;
}

export default Auction;
