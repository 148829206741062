// @flow

import * as EmailValidator from 'email-validator';
import * as React from 'react';

import { Box, Flexbox, Form } from '../common';

import CatalogueDownload from './CatalogueDownload';
import { getFetchURI } from '../../helpers';
import { useAddToAuctionNewsletter } from '../../hooks/customer';

function CatalogueRequest() {
  const addToAuctionNewsletter = useAddToAuctionNewsletter();

  return (
    <Flexbox flexDirection="column" element="section">
      <CatalogueDownload />
      {_getForm()}
    </Flexbox>
  );

  function _getForm(): React.Node {
    return (
      <Box>
        <Flexbox flexDirection="column" element="section">
          <Flexbox flexDirection="row">
            <h2>
              Receive details of our auctions every month with our FREE Email
              Newsletter
            </h2>
          </Flexbox>
          <Flexbox flexDirection="row">
            <p>
              Please sign me upto the Free Email Newsletter with details of the
              monthly auctions and special offers.
            </p>
          </Flexbox>
          <Flexbox flexDirection="row">
            <Form
              className="contactform"
              onValidate={_handleValidation}
              success={_getSuccessMsg()}
              honeyPot={true}
              type="auction_email"
              uri={getFetchURI('async/common/mail.php')}
              onSubmit={_handleSubmit}
            >
              <dl className="small">
                <dd>
                  <label htmlFor="name">Name</label>
                  <input type="text" name="name" id="name" />
                </dd>
                <dd>
                  <label htmlFor="email">Email</label>
                  <input type="text" name="email" id="email" />
                </dd>
              </dl>
              <input
                type="hidden"
                name="auction_catalogue_by_email"
                value="1"
              />
            </Form>
          </Flexbox>
          <Flexbox flexDirection="row">
            <p>
              or...Please post me a list of lots for this auction to the address
              quoted below.
            </p>
          </Flexbox>
          <Flexbox flexDirection="row">
            <Form
              className="contactform"
              onValidate={_handleValidation}
              success={_getSuccessMsg()}
              honeyPot={true}
              type="auction_post"
              uri={getFetchURI('async/common/mail.php')}
              onSubmit={_handleSubmit}
            >
              <dl className="small">
                <dd>
                  <label htmlFor="name">Name</label>
                  <input type="text" name="name" id="name" />
                </dd>
                <dd>
                  <label htmlFor="address">Address</label>
                  <textarea
                    name="address"
                    id="address"
                    className="small"
                  ></textarea>
                </dd>
                <dd>
                  <label htmlFor="postcode">Post Code</label>
                  <input type="text" name="postcode" id="postcode" />
                </dd>
                <dd>
                  <label htmlFor="telephone">Tel</label>
                  <input type="text" name="telephone" id="telephone" />
                </dd>
                <dd>
                  <label htmlFor="email">Email</label>
                  <input type="text" name="email" id="email" />
                </dd>
              </dl>
              <input type="hidden" name="auction_catalogue_by_post" value="1" />
            </Form>
          </Flexbox>
        </Flexbox>
      </Box>
    );
  }

  function _handleSubmit(values: Object): void {
    if (values.email) {
      addToAuctionNewsletter(values.email, values.name);
    }
  }

  function _getSuccessMsg(): React.Node {
    return (
      <span>
        <p>
          <strong>
            Thanks for requesting our auction catalogue, we have received your
            request and will send it shortly.
          </strong>
        </p>
        <p>You can also contact us by any of the following means.</p>
        <div class="fleft" style={{ width: '300px' }}>
          <p>
            <strong>By Post</strong>
            <br />
            London Cigarette Card Co Ltd
            <br />
            Sutton Road
            <br />
            Somerton
            <br />
            Somerset
            <br />
            TA11 6QP
            <br />
          </p>
        </div>
        <div className="fleft">
          <p>
            <strong>By Phone</strong>
            <br />
            01458 273452
            <br />
          </p>
          <p>
            <strong>By Email</strong>
            <br />
            cards@londoncigcard.co.uk
          </p>
        </div>
        <div className="clearfix clear">&nbsp;</div>
      </span>
    );
  }

  function _handleValidation(values: Object): Array<string> {
    let failures = [];
    if (!values.name) {
      failures.push('Please enter your name');
    }

    if (
      values.auction_catalogue_by_post &&
      (!values.address || !values.postcode)
    ) {
      failures.push(
        'Please enter your address if you would like us to post you a copy of the auction catalogue',
      );
    }

    if (values.auction_catalogue_by_email && !values.email) {
      failures.push(
        'Please enter your address if you would like us to post you a copy of the auction catalogue',
      );
    }

    if (values.email) {
      const isEmailValid = EmailValidator.validate(values.email);
      if (!isEmailValid) {
        failures.push('Please enter a valid email address.');
      }
    }

    return failures;
  }
}

export default CatalogueRequest;
