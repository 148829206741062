import { useCustomer, useMakeCustomer } from './';

import AnalyticsHelper from '../../helpers/AnalyticsHelper';
import { getURI } from '../../helpers';
import { useBasketHelper } from '../basket';
import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

export function useStartGuestCheckout(): void {
  const basketHelper = useBasketHelper();
  const analytics = new AnalyticsHelper();
  const [, setCustomer] = useCustomer();
  const makeCustomer = useMakeCustomer();
  const navigate = useNavigate();

  return useCallback(
    (rawCustomer: Object) => {
      analytics.continueCheckout(basketHelper.getItems(), 'guest_checkout');
      setCustomer(makeCustomer(rawCustomer));
      navigate(getURI('BASKET_CONFIRM'));
    },
    [setCustomer, makeCustomer, basketHelper],
  );
}
