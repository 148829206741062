// @flow

import * as React from 'react';

import Flexbox from 'flexbox-react';
import { Price } from '../common';
import { ProductContext } from '../../hooks/products';
import { useContext } from 'react';

export default function ProductPrice() {
  const product = useContext(ProductContext);

  if (product == null) {
    return null;
  }

  if (!product.price) {
    return <strong className="nowprice">SOLD OUT</strong>;
  }

  const price =
    product.price === product.wasPrice ? (
      <Flexbox style={{ marginBottom: '12px' }}>
        <Price bold={true} price={product.price} />
      </Flexbox>
    ) : (
      [
        <Flexbox key="was">
          <Price
            bold={true}
            label="Catalogue Price"
            price={product.wasPrice}
            strikethrough={true}
          />
        </Flexbox>,
        <Flexbox key="now">
          <Price
            bold={true}
            label="Special Offer"
            price={product.price}
            highlight={true}
          />
        </Flexbox>,
      ]
    );

  return <Flexbox flexDirection="column">{price}</Flexbox>;
}
