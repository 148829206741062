// @flow

import * as React from 'react';

import { useCategory, useFetchCategories } from '../../hooks/categories';
import { useEffect, useMemo } from 'react';

import { ProductContainer } from '../Product/';
import ProductImage from '../ProductPage/ProductImage';

type Props = {
  style?: Object,
};

function LatestCatalogueImage(props: Props) {
  const [cat] = useCategory(39);
  const [, fetchCategories] = useFetchCategories();

  const prodID = useMemo(() => {
    if (!cat) {
      return null;
    }
    // - 2 because 0 indexed AND we removed a product
    return [...cat.prodIDs]
      .filter((id) => parseInt(id, 10) !== 9339)
      .sort((a, b) => {
        return parseInt(a) - parseInt(b);
      })[cat.prodIDs.length - 2];
  }, [cat]);

  useEffect(() => {
    fetchCategories();
  }, [fetchCategories]);

  if (prodID == null) {
    return <span />;
  }

  return (
    <ProductContainer prodID={prodID}>
      <ProductImage style={props.style} excludeLink={true} />
    </ProductContainer>
  );
}

export default LatestCatalogueImage;
