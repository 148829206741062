// @flow

import * as React from 'react';

import { Loading, Page } from '../common';

import AlbumHome from './AlbumHome';
import { useEffect } from 'react';
import { useFetchAlbums } from '../../hooks/albums';
import { useFetchCCC } from '../../hooks/ccc';

type Props = {};

function Albums(props: Props) {
  const [isAlbumsFetching, fetchAlbums] = useFetchAlbums();
  const [isCCCFetching, fetchCCC] = useFetchCCC();

  useEffect(() => {
    fetchAlbums();
    fetchCCC();
  }, [fetchAlbums, fetchCCC]);

  function _getPageComponent(): React.Node {
    if (isAlbumsFetching || isCCCFetching) {
      return <Loading />;
    }

    return <AlbumHome />;
  }

  return <Page {...props}>{_getPageComponent()}</Page>;
}

export default Albums;
