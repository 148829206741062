import { useCustomer, useMakeCustomer } from './';

import { getURI } from '../../helpers';
import { useCallback } from 'react';
import { useNavigate } from 'react-router';

export function useReceiveCustomer(): void {
  const [, setCustomer] = useCustomer();
  const makeCustomer = useMakeCustomer();
  const navigate = useNavigate();

  return useCallback(
    (rawCustomer: Object, success: boolean, page: TPageNames) => {
      if (!success) {
        return;
      }
      const newCustomer = makeCustomer(rawCustomer);
      setCustomer(newCustomer);
      navigate(getURI(page));
      // analytics.trackUser(customer.id);
    },
    [makeCustomer, setCustomer],
  );
}
