// @flow

import * as React from 'react';

import { Box, Flexbox, RTEText } from './common';

import type { TPageContent } from '../types';

type Props = {
  content?: TPageContent,
};

export default class SimplePage extends React.Component<Props> {
  render(): React.Node {
    const { content } = this.props;
    if (!content) {
      return null;
    }
    const title = Object.keys(content)[0];
    return (
      <Box>
        <Flexbox flexDirection="column">
          <Flexbox element="section">
            <span>
              <RTEText content={content[title]} />
            </span>
          </Flexbox>
        </Flexbox>
      </Box>
    );
  }
}
