import AnalyticsHelper from '../../helpers/AnalyticsHelper';
import { basketState } from '../../helpers/Atoms';
import { useCallback } from 'react';
import { useRecoilState } from 'recoil';

export function useBasket() {
  const [basket, setBasket] = useRecoilState(basketState);
  return [basket, setBasket];
}

export function useBasketItems() {
  const [basket] = useRecoilState(basketState);
  return basket.items;
}

export function useUpdateBasketQty() {
  const [basket, setBasket] = useRecoilState(basketState);
  const items = basket.items;

  return useCallback(
    (hash: string, qty: number) => {
      if (qty <= 0) {
        qty = 0;
      }
      setBasket({
        ...basket,
        items: {
          ...items,
          [hash]: {
            ...items[hash],
            qty,
          },
        },
      });
      // analytics.trackEcommerceEvent('change_quantity', 'Set qty to ' + qty + ' for ' + existingItem.title);
    },
    [basket, items, setBasket],
  );
}

export function useRemoveFromBasket() {
  const [basket, setBasket] = useRecoilState(basketState);
  const items = basket.items;

  return useCallback(
    (hash: string) => {
      const analytics = new AnalyticsHelper();
      const item = items[hash];

      analytics.removeFromCart(item);

      delete items[hash];
      setBasket({
        ...basket,
        items,
      });
    },
    [basket, items, setBasket],
  );
}

export function useBasketComments() {
  const [basket, setBasket] = useRecoilState(basketState);
  const comments = basket.comments;

  const setComments = useCallback(
    (newComments: string) => {
      setBasket({
        ...basket,
        comments: newComments,
      });
      // analytics.trackEcommerceEvent('comments', 'Comment: ' + comments);
    },
    [basket, setBasket],
  );

  return [comments, setComments];
}

export function useSetOrderID() {
  const [basket, setBasket] = useRecoilState(basketState);
  return useCallback(
    (orderID: number) => {
      setBasket({
        ...basket,
        orderID,
      });
    },
    [basket, setBasket],
  );
}

export function getProdNameForBasket(product: TProduct): string {
  return product.name + ' - ' + product.issuer + ' - Set of ' + product.noinset;
}

export function useResetCart() {
  const [basket, setBasket] = useRecoilState(basketState);
  return useCallback(() => {
    setBasket({
      ...basket,
      items: {},
    });
  }, [basket, setBasket]);
}
