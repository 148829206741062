// @flow

import { useCallback, useEffect } from 'react';

import { auctionState } from '../../helpers/Atoms';
import { fetchWithErrHandler } from '../../helpers';
import { useRecoilState } from 'recoil';

export function useAuction() {
  const [auction, setAuctionState] = useRecoilState(auctionState);

  useEffect(() => {
    if (auction.fetchStatus === 'NONE') {
      setAuctionState({ ...auction, fetchStatus: 'FETCHING' });
      fetchWithErrHandler('async/auction/auction.php').then(
        (json) => {
          if (json == null || json.error) {
            setAuctionState({
              ...auction,
              fetchStatus: 'FETCHED',
              error: json?.error ?? 'Unknown error while loading Auction',
            });
            return;
          }
          json.info = json.info ? Object.values(json.info) : {};
          setAuctionState({
            ...auction,
            fetchStatus: 'FETCHED',
            data: json,
          });
        },
        (error) => {
          setAuctionState({
            ...auction,
            fetchStatus: 'FETCHED',
            error: 'Unknown error while loading Auction',
          });
          console.log('An error occured.', error);
        },
      );
    }
  }, [auction, setAuctionState]);

  const placeBid = useCallback(
    (lotNum: string, maxBid: number) => {
      setAuctionState({
        ...auction,
        bids: {
          ...auction.bids,
          [lotNum]: {
            lotnum: lotNum,
            max_bid: maxBid,
          },
        },
      });
    },
    [auction, setAuctionState],
  );

  const deleteBid = useCallback(
    (lotNum: string) => {
      const newBids = {
        ...auction.bids,
      };
      delete newBids[lotNum];
      setAuctionState({
        ...auction,
        bids: {
          ...newBids,
        },
      });
    },
    [auction, setAuctionState],
  );

  const resetBids = useCallback(() => {
    setAuctionState({
      ...auction,
      bids: {},
    });
  }, [auction, setAuctionState]);

  return {
    auction: auction.data,
    isFetching: auction.fetchStatus !== 'FETCHED',
    error: auction.error,
    bids: auction.bids ?? {},
    placeBid,
    deleteBid,
    resetBids,
    setAuctionState,
  };
}
