import { useCallback } from 'react';
import { useCustomer } from './';

export function useSubscriber(): [boolean, () => void] {
  const [customer, setCustomer] = useCustomer();

  const toggleSubscriber = useCallback(() => {
    const updatedIsSubscriber = !customer.isSubscriber;
    fetch(
      'async/customer/newsletter.php?subscribe=' +
        updatedIsSubscriber.toString(),
    ).then(
      (_response) => {
        setCustomer({
          ...customer,
          isSubscriber: updatedIsSubscriber,
        });
      },
      (error) => console.log('An error occured.', error),
    );
  }, [customer, setCustomer]);

  return [customer.isSubscriber, toggleSubscriber];
}
