// @flow

import * as React from 'react';

import { PageNames, getURI } from '../../helpers';
import { useEffect, useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import AccountAddress from './AccountAddress';
import AccountHome from './AccountHome';
import AccountSecurity from './AccountSecurity';
import { Page } from '../common';
import { useCustomerHelper } from '../../hooks/customer';
import { useFetchCustomerOrders } from '../../hooks/customer';

function Account() {
  const customerHelper = useCustomerHelper();
  const fetchCustomerOrders = useFetchCustomerOrders();
  const navigate = useNavigate();
  const params = useParams();

  const isLoggedIn = useMemo(() => {
    return customerHelper.isLoggedIn();
  }, [customerHelper]);

  useEffect(() => {
    if (isLoggedIn && customerHelper.getPreviousOrders() == null) {
      fetchCustomerOrders();
    }
  }, [customerHelper, isLoggedIn, fetchCustomerOrders]);

  useEffect(() => {
    if (!isLoggedIn) {
      navigate(getURI('LOG_IN'));
    }
  }, [navigate, isLoggedIn]);

  const pageComponent = useMemo(() => {
    const { title } = params;
    switch (title) {
      case PageNames.ACCOUNT_ADDRESS.title:
        return <AccountAddress customerHelper={customerHelper} />;
      case PageNames.ACCOUNT_SECURITY.title:
        return <AccountSecurity />;
      default:
        return <AccountHome customerHelper={customerHelper} />;
    }
  }, [params]);

  return <Page>{pageComponent}</Page>;
}

export default Account;
