// @flow

import * as React from 'react';

import { Flexbox, Link } from './common';
import { useCategories, useFetchCategories } from '../hooks/categories';

import Columns from 'react-columns';
import { getCategoryURI } from '../helpers';
import { useEffect } from 'react';

function Footer() {
  const [categories] = useCategories();
  const [isFetching, fetchCategories] = useFetchCategories();

  useEffect(() => {
    if (!Object.keys(categories).length) {
      fetchCategories();
    }
  }, [categories, fetchCategories]);

  let queries = [];
  for (let i = 1; i <= 3; i++) {
    queries.push({
      columns: i,
      query: 'min-width: 150px',
    });
  }

  const navCats = [];
  if (isFetching === false) {
    for (let i in categories) {
      const cat = categories[parseInt(i, 10)];
      if (cat.inNav) {
        navCats.push(cat);
      }
    }
  }

  const cats = navCats
    .sort((a, b) => a.navName.localeCompare(b.navName))
    .map((cat) => {
      return (
        <Link hrefOverride={getCategoryURI(parseInt(cat.id, 10), cat.navName)}>
          {cat.navName}
        </Link>
      );
    });

  return (
    <Flexbox
      flexDirection="row"
      element="section"
      className="content"
      flexGrow={1}
      style={{ padding: '12px' }}
    >
      <Flexbox style={{ marginRight: '12px' }}>
        <dl>
          <dt>About London Cigarette Card Company</dt>
          <dd>
            <Link page="ABOUT_US">About Us</Link>
          </dd>
          <dd>
            <Link page="CONTACT">Contact Us</Link>
          </dd>
          <dd>
            <Link page="VALUING">Valuing &amp; Selling</Link>
          </dd>
          <dd>
            <Link page="PRIVACY">Security &amp; Privacy</Link>
          </dd>
          <dd>
            <Link page="TERMS">Terms &amp; Conditions</Link>
          </dd>
          <dd>
            <Link page="DELIVERY">Delivery &amp; Returns</Link>
          </dd>
        </dl>
      </Flexbox>
      <Flexbox flexGrow={1}>
        <dl>
          <dt>Card Categories</dt>
          <Columns gap="4px" queries={queries}>
            {cats.map((cat, i) => {
              return (
                <Flexbox key={i} flexDirection="row">
                  {cat}
                </Flexbox>
              );
            })}
          </Columns>
        </dl>
      </Flexbox>
      <Flexbox style={{ marginLeft: '12px' }}>
        <div className="security">
          <img src="/images/cc.gif" alt="Secure Online Ordering" />
          <img src="/images/cardsecurity.gif" alt="Secure Online Ordering" />
          <h3 className="phone">24 hour order line 01458 273452</h3>
          <p>
            Copyright &copy; The London Cigarette Card Co. Ltd. Sutton Road,
            Somerton,
            <br />
            Somerset, England TA11 6QP cards@londoncigcard.co.uk
          </p>
        </div>
      </Flexbox>
    </Flexbox>
  );
}

export default Footer;
