// @flow

import * as React from 'react';

import { Box, Button, Flexbox, Link } from '../common';

import AccountPhones from '../account/AccountPhones';
import AddressForm from '../account/AddressForm';
import Addresses from '../account/Addresses';
import AnalyticsHelper from '../../helpers/AnalyticsHelper';
import PostageMessage from './PostageMessage';
import type { TPageContent } from '../../types';
import { useBasketHelper } from '../../hooks/basket';
import { useCustomerHelper } from '../../hooks/customer';

type Props = {
  content?: TPageContent,
  setDelivery: Function,
  inCheckout: boolean,
};

const analytics = new AnalyticsHelper();

export default function BasketAddress(props: Props) {
  const customerHelper = useCustomerHelper();
  const basketHelper = useBasketHelper();

  return (
    <Flexbox flexDirection="column" style={{ width: '100%' }}>
      {_getHeading()}
      <Flexbox>
        <Box>
          <span style={{ width: '100%' }}>
            <Flexbox flexDirection="column">
              <Flexbox flexDirection="row">
                <Flexbox style={{ width: '100%', marginRight: '12px' }}>
                  {_getLeftSide()}
                </Flexbox>
                <Flexbox style={{ width: '100%' }}>{_getRightSide()}</Flexbox>
              </Flexbox>
              <Flexbox>
                <AccountPhones />
              </Flexbox>
            </Flexbox>
          </span>
        </Box>
      </Flexbox>
    </Flexbox>
  );

  function _getHeading(): React.Node {
    return (
      <Flexbox>
        <Box highlight={true}>
          <span>
            <h1>Address Details</h1>
            <div>
              {customerHelper.getFirstName()}, in order to place an order you
              will need to enter your billing and delivery address details.
            </div>
          </span>
        </Box>
      </Flexbox>
    );
  }

  function _getLeftSide(): React.Node {
    let leftContent;
    if (!customerHelper.getBillingAddress()) {
      leftContent = (
        <em>
          Please first add your billing address in the form to the right. Then
          click 'Save Address'
        </em>
      );
    } else {
      const addresses = customerHelper.getAddresses();
      leftContent = (
        <Flexbox flexDirection="column">
          <div style={{ marginBottom: '8px' }}>
            Please select an address that you wish to have your item(s)
            delivered to, or add a new address.
          </div>
          <Addresses
            onSelect={props.setDelivery}
            addresses={addresses}
            inCheckout={true}
          />
        </Flexbox>
      );
    }

    const items = basketHelper.getItems();
    return (
      <Box>
        <span>
          <h3>Delivery Address</h3>
          {leftContent}
          <Flexbox style={{ marginTop: '12px' }}>
            <PostageMessage />
          </Flexbox>
          <Flexbox style={{ marginTop: '12px' }}>
            <Link
              page="BASKET_CONFIRM"
              callback={() => analytics.continueCheckout(items, 'from_address')}
            >
              <Button label="Proceed to Secure Checkout" />
            </Link>
          </Flexbox>
        </span>
      </Box>
    );
  }

  function _getRightSide(): React.Node {
    let heading;
    if (!customerHelper.getBillingAddress()) {
      heading = 'Add Billing Address';
    } else {
      heading = 'Or Add a New Address...';
    }

    return (
      <Box>
        <span style={{ width: '100%' }}>
          <h3>{heading}</h3>
          <AddressForm label="Save Address" />
        </span>
      </Box>
    );
  }
}
