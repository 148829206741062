import { downloadState } from '../../helpers/Atoms';
import { fetchWithErrHandler } from '../../helpers';
import { useCallback } from 'react';
import { useRecoilState } from 'recoil';

export function useDownloads() {
  const [download, setDownloadState] = useRecoilState(downloadState);

  const fetchDownloads = useCallback(
    (email: string) => {
      if (download.fetchStatus !== 'NONE') {
        return;
      }
      setDownloadState({ ...download, fetchStatus: 'FETCHING' });
      return fetchWithErrHandler('async/ccn_downloads.php?email=' + email).then(
        (json) => {
          if (json.error) {
            setDownloadState({
              ...download,
              fetchStatus: 'FETCHED',
              error: json.error,
            });
            return;
          }
          json.downloads = json.downloads.map((download) => {
            download.id = parseInt(download.id, 10);
            return download;
          });
          setDownloadState({
            ...download,
            fetchStatus: 'FETCHED',
            data: json,
          });
          return json;
        },
        (error) => {
          setDownloadState({ ...download, fetchStatus: 'FETCHED', error });
          console.log('An error occured.', error);
        },
      );
    },
    [download, setDownloadState],
  );

  return {
    downloads: download.data,
    isFetching: download.fetchStatus !== 'FETCHED',
    error: download.error,
    fetchDownloads,
  };
}
