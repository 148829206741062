import BasketHelper from '../../helpers/BasketHelper';
import { useBasket } from './';
import { useCustomerHelper } from '../customer';
import { useMemo } from 'react';

export function useBasketHelper(): BasketHelper {
  const [basket] = useBasket();
  const customerHelper = useCustomerHelper();

  return useMemo(() => {
    return new BasketHelper(basket, customerHelper);
  }, [basket, customerHelper]);
}
