//@flow

import type { TBasketItemType, TProduct } from '../../types';

import { bulkProdState } from '../../helpers';
import { getProdNameForBasket } from './';
import { useAlbums } from '../albums';
import { useCCC } from '../ccc';
import { useCallback } from 'react';
import { useCatalogues } from '../catalogues';
import { useOffers } from '../offers';
import { useRecoilCallback } from 'recoil';
import { useSOTMFromSnapshot } from '../sotm';
import { useSpecialRequestSnapshot } from '../requests';

export type TProdMetaData = {
  id: number,
  price: number,
  title: string,
  iShipping: boolean,
  wasPrice: number,
  weight: number,
  vatRate: number,
  typeToUse: string,
};

function useProductFromSnapshot() {
  return useRecoilCallback<[string], Promise<TProduct>>(
    ({ snapshot }) =>
      async (prodID: string) => {
        const products = await snapshot.getPromise(bulkProdState);
        console.log(products);
        return products[prodID].data;
      },
    [],
  );
}

export function useProdMetaForBasket() {
  const [catalogues] = useCatalogues();
  const [albums] = useAlbums();
  const [ccc] = useCCC();
  const { offers } = useOffers();

  const getSpecialRequestFromSnapshot = useSpecialRequestSnapshot();
  const getSOTMFromSnapshot = useSOTMFromSnapshot();
  const getProductFromSnapshot = useProductFromSnapshot();

  const getTypedProdForBasket = useCallback(
    (
      id: number,
      title: string,
      price: number,
      wasPrice: number,
      weight: number,
      iShipping: boolean,
      vatRate: number,
      typeToUse: string,
    ): TProdMetaData => {
      return {
        id,
        price,
        wasPrice,
        title,
        iShipping,
        weight,
        vatRate,
        typeToUse,
      };
    },
    [],
  );

  return useCallback(
    async (key: string, hash: string, type: TBasketItemType) => {
      let iShipping = false; // ignore for shipping calculation?
      let vatRate = 20;

      switch (type) {
        case 'specialRequest':
          return await getSpecialRequestFromSnapshot(parseInt(key, 10)).then(
            (sr) => {
              return getTypedProdForBasket(
                sr.id,
                `Special Request #${sr.id}`,
                sr.price,
                sr.price,
                0,
                true,
                vatRate,
                'specialRequest',
              );
            },
          );
        case 'accessories':
        case 'albums':
        case 'leaves':
        case 'ltAlbums':
        case 'luxuryBinders':
        case 'luxuryLeaves':
        case 'wants':
        case 'wrappings':
        case 'xlAccessories':
          let album;
          if (type === 'albums') {
            const allAlbumProds = [
              albums.accessories,
              albums.albums,
              albums.leaves,
              albums.ltAlbums,
              albums.luxuryBinders,
              albums.luxuryLeaves,
              albums.wants,
              albums.wrappings,
              albums.xlAccessories,
            ];
            allAlbumProds.forEach((albumProds) => {
              const canUse = albumProds.find((a) => a.id === parseInt(key));
              if (canUse != null) {
                album = canUse;
              }
            });
          } else {
            let albumsToUse = albums[type];
            album = albumsToUse.find((a) => a.id === parseInt(key));
          }
          if (album.hash === hash) {
            return getTypedProdForBasket(
              album.id,
              album.name,
              album.price,
              album.price,
              0,
              iShipping,
              vatRate,
              type, // 'albums', // override type
            );
          }
          break;
        case 'offer':
          const offer = offers.offers.find((o) => o.id === parseInt(key));
          if (offer.hash === hash) {
            return getTypedProdForBasket(
              offer.id,
              offer.name,
              offer.price,
              offer.price,
              0,
              iShipping,
              vatRate,
              type,
            );
          }
          break;
        case 'sotm':
          return await getSOTMFromSnapshot(key).then((sotm) => {
            if (sotm.hash === hash || sotm.ccnHash === hash) {
              return getTypedProdForBasket(
                sotm.id,
                sotm.ccnHash === hash
                  ? sotm.title + ' - Card Collectors Club Discount'
                  : sotm.title,
                sotm.ccnHash === hash ? sotm.ccnPrice : sotm.price,
                sotm.price,
                0,
                iShipping,
                vatRate,
                type,
              );
            }
          });
        case 'magazine':
        case 'magazineBinders':
        case 'magazineCurrentIssues':
        case 'subscriptions_digital':
        case 'subscriptions_digital_renew':
        case 'subscriptions_uk':
        case 'subscriptions_uk_renew':
        case 'subscriptions_eu':
        case 'subscriptions_eu_renew':
        case 'subscriptions_row':
        case 'subscriptions_row_renew':
          let cccsToUse;
          if (type === 'magazine') {
            const magazines = [
              ccc.binders,
              ccc.currentIssues,
              [ccc.subscriptions.digital],
              [ccc.subscriptions.digital_renew],
              [ccc.subscriptions.uk],
              [ccc.subscriptions.uk_renew],
              [ccc.subscriptions.eu],
              [ccc.subscriptions.eu_renew],
              [ccc.subscriptions.row],
              [ccc.subscriptions.row_renew],
            ];
            magazines.forEach((magazineProds) => {
              const canUse = magazineProds.find((a) => a.id === parseInt(key));
              if (canUse != null) {
                cccsToUse = canUse;
              }
            });
          } else {
            if (type.startsWith('subscriptions')) {
              const subscriptionType = type.replace('subscriptions_', '');
              cccsToUse = ccc.subscriptions[subscriptionType];
            } else {
              const unforMattedTypeToUse = type.replace('magazine', '');
              const typeToUse =
                unforMattedTypeToUse[0].toLowerCase() +
                unforMattedTypeToUse.slice(1);
              cccsToUse = ccc[typeToUse].find((a) => a.id === parseInt(key));
            }
          }
          return getTypedProdForBasket(
            cccsToUse.id,
            cccsToUse.name,
            cccsToUse.price,
            cccsToUse.price,
            0,
            type.startsWith('subscriptions') || type === 'magazineCurrentIssues'
              ? true
              : false, // iShipping,
            vatRate,
            type, // 'magazine', // override type
          );
        case 'newYearCatalogue':
        case 'catalogue':
          const catalogueProds = [catalogues.nextYear, catalogues.details];
          let catToReturn;
          catalogueProds.forEach((catalogue) => {
            if (catalogue == null) {
              return;
            }
            if (catalogue.hash === hash || catalogue.cccHash === hash) {
              catToReturn = getTypedProdForBasket(
                catalogue.cccHash === hash ? catalogue.cccID : catalogue.id,
                catalogue.cccHash === hash ? catalogue.cccName : catalogue.name,
                catalogue.cccHash === hash
                  ? catalogue.cccPrice
                  : catalogue.price,
                catalogue.price,
                0,
                iShipping,
                vatRate,
                type, // 'catalogue', // override to be catalogue when newYearCat is being used
              );
            }
          });
          return catToReturn;
        case 'product':
          return await getProductFromSnapshot(key).then((product) => {
            if (product.hash === hash) {
              return getTypedProdForBasket(
                product.id,
                getProdNameForBasket(product),
                product.price,
                product.wasPrice,
                product.weight,
                iShipping,
                vatRate,
                type,
              );
            }
          });
        default:
          throw Error('Unexpected Error: Type not implemented - ' + type);
      }
    },
    [
      catalogues,
      albums,
      ccc,
      offers,
      getProductFromSnapshot,
      getSOTMFromSnapshot,
      getSpecialRequestFromSnapshot,
    ],
  );
}
