import * as React from 'react';

import { Box, Flexbox } from '../common';

import ProductList from '../ProductList';
import { useFetchRelatedProducts } from '../../hooks/products';

type Props = {
  productID: Number,
};

export default function RelatedProducts({ productID }: Props) {
  const { relatedProds } = useFetchRelatedProducts(productID);
  if (relatedProds.length === 0) {
    return null;
  }
  return (
    <Flexbox flexDirection="column" style={{ width: '100%' }}>
      <Flexbox>
        <Box>
          <div style={{ width: '100%' }}>
            <h3>
              <em>You may also be interested in:</em>
            </h3>
            <div>
              <ProductList prodIDs={relatedProds} hidePagination={true} />
            </div>
          </div>
        </Box>
      </Flexbox>
    </Flexbox>
  );
}
