// @flow

import type { TProdSortByOptions, TProduct } from '../../types';

export function useFilteredProducts(
  products: $ReadOnlyArray<TProduct>,
  sortBy: TProdSortByOptions,
  filter: string,
  issuer: string,
  sortOverride?: Function,
): Array<TProduct> {
  const filertredProducts: Array<TProduct> = [];
  products.forEach((prod) => {
    let issuerFound = true;
    if (
      issuer.length !== 0 &&
      prod.issuer.toLowerCase().indexOf(issuer.toLowerCase()) === -1
    ) {
      issuerFound = false;
    }
    let filterFound = true;
    if (filter.length !== 0) {
      if (
        prod.name.toLowerCase().indexOf(filter) === -1 &&
        prod.issuer.toLowerCase().indexOf(filter) === -1
      ) {
        filterFound = false;
      }
    }
    if (issuerFound && filterFound) {
      filertredProducts.push(prod);
    }
  });

  if (sortOverride && sortOverride()) {
    return filertredProducts.sort(sortOverride());
  }

  switch (sortBy) {
    case 'price':
      return filertredProducts.sort(sortByPrice());
    case 'issuer':
      return filertredProducts.sort(sortByIssuer());
    case 'name':
    default:
      return filertredProducts.sort(sortByName());
  }
}

function sortByName(): Function {
  return function (a, b) {
    if (a.name < b.name) {
      return -1;
    }
    if (a.name > b.name) {
      return 1;
    }
    return 0;
  };
}

function sortByPrice(): Function {
  return function (a, b) {
    if (a.price < b.price) {
      return -1;
    }
    if (a.price > b.price) {
      return 1;
    }
    if (a.name < b.name) {
      return -1;
    }
    if (a.name > b.name) {
      return 1;
    }
    return 0;
  };
}

function sortByIssuer(): Function {
  return function (a, b) {
    if (a.issuer < b.issuer) {
      return -1;
    }
    if (a.issuer > b.issuer) {
      return 1;
    }
    if (a.name < b.name) {
      return -1;
    }
    if (a.name > b.name) {
      return 1;
    }
    return 0;
  };
}
