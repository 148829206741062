// @flow

import * as React from 'react';

import { Loading, Page } from '../common';

import EmailOfferHome from './EmailOfferHome';
import { useEmailOffer } from '../../hooks/offers';

type Props = {};

function EmailOffer(props: Props) {
  const { isFetching } = useEmailOffer();

  function _getPageComponent(): React.Node {
    if (isFetching) {
      return <Loading />;
    }

    return <EmailOfferHome />;
  }

  return <Page {...props}>{_getPageComponent()}</Page>;
}

export default EmailOffer;
