// @flow
import * as React from 'react';

import { Box, ButtonLink } from '../common';
import { useEffect, useState } from 'react';

import AnalyticsHelper from '../../helpers/AnalyticsHelper';
import { fetchPOST } from '../../helpers';
import { useBasketHelper } from '../../hooks/basket';
import { useCustomerHelper } from '../../hooks/customer';

type Props = {
  setOrderID: Function,
};

const analyticsHelper = new AnalyticsHelper();

export default function BasketPayment(props: Props) {
  const basketHelper = useBasketHelper();
  const customerHelper = useCustomerHelper();

  const [initiated, setInitiated] = useState(false);
  const [readyToSend, setReadyToSend] = useState(false);
  const [request, setRequest] = useState({});
  const [sagepayURL, setSagepayURL] = useState('');
  const [, setFailure] = useState('');

  useEffect(() => {
    if (initiated || readyToSend) {
      // already initiated
      return;
    } else {
      setInitiated(true);
    }
    const params = new URLSearchParams();
    params.set('items', JSON.stringify(basketHelper.getItems()));
    params.set('shipping', basketHelper.getShipping().toString());
    params.set('total', basketHelper.getTotal().toString());
    params.set('bID', customerHelper.getBID().toString());
    params.set('dID', customerHelper.getDID().toString());
    params.set('comments', basketHelper.getComments());
    fetchPOST('async/init_order.php', params)
      .then(
        (response) => response.json(),
        (error) => {
          console.log('An error occured.', error);
          setFailure(error);
        },
      )
      .then((json) => {
        props.setOrderID(json.orderID);
        setSagepayURL(json.sagepayURL);
        setRequest(json.request);
        setReadyToSend(true);
      });
  }, [
    props,
    basketHelper,
    customerHelper,
    initiated,
    props.setOrderID,
    readyToSend,
  ]);

  useEffect(() => {
    if (readyToSend) {
      // $FlowFixMe
      document.SagePayForm.submit();
      analyticsHelper.continueCheckout(
        basketHelper.getItems(),
        'go_to_sagepay',
      );
    }
  }, [basketHelper, readyToSend]);

  if (!readyToSend) {
    return (
      <Box>
        <form>
          <div style={{ textAlign: 'center', width: '100%' }}>
            <h3>
              We are now redirecting you to our Secure Payment Site: SagePay
            </h3>
          </div>
        </form>
      </Box>
    );
  }

  return (
    <Box>
      <form
        action={sagepayURL}
        method="POST"
        id="SagePayForm"
        name="SagePayForm"
      >
        {Object.keys(request).map((key) => {
          const value = request[key];
          return <input type="hidden" name={key} key={key} value={value} />;
        })}
        <div style={{ textAlign: 'center', width: '100%' }}>
          <h3>
            We are now redirecting you to our Secure Payment Site: SagePay
          </h3>
          <p>
            If you haven't been redirected within 5 seconds please click
            proceed.
          </p>
          {/* // $FlowFixMe */}
          <ButtonLink onClick={() => document.SagePayForm.submit()}>
            Proceed
          </ButtonLink>
        </div>
      </form>
    </Box>
  );
}
