// @flow

import * as React from 'react';

import { Loading, Page } from '../common';

import SpecialRequestHome from './SpecialRequestHome';
import { useParams } from 'react-router-dom';
import { useSpecialRequest } from '../../hooks/requests';

function SpecialRequest() {
  const params = useParams();
  const id = parseInt(params.id, 10);

  const { isFetching } = useSpecialRequest(id);

  function _getPageComponent(): React.Node {
    if (isFetching) {
      return <Loading />;
    }

    return <SpecialRequestHome id={id} />;
  }

  return <Page>{_getPageComponent()}</Page>;
}

export default SpecialRequest;
