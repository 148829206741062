// @flow

import * as React from 'react';

import { Loading, Page } from '../common';
import { useCallback, useEffect } from 'react';

import OffersHome from './OffersHome';
import { useLastList } from '../../helpers/ConfigHelper';
import { useOffers } from '../../hooks/offers';

type Props = {};

function Offers(props: Props) {
  const { lastList, setLastList } = useLastList();
  const { fetchOffers, isFetching } = useOffers();

  fetchOffers();

  useEffect((): void => {
    if (lastList !== 'Special Offers') {
      setLastList('Special Offers');
    }
  }, [lastList, setLastList]);

  const getPageComponent = useCallback(() => {
    if (isFetching) {
      return <Loading />;
    }

    return <OffersHome />;
  }, [isFetching]);

  return <Page {...props}>{getPageComponent()}</Page>;
}

export default Offers;
