import { useCallback, useEffect } from 'react';

import { fetchPOST } from '../../helpers';
import { relatedProductsState } from '../../helpers/Atoms';
import { useRecoilState } from 'recoil';

export function useFetchRelatedProducts(prodID: number): void {
  const [relatedProds, setRelatedProds] = useRecoilState(
    relatedProductsState(prodID),
  );

  const fetchRelatedProds = useCallback(() => {
    if (relatedProds.fetchStatus === 'FETCHED') {
      return;
    }

    const searchParams = new URLSearchParams();
    searchParams.set('id', JSON.stringify(prodID));
    setRelatedProds({ ...relatedProds, fetchStatus: 'FETCHING' });
    return fetchPOST('async/products/related.php', searchParams)
      .then(
        (response) => response.json(),
        (error) => {
          setRelatedProds({ ...relatedProds, fetchStatus: 'FETCHED', error });
          console.log('An error occured.', error);
        },
      )
      .then((json) => {
        if (json.error) {
          setRelatedProds({
            ...relatedProds,
            fetchStatus: 'FETCHED',
            error: json.error,
          });
          return;
        }
        setRelatedProds({
          ...relatedProds,
          fetchStatus: 'FETCHED',
          prodIDs: json.map((id) => parseInt(id, 10)),
        });
        return json;
      });
  }, [relatedProds, setRelatedProds, prodID]);

  useEffect(() => {
    if (relatedProds.fetchStatus === 'NONE') {
      fetchRelatedProds();
    }
  }, [relatedProds.fetchStatus, fetchRelatedProds]);

  return {
    relatedProds: relatedProds.prodIDs,
    isFetching: relatedProds.fetchStatus !== 'FETCHED',
    error: relatedProds.error,
  };
}
