import { useCallback, useEffect } from 'react';

import { fetchWithErrHandler } from '../../helpers';
import { searchState } from '../../helpers/Atoms';
import { useRecoilState } from 'recoil';

export function useSearch(searchString: string) {
  const [search, setSearchState] = useRecoilState(searchState(searchString));

  const fetchSearch = useCallback(() => {
    if (search.fetchStatus === 'FETCHED') {
      return;
    }
    setSearchState({ ...search, fetchStatus: 'FETCHING' });
    return fetchWithErrHandler('async/search.php?search=' + searchString).then(
      (json) => {
        if (json.error) {
          setSearchState({
            ...search,
            fetchStatus: 'FETCHED',
            error: json.error,
          });
          return;
        }
        json.prodIDs = json.prodIDs.map((id) => parseInt(id, 10));
        setSearchState({
          ...search,
          fetchStatus: 'FETCHED',
          data: json,
        });
        return json;
      },
      (error) => {
        setSearchState({ ...search, fetchStatus: 'FETCHED', error });
        console.log('An error occured.', error);
      },
    );
  }, [search, setSearchState, searchString]);

  useEffect(() => {
    if (search.fetchStatus === 'NONE') {
      fetchSearch();
    }
  }, [search.fetchStatus, fetchSearch]);

  return {
    search: search.data,
    isFetching: search.fetchStatus !== 'FETCHED',
    error: search.error,
  };
}
