// @flow

import * as React from 'react';

import { Box, Flexbox, Link, Loading, Page } from '../common';
import { useBasket, useFetchAndRestoreCart } from '../../hooks/basket';
import { useEffect, useState } from 'react';

import { useParams } from 'react-router';

function RestoreCart() {
  const [basket] = useBasket();
  const fetchCart = useFetchAndRestoreCart();
  const [startedFetch, setStartedFetch] = useState(false);
  const params = useParams();

  useEffect(() => {
    if (startedFetch === false) {
      setStartedFetch(true);
      fetchCart(parseInt(params.id, 10), params.hash);
    }
  }, [fetchCart, params.hash, params.id, startedFetch, setStartedFetch]);

  function _getPageComponent(): React.Node {
    if (!basket.restoration || basket.restoration.isFetching) {
      return <Loading />;
    }

    return (
      <Flexbox flexDirection="column" element="section">
        <Box highlight={true}>
          <div>
            <span>
              <h2>Basket Restored</h2>
              <span>
                Your basket has been restored,{' '}
                <Link page="BASKET">click here</Link> to view your basket
              </span>
            </span>
          </div>
        </Box>
      </Flexbox>
    );
  }

  return <Page>{_getPageComponent()}</Page>;
}

export default RestoreCart;
