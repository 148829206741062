// @flow

import passwordValidator from 'password-validator';

export function validatePassword(password: string): Array<string> {
  const schema = new passwordValidator();
  const minPassLength = 8;
  const maxPassLength = 30;
  schema
    .is()
    .min(minPassLength) // Minimum length 8
    .is()
    .max(maxPassLength) // Maximum length 100
    .has()
    .uppercase() // Must have uppercase letters
    .has()
    .lowercase() // Must have lowercase letters
    // .has().digits()                                 // Must have digits
    .has()
    .not()
    .spaces() // Should not have spaces
    .is()
    .not()
    .oneOf(['Passw0rd', 'Password123']); // Blacklist these values

  const errors = [];
  const passwordFailures = schema.validate(password, { list: true });
  passwordFailures.forEach((failure) => {
    switch (failure) {
      case 'min':
        errors.push(
          'Your password must be at least ' +
            minPassLength +
            ' characters long',
        );
        break;
      case 'max':
        errors.push(
          'Your password must no longer tha ' +
            maxPassLength +
            'characters long',
        );
        break;
      case 'uppercase':
        errors.push('Your password must have at least 1 uppercase letter');
        break;
      case 'lowercase':
        errors.push('Your password must have at least 1 lowercase letter');
        break;
      case 'spaces':
        errors.push('Your password can not contain spaces');
        break;
      default:
        errors.push(
          'Your password does not meet our requirements, please enter a different password',
        );
        break;
    }
  });

  return errors;
}
