// @flow

import * as React from 'react';

import {
  Box,
  Button,
  ButtonLink,
  Flexbox,
  Link,
  Price,
  RTEText,
} from '../common';
import { PageHelpers, getProdURIFromType } from '../../helpers';
import type { TBasketItem, TCustomerAddress, TPageContent } from '../../types';
import { useBasketHelper, useBasketItems } from '../../hooks/basket';

import AnalyticsHelper from '../../helpers/AnalyticsHelper';
import { useCustomerHelper } from '../../hooks/customer';
import { useNavigate } from 'react-router';

type Props = {
  content?: TPageContent,
  confirmBasket: boolean,
  updateBasketQty: Function,
  removeFromBasket: Function,
  updateBasketComments: Function,
  setCCC: Function,
  setSubscriber: Function,
};

function BasketTable(props: Props) {
  const basketHelper = useBasketHelper();
  const customerHelper = useCustomerHelper();
  const items = useBasketItems();
  const navigate = useNavigate();

  return (
    <Flexbox flexDirection="column">
      {_getHeading()}
      {_getAddressDetails()}
      <Flexbox>
        <table id="prodlist">
          <thead>
            <tr valign="top">
              <td>Product</td>
              <td width="90" align="right">
                Price
              </td>
              <td width="70" align="center">
                Qty
              </td>
              <td width="100" align="right">
                Total
              </td>
            </tr>
          </thead>
          <tbody>
            {_getEmptyMessage()}
            {Object.values(items).map((product) => {
              const hash = product.hash;
              return (
                <tr key={hash}>
                  <td valign="top">
                    <Link
                      hrefOverride={getProdURIFromType(
                        product.key,
                        product.title,
                        product.type,
                      )}
                    >
                      {product.title}
                    </Link>
                  </td>
                  <td valign="top" align="right">
                    {product.wasPrice !== product.price &&
                    product.wasPrice > 0 ? (
                      <Price price={product.wasPrice} strikethrough={true} />
                    ) : null}{' '}
                    <Price price={product.price} />
                  </td>
                  <td valign="top" align="center">
                    {_getQty(product)}
                  </td>
                  <td valign="top" className="highlight" align="right">
                    <Price price={product.price * product.qty} />
                  </td>
                </tr>
              );
            })}
            <tr>
              <td valign="top" align="right" colSpan="3">
                Tick this box if you are a Card Collectors Club member
              </td>
              {/* TODO don't allow click if customer IS a member and we know this from the DB */}
              <td valign="top" className="highlight" align="right">
                <input
                  type="checkbox"
                  name="ccc_member"
                  checked={customerHelper.isCCCMember()}
                  onChange={props.setCCC}
                />
              </td>
            </tr>
            {_getNewsletterRow()}
            <tr>
              <td valign="top" align="right" colSpan="3">
                Shipping (see below *)
              </td>
              <td valign="top" className="highlight" align="right">
                {_getShippingValue()}
              </td>
            </tr>
            <tr>
              <td
                valign="top"
                align="right"
                colSpan="3"
                style={{ verticalAlign: 'top' }}
              >
                {!props.confirmBasket && (
                  <Button
                    style={{ float: 'left' }}
                    label="Continue Shopping"
                    onClick={() => navigate(-1)}
                  />
                )}
                Grand Total
              </td>
              <td
                valign="top"
                className="highlight"
                align="right"
                style={{ verticalAlign: 'top' }}
              >
                <strong>
                  <Price price={basketHelper.getTotal()} />
                </strong>
              </td>
            </tr>
          </tbody>
        </table>
      </Flexbox>
      <Flexbox style={{ justifyContent: 'flex-end' }}>
        {_getProceedButton()}
      </Flexbox>
      <Flexbox style={{ marginTop: '12px' }}>
        <Box>
          <span>
            {_getComments()}
            {_getNonUKNotice()}
            {_getHandlingFeeNotice()}
            <img src="/images/cc.gif" alt="Secure Online Ordering" />
            <img src="/images/cardsecurity.gif" alt="Secure Online Ordering" />
          </span>
        </Box>
      </Flexbox>
    </Flexbox>
  );

  function _getNewsletterRow() {
    const { customerHelper } = props;
    if (!customerHelper.getEmail()) {
      return null;
    }
    return (
      <tr>
        <td valign="top" align="right" colSpan="3">
          Tick this box if you would like to subscribe to our newsletter
        </td>
        <td valign="top" className="highlight" align="right">
          <input
            type="checkbox"
            name="newsletter_subscriber"
            checked={customerHelper.isSubscriber()}
            onChange={props.setSubscriber}
          />
        </td>
      </tr>
    );
  }

  function _getEmptyMessage(): React.Node {
    if (Object.values(items).length > 0) {
      return null;
    }
    return (
      <tr>
        <td colSpan="4" style={{ textAlign: 'center' }}>
          <strong>Your basket is empty</strong>
        </td>
      </tr>
    );
  }

  function _getHeading(): React.Node {
    const { content } = props;
    const heading = props.confirmBasket
      ? PageHelpers.getContent(content, 'Review Basket')
      : PageHelpers.getContent(content, 'Intro');
    return (
      <Flexbox>
        <Box highlight={true}>
          <span style={{ width: '100%' }}>
            <RTEText content={heading} />
          </span>
        </Box>
      </Flexbox>
    );
  }

  function _getAddressDetails(): React.Node {
    if (props.confirmBasket) {
      return null;
    }

    const { customerHelper } = props;
    const billingAddress = customerHelper.getBillingAddress();
    const deliveryAddress = customerHelper.getDeliveryAddress();

    if (!billingAddress || !deliveryAddress) {
      return null;
    }
    return (
      <Flexbox>
        <Box>
          <Flexbox flexDirection="row" flexGrow={1}>
            <Flexbox flexGrow={1}>
              <span>
                <h3>Your Billing Address</h3>
                {_getAddress(billingAddress)}
              </span>
            </Flexbox>
            <Flexbox flexGrow={1}>
              <span>
                <h3>Your Delivery Address</h3>
                {_getAddress(deliveryAddress)}
              </span>
            </Flexbox>
          </Flexbox>
        </Box>
      </Flexbox>
    );
  }

  function _getAddress(address: TCustomerAddress): React.Node {
    return (
      <Flexbox flexDirection="column">
        <Flexbox>{address.line1}</Flexbox>
        <Flexbox>{address.line2}</Flexbox>
        <Flexbox>{address.county}</Flexbox>
        <Flexbox>{address.postcode}</Flexbox>
        <Flexbox>{address.country}</Flexbox>
        <Flexbox style={{ marginTop: '8px' }}>
          <span>
            <Link page="BASKET_ADDRESS">Click here</Link> to change/edit this
            address
          </span>
        </Flexbox>
      </Flexbox>
    );
  }

  function _getQty(product: TBasketItem): React.Node {
    if (props.confirmBasket) {
      return product.qty;
    }

    return (
      <Flexbox flexDirection="row">
        <Flexbox style={{ marginRight: '4px' }}>
          <ButtonLink
            onClick={() => props.updateBasketQty(product.hash, product.qty - 1)}
          >
            -
          </ButtonLink>
        </Flexbox>
        <Flexbox>
          <input
            size="4"
            type="text"
            style={{ textAlign: 'center' }}
            value={product.qty}
            onChange={(e) => {
              let qty = parseInt(e.target.value, 10);
              if (isNaN(qty)) {
                qty = 0;
              }
              props.updateBasketQty(product.hash, qty);
            }}
          />
        </Flexbox>
        <Flexbox style={{ marginLeft: '4px' }}>
          <ButtonLink
            onClick={() => props.updateBasketQty(product.hash, product.qty + 1)}
          >
            +
          </ButtonLink>
        </Flexbox>
        <Flexbox style={{ marginLeft: '4px' }}>
          <ButtonLink onClick={() => props.removeFromBasket(product.hash)}>
            remove
          </ButtonLink>
        </Flexbox>
      </Flexbox>
    );
  }

  function _getProceedButton(): React.Node {
    if (props.basketHelper.getItems().size === 0) {
      return null;
    }

    const analytics = new AnalyticsHelper();
    const items = props.basketHelper.getItems();
    const onStart = () => {
      analytics.beginCheckout(items);
    };
    const onPayment = () => {
      analytics.continueCheckout(items, 'go_to_payment');
    };
    return props.confirmBasket ? (
      <Link page="BASKET_PAYMENT" callback={onPayment}>
        <Button label="Proceed With Secure Payment" />
      </Link>
    ) : (
      <Link page="BASKET_CONFIRM" callback={onStart}>
        <Button label="Proceed to Secure Checkout" />
      </Link>
    );
  }

  function _getComments(): React.Node {
    return (
      <Flexbox style={{ marginBottom: '20px' }}>
        <Flexbox
          flexDirection="row"
          style={{ width: '220px', marginRight: '12px' }}
        >
          If you have any extra requirements/questions please enter them in the
          box here:
        </Flexbox>
        <Flexbox>
          <textarea
            style={{ width: '350px', height: '80px' }}
            onChange={(e: SyntheticEvent<HTMLButtonElement>) =>
              props.updateBasketComments(e.currentTarget.value)
            }
            defaultValue={props.basketHelper.getComments()}
          ></textarea>
        </Flexbox>
      </Flexbox>
    );
  }

  function _getNonUKNotice(): React.Node {
    if (props.customerHelper.isFreeShippingAvailable()) {
      return null;
    }

    const { content } = props;
    return <RTEText content={PageHelpers.getContent(content, 'Non UK')} />;
  }

  function _getHandlingFeeNotice(): React.Node {
    if (!props.customerHelper.isFreeShippingAvailable()) {
      return null;
    }

    const { content } = props;
    return (
      <RTEText content={PageHelpers.getContent(content, 'Shipping Notice')} />
    );
  }

  function _getShippingValue(): React.Node {
    const shipping = props.basketHelper.getShipping();
    if (shipping === 0) {
      return <em>FREE</em>;
    }

    return <Price price={shipping} />;
  }
}

export default BasketTable;
