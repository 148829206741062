// @flow

import type { TSpecialRequest } from '../../types';
import { specialRequestState } from '../../helpers/Atoms';
import { useRecoilCallback } from 'recoil';

export function useSpecialRequestSnapshot() {
  return useRecoilCallback<[number], Promise<TSpecialRequest>>(
    ({ snapshot }) =>
      async (id: number) => {
        const specialRequest = await snapshot.getPromise(
          specialRequestState(id),
        );
        return specialRequest.data;
      },
    [],
  );
}
