// @flow

import * as EmailValidator from 'email-validator';
import * as React from 'react';

import { Box, Flexbox, Form, Page } from './common';

import { getFetchURI } from '../helpers';

type Props = {};

class Newsletter extends React.Component<Props> {
  render(): React.Node {
    return (
      <Page {...this.props}>
        <NewsletterSubscription />
      </Page>
    );
  }
}

class NewsletterSubscription extends React.Component<{}> {
  render(): React.Node {
    return (
      <Flexbox flexDirection="column">
        <Flexbox element="section">
          <Box highlight={true}>
            <h2>Sign up to our Email Newsletter</h2>
          </Box>
        </Flexbox>
        <Flexbox element="section">
          <Box>
            <Flexbox flexDirection="column">
              <Flexbox style={{ marginBottom: '12px' }}>
                Signup to our email newsletter for special offers and updates to
                our site.
              </Flexbox>
              <Form
                onValidate={this._handleValidation.bind(this)}
                honeyPot={true}
                uri={getFetchURI('async/customer/newsletter.php')}
                success={
                  <div>
                    Thanks for subscribing to our newsletter, look out for an
                    email soon.
                  </div>
                }
                className="contactform"
              >
                <dl className="small">
                  <dd>
                    <label htmlFor="name">Name</label>
                    <input type="text" name="name" id="name" />
                  </dd>
                  <dd>
                    <label htmlFor="email">Email</label>
                    <input type="text" name="email" id="email" />
                  </dd>
                </dl>
              </Form>
            </Flexbox>
          </Box>
        </Flexbox>
      </Flexbox>
    );
  }

  _handleValidation(values: Object): Array<string> {
    let errors = [];

    if (!values.email) {
      errors.push('Please enter either your email address');
    }

    if (values.email) {
      const isEmailValid = EmailValidator.validate(values.email);
      if (!isEmailValid) {
        errors.push('Please enter a valid email address.');
      }
    }

    return errors;
  }
}

export default Newsletter;
